import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080 1080">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
		<g viewBox="0 0 980.000000 980.000000"  transform="">

<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M1.000000,403.000000 
	C1.000000,268.682098 1.000000,134.864227 1.000000,1.023165 
	C360.943024,1.023165 720.886047,1.023165 1080.914551,1.023165 
	C1080.914551,360.901459 1080.914551,720.802979 1080.914551,1080.852295 
	C721.000061,1080.852295 361.000031,1080.852295 1.000000,1080.852295 
	C1.000000,855.113525 1.000000,629.306763 1.000000,403.000000 
M152.143372,301.613922 
	C124.343163,347.019531 104.780617,395.700500 93.866638,447.843414 
	C82.166786,503.740936 81.039864,559.861938 90.367310,616.171509 
	C98.540276,665.511536 114.459236,712.298767 138.103271,756.341797 
	C161.659943,800.222107 191.511642,839.319275 227.879730,873.509949 
	C257.663391,901.510132 290.523438,925.085205 326.525177,944.269470 
	C378.705811,972.074951 434.394897,988.718384 493.114594,995.052551 
	C527.004211,998.708191 560.905273,998.356384 594.803406,994.220398 
	C636.393494,989.145813 676.613342,978.861511 715.239563,962.783203 
	C775.171265,937.836426 827.586975,901.811829 872.220825,854.557007 
	C905.278809,819.557617 932.185364,780.323181 952.809448,736.873352 
	C972.784302,694.791260 986.031311,650.628906 992.477234,604.430237 
	C997.964722,565.101135 998.484741,525.726868 993.618652,486.354980 
	C987.068420,433.356812 971.813721,382.961823 947.715942,335.218628 
	C925.697021,291.594299 897.317871,252.607910 862.831726,218.122833 
	C834.466309,189.758224 802.756714,165.662582 767.921997,145.642380 
	C719.176270,117.627296 667.076904,99.023300 611.619568,90.281738 
	C570.624329,83.819801 529.387024,83.241310 488.072235,87.888573 
	C429.916412,94.430176 375.307800,112.085312 323.829437,139.662445 
	C294.398895,155.428497 267.092377,174.329590 241.882843,196.234070 
	C206.847046,226.676544 176.912140,261.470123 152.143372,301.613922 
z"/>
<path fill="#683BB6" opacity="1.000000" stroke="none" 
	d="
M152.364929,301.327087 
	C176.912140,261.470123 206.847046,226.676544 241.882843,196.234070 
	C267.092377,174.329590 294.398895,155.428497 323.829437,139.662445 
	C375.307800,112.085312 429.916412,94.430176 488.072235,87.888573 
	C529.387024,83.241310 570.624329,83.819801 611.619568,90.281738 
	C667.076904,99.023300 719.176270,117.627296 767.921997,145.642380 
	C802.756714,165.662582 834.466309,189.758224 862.831726,218.122833 
	C897.317871,252.607910 925.697021,291.594299 947.715942,335.218628 
	C971.813721,382.961823 987.068420,433.356812 993.618652,486.354980 
	C998.484741,525.726868 997.964722,565.101135 992.477234,604.430237 
	C986.031311,650.628906 972.784302,694.791260 952.809448,736.873352 
	C932.185364,780.323181 905.278809,819.557617 872.220825,854.557007 
	C827.586975,901.811829 775.171265,937.836426 715.239563,962.783203 
	C676.613342,978.861511 636.393494,989.145813 594.803406,994.220398 
	C560.905273,998.356384 527.004211,998.708191 493.114594,995.052551 
	C434.394897,988.718384 378.705811,972.074951 326.525177,944.269470 
	C290.523438,925.085205 257.663391,901.510132 227.879730,873.509949 
	C191.511642,839.319275 161.659943,800.222107 138.103271,756.341797 
	C114.459236,712.298767 98.540276,665.511536 90.367310,616.171509 
	C81.039864,559.861938 82.166786,503.740936 93.866638,447.843414 
	C104.780617,395.700500 124.343163,347.019531 152.364929,301.327087 
M725.947144,388.052673 
	C725.947144,388.052673 726.009949,388.066833 726.179138,388.510742 
	C726.366638,388.778961 726.612793,388.972626 727.035522,389.709656 
	C727.668091,390.158051 728.300659,390.606415 729.055664,391.681458 
	C729.695068,392.140076 730.334412,392.598724 731.059021,393.737122 
	C733.702881,396.500183 736.346741,399.263245 739.075195,402.653381 
	C739.694946,403.100372 740.314697,403.547394 740.959412,404.051117 
	C740.959412,404.051117 741.019775,404.064911 741.184326,404.501526 
	C741.365051,404.766571 741.603088,404.961639 742.025513,405.774261 
	C744.312073,408.553345 746.598633,411.332428 748.983643,414.676147 
	C749.312866,415.116699 749.642090,415.557251 750.400391,416.536652 
	C751.281677,417.692108 752.163025,418.847565 753.031433,420.587372 
	C753.343628,421.037415 753.655823,421.487457 753.991943,421.992737 
	C753.991943,421.992737 754.041992,422.026093 754.294617,422.656708 
	C758.495056,429.538727 762.485168,436.562256 766.981201,443.245392 
	C769.367126,446.791870 770.775330,451.332855 776.996704,451.170135 
	C794.277710,450.718201 811.579102,450.913971 828.870239,451.082458 
	C832.769958,451.120453 833.996704,449.128174 832.751587,446.267975 
	C830.597412,441.319733 827.648315,436.717560 824.945862,431.242859 
	C824.120544,429.448822 823.439758,427.570190 822.447266,425.874054 
	C813.739075,410.991394 803.900146,396.970337 792.659302,383.810944 
	C781.351501,370.573303 768.782715,358.751801 755.366028,347.834381 
	C747.856567,341.723755 739.530762,336.594513 731.419739,331.255585 
	C728.199707,329.136047 724.565430,327.645813 720.988037,325.181122 
	C718.132996,323.344238 715.427429,321.200531 712.396790,319.724091 
	C699.933228,313.652313 687.656006,307.052795 674.772644,302.024658 
	C658.637390,295.727356 641.805298,291.639587 624.671387,288.512939 
	C609.255859,285.699829 593.804810,284.492004 578.244629,284.109344 
	C570.803040,283.926331 563.309387,284.352051 555.891296,285.052032 
	C543.062439,286.262482 530.179382,287.341827 517.490540,289.489349 
	C497.506653,292.871521 478.349548,299.406281 459.717102,307.265656 
	C450.940216,310.967865 442.342377,315.311493 434.198700,320.247620 
	C421.631226,327.865143 409.436676,336.113464 397.276276,344.377228 
	C393.835876,346.715210 391.065094,350.038574 387.301178,353.040375 
	C377.605927,362.472443 367.874817,371.868134 358.241821,381.363373 
	C355.974915,383.597870 358.040741,383.968506 359.760376,384.714325 
	C359.323730,385.137848 358.887085,385.561401 357.819641,385.860718 
	C357.610504,385.816681 357.401398,385.772675 356.695190,385.311707 
	C349.369781,389.605652 344.836029,396.120239 341.215027,404.006561 
	C340.068756,405.148132 338.705109,406.142487 337.814728,407.457336 
	C334.387451,412.518402 330.931671,417.575714 327.824005,422.833862 
	C323.770569,429.692322 319.832977,436.639221 316.309875,443.777222 
	C315.420990,445.578064 315.386108,449.321289 316.545624,450.308929 
	C319.318665,452.670990 321.444183,449.848450 322.769623,447.615112 
	C326.372894,441.543701 329.699219,435.307892 333.776947,428.938568 
	C335.182404,426.610229 336.587860,424.281860 338.327881,421.369598 
	C340.731262,418.221313 343.195618,415.117249 345.521729,411.912842 
	C348.108032,408.350006 350.568329,404.695679 353.771912,400.985443 
	C358.869720,395.867767 363.597748,390.301331 369.145508,385.729614 
	C379.416595,377.265472 389.897583,368.999420 400.783752,361.352081 
	C415.761871,350.830231 431.735168,342.091370 448.874481,335.329773 
	C465.261414,328.865021 481.955933,324.025055 499.223877,320.564148 
	C514.435913,317.515228 529.745300,316.540802 545.119324,316.107819 
	C554.230652,315.851166 563.416687,316.264160 572.489563,317.171265 
	C582.017761,318.123840 591.621216,319.348724 600.884155,321.677887 
	C613.533752,324.858551 626.039673,328.783447 638.328735,333.169464 
	C647.952515,336.604218 657.769531,340.223450 666.405396,345.539886 
	C681.403931,354.773407 695.613220,365.300781 710.033142,375.456390 
	C715.169922,379.074036 719.957092,383.187866 725.139709,387.491364 
	C725.338623,387.761963 725.603149,387.928131 725.947144,388.052673 
M360.960297,770.421265 
	C360.333313,769.921082 359.706329,769.420898 358.953796,768.235413 
	C356.999268,766.749756 355.044708,765.264099 352.752869,763.195496 
	C350.841644,760.802795 348.930450,758.410034 346.923431,755.371948 
	C346.288208,754.876770 345.652985,754.381592 344.993774,753.465454 
	C344.993774,753.465454 344.941437,753.047058 344.941986,752.301453 
	C343.965485,751.525452 342.988983,750.749451 341.996124,749.998474 
	C341.996124,749.998474 342.025513,749.994690 342.011841,749.259033 
	C340.363098,747.770386 338.714355,746.281738 336.735077,744.212769 
	C330.804932,735.926758 324.722687,727.743896 319.003143,719.315002 
	C315.699738,714.446777 312.981567,709.181458 310.011200,703.365540 
	C309.364868,702.504822 308.718536,701.644165 308.077148,700.432190 
	C308.077148,700.432190 308.039246,700.083069 308.025909,699.334839 
	C307.075989,698.501892 306.126099,697.669006 305.125397,696.143921 
	C305.091248,695.450745 305.057068,694.757629 305.017853,693.342407 
	C304.363281,692.499023 303.708740,691.655640 303.057220,690.450989 
	C303.057220,690.450989 303.018829,690.091736 302.834198,689.568542 
	C302.533264,689.368408 302.232300,689.168274 301.425476,688.477661 
	C301.356995,688.325806 301.264191,688.180237 301.223419,688.021240 
	C298.533905,677.529846 296.639923,676.024414 286.170593,676.049377 
	C267.586670,676.093689 249.001816,676.052124 230.419891,676.266846 
	C227.554855,676.299927 225.215164,677.920288 226.368973,681.565918 
	C227.214859,684.238647 227.909210,686.993530 229.079147,689.523132 
	C232.943054,697.877502 236.647980,706.334167 241.054932,714.401428 
	C246.918747,725.135620 252.784103,735.957642 259.741394,745.983765 
	C268.791565,759.025879 278.578979,771.601746 288.713379,783.827637 
	C294.002289,790.208069 300.714539,795.408752 306.987549,801.791992 
	C309.630188,804.223633 312.272858,806.655212 315.067627,809.742432 
	C318.015900,812.489563 320.878021,815.338379 323.949738,817.939819 
	C325.438110,819.200256 327.319641,819.996521 329.613007,821.066528 
	C330.005737,821.090149 330.398438,821.113708 330.869324,821.864502 
	C331.695679,823.030640 332.303253,824.483215 333.387634,825.311584 
	C337.308990,828.307068 341.473145,830.984558 345.397675,833.976196 
	C363.665253,847.901123 383.567871,859.094360 404.592102,868.108765 
	C428.813416,878.493896 453.567230,887.589844 479.901520,891.074707 
	C497.432861,893.394653 515.013611,895.976807 532.644653,896.717651 
	C548.337646,897.377136 564.218567,896.477234 579.854126,894.774353 
	C595.749634,893.043091 611.658264,890.339966 627.167908,886.481445 
	C641.214661,882.986816 654.944458,877.895020 668.416809,872.513245 
	C680.010986,867.881653 691.103271,861.964417 702.296082,856.372681 
	C706.401855,854.321655 710.186707,851.616150 714.070068,849.134277 
	C723.650085,843.011719 733.433044,837.170593 742.694397,830.596375 
	C750.325012,825.179810 757.486267,819.059448 764.544617,812.894958 
	C769.013428,808.992004 772.911743,804.435852 777.786682,800.059753 
	C781.524109,796.845886 785.603455,793.947815 788.925537,790.350464 
	C795.245911,783.506653 801.238831,776.348694 807.116455,769.116943 
	C808.361389,767.585205 808.528198,765.177185 809.905823,763.095398 
	C812.586243,759.915039 815.552612,756.930664 817.892639,753.516968 
	C824.506287,743.868713 831.426941,734.363403 837.172302,724.205383 
	C843.721191,712.626770 849.284180,700.474854 854.875977,688.387146 
	C855.728760,686.543884 855.052551,682.033630 854.000793,681.638550 
	C850.915649,680.479309 849.461182,683.126282 848.954041,686.778015 
	C847.169678,689.422729 845.196655,691.962097 843.647339,694.737915 
	C841.560364,698.476929 840.112305,702.595032 837.839050,706.203308 
	C831.673401,715.989624 825.907227,726.144470 818.716370,735.141052 
	C807.576355,749.078369 796.303955,763.146973 783.386902,775.361328 
	C770.007019,788.013428 755.068909,799.191772 739.944763,809.774841 
	C725.659851,819.770752 710.492126,828.587158 693.760132,834.259338 
	C678.951660,839.279541 664.384155,845.064209 649.433228,849.597412 
	C628.570068,855.923218 607.005981,858.935059 585.248779,858.798340 
	C567.205688,858.684875 549.153503,857.233337 531.144287,855.862854 
	C523.768433,855.301575 516.419006,853.717102 509.194641,852.015991 
	C500.702789,850.016479 492.248535,847.716797 483.979126,844.946838 
	C472.366150,841.056763 460.670013,837.214111 449.512604,832.225769 
	C439.771423,827.870483 430.578949,822.202209 421.415314,816.665039 
	C412.382538,811.206848 403.577301,805.348938 394.887421,799.354431 
	C388.798248,795.153992 383.070526,790.429626 376.895020,785.506653 
	C376.606110,785.328125 376.317200,785.149597 376.021118,784.974976 
	C376.021118,784.974976 376.037994,784.957520 375.917023,784.286865 
	C374.132141,782.073975 372.254517,780.108948 368.943390,781.656921 
	C368.943390,781.656921 368.956482,781.545532 369.027710,780.769653 
	C368.724030,779.847229 368.420349,778.924744 367.808655,777.383423 
	C365.560699,775.521179 363.312744,773.658997 361.056335,771.437500 
	C361.056335,771.437500 361.023560,771.079590 360.960297,770.421265 
M467.554901,800.859375 
	C467.554901,800.859375 467.107086,800.781677 466.914734,800.100830 
	C453.856628,794.107117 440.808685,788.091064 427.733215,782.135559 
	C424.525818,780.674744 421.228119,779.412231 417.559753,777.982849 
	C417.559753,777.982849 417.143127,777.933899 416.965302,777.254333 
	C415.350037,776.459839 413.734741,775.665344 411.984406,774.178223 
	C408.267365,771.565857 404.511200,769.007141 400.839966,766.331909 
	C384.637817,754.525085 369.022491,742.007385 356.115906,726.544739 
	C348.171936,717.027588 340.831207,707.009094 333.092010,697.317566 
	C330.763763,694.401978 328.006836,691.828735 325.341461,689.055054 
	C325.341461,689.055054 325.235870,688.992188 324.952423,688.539185 
	C324.683685,688.311646 324.414948,688.084167 324.088837,687.534668 
	C324.088837,687.534668 324.067444,687.208191 324.753784,687.029114 
	C327.232849,682.238770 323.104279,679.992249 320.713928,677.368774 
	C318.198517,674.607971 316.205627,676.528992 315.209198,679.146362 
	C314.070892,682.136292 317.638916,687.896057 321.001526,688.999329 
	C321.001526,688.999329 321.013855,688.988586 320.942871,689.793091 
	C322.649200,692.848450 324.355560,695.903870 326.059906,698.937927 
	C326.059906,698.937927 326.086334,698.916626 326.016602,699.673645 
	C326.134338,700.322754 326.063660,701.096130 326.397583,701.602783 
	C333.756378,712.769409 341.100739,723.947021 348.644470,734.988159 
	C349.422852,736.127380 351.441162,736.419434 352.918732,737.847168 
	C353.411804,739.186584 353.616791,740.748901 354.449646,741.825439 
	C357.452820,745.707092 360.335693,749.779297 363.890839,753.110535 
	C367.862366,756.832092 372.516510,759.825073 376.920837,763.849121 
	C377.895660,765.336670 378.588776,767.158997 379.892395,768.255798 
	C386.831207,774.094238 393.554382,780.296082 401.039307,785.355774 
	C416.061737,795.510620 430.914795,806.196899 446.963409,814.483887 
	C468.473145,825.590698 491.610626,832.947021 515.414490,837.896545 
	C533.635803,841.685242 552.074341,843.499084 570.641113,843.865234 
	C578.424133,844.018738 586.248718,843.571716 594.011108,842.898621 
	C604.383240,841.999207 614.789795,841.061646 625.039124,839.307617 
	C634.595703,837.672119 643.984924,835.023071 653.409485,832.666931 
	C657.741760,831.583862 662.143372,830.514648 666.234985,828.781433 
	C679.784302,823.041626 693.154907,816.880737 706.691345,811.109436 
	C723.439636,803.968750 738.309021,793.603333 752.424805,782.538025 
	C765.496277,772.291443 777.310303,760.302795 788.873779,748.306091 
	C802.599548,734.066162 812.951538,717.188354 823.190308,700.359314 
	C826.704285,694.583618 829.638367,688.403625 832.299927,682.178528 
	C834.112061,677.940308 832.752930,676.120178 828.223450,676.091736 
	C811.066833,675.983948 793.907654,675.950378 776.753235,676.174988 
	C774.529724,676.204102 771.406677,677.276489 770.276917,678.937805 
	C765.554077,685.882629 761.376465,693.204285 757.195129,700.500122 
	C756.852539,701.097839 757.984680,702.540771 758.294373,704.219727 
	C758.238220,704.652161 758.182068,705.084656 757.764954,705.382690 
	C757.764954,705.382690 757.578125,705.045776 756.721497,704.970520 
	C752.288940,704.688049 751.387268,708.754272 749.319092,711.202820 
	C742.700745,719.038452 736.827393,727.574341 729.676270,734.869385 
	C722.048401,742.650696 713.732605,749.931152 704.922302,756.337280 
	C693.225098,764.842529 680.764038,772.298157 668.618958,780.186035 
	C664.070557,783.139954 659.488037,786.041260 654.166260,788.979675 
	C651.412415,790.248413 648.658508,791.517090 645.414490,792.876648 
	C645.414490,792.876648 644.919128,792.938171 644.506653,792.933472 
	C644.506653,792.933472 644.095032,792.961182 643.292480,792.958801 
	C638.465576,794.948303 633.638672,796.937805 628.087646,798.935913 
	C626.415588,799.614258 624.743530,800.292603 622.246094,800.945679 
	C620.479492,801.297241 618.712891,801.648865 616.380371,802.036743 
	C615.915649,802.317200 615.450928,802.597717 614.307068,802.930420 
	C613.853455,802.961731 613.399902,802.992981 612.299622,803.018311 
	C611.517273,803.277893 610.734924,803.537537 609.189636,803.902954 
	C607.774536,804.265076 606.359497,804.627197 604.305603,804.993530 
	C603.880249,805.014465 603.454956,805.035400 602.423096,805.060120 
	C601.934998,805.371277 601.446960,805.682434 600.234985,806.007202 
	C598.449585,806.570374 596.664185,807.133484 594.391052,807.825684 
	C594.226013,807.857971 594.060974,807.890320 593.213318,808.216248 
	C591.504578,808.449280 589.795898,808.682312 587.641663,808.477295 
	C586.413147,808.656738 585.184570,808.836182 583.224609,809.010437 
	C582.494080,809.041565 581.763489,809.072693 580.252686,809.045288 
	C578.494568,809.377258 576.736511,809.709167 574.332397,810.028137 
	C573.901917,810.041321 573.471436,810.054504 572.108093,810.019653 
	C559.229065,810.343567 546.350464,810.690308 533.470642,810.976379 
	C529.953369,811.054504 526.432068,810.949890 522.305969,810.930786 
	C521.900696,810.918823 521.495483,810.906860 520.690796,810.440369 
	C520.111938,810.309204 519.533142,810.177979 518.310974,810.019714 
	C517.882202,810.025452 517.453491,810.031189 516.442810,809.458984 
	C509.942749,808.642273 503.442688,807.825500 496.327484,806.705505 
	C495.226685,806.485901 494.125885,806.266235 492.305237,805.713196 
	C490.187103,805.459412 488.068939,805.205566 485.378235,804.919189 
	C484.995941,804.914856 484.613678,804.910522 483.956329,804.227600 
	C482.625275,803.830200 481.294220,803.432739 479.329285,803.002625 
	C478.906281,802.992920 478.483276,802.983154 477.544159,802.943726 
	C477.372009,802.950745 477.199829,802.957825 477.002441,803.000000 
	C477.002441,803.000000 477.021973,802.963989 476.686066,802.327759 
	C475.117523,801.870117 473.548981,801.412476 471.186890,800.930603 
	C470.127075,800.920776 469.067291,800.910889 467.554901,800.859375 
M809.104065,289.756378 
	C808.848816,289.971619 808.652222,290.231262 807.782410,290.659515 
	C802.840210,293.237488 798.006836,293.854187 793.406494,289.896484 
	C787.489258,284.805908 787.056885,277.363739 792.897339,270.319763 
	C800.112000,261.618378 807.644043,253.172119 815.281799,244.838196 
	C816.467529,243.544434 819.063354,243.653229 820.798828,242.724304 
	C822.828552,241.637863 825.072937,240.435623 826.323792,238.652100 
	C826.863281,237.882812 825.564331,235.074478 824.377136,233.969925 
	C818.292053,228.308517 812.049438,222.806824 805.665222,217.485107 
	C804.436157,216.460526 801.472961,215.579147 800.799194,216.213608 
	C799.211853,217.708176 798.349487,220.090378 797.522156,222.239838 
	C796.760010,224.220016 797.001831,226.816391 795.796753,228.353760 
	C792.007507,233.187698 787.482300,237.447815 783.730225,242.307053 
	C778.593872,248.958954 772.265564,255.334824 769.380432,262.941254 
	C764.024597,277.061615 773.676208,294.673340 788.858337,301.884949 
	C800.405151,307.369751 812.068726,304.790161 822.182312,294.007172 
	C829.342346,286.373199 835.956848,278.228119 843.096558,270.573975 
	C844.505981,269.062988 847.312561,268.947083 849.273010,267.832794 
	C851.296448,266.682678 853.438721,265.370544 854.775757,263.569977 
	C855.297913,262.866943 854.148376,260.274384 853.097168,259.178619 
	C850.456665,256.426239 847.579834,253.844025 844.510193,251.584198 
	C843.013672,250.482422 840.208252,249.195618 839.166687,249.851837 
	C837.070374,251.172668 834.298523,252.900009 835.836792,256.691895 
	C836.365417,257.994873 835.188477,259.989807 834.236450,262.033966 
	C828.789001,268.327423 823.341492,274.620880 817.303284,281.235779 
	C814.707336,283.987457 812.111389,286.739136 809.104065,289.756378 
M513.910461,274.041992 
	C518.964294,273.052124 524.018127,272.062225 529.982300,271.055847 
	C533.293762,270.874298 536.607971,270.732910 539.916138,270.503937 
	C551.474365,269.703949 563.036499,268.154968 574.585938,268.250366 
	C586.963074,268.352661 599.313171,270.290283 611.701111,270.887054 
	C628.545776,271.698547 644.579834,276.384979 660.585022,280.901947 
	C672.440369,284.247650 683.954468,288.887360 695.452759,293.380219 
	C702.063599,295.963318 708.602600,298.955994 714.711487,302.550354 
	C726.791992,309.658295 738.726501,317.050049 750.376587,324.840057 
	C757.163147,329.377930 763.300903,334.890900 769.694519,340.010101 
	C775.100769,344.338715 780.455200,348.732025 786.023865,353.752197 
	C791.103027,359.052094 796.472168,364.107971 801.188904,369.712860 
	C807.255981,376.922272 812.930237,384.475464 818.539551,392.053894 
	C827.951660,404.769897 836.821716,417.834167 843.316040,432.363647 
	C845.182190,436.538635 847.175720,440.691010 849.548279,444.587402 
	C850.428101,446.032227 852.562500,446.713043 854.124878,447.742249 
	C854.751221,446.182739 855.974915,444.580597 855.867432,443.073395 
	C855.695374,440.660980 854.919250,438.186035 853.915100,435.955078 
	C844.762085,415.619598 833.879395,396.233887 821.343323,377.797272 
	C819.355042,374.873169 816.601501,372.469452 814.008118,369.145721 
	C813.351013,367.641754 813.024963,365.841125 811.984741,364.680511 
	C803.056702,354.718842 793.999512,344.872894 784.743713,334.364563 
	C784.278503,333.678101 783.922241,332.874695 783.330750,332.323792 
	C778.643860,327.958496 773.893982,323.660919 769.199463,319.303680 
	C764.472839,314.916565 760.176880,309.951416 755.007263,306.177795 
	C742.210999,296.836884 729.469788,287.278687 715.870483,279.218262 
	C701.507812,270.705444 686.371643,263.405243 671.197327,256.388702 
	C661.923828,252.100677 652.111694,248.716934 642.238953,246.045135 
	C626.605835,241.814453 610.803711,238.088257 594.910034,234.983353 
	C584.333069,232.917114 573.500732,231.698975 562.731323,231.192856 
	C549.172729,230.555649 535.542358,230.581863 521.979553,231.168732 
	C510.929779,231.646866 499.867218,232.946701 488.933350,234.665009 
	C478.568085,236.293945 468.319855,238.759018 458.095459,241.175140 
	C432.824646,247.146851 408.695679,256.400055 385.732117,268.365356 
	C372.991547,275.003906 360.748810,282.756897 348.907043,290.915283 
	C337.737915,298.610260 327.173218,307.237122 316.746765,315.938263 
	C308.502258,322.818604 300.424561,330.015564 293.094971,337.841888 
	C286.124786,345.284454 279.812042,353.438232 273.940552,361.792572 
	C264.340790,375.451843 255.185822,389.432831 246.171417,403.488586 
	C243.649155,407.421448 242.425797,412.164886 240.348724,416.410309 
	C235.816742,425.673431 230.825714,434.724030 226.612579,444.126007 
	C224.495224,448.851013 226.318878,450.961884 231.581482,450.978516 
	C252.076157,451.043274 272.571381,451.033539 293.065582,450.910065 
	C294.383514,450.902100 295.694244,449.698792 297.013214,449.013275 
	C297.013214,449.013275 297.051483,449.008331 297.745361,448.883148 
	C300.163635,444.057983 302.467194,439.170959 305.028168,434.422760 
	C308.519165,427.950348 312.116150,421.530914 315.833252,415.185730 
	C320.171753,407.779755 324.688660,400.478333 329.769348,392.945984 
	C334.624725,387.313538 339.193359,381.399689 344.391113,376.103241 
	C358.059357,362.175201 371.194427,347.562256 386.043182,334.996155 
	C403.697083,320.056152 423.243164,307.489929 444.786469,298.486694 
	C456.819244,293.457977 468.731354,288.081421 480.987244,283.664551 
	C490.765625,280.140533 500.981812,277.831451 511.600189,274.978577 
	C512.086182,274.681702 512.572144,274.384827 513.910461,274.041992 
M229.164825,333.542389 
	C239.892654,337.135590 250.688034,340.543213 261.316040,344.410736 
	C265.744720,346.022308 267.733521,343.253113 269.278107,340.540344 
	C270.257233,338.820679 270.339813,335.763672 269.461456,333.976227 
	C267.270386,329.517273 264.285797,325.450226 261.647308,321.208710 
	C258.202454,315.670929 254.784164,310.116638 251.354446,304.569427 
	C251.676910,304.243622 251.999374,303.917847 252.321854,303.592041 
	C259.543030,307.540558 266.802765,311.422211 273.929810,315.533875 
	C274.701569,315.979095 274.492249,317.887115 275.109589,318.887268 
	C276.392609,320.965851 277.633484,323.286835 279.510071,324.667694 
	C280.184479,325.163971 283.221436,323.673615 284.186737,322.359924 
	C293.198486,310.095459 303.203125,298.890533 316.269257,290.769501 
	C320.136230,288.366089 319.454590,285.538818 316.611511,283.129028 
	C315.186646,281.921326 312.930664,281.694122 310.441559,280.806213 
	C310.394897,277.355713 305.226715,273.618347 311.021088,269.189423 
	C323.373169,259.748138 323.231995,259.563416 331.308197,265.684601 
	C331.280579,266.128174 331.414246,266.711243 331.194397,267.087982 
	C329.096680,270.682251 331.745636,273.001526 333.944611,274.608826 
	C334.891541,275.300964 337.896881,274.016479 339.443329,272.902740 
	C345.922607,268.236298 352.334290,263.452332 358.481110,258.361725 
	C360.444611,256.735596 361.513367,254.029068 363.528412,251.013443 
	C375.557465,251.021469 387.589691,246.763321 397.737549,237.856842 
	C405.103058,231.392334 406.713470,223.005356 404.285034,213.742508 
	C401.938446,204.791794 395.830383,200.170197 386.870392,198.950684 
	C378.806519,197.853149 370.728668,196.845139 362.687195,195.602386 
	C358.806976,195.002731 355.946167,192.574081 356.394958,188.528519 
	C356.881775,184.140015 360.726807,182.554413 364.551361,182.877533 
	C367.357849,183.114655 370.192993,184.604950 372.719360,186.053116 
	C374.687622,187.181381 376.164581,189.145920 377.923676,190.664917 
	C380.238220,192.663528 386.685242,190.670624 385.856934,188.390228 
	C383.720001,182.507141 380.281586,177.094330 377.294556,171.530426 
	C377.156464,171.273224 376.352936,171.311569 375.860168,171.315521 
	C364.039856,171.410416 353.009094,173.807220 344.278198,182.532043 
	C338.797058,188.009369 335.964081,194.371887 337.563904,202.378067 
	C339.861938,213.877930 347.398651,219.755264 358.284607,221.848907 
	C364.640106,223.071213 371.168030,223.386017 377.537476,224.551178 
	C381.276398,225.235153 386.013275,225.405426 386.234100,230.946899 
	C386.373718,234.449585 381.613953,238.053711 376.199249,238.161270 
	C373.147522,238.221893 369.839233,237.677460 367.072876,236.447021 
	C364.115021,235.131409 361.403198,232.951889 359.048767,230.669937 
	C356.177002,227.886566 353.548492,229.192093 351.615692,231.108505 
	C350.613403,232.102325 350.633759,234.969971 351.250763,236.587204 
	C352.749634,240.515854 354.867828,244.208252 356.736511,247.995819 
	C356.372284,248.492859 356.008087,248.989914 355.643860,249.486954 
	C353.128845,248.718887 350.370178,248.379807 348.138702,247.112488 
	C334.537872,239.388184 321.029602,231.499374 307.543152,223.575729 
	C292.053406,214.475098 292.077148,214.434586 278.058655,225.720200 
	C277.799133,225.929138 277.457306,226.092499 277.300018,226.362335 
	C276.516266,227.707016 274.824829,229.557861 275.219574,230.348495 
	C276.175201,232.262543 278.198639,235.117462 279.635040,235.029694 
	C283.336273,234.803574 283.752380,237.028748 284.473328,239.385986 
	C289.193420,254.818573 294.034241,270.217529 298.431702,285.741852 
	C299.078369,288.024750 298.429413,291.765625 296.871460,293.316742 
	C294.736389,295.442413 291.545197,296.801971 287.809540,294.780762 
	C274.056335,287.339447 260.087006,280.297760 246.329224,272.864685 
	C244.939545,272.113861 244.530869,269.665497 243.486572,268.127380 
	C242.168076,266.185455 240.956711,263.918121 239.100342,262.715363 
	C238.330353,262.216461 235.595413,263.736420 234.472855,264.984711 
	C231.027847,268.815491 228.044296,273.058197 224.655029,276.943359 
	C222.684052,279.202728 222.512787,281.118317 224.166504,283.618073 
	C227.011139,287.917877 229.634201,292.365784 232.302292,296.780518 
	C235.576508,302.198242 238.801453,307.645782 242.612823,314.026520 
	C230.691483,309.873047 219.980576,306.277008 209.386627,302.364349 
	C206.367920,301.249420 204.462997,301.632355 202.540253,304.180573 
	C199.434723,308.296295 196.157684,312.292511 192.781754,316.190857 
	C190.544220,318.774628 190.390274,321.117004 193.491226,322.694550 
	C195.529099,323.731262 198.015259,323.886810 200.300674,324.436981 
	C211.899368,335.696808 223.168625,347.936462 234.202820,360.384460 
	C235.532364,361.884369 235.328125,364.644073 236.306122,366.582001 
	C237.399841,368.749237 238.741440,371.014526 240.584824,372.459229 
	C241.230453,372.965240 244.082336,371.542908 245.234619,370.338776 
	C247.985382,367.464325 250.541229,364.339111 252.733032,361.022552 
	C253.748901,359.485413 254.720459,356.574585 253.971542,355.503540 
	C252.539642,353.455658 250.455139,350.787537 246.903809,352.906036 
	C246.212036,353.318726 244.346268,352.790894 243.693848,352.080139 
	C238.300323,346.204498 233.065033,340.183563 228.102646,333.887726 
	C228.102646,333.887726 228.402924,333.549438 229.164825,333.542389 
M307.966736,639.544128 
	C314.016998,649.807007 322.964783,655.518799 334.893738,655.703552 
	C346.135284,655.877625 353.372253,649.402893 358.401917,639.998352 
	C368.279999,621.528137 378.107239,603.030762 387.969116,584.551819 
	C391.130798,578.627563 394.335266,572.726135 398.245850,565.467285 
	C399.585449,568.350891 400.580780,569.796692 400.935211,571.385498 
	C402.052002,576.391663 402.856415,581.466553 403.910248,586.487915 
	C408.281403,607.315735 412.781250,628.117065 417.024841,648.970642 
	C417.778503,652.674377 419.541748,654.007385 423.112823,653.987488 
	C437.443909,653.907715 451.776520,653.844543 466.106567,653.982971 
	C470.865295,654.028931 471.423737,651.600159 470.498535,647.894531 
	C463.690796,620.627808 456.833496,593.373474 450.055145,566.099426 
	C445.799530,548.976013 441.926605,531.752197 437.358734,514.713684 
	C434.500458,504.052216 433.495483,492.713806 426.173370,483.509247 
	C418.966064,474.448975 404.178894,470.405670 394.887177,475.513184 
	C385.156830,480.861877 380.464478,489.886902 375.665192,498.991852 
	C362.425812,524.108887 349.276886,549.273621 336.033173,574.388428 
	C335.464111,575.467590 334.307587,576.237061 333.424744,577.150757 
	C332.447266,576.164734 331.156158,575.343628 330.538544,574.168457 
	C317.536194,549.429321 304.819580,524.537048 291.540405,499.948303 
	C287.576508,492.608429 282.886688,485.309540 277.105743,479.379822 
	C267.104095,469.120789 249.004990,472.166229 240.381393,483.794769 
	C235.685715,490.126617 234.009506,497.167145 232.194626,504.384186 
	C228.482468,519.145752 224.771210,533.907654 221.075058,548.673218 
	C216.674011,566.254761 212.295807,583.842041 207.895416,601.423767 
	C204.058731,616.753296 200.139114,632.062561 196.394928,647.414612 
	C195.109604,652.684753 196.272522,653.952026 201.787140,653.964539 
	C215.619156,653.995911 229.452682,653.874878 243.282486,654.054626 
	C247.203461,654.105652 248.882614,652.654175 249.661087,648.841919 
	C254.646896,624.425964 259.799957,600.044006 264.959290,575.663879 
	C265.610992,572.584351 266.620422,569.580505 267.464081,566.541626 
	C268.021149,566.526428 268.578186,566.511230 269.135223,566.496094 
	C281.980164,590.620972 294.825073,614.745789 307.966736,639.544128 
M739.996948,525.230164 
	C742.903320,524.706909 745.808105,523.739197 748.716309,523.728577 
	C780.879089,523.611633 813.043091,523.734009 845.204285,523.481995 
	C848.143921,523.458984 852.262207,522.147827 853.800964,519.987366 
	C862.957458,507.131775 871.547668,493.871124 880.249695,480.694794 
	C882.106140,477.883789 880.812622,475.638092 877.340027,475.336212 
	C875.849670,475.206635 874.342590,475.245880 872.843140,475.246185 
	C831.014465,475.254822 789.184998,475.144775 747.357727,475.394135 
	C739.771729,475.439301 732.011963,476.193298 724.648926,477.948242 
	C705.458679,482.522278 686.258545,497.818909 683.546997,520.919983 
	C681.148315,541.355103 686.953613,558.335754 704.041260,570.781067 
	C715.361206,579.025574 728.215881,582.732178 741.936218,582.926331 
	C766.595398,583.275208 791.262939,582.998169 815.926697,583.080383 
	C820.413574,583.095337 824.912292,583.439148 829.381409,583.877075 
	C834.674011,584.395874 839.766968,588.575317 840.322998,592.726990 
	C841.061951,598.244446 837.412964,601.257019 833.183838,602.943665 
	C829.434021,604.439148 825.151184,605.209778 821.097107,605.234375 
	C787.435303,605.438904 753.771667,605.487366 720.109741,605.328186 
	C715.116638,605.304626 712.184570,607.151245 709.583252,611.230652 
	C701.910461,623.262878 693.833618,635.036804 686.064514,647.008911 
	C685.086487,648.515930 685.003540,650.603821 684.505920,652.422607 
	C686.181519,652.878052 687.856750,653.729614 689.532776,653.731201 
	C734.527222,653.774658 779.523254,653.930298 824.515442,653.592834 
	C840.169495,653.475464 854.451843,648.635071 866.920410,638.565735 
	C891.649109,618.595459 894.467407,578.303345 872.307983,555.225098 
	C860.240601,542.657532 845.237915,535.758728 827.909119,535.321899 
	C804.259399,534.725708 780.586243,535.077881 756.923462,534.965637 
	C752.436279,534.944336 747.920044,534.922729 743.471741,534.419189 
	C737.924683,533.791199 736.687744,530.898560 739.996948,525.230164 
M648.869995,500.629364 
	C632.713745,484.665619 612.743103,476.584167 590.582092,475.583221 
	C564.976379,474.426697 539.279602,475.206421 513.624084,475.384399 
	C511.669220,475.397980 508.973145,476.808014 507.898804,478.420105 
	C499.155396,491.539886 490.658234,504.825775 482.223572,518.148071 
	C479.952515,521.735168 481.218170,523.643127 485.591919,523.695740 
	C489.589203,523.743835 493.587952,523.647644 497.586029,523.652283 
	C526.407959,523.685974 555.230042,523.688843 584.051819,523.784180 
	C601.922119,523.843262 619.223694,537.017639 623.552307,554.511963 
	C629.338867,577.898315 614.092285,604.530701 585.824097,605.195435 
	C568.506775,605.602722 551.171814,605.206116 533.847961,605.423706 
	C529.768555,605.474976 528.775818,603.986877 528.807190,600.175964 
	C528.951355,582.683777 528.683411,565.187256 528.938049,547.697693 
	C529.008789,542.845032 527.485046,541.213135 522.688660,541.310303 
	C510.864594,541.549988 499.032501,541.389221 487.203644,541.403503 
	C480.522919,541.411560 479.444122,542.471680 479.428040,549.267090 
	C479.396149,562.761963 479.417358,576.257019 479.413055,589.751953 
	C479.407043,608.578186 479.380646,627.404419 479.399170,646.230652 
	C479.405548,652.708252 480.543640,653.822388 486.877350,653.820679 
	C518.532166,653.812073 550.187073,653.834045 581.841797,653.766785 
	C594.428772,653.740051 606.621765,651.537842 618.390686,646.921570 
	C638.117126,639.184082 652.718079,625.520752 662.897400,607.329956 
	C672.194702,590.715332 674.397888,572.395203 672.987122,553.624695 
	C671.460510,533.315186 663.858154,515.657043 648.869995,500.629364 
M721.328186,219.897888 
	C719.838257,220.987915 717.958435,221.799454 716.939514,223.225922 
	C714.819580,226.193878 713.393250,229.662033 711.237488,232.598282 
	C709.087219,235.526886 709.421509,237.958740 711.252625,240.800018 
	C717.401306,250.340683 725.817871,257.219788 736.108643,261.707367 
	C746.555237,266.262909 756.428040,264.316010 763.765930,257.006226 
	C771.724854,249.077988 771.406982,240.176926 767.081238,230.793839 
	C764.226013,224.600494 761.013489,218.569382 758.244507,212.339615 
	C756.177246,207.688599 757.714111,202.916779 761.281433,201.535995 
	C764.379944,200.336624 769.510681,203.512482 770.776245,207.813034 
	C771.416626,209.988998 771.466553,212.413269 771.373779,214.708725 
	C771.287292,216.848068 770.776917,218.996002 770.241150,221.085800 
	C769.299561,224.758728 772.055969,226.023178 774.448730,226.695084 
	C775.706421,227.048264 778.070618,225.801178 778.987915,224.570602 
	C781.364807,221.381592 783.454773,217.914307 785.162231,214.321838 
	C785.861572,212.850372 785.945190,210.396637 785.174561,209.028717 
	C780.398438,200.549744 773.626282,194.048523 764.631592,190.113556 
	C752.650330,184.872040 741.519958,187.944977 734.781067,198.271225 
	C730.542175,204.766785 730.132812,211.855469 732.703064,218.881363 
	C735.494385,226.511520 739.310303,233.771454 741.999756,241.431488 
	C742.811951,243.744797 741.360168,246.853027 740.945374,249.597137 
	C738.012024,249.123032 734.541382,249.488708 732.244873,248.019287 
	C724.997742,243.382309 724.721008,235.879578 726.058960,228.405182 
	C726.793762,224.300171 726.106812,221.590088 721.328186,219.897888 
M582.911743,197.321259 
	C578.668213,198.066132 578.512512,201.384537 578.629456,204.572098 
	C578.770081,208.406540 582.058105,207.456161 584.313843,207.765701 
	C591.724609,208.782639 599.141785,209.789963 606.584351,210.513885 
	C608.483521,210.698608 612.075623,210.032684 612.159912,209.395981 
	C612.488586,206.914383 611.829651,204.265854 611.323486,201.722092 
	C610.964905,199.919937 609.668152,198.135025 609.815674,196.431152 
	C610.431885,189.310440 611.417908,182.221054 612.301697,175.124344 
	C613.382385,166.446915 614.496399,157.773636 615.590881,149.138428 
	C622.418091,149.655365 624.627319,151.852737 626.802307,161.264694 
	C627.763855,165.425629 630.761292,165.031616 633.311279,164.721802 
	C634.484497,164.579269 636.170227,162.200043 636.263062,160.748062 
	C636.644836,154.777039 636.533142,148.772659 636.542603,142.780457 
	C636.546997,140.011429 635.196167,138.725510 632.304749,138.370972 
	C613.821533,136.104538 595.354004,133.708344 576.891418,131.277771 
	C573.753113,130.864624 572.344421,132.134262 571.670654,135.107117 
	C570.532776,140.128143 568.951721,145.051056 567.883728,150.084549 
	C567.394104,152.391846 565.362183,155.617020 569.348389,156.800354 
	C572.539368,157.747650 575.479126,158.199219 577.310181,153.626373 
	C579.888000,147.188950 584.237976,144.971603 590.118591,145.636215 
	C590.118591,147.061569 590.272400,148.536819 590.094360,149.970901 
	C588.577881,162.188446 587.045105,174.404404 585.414307,186.607086 
	C584.957397,190.025467 584.158752,193.398178 582.911743,197.321259 
M572.104980,195.404037 
	C569.762878,193.292877 566.513306,191.626587 565.225098,188.998795 
	C556.511597,171.225021 548.154480,153.274933 539.835388,135.310898 
	C538.383484,132.175629 536.620239,130.634094 533.008911,131.027390 
	C528.381958,131.531281 523.690857,131.445496 519.063843,131.949127 
	C517.479919,132.121552 514.886230,133.103745 514.712830,134.068085 
	C514.341370,136.134796 514.499756,139.562988 515.777954,140.405441 
	C518.754517,142.367294 518.118896,144.260880 517.315186,146.750687 
	C512.664734,161.156693 508.030792,175.568405 503.494476,190.010590 
	C502.375000,193.574661 501.215057,196.665421 497.459930,198.655884 
	C496.076263,199.389343 495.606506,202.937271 495.814728,205.095871 
	C495.910248,206.085831 498.627411,207.492096 500.194214,207.543869 
	C504.671539,207.691818 509.168976,207.249893 513.657776,207.026077 
	C518.613281,206.778992 520.630188,203.952942 518.671387,199.512741 
	C518.022400,198.041748 516.314148,197.038071 515.114380,195.844772 
	C517.994995,185.326782 517.996887,185.277603 534.160645,185.905334 
	C540.409607,186.148026 538.760498,192.079803 541.628418,195.466385 
	C536.387695,196.896286 536.850159,200.544144 538.157837,203.285782 
	C538.830505,204.696213 542.105530,205.528168 544.182495,205.487564 
	C551.832764,205.338089 559.478210,204.778961 567.116943,204.250565 
	C574.143555,203.764481 574.442505,203.237595 572.104980,195.404037 
M674.225098,229.114105 
	C678.658752,230.547531 683.046265,232.161911 687.552673,233.309509 
	C688.997009,233.677338 691.860840,233.222351 692.155884,232.429520 
	C692.932251,230.343338 693.204468,227.727783 692.668640,225.574066 
	C691.835999,222.227341 689.318237,219.171478 688.993713,215.847931 
	C687.102295,196.474838 685.710938,177.053253 683.917419,157.669632 
	C683.759277,155.959503 682.282593,153.553268 680.804565,152.933136 
	C675.911560,150.880249 670.769714,149.383575 665.649780,147.933380 
	C664.280762,147.545624 661.624268,147.624664 661.327576,148.284622 
	C660.388794,150.372604 659.182068,153.519974 660.101379,155.008240 
	C661.807739,157.770584 660.773071,159.238708 659.293457,161.111801 
	C649.602295,173.380096 640.049438,185.762024 630.116638,197.831253 
	C628.215637,200.141144 624.599182,200.955444 622.201233,202.968552 
	C620.508545,204.389618 619.127197,206.539841 618.461121,208.636002 
	C618.220032,209.394501 620.247437,211.487030 621.613281,212.028336 
	C625.780823,213.680054 630.081177,215.069901 634.431885,216.151276 
	C636.269897,216.608139 639.682861,216.657761 640.077637,215.814575 
	C641.034485,213.770721 640.642029,211.094254 640.789062,208.672485 
	C640.867920,207.372864 640.485535,205.794128 641.086182,204.818314 
	C644.795044,198.792740 645.439880,198.646194 652.367737,200.955887 
	C655.047791,201.849396 657.671570,203.146118 660.423218,203.534042 
	C664.251038,204.073700 664.139648,206.811829 664.268555,209.366882 
	C664.388611,211.747772 665.799377,214.473282 661.711975,215.822205 
	C660.098572,216.354691 658.927856,219.725754 658.609009,221.954910 
	C658.479797,222.859039 661.000549,224.547028 662.593628,225.209915 
	C666.117188,226.676071 669.817017,227.718658 674.225098,229.114105 
M874.658813,344.631104 
	C874.527527,342.884979 874.396179,341.138885 874.249023,339.181763 
	C875.429749,339.118164 876.244080,339.071625 877.058655,339.030823 
	C888.762573,338.444489 896.309692,330.186798 894.760864,318.604187 
	C894.122437,313.829742 892.285828,308.807770 889.638672,304.791809 
	C883.523254,295.514221 876.644165,286.733307 869.896057,277.887360 
	C868.980896,276.687744 866.269958,275.255615 865.711609,275.677856 
	C863.812500,277.114227 862.356018,279.235901 861.021118,281.285156 
	C860.033936,282.800507 859.963440,285.207794 858.690979,286.196930 
	C846.360352,295.782440 833.899536,305.202911 821.350891,314.501770 
	C820.144470,315.395721 818.054260,315.011078 816.443848,315.466003 
	C814.104187,316.126923 811.421936,316.542358 809.713684,318.019745 
	C808.975098,318.658539 809.727478,322.107086 810.787354,323.561737 
	C817.243530,332.422302 823.701294,341.312836 830.739685,349.705994 
	C834.631592,354.346985 839.157959,358.841003 844.263428,362.005402 
	C858.223938,370.658234 875.271179,360.399872 874.658813,344.631104 
M449.048615,142.463058 
	C438.363251,144.981033 427.657715,147.419235 417.016235,150.110718 
	C415.569153,150.476715 413.260620,152.303879 413.394379,153.119125 
	C413.761444,155.355438 415.013672,157.547302 416.302948,159.501083 
	C417.436432,161.218796 419.920898,162.372681 420.389587,164.149841 
	C424.332123,179.099121 428.013000,194.119232 431.556335,209.168930 
	C431.893921,210.602646 431.256470,212.914688 430.208099,213.901230 
	C427.347229,216.593262 428.565643,219.398285 430.105164,221.511368 
	C430.922546,222.633270 433.742157,222.629990 435.631470,222.545151 
	C437.586578,222.457367 439.522827,221.702469 441.444641,221.159180 
	C452.155334,218.131287 463.209808,215.949707 473.476746,211.815063 
	C486.351715,206.630112 494.658295,196.660278 495.530029,182.272400 
	C496.384796,168.164536 491.890686,155.985138 479.729919,147.649139 
	C470.652435,141.426697 460.410583,141.109528 449.048615,142.463058 
z"/>
<path fill="#FC9606" opacity="1.000000" stroke="none" 
	d="
M307.818359,639.207397 
	C294.825073,614.745789 281.980164,590.620972 269.135223,566.496094 
	C268.578186,566.511230 268.021149,566.526428 267.464081,566.541626 
	C266.620422,569.580505 265.610992,572.584351 264.959290,575.663879 
	C259.799957,600.044006 254.646896,624.425964 249.661087,648.841919 
	C248.882614,652.654175 247.203461,654.105652 243.282486,654.054626 
	C229.452682,653.874878 215.619156,653.995911 201.787140,653.964539 
	C196.272522,653.952026 195.109604,652.684753 196.394928,647.414612 
	C200.139114,632.062561 204.058731,616.753296 207.895416,601.423767 
	C212.295807,583.842041 216.674011,566.254761 221.075058,548.673218 
	C224.771210,533.907654 228.482468,519.145752 232.194626,504.384186 
	C234.009506,497.167145 235.685715,490.126617 240.381393,483.794769 
	C249.004990,472.166229 267.104095,469.120789 277.105743,479.379822 
	C282.886688,485.309540 287.576508,492.608429 291.540405,499.948303 
	C304.819580,524.537048 317.536194,549.429321 330.538544,574.168457 
	C331.156158,575.343628 332.447266,576.164734 333.424744,577.150757 
	C334.307587,576.237061 335.464111,575.467590 336.033173,574.388428 
	C349.276886,549.273621 362.425812,524.108887 375.665192,498.991852 
	C380.464478,489.886902 385.156830,480.861877 394.887177,475.513184 
	C404.178894,470.405670 418.966064,474.448975 426.173370,483.509247 
	C433.495483,492.713806 434.500458,504.052216 437.358734,514.713684 
	C441.926605,531.752197 445.799530,548.976013 450.055145,566.099426 
	C456.833496,593.373474 463.690796,620.627808 470.498535,647.894531 
	C471.423737,651.600159 470.865295,654.028931 466.106567,653.982971 
	C451.776520,653.844543 437.443909,653.907715 423.112823,653.987488 
	C419.541748,654.007385 417.778503,652.674377 417.024841,648.970642 
	C412.781250,628.117065 408.281403,607.315735 403.910248,586.487915 
	C402.856415,581.466553 402.052002,576.391663 400.935211,571.385498 
	C400.580780,569.796692 399.585449,568.350891 398.245850,565.467285 
	C394.335266,572.726135 391.130798,578.627563 387.969116,584.551819 
	C378.107239,603.030762 368.279999,621.528137 358.401917,639.998352 
	C353.372253,649.402893 346.135284,655.877625 334.893738,655.703552 
	C322.964783,655.518799 314.016998,649.807007 307.818359,639.207397 
z"/>
<path fill="#FB9607" opacity="1.000000" stroke="none" 
	d="
M739.674072,525.494629 
	C736.687744,530.898560 737.924683,533.791199 743.471741,534.419189 
	C747.920044,534.922729 752.436279,534.944336 756.923462,534.965637 
	C780.586243,535.077881 804.259399,534.725708 827.909119,535.321899 
	C845.237915,535.758728 860.240601,542.657532 872.307983,555.225098 
	C894.467407,578.303345 891.649109,618.595459 866.920410,638.565735 
	C854.451843,648.635071 840.169495,653.475464 824.515442,653.592834 
	C779.523254,653.930298 734.527222,653.774658 689.532776,653.731201 
	C687.856750,653.729614 686.181519,652.878052 684.505920,652.422607 
	C685.003540,650.603821 685.086487,648.515930 686.064514,647.008911 
	C693.833618,635.036804 701.910461,623.262878 709.583252,611.230652 
	C712.184570,607.151245 715.116638,605.304626 720.109741,605.328186 
	C753.771667,605.487366 787.435303,605.438904 821.097107,605.234375 
	C825.151184,605.209778 829.434021,604.439148 833.183838,602.943665 
	C837.412964,601.257019 841.061951,598.244446 840.322998,592.726990 
	C839.766968,588.575317 834.674011,584.395874 829.381409,583.877075 
	C824.912292,583.439148 820.413574,583.095337 815.926697,583.080383 
	C791.262939,582.998169 766.595398,583.275208 741.936218,582.926331 
	C728.215881,582.732178 715.361206,579.025574 704.041260,570.781067 
	C686.953613,558.335754 681.148315,541.355103 683.546997,520.919983 
	C686.258545,497.818909 705.458679,482.522278 724.648926,477.948242 
	C732.011963,476.193298 739.771729,475.439301 747.357727,475.394135 
	C789.184998,475.144775 831.014465,475.254822 872.843140,475.246185 
	C874.342590,475.245880 875.849670,475.206635 877.340027,475.336212 
	C880.812622,475.638092 882.106140,477.883789 880.249695,480.694794 
	C871.547668,493.871124 862.957458,507.131775 853.800964,519.987366 
	C852.262207,522.147827 848.143921,523.458984 845.204285,523.481995 
	C813.043091,523.734009 780.879089,523.611633 748.716309,523.728577 
	C745.808105,523.739197 742.903320,524.706909 739.674072,525.494629 
z"/>
<path fill="#FB9607" opacity="1.000000" stroke="none" 
	d="
M649.116821,500.886322 
	C663.858154,515.657043 671.460510,533.315186 672.987122,553.624695 
	C674.397888,572.395203 672.194702,590.715332 662.897400,607.329956 
	C652.718079,625.520752 638.117126,639.184082 618.390686,646.921570 
	C606.621765,651.537842 594.428772,653.740051 581.841797,653.766785 
	C550.187073,653.834045 518.532166,653.812073 486.877350,653.820679 
	C480.543640,653.822388 479.405548,652.708252 479.399170,646.230652 
	C479.380646,627.404419 479.407043,608.578186 479.413055,589.751953 
	C479.417358,576.257019 479.396149,562.761963 479.428040,549.267090 
	C479.444122,542.471680 480.522919,541.411560 487.203644,541.403503 
	C499.032501,541.389221 510.864594,541.549988 522.688660,541.310303 
	C527.485046,541.213135 529.008789,542.845032 528.938049,547.697693 
	C528.683411,565.187256 528.951355,582.683777 528.807190,600.175964 
	C528.775818,603.986877 529.768555,605.474976 533.847961,605.423706 
	C551.171814,605.206116 568.506775,605.602722 585.824097,605.195435 
	C614.092285,604.530701 629.338867,577.898315 623.552307,554.511963 
	C619.223694,537.017639 601.922119,523.843262 584.051819,523.784180 
	C555.230042,523.688843 526.407959,523.685974 497.586029,523.652283 
	C493.587952,523.647644 489.589203,523.743835 485.591919,523.695740 
	C481.218170,523.643127 479.952515,521.735168 482.223572,518.148071 
	C490.658234,504.825775 499.155396,491.539886 507.898804,478.420105 
	C508.973145,476.808014 511.669220,475.397980 513.624084,475.384399 
	C539.279602,475.206421 564.976379,474.426697 590.582092,475.583221 
	C612.743103,476.584167 632.713745,484.665619 649.116821,500.886322 
z"/>
<path fill="#F4DFD7" opacity="1.000000" stroke="none" 
	d="
M227.781830,334.206604 
	C233.065033,340.183563 238.300323,346.204498 243.693848,352.080139 
	C244.346268,352.790894 246.212036,353.318726 246.903809,352.906036 
	C250.455139,350.787537 252.539642,353.455658 253.971542,355.503540 
	C254.720459,356.574585 253.748901,359.485413 252.733032,361.022552 
	C250.541229,364.339111 247.985382,367.464325 245.234619,370.338776 
	C244.082336,371.542908 241.230453,372.965240 240.584824,372.459229 
	C238.741440,371.014526 237.399841,368.749237 236.306122,366.582001 
	C235.328125,364.644073 235.532364,361.884369 234.202820,360.384460 
	C223.168625,347.936462 211.899368,335.696808 200.300674,324.436981 
	C198.015259,323.886810 195.529099,323.731262 193.491226,322.694550 
	C190.390274,321.117004 190.544220,318.774628 192.781754,316.190857 
	C196.157684,312.292511 199.434723,308.296295 202.540253,304.180573 
	C204.462997,301.632355 206.367920,301.249420 209.386627,302.364349 
	C219.980576,306.277008 230.691483,309.873047 242.612823,314.026520 
	C238.801453,307.645782 235.576508,302.198242 232.302292,296.780518 
	C229.634201,292.365784 227.011139,287.917877 224.166504,283.618073 
	C222.512787,281.118317 222.684052,279.202728 224.655029,276.943359 
	C228.044296,273.058197 231.027847,268.815491 234.472855,264.984711 
	C235.595413,263.736420 238.330353,262.216461 239.100342,262.715363 
	C240.956711,263.918121 242.168076,266.185455 243.486572,268.127380 
	C244.530869,269.665497 244.939545,272.113861 246.329224,272.864685 
	C260.087006,280.297760 274.056335,287.339447 287.809540,294.780762 
	C291.545197,296.801971 294.736389,295.442413 296.871460,293.316742 
	C298.429413,291.765625 299.078369,288.024750 298.431702,285.741852 
	C294.034241,270.217529 289.193420,254.818573 284.473328,239.385986 
	C283.752380,237.028748 283.336273,234.803574 279.635040,235.029694 
	C278.198639,235.117462 276.175201,232.262543 275.219574,230.348495 
	C274.824829,229.557861 276.516266,227.707016 277.300018,226.362335 
	C277.457306,226.092499 277.799133,225.929138 278.058655,225.720200 
	C292.077148,214.434586 292.053406,214.475098 307.543152,223.575729 
	C321.029602,231.499374 334.537872,239.388184 348.138702,247.112488 
	C350.370178,248.379807 353.128845,248.718887 355.643860,249.486954 
	C356.008087,248.989914 356.372284,248.492859 356.736511,247.995819 
	C354.867828,244.208252 352.749634,240.515854 351.250763,236.587204 
	C350.633759,234.969971 350.613403,232.102325 351.615692,231.108505 
	C353.548492,229.192093 356.177002,227.886566 359.048767,230.669937 
	C361.403198,232.951889 364.115021,235.131409 367.072876,236.447021 
	C369.839233,237.677460 373.147522,238.221893 376.199249,238.161270 
	C381.613953,238.053711 386.373718,234.449585 386.234100,230.946899 
	C386.013275,225.405426 381.276398,225.235153 377.537476,224.551178 
	C371.168030,223.386017 364.640106,223.071213 358.284607,221.848907 
	C347.398651,219.755264 339.861938,213.877930 337.563904,202.378067 
	C335.964081,194.371887 338.797058,188.009369 344.278198,182.532043 
	C353.009094,173.807220 364.039856,171.410416 375.860168,171.315521 
	C376.352936,171.311569 377.156464,171.273224 377.294556,171.530426 
	C380.281586,177.094330 383.720001,182.507141 385.856934,188.390228 
	C386.685242,190.670624 380.238220,192.663528 377.923676,190.664917 
	C376.164581,189.145920 374.687622,187.181381 372.719360,186.053116 
	C370.192993,184.604950 367.357849,183.114655 364.551361,182.877533 
	C360.726807,182.554413 356.881775,184.140015 356.394958,188.528519 
	C355.946167,192.574081 358.806976,195.002731 362.687195,195.602386 
	C370.728668,196.845139 378.806519,197.853149 386.870392,198.950684 
	C395.830383,200.170197 401.938446,204.791794 404.285034,213.742508 
	C406.713470,223.005356 405.103058,231.392334 397.737549,237.856842 
	C387.589691,246.763321 375.557465,251.021469 363.528412,251.013443 
	C361.513367,254.029068 360.444611,256.735596 358.481110,258.361725 
	C352.334290,263.452332 345.922607,268.236298 339.443329,272.902740 
	C337.896881,274.016479 334.891541,275.300964 333.944611,274.608826 
	C331.745636,273.001526 329.096680,270.682251 331.194397,267.087982 
	C331.414246,266.711243 331.280579,266.128174 331.308197,265.684601 
	C323.231995,259.563416 323.373169,259.748138 311.021088,269.189423 
	C305.226715,273.618347 310.394897,277.355713 310.441559,280.806213 
	C312.930664,281.694122 315.186646,281.921326 316.611511,283.129028 
	C319.454590,285.538818 320.136230,288.366089 316.269257,290.769501 
	C303.203125,298.890533 293.198486,310.095459 284.186737,322.359924 
	C283.221436,323.673615 280.184479,325.163971 279.510071,324.667694 
	C277.633484,323.286835 276.392609,320.965851 275.109589,318.887268 
	C274.492249,317.887115 274.701569,315.979095 273.929810,315.533875 
	C266.802765,311.422211 259.543030,307.540558 252.321854,303.592041 
	C251.999374,303.917847 251.676910,304.243622 251.354446,304.569427 
	C254.784164,310.116638 258.202454,315.670929 261.647308,321.208710 
	C264.285797,325.450226 267.270386,329.517273 269.461456,333.976227 
	C270.339813,335.763672 270.257233,338.820679 269.278107,340.540344 
	C267.733521,343.253113 265.744720,346.022308 261.316040,344.410736 
	C250.688034,340.543213 239.892654,337.135590 228.610016,333.396271 
	C227.964096,333.568970 227.872971,333.887787 227.781830,334.206604 
M235.446198,297.019958 
	C239.543137,303.699493 243.640076,310.379059 247.737015,317.058594 
	C244.980392,317.521545 243.080048,317.105133 241.262390,316.456909 
	C231.236511,312.881409 221.274872,309.117279 211.180954,305.748077 
	C209.163269,305.074585 205.824341,304.927246 204.555176,306.107635 
	C201.184097,309.242981 198.547256,313.188904 195.771606,316.926239 
	C195.181915,317.720245 194.745697,319.288635 195.144485,319.970734 
	C195.543777,320.653717 197.120972,320.573090 198.104080,321.004425 
	C200.503632,322.057098 203.450485,322.648804 205.134033,324.418396 
	C215.451843,335.263519 225.617050,346.264008 235.487595,357.515869 
	C237.459808,359.764099 237.930893,363.325500 239.110489,366.274841 
	C239.501160,367.251709 239.706848,368.666779 240.430725,369.039581 
	C241.100418,369.384552 242.719131,368.921021 243.282501,368.265625 
	C246.283737,364.774109 249.084717,361.110474 252.221863,357.171326 
	C250.827713,356.328064 249.568863,355.056183 249.015259,355.311798 
	C244.197708,357.536194 242.051636,354.070587 239.489899,351.233337 
	C236.701401,348.144928 233.750305,345.203644 230.949051,342.126343 
	C226.968338,337.753357 223.059723,333.314697 219.120300,328.904114 
	C221.577744,328.404327 223.482941,328.723083 225.295334,329.322479 
	C236.982651,333.187836 248.661896,337.078735 260.313202,341.050995 
	C262.677643,341.857117 264.493134,342.322632 266.406982,339.846527 
	C268.262115,337.446350 267.613678,335.757568 266.256531,333.629883 
	C261.160858,325.641174 256.145721,317.600769 251.138580,309.556000 
	C248.739731,305.701843 246.426254,301.794586 243.606400,297.139191 
	C245.367569,297.320404 246.069580,297.204834 246.566422,297.471497 
	C256.239288,302.663452 265.919830,307.843475 275.500061,313.202484 
	C276.634247,313.836914 277.364716,315.420105 277.972137,316.709625 
	C278.675507,318.202759 279.035736,319.857513 279.544952,321.442108 
	C281.013336,320.347076 282.565430,319.345245 283.921967,318.125793 
	C284.899200,317.247345 285.611389,316.075470 286.449463,315.040649 
	C290.001282,310.654846 293.628479,306.326569 297.047028,301.839355 
	C297.712311,300.966095 297.569855,299.477386 297.799957,298.272583 
	C296.621429,298.116882 295.442810,297.962128 294.264435,297.805298 
	C291.443970,297.429840 288.266937,297.765167 285.862183,296.559875 
	C272.042236,289.633179 258.339508,282.461456 244.780060,275.038483 
	C242.837463,273.975037 241.830627,271.135468 240.503555,269.046173 
	C239.945236,268.167175 239.710800,266.295685 239.275787,266.279144 
	C238.167267,266.237030 236.676743,266.671692 235.963074,267.472015 
	C232.867065,270.943970 229.804245,274.483307 227.161575,278.297607 
	C226.414734,279.375549 226.598557,281.749390 227.277420,283.046356 
	C229.664093,287.606171 232.452011,291.955933 235.446198,297.019958 
M327.063477,259.555573 
	C333.605652,261.026611 333.776306,267.147156 335.235535,272.589630 
	C343.011932,266.770447 349.953644,261.642456 356.789978,256.377686 
	C357.665924,255.703064 357.993774,254.316696 358.575531,253.260056 
	C357.364868,252.746429 356.189178,252.114792 354.936310,251.743637 
	C352.301331,250.963089 349.323669,250.849548 347.015320,249.534378 
	C330.266846,239.991943 313.677368,230.170700 296.949585,220.591370 
	C295.261108,219.624451 292.351898,218.657104 291.100677,219.438248 
	C286.396393,222.375214 282.144257,226.036392 277.334198,229.724091 
	C278.712006,230.914795 279.431274,232.064255 280.118042,232.045166 
	C284.995850,231.909622 286.048370,235.371170 287.147766,238.997177 
	C291.342102,252.831039 295.615448,266.640900 299.817749,280.472351 
	C301.161591,284.895538 303.064209,289.291626 300.513000,293.987823 
	C300.185791,294.590240 300.585236,296.089783 301.150726,296.534393 
	C301.724152,296.985260 303.196411,297.024323 303.799957,296.587463 
	C307.925354,293.601440 311.925659,290.442566 316.221954,287.143036 
	C315.267487,286.051086 314.633087,284.705536 314.014587,284.712799 
	C307.181671,284.793060 307.268372,279.159943 305.626099,274.895294 
	C304.585327,272.192688 305.039307,270.170593 307.498718,268.419342 
	C311.557983,265.528870 315.569153,262.551178 319.406311,259.375488 
	C321.860046,257.344788 324.049469,257.068268 327.063477,259.555573 
M340.423401,203.597885 
	C343.533905,214.175232 351.685364,218.304626 361.628815,219.848267 
	C368.187744,220.866470 374.842163,221.264130 381.407654,222.247726 
	C385.585205,222.873581 388.285187,225.547958 388.948456,229.804779 
	C389.577057,233.839294 387.329559,236.661804 384.015015,238.224350 
	C381.079071,239.608429 377.797943,240.571533 374.576080,240.973312 
	C367.837097,241.813629 363.002441,237.852829 358.215851,233.823944 
	C357.191681,232.961868 355.405548,233.005051 353.970428,232.631195 
	C354.015747,233.965714 353.704529,235.446182 354.178864,236.605118 
	C355.245880,239.212128 356.892883,241.582489 357.951080,244.192200 
	C359.213013,247.304291 361.292938,248.425110 364.556763,248.132324 
	C376.078644,247.098801 386.965973,244.191025 395.389526,235.826202 
	C401.450684,229.807297 404.568634,222.682434 401.546295,213.654602 
	C398.744232,205.284775 392.541412,202.198257 384.696320,201.053314 
	C380.754395,200.478012 376.750702,200.333755 372.801208,199.800293 
	C368.530151,199.223389 364.186646,198.781784 360.066193,197.608704 
	C356.303192,196.537384 353.996948,193.709961 353.835052,189.481979 
	C353.670837,185.194687 355.945129,182.103317 359.616730,181.129684 
	C362.915985,180.254761 366.927307,180.204269 370.112427,181.291061 
	C373.206085,182.346649 375.582214,185.405624 378.416046,187.380219 
	C379.313232,188.005356 380.722229,188.275970 381.788818,188.089661 
	C382.211426,188.015854 382.767426,186.143219 382.537079,185.284119 
	C379.778839,174.996658 376.588104,172.959061 366.143890,174.586685 
	C365.321655,174.714813 364.485931,174.783951 363.678040,174.972672 
	C350.211456,178.118164 337.116547,188.473663 340.423401,203.597885 
z"/>
<path fill="#69547B" opacity="1.000000" stroke="none" 
	d="
M297.008331,449.051544 
	C295.694244,449.698792 294.383514,450.902100 293.065582,450.910065 
	C272.571381,451.033539 252.076157,451.043274 231.581482,450.978516 
	C226.318878,450.961884 224.495224,448.851013 226.612579,444.126007 
	C230.825714,434.724030 235.816742,425.673431 240.348724,416.410309 
	C242.425797,412.164886 243.649155,407.421448 246.171417,403.488586 
	C255.185822,389.432831 264.340790,375.451843 273.940552,361.792572 
	C279.812042,353.438232 286.124786,345.284454 293.094971,337.841888 
	C300.424561,330.015564 308.502258,322.818604 316.746765,315.938263 
	C327.173218,307.237122 337.737915,298.610260 348.907043,290.915283 
	C360.748810,282.756897 372.991547,275.003906 385.732117,268.365356 
	C408.695679,256.400055 432.824646,247.146851 458.095459,241.175140 
	C468.319855,238.759018 478.568085,236.293945 488.933350,234.665009 
	C499.867218,232.946701 510.929779,231.646866 521.979553,231.168732 
	C535.542358,230.581863 549.172729,230.555649 562.731323,231.192856 
	C573.500732,231.698975 584.333069,232.917114 594.910034,234.983353 
	C610.803711,238.088257 626.605835,241.814453 642.238953,246.045135 
	C652.111694,248.716934 661.923828,252.100677 671.197327,256.388702 
	C686.371643,263.405243 701.507812,270.705444 715.870483,279.218262 
	C729.469788,287.278687 742.210999,296.836884 755.007263,306.177795 
	C760.176880,309.951416 764.472839,314.916565 769.199463,319.303680 
	C773.893982,323.660919 778.643860,327.958496 783.330750,332.323792 
	C783.922241,332.874695 784.278503,333.678101 784.400513,334.714966 
	C783.450623,335.180176 782.844055,335.294983 782.019165,335.371826 
	C781.542542,335.184174 781.267090,335.079773 780.890015,334.694336 
	C778.855347,332.607635 776.905029,330.847168 774.787842,328.888672 
	C774.230652,328.352966 773.840393,328.015381 773.336060,327.375244 
	C772.251648,326.289062 771.281433,325.505402 770.218262,324.798218 
	C770.125366,324.874695 770.272888,324.684570 770.186768,324.367889 
	C768.253296,322.565887 766.406006,321.080597 764.505676,319.567749 
	C764.452698,319.540192 764.380920,319.444794 764.294922,319.152618 
	C763.245789,318.145630 762.282776,317.430817 761.229309,316.789490 
	C761.138916,316.863007 761.279419,316.677124 761.212402,316.368286 
	C760.451843,315.680450 759.758301,315.301483 759.033691,314.961548 
	C759.002625,315.000580 759.074951,314.931274 759.035156,314.602997 
	C757.791931,313.408112 756.588501,312.541504 755.346252,311.681763 
	C755.307495,311.688660 755.313354,311.610168 755.232117,311.296936 
	C753.768188,309.986908 752.385498,308.990143 750.892700,307.870483 
	C750.782532,307.747620 750.471619,307.637115 750.335449,307.338928 
	C749.159485,306.329468 748.119812,305.618164 747.038818,304.947235 
	C746.997559,304.987610 747.081665,304.913757 746.972534,304.599030 
	C745.593018,303.488983 744.322632,302.693665 742.921997,301.698212 
	C742.568542,301.256256 742.296875,301.097290 741.892090,300.703613 
	C740.880066,299.894928 739.952759,299.403809 738.918030,298.800934 
	C738.810547,298.689209 738.508789,298.617767 738.393860,298.305542 
	C737.181763,297.334442 736.084595,296.675568 734.993713,296.008331 
	C735.000000,296.000000 734.984680,296.013275 734.897278,295.693298 
	C733.545288,294.566803 732.280701,293.760284 730.908325,292.832581 
	C730.800476,292.711334 730.489441,292.618896 730.332092,292.317963 
	C725.790527,289.317719 721.406372,286.618408 716.907837,283.808807 
	C716.793579,283.698517 716.480835,283.643127 716.316589,283.343750 
	C714.134583,281.963501 712.116760,280.882599 709.983398,279.690247 
	C709.867737,279.578766 709.552124,279.519043 709.374695,279.235168 
	C707.807861,278.273132 706.418396,277.595001 704.920654,276.798706 
	C704.812378,276.680573 704.503967,276.593262 704.325378,276.300964 
	C696.750549,272.363007 689.354370,268.717377 681.739502,264.940979 
	C681.196960,264.714294 680.873047,264.618317 680.365417,264.225769 
	C676.427246,262.358643 672.672729,260.788086 668.646606,259.068665 
	C667.778076,258.761902 667.181152,258.604004 666.397949,258.157776 
	C665.139343,257.570801 664.067017,257.272186 662.757690,256.866608 
	C662.180237,256.702179 661.839783,256.644745 661.319702,256.280579 
	C659.096741,255.297958 657.053345,254.622070 654.788818,253.818253 
	C654.237915,253.612473 653.908203,253.534637 653.360413,253.178680 
	C642.395386,249.697495 631.648499,246.494431 620.598511,243.181122 
	C619.868286,243.040558 619.441101,243.010223 618.735596,242.728241 
	C612.925476,241.480820 607.393555,240.485062 601.493896,239.359253 
	C600.091492,239.143265 599.056763,239.057343 597.808289,238.702271 
	C596.707031,238.348190 595.819519,238.263260 594.622070,238.082413 
	C593.880493,237.976334 593.448914,237.966171 592.761292,237.691956 
	C591.289185,237.429230 590.073120,237.430542 588.487061,237.313034 
	C587.084778,237.131470 586.052368,237.068741 584.770874,236.716797 
	C582.987549,236.357895 581.453247,236.288208 579.570679,236.115372 
	C578.498474,235.971039 577.774658,235.929871 576.749817,235.605087 
	C563.669922,234.659103 550.876648,233.240982 538.115112,233.485123 
	C507.596222,234.068985 477.496368,238.208191 448.179779,246.993073 
	C440.135101,249.403702 432.329010,252.610397 424.105072,255.463470 
	C422.840668,255.909241 421.884613,256.343353 420.565369,256.753387 
	C418.986633,257.363770 417.771118,257.998199 416.218994,258.666382 
	C414.259827,259.461060 412.637177,260.222015 410.632324,260.925537 
	C404.435760,263.678345 398.621429,266.488617 392.499756,269.394897 
	C391.806732,269.676605 391.421051,269.862366 390.671722,270.025269 
	C387.727570,271.558502 385.147034,273.114594 382.376801,274.722687 
	C381.921875,274.874359 381.744659,275.058624 381.304321,275.350403 
	C379.319183,276.281158 377.685089,277.189026 375.705383,278.060974 
	C374.099609,278.923615 372.839478,279.822113 371.422211,280.769836 
	C371.265045,280.819031 371.036255,281.056000 370.718933,281.045349 
	C369.839233,281.570740 369.276825,282.106812 368.696167,282.693726 
	C368.677948,282.744598 368.767029,282.805695 368.488647,282.805023 
	C367.736115,283.075989 367.261963,283.347595 366.448853,283.649902 
	C365.270111,284.352844 364.430298,285.025024 363.429871,285.746765 
	C363.269257,285.796326 363.033051,286.035522 362.675049,286.011902 
	C360.115448,287.593262 357.913788,289.198212 355.537659,290.872314 
	C355.363220,290.941437 355.099060,291.208008 354.752991,291.164795 
	C352.801392,292.324707 351.195862,293.527802 349.313293,294.822083 
	C348.331757,295.559540 347.627228,296.205780 346.593445,296.868530 
	C345.672485,297.468658 345.080719,298.052277 344.337860,298.656982 
	C344.186737,298.678131 343.959747,298.882141 343.638062,298.903717 
	C342.726837,299.514679 342.137299,300.104065 341.390625,300.719208 
	C341.233521,300.744965 340.994446,300.955261 340.645996,300.973206 
	C338.098663,302.933075 335.899780,304.874969 333.417480,306.901917 
	C332.399994,307.616516 331.665924,308.246002 330.598083,308.908508 
	C328.808777,310.259949 327.353241,311.578369 325.953796,312.932434 
	C326.009949,312.968048 325.902527,312.884430 325.569397,312.928558 
	C323.690735,314.563171 322.145233,316.153656 320.347107,317.818970 
	C319.724884,318.251007 319.355316,318.608246 318.664551,319.042816 
	C317.145874,320.335175 315.948395,321.550201 314.429596,322.850739 
	C312.970184,324.227417 311.832092,325.518585 310.429993,326.951843 
	C309.646515,327.690002 309.127075,328.286041 308.288391,328.943604 
	C292.137787,343.904114 278.372192,360.572937 265.942810,378.358948 
	C263.225037,382.247955 261.026337,386.499725 258.757141,390.642090 
	C258.925690,390.699768 258.577026,390.626343 258.249939,390.699036 
	C253.873764,397.827454 249.824677,404.883179 245.627625,412.034821 
	C245.479660,412.130768 245.352386,412.459686 245.099945,412.632812 
	C244.568466,413.545593 244.289413,414.285309 243.854324,415.241211 
	C243.578262,415.790558 243.458252,416.123657 243.073730,416.632141 
	C242.381393,417.752502 241.953583,418.697479 241.360260,419.916504 
	C241.128418,420.393494 241.062103,420.596375 240.731476,420.930328 
	C239.963211,422.368042 239.459274,423.674652 238.728516,425.209900 
	C238.160843,426.461121 237.820007,427.483734 237.214752,428.671478 
	C236.674759,429.581665 236.399185,430.326691 235.983139,431.297546 
	C235.751175,431.864685 235.659668,432.205963 235.287766,432.699707 
	C234.768784,433.613281 234.530197,434.374390 234.066040,435.360962 
	C233.587479,436.392822 233.334457,437.199249 232.763214,438.164032 
	C231.468536,440.533752 230.312042,442.691681 229.630371,444.990417 
	C229.437332,445.641418 230.630539,446.703491 231.550873,447.738831 
	C237.553253,447.950928 243.192673,448.011871 248.832260,448.049377 
	C262.272552,448.138702 275.712921,448.216980 289.298889,448.477966 
	C290.154083,448.682709 290.863647,448.709198 291.949890,448.569855 
	C293.767670,447.207611 295.671204,446.282013 296.561005,444.762726 
	C298.982544,440.628113 300.975311,436.242340 303.352966,431.793396 
	C303.719025,431.122009 303.870148,430.613281 304.122284,429.763763 
	C307.602753,423.050873 310.982208,416.678802 314.482239,410.141510 
	C314.602753,409.976288 314.708801,409.581268 314.990417,409.435791 
	C315.887299,408.215118 316.502533,407.139893 317.239624,405.940186 
	C317.361450,405.815674 317.488251,405.491119 317.767395,405.344666 
	C318.340302,404.449280 318.634094,403.700409 319.043213,402.815796 
	C319.158508,402.680115 319.264069,402.339996 319.590118,402.249878 
	C320.704163,400.835114 321.492157,399.510437 322.472595,398.010620 
	C322.895599,397.372101 323.126190,396.908722 323.644043,396.325684 
	C325.068054,395.042572 326.204773,393.879150 327.728760,392.791016 
	C328.378174,392.887146 328.640381,392.908020 328.960205,392.978210 
	C329.017822,393.027496 329.128204,393.131561 329.128204,393.131561 
	C324.688660,400.478333 320.171753,407.779755 315.833252,415.185730 
	C312.116150,421.530914 308.519165,427.950348 305.028168,434.422760 
	C302.467194,439.170959 300.163635,444.057983 297.330444,448.807739 
	C296.825653,448.682770 296.735809,448.633240 296.645935,448.583710 
	C296.766724,448.739655 296.887512,448.895599 297.008331,449.051544 
z"/>
<path fill="#644D77" opacity="1.000000" stroke="none" 
	d="
M758.431580,703.590942 
	C757.984680,702.540771 756.852539,701.097839 757.195129,700.500122 
	C761.376465,693.204285 765.554077,685.882629 770.276917,678.937805 
	C771.406677,677.276489 774.529724,676.204102 776.753235,676.174988 
	C793.907654,675.950378 811.066833,675.983948 828.223450,676.091736 
	C832.752930,676.120178 834.112061,677.940308 832.299927,682.178528 
	C829.638367,688.403625 826.704285,694.583618 823.190308,700.359314 
	C812.951538,717.188354 802.599548,734.066162 788.873779,748.306091 
	C777.310303,760.302795 765.496277,772.291443 752.424805,782.538025 
	C738.309021,793.603333 723.439636,803.968750 706.691345,811.109436 
	C693.154907,816.880737 679.784302,823.041626 666.234985,828.781433 
	C662.143372,830.514648 657.741760,831.583862 653.409485,832.666931 
	C643.984924,835.023071 634.595703,837.672119 625.039124,839.307617 
	C614.789795,841.061646 604.383240,841.999207 594.011108,842.898621 
	C586.248718,843.571716 578.424133,844.018738 570.641113,843.865234 
	C552.074341,843.499084 533.635803,841.685242 515.414490,837.896545 
	C491.610626,832.947021 468.473145,825.590698 446.963409,814.483887 
	C430.914795,806.196899 416.061737,795.510620 401.039307,785.355774 
	C393.554382,780.296082 386.831207,774.094238 379.892395,768.255798 
	C378.588776,767.158997 377.895660,765.336670 377.081543,763.300659 
	C377.365692,762.625061 377.489136,762.497986 377.922119,762.494507 
	C386.153137,769.374939 394.074615,776.131592 402.037994,783.062256 
	C402.355713,783.283447 402.631531,783.330566 403.176819,783.333374 
	C403.620636,783.236694 403.795044,783.184448 404.051117,783.241760 
	C404.132751,783.351318 404.405273,783.371460 404.512817,783.699463 
	C406.796967,785.488403 408.973602,786.949341 411.311707,788.634399 
	C411.800171,789.017029 412.127197,789.175415 412.599762,789.643677 
	C414.841309,791.278687 416.937317,792.603821 419.207886,794.108032 
	C419.696320,794.385742 420.010193,794.484253 420.499207,794.869934 
	C424.705627,797.525146 428.736938,799.893250 432.819275,802.131836 
	C432.870331,802.002319 432.735138,802.245789 432.723206,802.459717 
	C432.711243,802.673645 432.754608,803.099854 432.655884,803.281006 
	C433.047058,803.623962 433.536926,803.785645 434.016357,803.972778 
	C434.005890,803.998230 434.040161,803.954163 434.209503,804.226318 
	C434.986145,804.632996 435.593445,804.767456 436.498657,804.892700 
	C437.188995,804.938965 437.581451,804.994385 438.113556,805.361084 
	C444.178986,808.836548 450.104767,812.000732 456.170166,815.367798 
	C456.566650,815.806519 456.869385,815.918701 457.534973,815.896606 
	C458.263611,815.817505 458.675293,815.749146 459.323212,815.908081 
	C460.560516,816.553101 461.561584,816.970764 462.545959,817.602295 
	C462.529236,817.816162 462.539459,818.245239 462.520203,818.432312 
	C462.986206,818.748352 463.471436,818.877258 464.304413,819.108337 
	C465.818787,819.396179 466.985382,819.581848 468.440063,819.901367 
	C469.316315,820.274841 469.904480,820.514465 470.620728,820.977417 
	C471.173370,821.499390 471.597961,821.797974 472.194794,822.325623 
	C472.909851,822.701050 473.452637,822.847412 474.331909,822.990784 
	C475.115875,822.989136 475.563385,822.990540 476.324463,823.159302 
	C484.791016,825.782349 492.943939,828.238037 501.393951,830.819946 
	C502.117126,830.992126 502.543182,831.038025 503.169556,831.336060 
	C504.579346,832.055237 505.788818,832.522217 507.239258,833.274048 
	C510.039673,834.009277 512.599060,834.459656 515.528992,834.893982 
	C516.631042,834.819092 517.362610,834.760193 518.441345,834.815552 
	C519.516418,834.977600 520.244263,835.025391 521.203003,835.344849 
	C523.290100,836.076355 525.146240,836.536194 527.081909,837.165649 
	C527.437439,837.242432 527.713440,837.149597 528.332153,837.026001 
	C529.780212,837.266968 530.885681,837.538635 532.060181,838.009033 
	C532.421692,838.113281 532.714233,838.018677 533.411743,837.892212 
	C534.873352,838.041199 535.929871,838.222168 537.064392,838.695068 
	C538.110352,838.952087 539.078247,838.917114 540.492065,838.852295 
	C543.323059,838.801514 545.708130,838.780457 548.332214,838.901245 
	C548.711426,839.163940 548.851685,839.284912 549.043701,839.668701 
	C549.742981,839.935425 550.390503,839.939453 551.474976,839.886169 
	C555.537415,840.069214 559.164185,840.530273 562.788086,840.508606 
	C573.858398,840.442505 584.927612,840.190735 596.179016,840.257812 
	C597.242554,840.120056 598.124329,839.735718 599.214600,839.179810 
	C599.581665,838.923279 599.740356,838.838318 600.345947,838.887939 
	C604.868896,838.711121 608.944824,838.399658 613.183472,838.305359 
	C613.873291,838.276062 614.400513,838.029602 615.268494,837.593262 
	C618.754761,837.003174 621.900208,836.603027 625.217224,836.396851 
	C625.906921,836.348267 626.425110,836.105652 627.268127,835.658142 
	C630.182800,835.226074 632.772583,834.998840 635.755737,834.750732 
	C637.759094,834.143738 639.369202,833.557739 640.989624,832.985840 
	C641.000000,833.000000 640.982849,832.971863 641.359253,832.987915 
	C642.792297,832.590332 643.848938,832.176636 644.939453,831.555115 
	C644.973267,831.347290 644.918518,830.929626 645.174805,830.944946 
	C645.593933,830.907715 645.756775,830.855225 646.325500,830.909668 
	C652.319275,829.149719 657.907166,827.282715 663.748169,825.443115 
	C664.325928,825.380737 664.650757,825.290894 665.244629,825.371704 
	C668.798828,824.608093 672.083862,823.673950 675.594910,822.780518 
	C675.964600,822.794250 676.108337,822.767334 676.252014,822.740417 
	C676.177856,822.496826 676.103760,822.253296 676.051514,821.767334 
	C676.073486,821.524902 676.038757,821.039429 676.385193,821.032471 
	C677.792542,820.609924 678.853394,820.194275 680.293823,819.841553 
	C683.641968,818.424805 686.610596,816.945190 689.832153,815.457275 
	C690.389221,815.310425 690.693420,815.171814 691.376770,815.056091 
	C694.491089,813.660583 697.226318,812.242126 700.245789,810.806152 
	C701.010864,810.510803 701.491699,810.233032 702.343567,809.974304 
	C706.994629,807.476440 711.274597,804.959473 715.844421,802.385376 
	C716.702820,802.193481 717.271423,802.058777 718.160522,801.933289 
	C718.974121,801.603149 719.467224,801.263794 720.122192,800.696899 
	C720.572693,800.005615 720.861389,799.541931 721.390015,798.961060 
	C722.081970,798.562317 722.533875,798.280640 723.342285,797.997803 
	C726.160522,796.670593 728.622314,795.344482 731.383545,793.986084 
	C732.103455,793.575623 732.523926,793.197449 733.030212,792.564941 
	C733.222046,791.971008 733.328064,791.631287 733.594971,791.261108 
	C733.755981,791.230713 733.992676,791.004211 734.314575,791.023804 
	C735.412109,790.697876 736.187744,790.352295 737.167358,789.931396 
	C737.636719,789.714478 737.829834,789.505981 738.226929,789.015381 
	C739.949219,787.678650 741.435852,786.605408 742.834534,785.427795 
	C750.022949,779.375732 757.188843,773.296814 764.528564,767.161499 
	C764.694641,767.095703 764.914490,766.814209 765.241821,766.766235 
	C767.236450,765.017761 768.903687,763.317261 770.862061,761.499084 
	C771.790405,760.605835 772.427551,759.830261 773.035278,759.026123 
	C773.005798,758.997559 773.051147,759.065308 773.363159,759.048462 
	C774.667297,758.311829 775.659424,757.591919 776.851440,756.868896 
	C777.169250,756.812622 777.393921,756.738098 777.388184,756.709656 
	C777.342407,756.480835 777.253296,756.260620 777.213623,755.772583 
	C777.372803,755.173584 777.495667,754.839783 777.813538,754.408081 
	C778.252441,754.131287 778.419556,753.897583 778.810852,753.488098 
	C779.736877,752.552673 780.362061,751.738159 781.265198,750.841187 
	C781.984436,750.163269 782.425659,749.567871 783.184570,748.917114 
	C784.126648,748.066589 784.750977,747.271484 785.384766,746.432251 
	C785.394226,746.388000 785.478271,746.354614 785.776611,746.249390 
	C786.749939,745.108154 787.424927,744.072144 788.220825,742.944641 
	C788.341736,742.853149 788.425049,742.561462 788.752014,742.453613 
	C790.432434,740.603333 791.785828,738.860962 793.079590,737.059753 
	C793.019958,737.000977 793.119751,737.135254 793.449585,737.073120 
	C795.196045,735.006714 796.612732,733.002502 798.156738,730.908691 
	C798.283936,730.819092 798.382263,730.523804 798.686646,730.385986 
	C799.689880,729.179688 800.388733,728.111267 801.297546,726.933899 
	C801.772644,726.628540 801.944885,726.370300 802.347900,725.944336 
	C806.121582,720.557617 809.571594,715.276733 813.145813,709.896912 
	C813.269958,709.797974 813.348755,709.490356 813.656494,709.334900 
	C815.138977,707.450562 816.313721,705.721680 817.782837,703.903687 
	C818.336609,703.089478 818.596069,702.364502 818.831421,701.365845 
	C818.861877,700.730591 818.916504,700.369019 819.289307,699.874756 
	C821.472839,696.189819 823.338196,692.637756 825.302368,688.966553 
	C825.401245,688.847473 825.424622,688.538818 825.725586,688.354004 
	C826.998352,685.174500 827.970215,682.179749 828.722900,678.856934 
	C826.002563,678.686218 823.501404,678.843506 820.587646,679.078247 
	C818.781433,679.100159 817.387878,679.044617 815.700256,678.693359 
	C807.316772,678.265076 799.227295,678.060059 791.137695,678.050537 
	C789.092224,678.048096 787.045959,678.669617 784.576477,679.083740 
	C782.434448,679.107239 780.716064,679.051392 778.759888,678.721313 
	C776.985168,678.722778 775.448059,678.998413 773.758057,679.536255 
	C772.707642,681.208557 771.810181,682.618591 770.686279,684.185791 
	C770.301331,684.860229 770.142822,685.377502 769.865234,686.218079 
	C767.474243,690.688538 765.202209,694.835693 762.822876,699.096191 
	C762.715454,699.209534 762.673706,699.519043 762.358582,699.618408 
	C760.839539,701.008850 759.635559,702.299927 758.431580,703.590942 
z"/>
<path fill="#695378" opacity="1.000000" stroke="none" 
	d="
M377.181396,785.939270 
	C383.070526,790.429626 388.798248,795.153992 394.887421,799.354431 
	C403.577301,805.348938 412.382538,811.206848 421.415314,816.665039 
	C430.578949,822.202209 439.771423,827.870483 449.512604,832.225769 
	C460.670013,837.214111 472.366150,841.056763 483.979126,844.946838 
	C492.248535,847.716797 500.702789,850.016479 509.194641,852.015991 
	C516.419006,853.717102 523.768433,855.301575 531.144287,855.862854 
	C549.153503,857.233337 567.205688,858.684875 585.248779,858.798340 
	C607.005981,858.935059 628.570068,855.923218 649.433228,849.597412 
	C664.384155,845.064209 678.951660,839.279541 693.760132,834.259338 
	C710.492126,828.587158 725.659851,819.770752 739.944763,809.774841 
	C755.068909,799.191772 770.007019,788.013428 783.386902,775.361328 
	C796.303955,763.146973 807.576355,749.078369 818.716370,735.141052 
	C825.907227,726.144470 831.673401,715.989624 837.839050,706.203308 
	C840.112305,702.595032 841.560364,698.476929 843.647339,694.737915 
	C845.196655,691.962097 847.169678,689.422729 849.326172,686.462708 
	C849.927185,686.191345 850.156006,686.235291 850.312378,686.640442 
	C849.650513,688.693726 849.196655,690.448853 848.444214,692.065002 
	C846.722900,695.762390 844.855713,699.391846 842.804932,703.283203 
	C839.299194,709.305908 836.036316,715.094421 832.505371,721.081421 
	C828.144165,727.510193 824.051025,733.740479 819.710815,740.196899 
	C818.807007,741.172607 818.103638,741.886475 817.500549,742.676880 
	C796.839966,769.752380 772.334106,792.721680 743.980652,811.590515 
	C726.334290,823.333984 707.487244,832.803528 687.734070,841.121765 
	C686.735657,841.547485 686.061523,842.026855 685.044800,842.518677 
	C683.825256,842.776672 682.948364,843.022339 681.760498,843.191895 
	C680.759033,843.561829 680.068481,844.007812 679.029907,844.505615 
	C677.152832,845.178772 675.623840,845.800232 673.747864,846.327881 
	C671.740112,847.010437 670.079346,847.786621 668.073669,848.559570 
	C666.835876,848.753967 665.943054,848.951599 664.705811,849.105896 
	C663.591736,849.440430 662.822021,849.818237 661.672241,850.202820 
	C658.574829,851.002319 655.857544,851.795166 652.850830,852.464233 
	C652.039307,852.607605 651.517151,852.874756 650.695557,853.118652 
	C649.928223,853.399414 649.460510,853.703369 648.600098,853.981567 
	C647.194092,854.186279 646.180725,854.416687 644.818970,854.509766 
	C642.647583,854.939087 640.824707,855.505737 638.602661,856.064270 
	C637.171692,856.282715 636.140015,856.509216 634.822021,856.586792 
	C633.834534,856.815796 633.133423,857.193665 632.201050,857.503906 
	C631.641968,857.385498 631.327148,857.428955 630.832153,857.417969 
	C630.088074,857.509460 629.537048,857.749634 628.570984,857.980591 
	C626.820496,858.237732 625.485107,858.504028 623.880005,858.599976 
	C622.884705,858.748413 622.159058,859.067200 621.199280,859.374512 
	C620.649048,859.381287 620.346497,859.454895 619.807983,859.404297 
	C618.361572,859.506409 617.164673,859.788086 615.532715,860.032837 
	C613.104858,860.257446 611.112183,860.518982 608.852051,860.636108 
	C608.058533,860.681030 607.532410,860.870483 606.638794,861.017090 
	C605.547180,861.098572 604.823059,861.222839 603.678955,861.217834 
	C600.843567,861.430359 598.428223,861.772156 595.548889,862.069458 
	C591.083557,862.298035 587.082275,862.571106 582.645508,862.642334 
	C576.448425,862.538025 570.686829,862.635681 564.509521,862.571899 
	C561.408264,862.386414 558.722717,862.362366 555.684998,862.178467 
	C554.562256,862.002136 553.791626,861.985718 552.744995,861.708496 
	C550.944885,861.480896 549.420776,861.514099 547.491577,861.429138 
	C545.397888,861.214844 543.709351,861.118591 541.880371,860.809204 
	C541.143494,860.670166 540.547119,860.744202 539.573059,860.737610 
	C537.134888,860.443787 535.074341,860.230591 532.756470,859.739380 
	C529.292664,859.088623 526.086060,858.716003 522.574951,858.249573 
	C521.846497,858.127686 521.422546,858.099609 520.775818,857.768188 
	C517.986816,857.131042 515.420593,856.797302 512.552307,856.353516 
	C511.823517,856.234619 511.396790,856.225830 510.742676,855.914307 
	C505.627289,854.578247 500.739349,853.544983 495.555908,852.386902 
	C494.839539,852.196106 494.418640,852.130188 493.785248,851.763489 
	C488.668304,849.937012 483.749878,848.454651 478.863007,846.874512 
	C472.896484,844.945435 466.956482,842.934387 460.864807,840.860718 
	C460.724640,840.762634 460.385620,840.716309 460.224396,840.401733 
	C458.043701,839.375916 456.024231,838.664734 453.786072,837.814575 
	C453.235443,837.586121 452.903564,837.496582 452.368866,837.131836 
	C447.121613,834.546631 442.077179,832.236572 436.813812,829.784790 
	C436.264862,829.537964 435.934875,829.432800 435.425720,829.049805 
	C433.813782,828.211365 432.380920,827.650696 430.810211,826.975220 
	C430.672333,826.860413 430.323456,826.776428 430.202026,826.454346 
	C427.393799,824.733704 424.707001,823.335205 421.796631,821.752502 
	C420.869812,821.258118 420.166565,820.947937 419.315857,820.330566 
	C413.202667,816.261047 407.236938,812.498718 401.197937,808.857483 
	C401.124695,808.978577 401.240021,808.720154 401.148315,808.400024 
	C398.381653,806.358154 395.706696,804.636414 392.926880,802.791260 
	C392.821991,802.667908 392.521759,802.546387 392.381958,802.242920 
	C389.837402,800.273621 387.432709,798.607788 384.880249,796.691895 
	C383.958862,795.860962 383.185272,795.280151 382.327637,794.372070 
	C380.815521,793.049194 379.387421,792.053589 377.837830,790.941284 
	C377.716339,790.824463 377.395752,790.720276 377.298828,790.400757 
	C375.707275,788.436340 374.212616,786.791443 373.130981,785.099670 
	C374.375305,785.020996 375.206635,784.989258 376.037994,784.957520 
	C376.037994,784.957520 376.021118,784.974976 376.135315,785.216797 
	C376.560181,785.618774 376.870789,785.778992 377.181396,785.939270 
z"/>
<path fill="#69517A" opacity="1.000000" stroke="none" 
	d="
M777.065918,800.172668 
	C772.911743,804.435852 769.013428,808.992004 764.544617,812.894958 
	C757.486267,819.059448 750.325012,825.179810 742.694397,830.596375 
	C733.433044,837.170593 723.650085,843.011719 714.070068,849.134277 
	C710.186707,851.616150 706.401855,854.321655 702.296082,856.372681 
	C691.103271,861.964417 680.010986,867.881653 668.416809,872.513245 
	C654.944458,877.895020 641.214661,882.986816 627.167908,886.481445 
	C611.658264,890.339966 595.749634,893.043091 579.854126,894.774353 
	C564.218567,896.477234 548.337646,897.377136 532.644653,896.717651 
	C515.013611,895.976807 497.432861,893.394653 479.901520,891.074707 
	C453.567230,887.589844 428.813416,878.493896 404.592102,868.108765 
	C383.567871,859.094360 363.665253,847.901123 345.397675,833.976196 
	C341.473145,830.984558 337.308990,828.307068 333.387634,825.311584 
	C332.303253,824.483215 331.695679,823.030640 331.235382,821.409363 
	C331.870850,820.897644 332.140259,820.841125 332.678650,820.807007 
	C333.302490,820.865662 333.657349,820.901855 334.113373,821.233643 
	C334.893982,821.975220 335.573364,822.421204 336.283905,823.192871 
	C337.397736,824.133545 338.480408,824.748596 339.603210,825.363831 
	C339.643280,825.364075 339.670105,825.439636 339.750366,825.757141 
	C342.239410,827.674500 344.648193,829.274353 347.186768,830.998535 
	C347.316559,831.122864 347.654694,831.244873 347.751526,831.567871 
	C349.137207,832.718323 350.426086,833.545654 351.891357,834.515686 
	C352.329041,834.810608 352.595764,834.952515 352.993042,835.386841 
	C354.071686,836.143066 355.025269,836.596436 356.168884,837.290405 
	C357.427887,838.135132 358.496918,838.739441 359.670593,839.668945 
	C362.540802,841.619995 365.306335,843.245728 368.222229,844.977844 
	C368.372559,845.084229 368.728363,845.179321 368.848419,845.502197 
	C371.768005,847.537903 374.441498,849.515930 377.389099,850.917297 
	C389.555420,856.701172 401.804840,862.310303 414.246826,868.124390 
	C414.801758,868.378052 415.133545,868.491028 415.678406,868.869263 
	C426.031433,872.834045 436.088226,876.788086 446.342560,880.137207 
	C452.448334,882.131348 458.864594,883.174866 465.440979,884.751465 
	C466.163422,884.865356 466.586121,884.873596 467.253601,885.172791 
	C471.044952,886.270386 474.556641,887.317688 478.144745,887.837524 
	C488.111938,889.281494 498.110901,890.506042 508.405579,891.894287 
	C509.136292,891.980408 509.558746,891.989441 510.292755,892.252502 
	C513.446533,892.562683 516.288757,892.618835 519.433838,892.744995 
	C520.150879,892.816101 520.565002,892.817200 521.265625,893.123291 
	C532.310974,893.589294 543.069519,893.811462 553.828857,893.864014 
	C556.224121,893.875732 558.622253,893.290039 561.380920,892.989868 
	C562.460754,892.930176 563.178833,892.859802 564.342224,892.917847 
	C568.190796,892.683105 571.593994,892.320068 575.320068,891.994019 
	C576.066711,891.958923 576.490601,891.886780 577.344360,891.910095 
	C581.860901,891.348755 585.947449,890.692017 590.393555,890.039429 
	C591.465210,889.953003 592.177246,889.862305 593.309204,889.895264 
	C596.678467,889.571594 599.675049,889.323120 602.567749,888.636658 
	C609.100830,887.086182 615.582581,885.319336 622.486389,883.616150 
	C624.594238,883.059998 626.300964,882.528320 628.372864,882.028320 
	C629.430176,881.839050 630.122314,881.618042 631.094849,881.541077 
	C632.093445,881.345642 632.811462,881.006226 633.780334,880.674866 
	C634.360168,880.627686 634.689209,880.572510 635.241089,880.643188 
	C636.126099,880.298279 636.788269,879.827454 637.741821,879.321167 
	C638.365356,879.090393 638.697449,878.895203 639.330750,878.778809 
	C640.029663,878.762756 640.427429,878.667969 641.198669,878.630005 
	C644.047363,877.732666 646.522583,876.778442 649.319702,875.782593 
	C650.414001,875.382507 651.186279,875.024170 652.318359,874.726685 
	C664.623108,869.787231 676.807068,865.284119 688.463440,859.683167 
	C712.923096,847.930054 735.429199,832.998474 756.381165,815.766052 
	C763.275879,810.095276 769.883362,804.075256 776.741699,798.702393 
	C776.929504,799.517517 776.997681,799.845093 777.065918,800.172668 
z"/>
<path fill="#6D587B" opacity="1.000000" stroke="none" 
	d="
M724.903870,387.075226 
	C719.957092,383.187866 715.169922,379.074036 710.033142,375.456390 
	C695.613220,365.300781 681.403931,354.773407 666.405396,345.539886 
	C657.769531,340.223450 647.952515,336.604218 638.328735,333.169464 
	C626.039673,328.783447 613.533752,324.858551 600.884155,321.677887 
	C591.621216,319.348724 582.017761,318.123840 572.489563,317.171265 
	C563.416687,316.264160 554.230652,315.851166 545.119324,316.107819 
	C529.745300,316.540802 514.435913,317.515228 499.223877,320.564148 
	C481.955933,324.025055 465.261414,328.865021 448.874481,335.329773 
	C431.735168,342.091370 415.761871,350.830231 400.783752,361.352081 
	C389.897583,368.999420 379.416595,377.265472 369.145508,385.729614 
	C363.597748,390.301331 358.869720,395.867767 353.272766,400.883301 
	C352.773651,400.781128 352.463867,400.480957 352.571716,400.179504 
	C353.506805,398.615906 354.334045,397.353760 355.433167,395.901306 
	C363.829468,387.857697 371.953888,380.004395 380.389954,372.092346 
	C381.288605,371.450043 381.875580,370.866486 382.744019,370.115601 
	C399.948578,356.730072 417.841461,345.025604 437.589142,336.486023 
	C449.281677,331.429749 461.318115,327.168671 473.427856,322.550781 
	C473.655823,322.551544 474.040649,322.305023 474.450623,322.343201 
	C478.573578,321.277679 482.286560,320.173981 486.379456,319.128357 
	C487.495605,319.015259 488.231903,318.844086 489.354919,318.726501 
	C493.199066,317.942902 496.656464,317.105743 500.440613,316.309692 
	C501.175659,316.204102 501.583984,316.057434 502.364197,316.009247 
	C504.184326,315.803772 505.632599,315.499817 507.407104,315.248627 
	C508.153259,315.182556 508.573120,315.063690 509.423859,315.031342 
	C523.303162,314.375214 536.747009,313.169403 550.201416,313.037781 
	C560.798096,312.934113 571.407654,314.154480 582.397095,314.941589 
	C584.160645,315.135834 585.538635,315.175262 587.194580,315.465088 
	C589.987183,316.088257 592.501709,316.461060 595.324158,316.977234 
	C596.079651,317.118958 596.527161,317.117371 597.229675,317.373047 
	C599.663147,317.999969 601.841736,318.369659 604.361694,318.891022 
	C605.444153,319.087494 606.185181,319.132263 607.167603,319.455322 
	C612.982727,321.121643 618.556580,322.509705 624.414795,324.017700 
	C625.705200,324.406219 626.711121,324.674805 627.879395,325.216125 
	C635.976135,328.422821 643.910400,331.356812 652.101379,334.545349 
	C653.265625,335.101654 654.173279,335.403381 655.261658,335.939880 
	C664.409851,341.071106 673.398438,345.929321 682.335632,350.880371 
	C686.760559,353.331696 691.090820,355.953949 695.495483,358.472473 
	C695.527039,358.446106 695.567993,358.517487 695.643799,358.835663 
	C699.470825,361.829803 703.221985,364.505737 707.041565,367.248932 
	C707.109924,367.316193 707.301270,367.302307 707.396973,367.647980 
	C708.570801,368.993286 709.661377,369.979858 710.725037,370.994629 
	C717.595154,377.549408 724.459473,384.110352 731.228882,390.767700 
	C731.132080,390.866119 730.937805,391.062286 730.604492,391.041992 
	C729.825256,391.032745 729.379211,391.043793 728.933228,391.054810 
	C728.300659,390.606415 727.668091,390.158051 726.908936,389.165527 
	C726.606140,388.323395 726.348694,388.138550 726.009949,388.066833 
	C726.009949,388.066833 725.947144,388.052673 725.843323,387.797058 
	C725.530334,387.261749 725.251770,387.106354 724.903870,387.075226 
z"/>
<path fill="#6A537A" opacity="1.000000" stroke="none" 
	d="
M387.987183,352.909973 
	C391.065094,350.038574 393.835876,346.715210 397.276276,344.377228 
	C409.436676,336.113464 421.631226,327.865143 434.198700,320.247620 
	C442.342377,315.311493 450.940216,310.967865 459.717102,307.265656 
	C478.349548,299.406281 497.506653,292.871521 517.490540,289.489349 
	C530.179382,287.341827 543.062439,286.262482 555.891296,285.052032 
	C563.309387,284.352051 570.803040,283.926331 578.244629,284.109344 
	C593.804810,284.492004 609.255859,285.699829 624.671387,288.512939 
	C641.805298,291.639587 658.637390,295.727356 674.772644,302.024658 
	C687.656006,307.052795 699.933228,313.652313 712.396790,319.724091 
	C715.427429,321.200531 718.132996,323.344238 720.839661,325.759796 
	C720.548218,326.494751 720.405151,326.651001 719.947632,326.676758 
	C709.165344,321.244385 698.911377,315.449493 688.165466,310.788452 
	C678.675354,306.672119 668.636902,303.820099 658.551086,300.288452 
	C657.849731,300.095703 657.437805,300.024292 656.832947,299.644226 
	C652.695984,298.406128 648.751953,297.476685 644.523926,296.413452 
	C643.828491,296.214630 643.417114,296.149628 642.789307,295.781006 
	C640.333801,295.108124 638.094788,294.738831 635.556458,294.265381 
	C634.837402,294.139008 634.417603,294.116760 633.745728,293.808472 
	C624.032959,292.086182 614.572205,290.649963 604.851074,289.181396 
	C604.241211,289.150085 603.891724,289.151123 603.251221,288.925201 
	C599.633240,288.582947 596.306213,288.467682 592.674561,288.318787 
	C591.758423,288.302399 591.146912,288.319611 590.267334,288.056580 
	C585.606506,287.688171 581.213623,287.599976 576.394897,287.434418 
	C573.376282,287.412537 570.783447,287.467957 567.750488,287.351013 
	C563.872253,287.479919 560.434265,287.781219 556.653870,288.060425 
	C555.880249,288.167969 555.449036,288.297668 554.653320,288.274902 
	C553.246094,288.214874 552.203430,288.307281 550.768555,288.262665 
	C548.577454,288.405151 546.778381,288.684692 544.614807,288.956696 
	C543.527954,289.027924 542.805664,289.106659 541.649109,289.081482 
	C537.034058,289.747070 532.853333,290.516510 528.650146,291.350220 
	C528.627808,291.414459 528.680054,291.540131 528.426758,291.439545 
	C527.806946,291.360260 527.440430,291.381531 526.739624,291.246735 
	C521.760742,292.186066 517.116089,293.281433 512.121338,294.398285 
	C510.859406,294.623779 509.947571,294.827850 508.667419,294.951965 
	C507.199829,295.280518 506.100555,295.688995 504.682556,296.089050 
	C503.954254,296.185303 503.544769,296.290009 502.831848,296.292480 
	C502.016052,296.495361 501.503693,296.800415 500.679626,297.125214 
	C499.978699,297.287018 499.589447,297.429077 498.903137,297.434570 
	C498.073669,297.571716 497.541260,297.845428 496.686707,298.140320 
	C495.957001,298.291534 495.549438,298.421539 494.848328,298.430298 
	C493.856079,298.700165 493.157379,299.091309 492.117035,299.537720 
	C490.890717,299.847137 490.006042,300.101349 488.771606,300.282074 
	C486.778961,300.947418 485.136047,301.686249 483.198517,302.456238 
	C482.190430,302.784088 481.476959,303.080750 480.394775,303.382324 
	C478.650940,303.857941 477.275726,304.328674 475.559998,304.789307 
	C474.479126,305.202209 473.738831,305.625183 472.632935,306.091827 
	C468.212860,307.848206 464.158417,309.560913 459.789917,311.217285 
	C458.812836,311.625153 458.149811,312.089386 457.233459,312.606506 
	C456.672394,312.813904 456.364655,312.968414 455.732391,313.069763 
	C454.761688,313.528168 454.115509,314.039734 453.214569,314.598450 
	C452.652954,314.800903 452.346100,314.956177 451.697174,315.068848 
	C450.484802,315.611237 449.614441,316.196228 448.596222,316.873199 
	C448.255096,317.093872 448.086151,317.248932 447.560791,317.425903 
	C445.831177,318.090088 444.482239,318.758881 442.821289,319.349426 
	C441.906525,319.779114 441.303833,320.286987 440.519592,320.843445 
	C440.338104,320.892029 440.030182,321.107544 439.705322,321.082031 
	C438.769531,321.614960 438.158569,322.173370 437.209229,322.797546 
	C432.266357,325.635651 427.661896,328.407990 422.738190,331.166168 
	C421.793274,331.678345 421.167572,332.204681 420.226166,332.812866 
	C418.641449,333.723175 417.372467,334.551636 415.812683,335.383301 
	C415.196564,335.852997 414.871216,336.319519 414.392822,336.800659 
	C414.239807,336.815247 414.015289,337.025299 413.687958,337.026245 
	C412.806549,337.629974 412.252441,338.232697 411.513245,338.894165 
	C411.328125,338.952911 411.066010,339.239563 410.749573,339.208984 
	C409.884613,339.747589 409.336090,340.316833 408.466187,340.950195 
	C406.439728,342.368256 404.734711,343.722198 402.706848,345.079010 
	C401.817932,345.663177 401.251831,346.244476 400.369263,346.915588 
	C398.391266,348.381714 396.729767,349.757965 394.739197,351.207825 
	C392.701813,352.812561 390.993469,354.343689 389.032166,355.810974 
	C388.616119,355.686737 388.452972,355.626434 388.204895,355.127686 
	C388.075714,354.096191 388.031433,353.503082 387.987183,352.909973 
z"/>
<path fill="#F9FAF9" opacity="1.000000" stroke="none" 
	d="
M762.673706,699.519043 
	C762.673706,699.519043 762.715454,699.209534 763.170654,699.066956 
	C766.071350,694.590454 768.516846,690.256592 771.006836,685.695129 
	C771.051392,685.467590 771.306519,685.080261 771.649414,685.012573 
	C774.427002,681.206909 777.993835,680.510681 782.129944,680.960754 
	C784.398499,681.207642 786.712463,681.036743 788.958130,681.419861 
	C787.905334,682.813110 787.082520,684.314087 785.862854,684.783447 
	C781.390015,686.504639 776.785278,687.882996 773.154541,689.084473 
	C772.717285,692.770874 773.273743,696.099243 771.900940,698.111572 
	C768.161560,703.593079 768.676941,709.261597 769.851318,715.285339 
	C764.912354,716.170227 760.205200,717.013611 754.999451,717.946289 
	C754.999451,720.193298 754.973572,722.660889 755.005127,725.127808 
	C755.060425,729.445435 752.541077,731.446777 748.643738,731.956848 
	C741.865356,732.843994 740.266907,734.131348 741.159180,740.739685 
	C741.794495,745.445190 741.177917,747.051636 735.975159,747.212036 
	C729.196838,747.420898 725.695190,751.732056 724.941528,758.474670 
	C724.772095,759.990479 723.999512,761.438721 723.347961,763.386108 
	C721.965332,762.495728 721.234253,761.877075 720.393738,761.507446 
	C714.599304,758.959656 710.823547,759.760498 710.080627,763.727722 
	C709.594849,766.321594 708.931824,768.990479 709.103333,771.575684 
	C709.413147,776.243713 707.197693,777.468933 703.106445,777.007141 
	C695.005798,776.093079 688.510498,780.662109 681.509583,783.461792 
	C680.887695,783.710510 680.390747,784.651245 680.134460,785.376160 
	C677.682312,792.312988 676.748840,792.973816 669.005371,792.623657 
	C668.964172,790.471191 668.917603,788.695007 669.053650,786.624451 
	C672.808167,784.241943 676.482788,782.308655 679.925903,780.026184 
	C684.120239,777.245911 688.119873,774.172058 692.541443,771.148071 
	C693.552063,770.317993 694.224915,769.565002 695.052185,768.606689 
	C695.654785,768.168884 696.103088,767.936401 696.910461,767.767212 
	C697.849731,767.216187 698.429932,766.601868 699.110046,765.868774 
	C699.210022,765.749939 699.510864,765.673096 699.885559,765.753418 
	C701.150330,764.860718 702.040344,763.887695 702.963013,762.957031 
	C702.995667,762.999390 702.916626,762.927002 703.305664,762.968262 
	C708.199585,761.937134 711.508972,759.494202 713.067261,754.708984 
	C713.359070,754.211792 713.619202,754.038818 714.331787,754.005859 
	C716.801270,752.009521 718.890503,749.981873 721.098877,747.719116 
	C721.672058,747.234863 722.126160,746.985718 722.963745,746.790527 
	C724.090088,745.776306 724.832886,744.708130 725.613220,743.646423 
	C725.650757,743.652893 725.663147,743.577759 726.025208,743.675293 
	C726.778015,743.066650 727.168762,742.360535 727.598511,741.642639 
	C727.637512,741.630920 727.639404,741.549500 728.004150,741.655518 
	C729.589294,740.188599 730.809631,738.615723 732.236328,736.817322 
	C733.317444,735.741272 734.192139,734.890747 735.443726,734.045898 
	C736.780151,732.876648 737.739685,731.701721 738.641113,730.322388 
	C738.583069,730.117981 738.360840,729.755615 738.632202,729.657104 
	C739.227478,729.353638 739.551453,729.148682 740.242310,728.943359 
	C741.635803,727.475037 742.662292,726.006958 743.813965,724.315369 
	C744.307739,723.738159 744.676270,723.384460 745.409302,723.002991 
	C752.656433,721.099548 754.133789,715.287781 755.729370,709.147034 
	C756.427612,707.562988 757.002869,706.304382 757.578125,705.045776 
	C757.578125,705.045776 757.764954,705.382690 758.206421,705.608948 
	C762.143066,705.102234 763.657043,703.106506 762.673706,699.519043 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M411.066040,339.239563 
	C411.066010,339.239563 411.328125,338.952911 411.863068,338.928284 
	C412.937103,338.277527 413.476196,337.651428 414.015289,337.025299 
	C414.015289,337.025299 414.239807,336.815247 414.695801,336.795105 
	C415.469025,336.309998 415.786255,335.845062 416.103485,335.380096 
	C417.372467,334.551636 418.641449,333.723175 420.575989,332.883667 
	C421.846832,332.308533 422.452118,331.744446 423.057404,331.180328 
	C427.661896,328.407990 432.266357,325.635651 437.562653,322.864807 
	C438.846375,322.280060 439.438293,321.693817 440.030212,321.107544 
	C440.030182,321.107544 440.338104,320.892029 440.838379,320.887451 
	C441.936859,320.397766 442.535065,319.912720 443.133301,319.427643 
	C444.482239,318.758881 445.831177,318.090088 447.601990,317.862671 
	C449.450439,319.538666 450.695374,321.211792 452.349518,321.897095 
	C454.419922,322.754822 457.147430,322.243256 459.017944,323.319336 
	C462.406555,325.268829 464.553497,324.265625 467.328857,322.104218 
	C468.475037,321.211609 471.195465,322.340515 473.199921,322.550018 
	C461.318115,327.168671 449.281677,331.429749 437.589142,336.486023 
	C417.841461,345.025604 399.948578,356.730072 382.371033,370.057617 
	C381.170502,370.828339 380.624420,371.489716 380.078308,372.151062 
	C371.953888,380.004395 363.829468,387.857697 355.081512,395.868225 
	C353.318939,395.856720 352.179901,395.688019 351.224304,395.219055 
	C351.458588,394.689758 351.509399,394.460754 351.721771,394.129944 
	C352.061493,393.828094 352.148956,393.594086 352.421265,393.205811 
	C352.836884,392.725677 352.976929,392.365875 353.216614,391.926758 
	C353.316223,391.847443 353.315460,391.592804 353.644196,391.489655 
	C354.460938,390.645233 354.948975,389.903931 355.705811,389.053833 
	C356.434143,388.342438 356.893646,387.739838 357.621796,387.044678 
	C358.077118,386.629730 358.263763,386.307312 358.450439,385.984924 
	C358.887085,385.561401 359.323730,385.137848 359.867615,384.455994 
	C359.974854,384.197693 360.052155,384.057922 360.359558,383.969116 
	C360.980255,383.336792 361.293549,382.793274 361.891632,382.147400 
	C363.356659,380.983429 364.536896,379.921783 366.031860,378.899414 
	C366.850739,378.049255 367.354919,377.159729 367.887390,376.020782 
	C368.404938,375.071289 368.894165,374.371246 369.689514,373.551941 
	C370.219391,373.068024 370.443115,372.703369 370.928131,372.178772 
	C372.927094,370.581085 374.664764,369.143280 376.792511,367.776642 
	C378.144287,366.611664 379.105957,365.375519 380.165344,363.886719 
	C380.718201,363.361694 381.173370,363.089294 381.993469,362.852570 
	C382.883698,362.220825 383.409027,361.553436 383.949951,360.645142 
	C383.965576,360.404205 383.869751,359.930969 384.162048,359.793335 
	C385.732880,358.292480 387.011383,356.929291 388.289856,355.566101 
	C388.452972,355.626434 388.616119,355.686737 389.317566,355.884033 
	C391.593353,354.392090 393.330811,352.763153 395.068237,351.134216 
	C396.729767,349.757965 398.391266,348.381714 400.716919,346.955811 
	C401.930573,346.296112 402.480103,345.686127 403.029663,345.076111 
	C404.734711,343.722198 406.439728,342.368256 408.795166,340.974487 
	C409.985718,340.369629 410.525879,339.804596 411.066040,339.239563 
z"/>
<path fill="#F4DED6" opacity="1.000000" stroke="none" 
	d="
M834.790710,261.668640 
	C835.188477,259.989807 836.365417,257.994873 835.836792,256.691895 
	C834.298523,252.900009 837.070374,251.172668 839.166687,249.851837 
	C840.208252,249.195618 843.013672,250.482422 844.510193,251.584198 
	C847.579834,253.844025 850.456665,256.426239 853.097168,259.178619 
	C854.148376,260.274384 855.297913,262.866943 854.775757,263.569977 
	C853.438721,265.370544 851.296448,266.682678 849.273010,267.832794 
	C847.312561,268.947083 844.505981,269.062988 843.096558,270.573975 
	C835.956848,278.228119 829.342346,286.373199 822.182312,294.007172 
	C812.068726,304.790161 800.405151,307.369751 788.858337,301.884949 
	C773.676208,294.673340 764.024597,277.061615 769.380432,262.941254 
	C772.265564,255.334824 778.593872,248.958954 783.730225,242.307053 
	C787.482300,237.447815 792.007507,233.187698 795.796753,228.353760 
	C797.001831,226.816391 796.760010,224.220016 797.522156,222.239838 
	C798.349487,220.090378 799.211853,217.708176 800.799194,216.213608 
	C801.472961,215.579147 804.436157,216.460526 805.665222,217.485107 
	C812.049438,222.806824 818.292053,228.308517 824.377136,233.969925 
	C825.564331,235.074478 826.863281,237.882812 826.323792,238.652100 
	C825.072937,240.435623 822.828552,241.637863 820.798828,242.724304 
	C819.063354,243.653229 816.467529,243.544434 815.281799,244.838196 
	C807.644043,253.172119 800.112000,261.618378 792.897339,270.319763 
	C787.056885,277.363739 787.489258,284.805908 793.406494,289.896484 
	C798.006836,293.854187 802.840210,293.237488 808.461670,290.882629 
	C809.352722,291.292511 809.564514,291.479279 809.528564,291.933228 
	C803.974121,295.877014 798.505615,296.393524 792.943298,292.859070 
	C784.848022,287.715088 783.432434,277.393768 790.318176,268.842926 
	C797.722595,259.647949 805.672668,250.878952 813.675476,242.192032 
	C815.093811,240.652405 817.944397,240.447067 820.114929,239.578766 
	C821.100952,239.184341 822.579651,238.881699 822.854797,238.162521 
	C823.144531,237.405182 822.554565,235.879242 821.849426,235.241562 
	C816.048340,229.995728 810.156250,224.848984 804.212097,219.765259 
	C803.572266,219.218033 801.975830,218.789658 801.610046,219.136383 
	C800.918030,219.792389 800.760132,221.039963 800.446960,222.062149 
	C799.677856,224.572906 799.619934,227.649536 798.083008,229.516434 
	C791.634216,237.349899 784.727722,244.805527 778.028198,252.434052 
	C774.122559,256.881256 771.243225,261.691101 770.729553,267.910980 
	C769.835999,278.731964 774.566223,286.804535 782.058350,293.877838 
	C793.438354,304.621796 807.988525,304.491669 818.772949,293.097473 
	C826.550659,284.879944 833.824707,276.184723 841.633362,267.998169 
	C843.322205,266.227570 846.342407,265.732269 848.740356,264.629974 
	C849.688904,264.193939 850.865662,263.899902 851.440308,263.156281 
	C851.722656,262.790833 851.093201,261.309418 850.491211,260.740936 
	C847.287659,257.715912 843.962097,254.820068 839.813660,251.104691 
	C839.274658,253.567459 839.088989,254.880035 838.692871,256.125641 
	C837.945862,258.474609 837.077637,260.785004 835.949219,262.945435 
	C835.355957,262.409149 835.073364,262.038879 834.790710,261.668640 
z"/>
<path fill="#F4E0D9" opacity="1.000000" stroke="none" 
	d="
M721.684937,220.029694 
	C726.106812,221.590088 726.793762,224.300171 726.058960,228.405182 
	C724.721008,235.879578 724.997742,243.382309 732.244873,248.019287 
	C734.541382,249.488708 738.012024,249.123032 740.945374,249.597137 
	C741.360168,246.853027 742.811951,243.744797 741.999756,241.431488 
	C739.310303,233.771454 735.494385,226.511520 732.703064,218.881363 
	C730.132812,211.855469 730.542175,204.766785 734.781067,198.271225 
	C741.519958,187.944977 752.650330,184.872040 764.631592,190.113556 
	C773.626282,194.048523 780.398438,200.549744 785.174561,209.028717 
	C785.945190,210.396637 785.861572,212.850372 785.162231,214.321838 
	C783.454773,217.914307 781.364807,221.381592 778.987915,224.570602 
	C778.070618,225.801178 775.706421,227.048264 774.448730,226.695084 
	C772.055969,226.023178 769.299561,224.758728 770.241150,221.085800 
	C770.776917,218.996002 771.287292,216.848068 771.373779,214.708725 
	C771.466553,212.413269 771.416626,209.988998 770.776245,207.813034 
	C769.510681,203.512482 764.379944,200.336624 761.281433,201.535995 
	C757.714111,202.916779 756.177246,207.688599 758.244507,212.339615 
	C761.013489,218.569382 764.226013,224.600494 767.081238,230.793839 
	C771.406982,240.176926 771.724854,249.077988 763.765930,257.006226 
	C756.428040,264.316010 746.555237,266.262909 736.108643,261.707367 
	C725.817871,257.219788 717.401306,250.340683 711.252625,240.800018 
	C709.421509,237.958740 709.087219,235.526886 711.237488,232.598282 
	C713.393250,229.662033 714.819580,226.193878 716.939514,223.225922 
	C717.958435,221.799454 719.838257,220.987915 721.684937,220.029694 
M719.887207,246.336945 
	C719.887207,246.336945 719.656616,246.318893 719.756653,246.883728 
	C720.173035,247.252548 720.589417,247.621368 721.000000,248.000000 
	C721.000000,248.000000 721.009460,247.994507 721.204163,248.628021 
	C722.748169,249.849838 724.292114,251.071671 726.105042,252.663406 
	C726.332764,252.884201 726.604065,253.013763 727.262390,253.620132 
	C728.416382,254.272095 729.570374,254.924057 730.836365,255.839645 
	C730.836365,255.839645 731.085571,255.980820 731.343079,256.501007 
	C731.891357,256.640991 732.439697,256.780975 733.290955,257.142303 
	C733.290955,257.142303 733.658508,257.217896 734.125366,257.787323 
	C739.731689,258.816864 745.440979,261.074310 750.922424,260.614685 
	C761.604187,259.719025 771.884827,247.265976 765.446960,234.803375 
	C765.228943,234.216339 765.010864,233.629318 764.835327,232.349731 
	C763.875183,230.570694 762.915100,228.791672 761.900085,226.429123 
	C761.576477,225.964081 761.252808,225.499039 760.900330,224.388916 
	C760.387512,223.741135 759.874756,223.093353 759.397766,222.173157 
	C759.397766,222.173157 759.230347,221.955338 759.199646,221.197083 
	C757.713257,215.785873 756.226807,210.374680 754.960571,204.386200 
	C755.094910,203.783600 755.229309,203.181000 756.018494,202.278625 
	C759.010010,201.150665 762.001526,200.022690 765.680969,199.145767 
	C766.158020,199.247055 766.635010,199.348358 767.499573,199.885513 
	C769.239258,201.405930 770.978882,202.926346 772.750793,204.767639 
	C772.750793,204.767639 772.963013,205.010452 773.032837,205.832535 
	C773.155334,210.244980 773.277832,214.657440 773.184021,219.668457 
	C773.182556,220.090225 773.181030,220.511978 772.989258,221.674500 
	C773.844910,222.466217 774.700500,223.257919 775.556152,224.049637 
	C776.154236,223.166306 776.752258,222.282974 777.330322,221.333588 
	C777.330322,221.333588 777.393188,221.305054 777.993469,221.050018 
	C779.042175,219.069351 780.090881,217.088669 781.094971,214.989334 
	C781.094971,214.989334 781.113037,215.113861 781.751892,214.746353 
	C785.101440,209.945023 781.378357,207.435104 778.404175,204.703964 
	C778.404175,204.703964 778.400330,204.612167 778.330933,204.037109 
	C777.935181,203.644363 777.539490,203.251617 777.129150,202.986313 
	C777.129150,202.986313 777.163452,202.857559 777.028748,202.209183 
	C775.175049,200.746246 773.321411,199.283325 771.275879,197.723801 
	C771.275879,197.723801 771.176025,197.533585 771.005615,196.911682 
	C769.339966,195.940506 767.674255,194.969330 765.638977,193.547272 
	C765.079285,193.408447 764.519653,193.269623 763.516113,192.536804 
	C755.321777,189.055679 747.415649,188.601532 740.465637,195.220856 
	C732.286316,203.011124 731.416016,213.714432 737.813171,225.151047 
	C737.813171,225.151047 737.803162,225.168777 737.873230,225.846664 
	C738.865662,227.584167 739.858154,229.321671 740.954407,231.629623 
	C741.259705,232.106613 741.565002,232.583603 741.916809,233.805435 
	C744.374390,239.707520 748.195557,245.539124 742.976379,251.842178 
	C742.976379,251.842178 742.846924,251.822128 742.083801,251.943558 
	C736.962219,254.567627 732.990356,251.660110 728.595337,248.686829 
	C728.095520,248.333115 727.595642,247.979401 726.866516,247.074539 
	C725.970398,245.997513 725.074280,244.920471 724.048340,243.986572 
	C724.048340,243.986572 724.205200,243.873703 724.146484,243.050949 
	C723.607849,239.010956 723.069214,234.970963 722.729248,230.301056 
	C722.745728,229.862488 722.762207,229.423935 723.074219,228.302689 
	C722.743774,226.590927 722.413330,224.879166 722.082886,223.167419 
	C720.679932,224.165359 718.916260,224.901993 717.959167,226.222992 
	C716.552612,228.164352 715.722107,230.523102 714.699646,232.907639 
	C714.699646,232.907639 714.628662,232.699448 713.996765,232.934204 
	C711.386108,237.247940 712.544373,240.363632 718.660645,245.301132 
	C718.660645,245.301132 718.708374,245.342453 718.848877,245.809998 
	C719.038330,246.108688 719.309570,246.269760 719.887207,246.336945 
z"/>
<path fill="#F5E1DA" opacity="1.000000" stroke="none" 
	d="
M583.214355,197.056549 
	C584.158752,193.398178 584.957397,190.025467 585.414307,186.607086 
	C587.045105,174.404404 588.577881,162.188446 590.094360,149.970901 
	C590.272400,148.536819 590.118591,147.061569 590.118591,145.636215 
	C584.237976,144.971603 579.888000,147.188950 577.310181,153.626373 
	C575.479126,158.199219 572.539368,157.747650 569.348389,156.800354 
	C565.362183,155.617020 567.394104,152.391846 567.883728,150.084549 
	C568.951721,145.051056 570.532776,140.128143 571.670654,135.107117 
	C572.344421,132.134262 573.753113,130.864624 576.891418,131.277771 
	C595.354004,133.708344 613.821533,136.104538 632.304749,138.370972 
	C635.196167,138.725510 636.546997,140.011429 636.542603,142.780457 
	C636.533142,148.772659 636.644836,154.777039 636.263062,160.748062 
	C636.170227,162.200043 634.484497,164.579269 633.311279,164.721802 
	C630.761292,165.031616 627.763855,165.425629 626.802307,161.264694 
	C624.627319,151.852737 622.418091,149.655365 615.590881,149.138428 
	C614.496399,157.773636 613.382385,166.446915 612.301697,175.124344 
	C611.417908,182.221054 610.431885,189.310440 609.815674,196.431152 
	C609.668152,198.135025 610.964905,199.919937 611.323486,201.722092 
	C611.829651,204.265854 612.488586,206.914383 612.159912,209.395981 
	C612.075623,210.032684 608.483521,210.698608 606.584351,210.513885 
	C599.141785,209.789963 591.724609,208.782639 584.313843,207.765701 
	C582.058105,207.456161 578.770081,208.406540 578.629456,204.572098 
	C578.512512,201.384537 578.668213,198.066132 583.214355,197.056549 
M608.881470,180.380371 
	C610.067139,170.649658 611.252197,160.918869 612.438538,151.188248 
	C613.105530,145.717789 614.743408,144.773453 620.023743,146.793900 
	C625.668762,148.953842 627.795959,153.613495 629.068054,159.058121 
	C629.327026,160.166794 630.793762,160.993271 631.703735,161.949829 
	C632.388184,160.840591 633.601929,159.759933 633.663635,158.617065 
	C633.905823,154.133957 633.624146,149.623001 633.853699,145.138596 
	C634.011658,142.054214 632.644775,141.180832 629.835022,140.834488 
	C612.676147,138.719543 595.531189,136.488342 578.394043,134.202911 
	C575.495239,133.816315 574.156555,134.719696 573.627197,137.665771 
	C572.952209,141.422226 571.631165,145.061905 570.941650,148.816742 
	C570.630981,150.508804 571.174683,152.357742 571.330688,154.135498 
	C572.751221,153.029022 574.607117,152.200134 575.519104,150.769485 
	C580.203552,143.420868 584.288025,141.581940 592.742126,143.589935 
	C592.742126,145.835419 592.986328,148.155273 592.702271,150.408554 
	C590.914612,164.590210 588.966675,178.751648 587.175964,192.932938 
	C586.771484,196.135773 586.803894,199.309464 582.757141,200.412491 
	C582.039185,200.608170 581.235596,202.255127 581.335938,203.133179 
	C581.417297,203.844803 582.735718,204.847168 583.609802,204.968475 
	C591.347473,206.042328 599.103577,206.993500 606.868896,207.846359 
	C607.836609,207.952637 609.417969,207.550385 609.763184,206.887817 
	C610.145569,206.154144 609.445557,204.847656 609.196533,203.795441 
	C608.471924,200.733917 607.125000,197.669449 607.151672,194.616013 
	C607.190735,190.163925 608.214600,185.720459 608.881470,180.380371 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M785.832520,353.096741 
	C780.455200,348.732025 775.100769,344.338715 769.694519,340.010101 
	C763.300903,334.890900 757.163147,329.377930 750.376587,324.840057 
	C738.726501,317.050049 726.791992,309.658295 714.711487,302.550354 
	C708.602600,298.955994 702.063599,295.963318 695.452759,293.380219 
	C683.954468,288.887360 672.440369,284.247650 660.585022,280.901947 
	C644.579834,276.384979 628.545776,271.698547 611.701111,270.887054 
	C599.313171,270.290283 586.963074,268.352661 574.585938,268.250366 
	C563.036499,268.154968 551.474365,269.703949 539.916138,270.503937 
	C536.607971,270.732910 533.293762,270.874298 529.138794,271.033264 
	C526.806396,270.643219 525.317505,270.275787 524.127502,269.713318 
	C525.968872,269.061157 527.511292,268.604065 529.441895,268.141724 
	C530.848755,267.982452 531.867432,267.828461 533.243530,267.792877 
	C534.743958,267.625183 535.886963,267.339081 537.393555,267.053040 
	C538.474609,266.958099 539.191956,266.863098 540.330811,266.887085 
	C543.164795,266.640503 545.577332,266.274872 548.423218,265.943207 
	C551.902161,265.700562 554.947632,265.423950 558.354065,265.236145 
	C559.454468,265.294495 560.193970,265.264099 561.220764,265.414337 
	C563.012695,265.397400 564.517273,265.199768 566.476562,265.047180 
	C570.281860,265.050812 573.632507,265.009430 577.309021,265.217377 
	C583.473999,265.450439 589.313171,265.434204 595.554077,265.544495 
	C597.642517,265.733490 599.329224,265.795929 601.302551,266.146912 
	C606.719849,266.809418 611.850403,267.183319 617.277344,267.627808 
	C618.180725,267.688721 618.787842,267.678986 619.635742,267.947266 
	C622.952820,268.694397 626.029175,269.163544 629.408081,269.752197 
	C630.140625,269.907959 630.570679,269.944214 631.281494,270.243958 
	C638.411438,271.898254 645.260498,273.289032 652.408447,274.796173 
	C653.131104,274.954224 653.554993,274.995880 654.225708,275.307800 
	C671.669006,281.996185 688.864624,288.416809 706.063293,294.829010 
	C708.041260,295.566437 710.036804,296.256500 712.176147,297.063324 
	C712.328003,297.158142 712.682434,297.208984 712.810303,297.533630 
	C715.255127,299.001984 717.572205,300.145721 720.099915,301.436188 
	C720.474365,301.632599 720.638184,301.682281 720.919922,302.019226 
	C726.538574,305.332092 732.039246,308.357666 737.582275,311.383484 
	C737.624634,311.383759 737.671448,311.454315 737.762939,311.746185 
	C738.566711,312.373627 739.279053,312.709167 740.108643,313.154175 
	C740.225830,313.263580 740.536438,313.343170 740.676453,313.648560 
	C742.169067,314.708740 743.521667,315.463531 745.065979,316.472778 
	C746.691528,317.589783 748.125427,318.452332 749.681274,319.615967 
	C750.524109,320.312714 751.245056,320.708344 752.188354,321.332703 
	C754.153198,322.789337 755.895752,324.017303 757.759155,325.561493 
	C759.563965,326.960632 761.248047,328.043549 763.083252,329.374939 
	C763.999146,330.199860 764.763794,330.776306 765.645020,331.662415 
	C766.825134,332.678406 767.888855,333.384735 769.079224,334.320190 
	C769.653870,334.805023 770.101746,335.060730 770.640259,335.637207 
	C771.765503,336.728119 772.800171,337.498260 773.946472,338.386017 
	C774.058167,338.503601 774.367737,338.600525 774.491333,338.906433 
	C778.717346,342.854584 782.819824,346.496796 786.994141,350.351349 
	C787.245361,350.818604 787.326355,351.101654 787.142700,351.756104 
	C786.595032,352.431793 786.213806,352.764282 785.832520,353.096741 
z"/>
<path fill="#F5E0D9" opacity="1.000000" stroke="none" 
	d="
M572.392822,195.653061 
	C574.442505,203.237595 574.143555,203.764481 567.116943,204.250565 
	C559.478210,204.778961 551.832764,205.338089 544.182495,205.487564 
	C542.105530,205.528168 538.830505,204.696213 538.157837,203.285782 
	C536.850159,200.544144 536.387695,196.896286 541.628418,195.466385 
	C538.760498,192.079803 540.409607,186.148026 534.160645,185.905334 
	C517.996887,185.277603 517.994995,185.326782 515.114380,195.844772 
	C516.314148,197.038071 518.022400,198.041748 518.671387,199.512741 
	C520.630188,203.952942 518.613281,206.778992 513.657776,207.026077 
	C509.168976,207.249893 504.671539,207.691818 500.194214,207.543869 
	C498.627411,207.492096 495.910248,206.085831 495.814728,205.095871 
	C495.606506,202.937271 496.076263,199.389343 497.459930,198.655884 
	C501.215057,196.665421 502.375000,193.574661 503.494476,190.010590 
	C508.030792,175.568405 512.664734,161.156693 517.315186,146.750687 
	C518.118896,144.260880 518.754517,142.367294 515.777954,140.405441 
	C514.499756,139.562988 514.341370,136.134796 514.712830,134.068085 
	C514.886230,133.103745 517.479919,132.121552 519.063843,131.949127 
	C523.690857,131.445496 528.381958,131.531281 533.008911,131.027390 
	C536.620239,130.634094 538.383484,132.175629 539.835388,135.310898 
	C548.154480,153.274933 556.511597,171.225021 565.225098,188.998795 
	C566.513306,191.626587 569.762878,193.292877 572.392822,195.653061 
M531.219299,133.874466 
	C527.722961,133.993515 524.216614,133.995834 520.735962,134.296387 
	C519.554443,134.398407 517.912109,134.980026 517.460144,135.850952 
	C517.105774,136.533783 518.437866,137.959198 518.708923,139.127060 
	C519.255249,141.481064 520.486938,144.152328 519.868225,146.224701 
	C515.106934,162.172607 510.083374,178.046082 504.830048,193.839157 
	C504.016632,196.284454 501.565491,198.165222 499.970764,200.378448 
	C499.132996,201.541138 498.509735,202.858414 497.159790,205.196960 
	C503.664093,204.853302 508.468170,204.678055 513.258301,204.317963 
	C516.304382,204.088989 517.438110,202.131271 515.059082,200.142334 
	C509.662140,195.630341 514.124573,191.290710 514.856506,186.965805 
	C515.524719,183.017441 519.041382,183.938080 521.737732,183.751801 
	C525.890137,183.464935 530.076660,183.496307 534.197571,182.982452 
	C537.986328,182.510025 539.858521,184.361526 540.779602,187.554291 
	C541.789246,191.053436 545.912354,194.329254 541.599976,198.356979 
	C540.812805,199.092163 540.375671,200.665344 540.528198,201.745651 
	C540.603088,202.276703 542.409973,202.940048 543.410156,202.904205 
	C551.560913,202.612274 559.709229,202.216995 567.849243,201.708237 
	C568.809692,201.648209 569.715515,200.713455 570.646667,200.184189 
	C570.040649,199.162277 569.567566,198.025375 568.802429,197.141159 
	C566.914856,194.959900 564.238831,193.213211 563.030823,190.731537 
	C554.438660,173.079285 546.086243,155.309341 537.794434,137.513062 
	C536.571167,134.887650 535.065796,133.455093 531.219299,133.874466 
z"/>
<path fill="#F4DFD8" opacity="1.000000" stroke="none" 
	d="
M673.835022,229.024048 
	C669.817017,227.718658 666.117188,226.676071 662.593628,225.209915 
	C661.000549,224.547028 658.479797,222.859039 658.609009,221.954910 
	C658.927856,219.725754 660.098572,216.354691 661.711975,215.822205 
	C665.799377,214.473282 664.388611,211.747772 664.268555,209.366882 
	C664.139648,206.811829 664.251038,204.073700 660.423218,203.534042 
	C657.671570,203.146118 655.047791,201.849396 652.367737,200.955887 
	C645.439880,198.646194 644.795044,198.792740 641.086182,204.818314 
	C640.485535,205.794128 640.867920,207.372864 640.789062,208.672485 
	C640.642029,211.094254 641.034485,213.770721 640.077637,215.814575 
	C639.682861,216.657761 636.269897,216.608139 634.431885,216.151276 
	C630.081177,215.069901 625.780823,213.680054 621.613281,212.028336 
	C620.247437,211.487030 618.220032,209.394501 618.461121,208.636002 
	C619.127197,206.539841 620.508545,204.389618 622.201233,202.968552 
	C624.599182,200.955444 628.215637,200.141144 630.116638,197.831253 
	C640.049438,185.762024 649.602295,173.380096 659.293457,161.111801 
	C660.773071,159.238708 661.807739,157.770584 660.101379,155.008240 
	C659.182068,153.519974 660.388794,150.372604 661.327576,148.284622 
	C661.624268,147.624664 664.280762,147.545624 665.649780,147.933380 
	C670.769714,149.383575 675.911560,150.880249 680.804565,152.933136 
	C682.282593,153.553268 683.759277,155.959503 683.917419,157.669632 
	C685.710938,177.053253 687.102295,196.474838 688.993713,215.847931 
	C689.318237,219.171478 691.835999,222.227341 692.668640,225.574066 
	C693.204468,227.727783 692.932251,230.343338 692.155884,232.429520 
	C691.860840,233.222351 688.997009,233.677338 687.552673,233.309509 
	C683.046265,232.161911 678.658752,230.547531 673.835022,229.024048 
M637.716553,204.506409 
	C643.936279,195.652298 643.936646,195.651169 653.871521,198.743454 
	C655.615723,199.286362 657.369324,199.803650 659.095154,200.400482 
	C666.906372,203.101791 666.870850,203.111237 667.047668,211.420837 
	C667.113647,214.524551 667.310120,217.487869 663.127991,218.243835 
	C662.379028,218.379211 661.328796,219.943100 661.415527,220.738541 
	C661.502563,221.536499 662.717957,222.548996 663.644165,222.864548 
	C671.352234,225.490555 679.088806,228.038803 686.872559,230.429062 
	C687.866638,230.734344 689.460938,230.311356 690.238525,229.619949 
	C690.680908,229.226562 690.201416,227.543045 689.841431,226.547318 
	C688.697693,223.383362 686.712280,220.354828 686.320007,217.120193 
	C685.200684,207.890320 684.701233,198.585022 683.969910,189.308441 
	C683.225281,179.863327 682.617432,170.404633 681.662231,160.981033 
	C681.453064,158.917114 680.546997,155.889069 679.066956,155.184143 
	C674.752991,153.129501 670.014282,151.928268 665.386230,150.585098 
	C662.398865,149.718094 661.326721,151.771561 662.633789,153.814789 
	C664.890076,157.341965 663.372559,159.665726 661.322388,162.288712 
	C651.290771,175.122894 641.405273,188.073196 631.213440,200.778091 
	C629.587219,202.805206 626.656189,203.777664 624.348877,205.269440 
	C623.130920,206.056885 621.957153,206.912750 619.515503,208.600098 
	C625.960999,210.711960 630.889648,212.582199 635.963135,213.891754 
	C638.923523,214.655869 639.215637,212.658020 638.473633,210.244659 
	C637.970398,208.607971 637.745789,206.885590 637.716553,204.506409 
z"/>
<path fill="#F4DED4" opacity="1.000000" stroke="none" 
	d="
M874.662476,345.061523 
	C875.271179,360.399872 858.223938,370.658234 844.263428,362.005402 
	C839.157959,358.841003 834.631592,354.346985 830.739685,349.705994 
	C823.701294,341.312836 817.243530,332.422302 810.787354,323.561737 
	C809.727478,322.107086 808.975098,318.658539 809.713684,318.019745 
	C811.421936,316.542358 814.104187,316.126923 816.443848,315.466003 
	C818.054260,315.011078 820.144470,315.395721 821.350891,314.501770 
	C833.899536,305.202911 846.360352,295.782440 858.690979,286.196930 
	C859.963440,285.207794 860.033936,282.800507 861.021118,281.285156 
	C862.356018,279.235901 863.812500,277.114227 865.711609,275.677856 
	C866.269958,275.255615 868.980896,276.687744 869.896057,277.887360 
	C876.644165,286.733307 883.523254,295.514221 889.638672,304.791809 
	C892.285828,308.807770 894.122437,313.829742 894.760864,318.604187 
	C896.309692,330.186798 888.762573,338.444489 877.058655,339.030823 
	C876.244080,339.071625 875.429749,339.118164 874.249023,339.181763 
	C874.396179,341.138885 874.527527,342.884979 874.662476,345.061523 
M871.658569,284.803680 
	C870.809326,283.571014 870.112305,282.184296 869.066284,281.150787 
	C868.099976,280.196014 866.759583,279.619843 865.583984,278.876923 
	C865.059937,280.139435 864.668884,281.480164 863.982483,282.647156 
	C862.781067,284.689392 861.798828,287.137115 860.026917,288.522461 
	C847.986267,297.936676 835.859741,307.250183 823.504944,316.244507 
	C821.420532,317.761902 818.090210,317.547852 815.349426,318.192627 
	C814.125854,318.480438 812.936584,318.913849 811.015137,319.499695 
	C816.835938,327.167236 821.775940,334.259430 827.333740,340.829041 
	C832.762329,347.245972 838.260132,353.830444 844.768188,359.041779 
	C850.777344,363.853577 858.211487,363.522247 864.867859,359.111511 
	C870.738342,355.221558 872.806519,348.522522 870.956787,340.534668 
	C870.597961,338.985291 870.333801,337.414032 869.964661,335.541931 
	C872.562988,335.835022 874.654968,336.241974 876.753174,336.277588 
	C885.204834,336.420990 892.508484,329.686340 891.968750,321.295471 
	C891.670532,316.659119 890.333557,311.623993 887.949463,307.686035 
	C883.235901,299.900482 877.469482,292.752319 871.658569,284.803680 
z"/>
<path fill="#F4E0D9" opacity="1.000000" stroke="none" 
	d="
M449.497864,142.393219 
	C460.410583,141.109528 470.652435,141.426697 479.729919,147.649139 
	C491.890686,155.985138 496.384796,168.164536 495.530029,182.272400 
	C494.658295,196.660278 486.351715,206.630112 473.476746,211.815063 
	C463.209808,215.949707 452.155334,218.131287 441.444641,221.159180 
	C439.522827,221.702469 437.586578,222.457367 435.631470,222.545151 
	C433.742157,222.629990 430.922546,222.633270 430.105164,221.511368 
	C428.565643,219.398285 427.347229,216.593262 430.208099,213.901230 
	C431.256470,212.914688 431.893921,210.602646 431.556335,209.168930 
	C428.013000,194.119232 424.332123,179.099121 420.389587,164.149841 
	C419.920898,162.372681 417.436432,161.218796 416.302948,159.501083 
	C415.013672,157.547302 413.761444,155.355438 413.394379,153.119125 
	C413.260620,152.303879 415.569153,150.476715 417.016235,150.110718 
	C427.657715,147.419235 438.363251,144.981033 449.497864,142.393219 
M476.572510,207.155273 
	C491.541504,197.128036 496.512573,182.845535 490.781616,166.630127 
	C484.927246,150.065567 471.406189,142.847458 455.370911,144.618912 
	C443.064178,145.978439 431.002289,149.585587 418.854980,152.298111 
	C417.887024,152.514236 416.597900,153.421463 416.389954,154.243301 
	C416.221100,154.910721 417.434082,155.945221 418.064850,156.794174 
	C419.731842,159.037750 422.293030,161.039398 422.947937,163.542923 
	C426.772034,178.161484 430.104095,192.908066 433.718903,207.582687 
	C434.461243,210.596191 435.208130,213.340118 432.581360,215.910828 
	C431.964050,216.514984 431.701996,218.251541 432.128967,218.933105 
	C432.540863,219.590607 434.179504,220.008179 435.064758,219.761520 
	C448.768402,215.943222 463.217896,214.606201 476.572510,207.155273 
z"/>
<path fill="#6B567C" opacity="1.000000" stroke="none" 
	d="
M305.022919,694.064453 
	C305.057068,694.757629 305.091248,695.450745 304.942932,696.663574 
	C304.613983,697.286011 304.467499,697.388794 304.039856,697.316895 
	C301.644806,693.001282 299.186401,688.993591 297.517334,684.680542 
	C295.958771,680.653015 293.673828,679.085876 289.310913,679.132751 
	C271.153687,679.327942 252.993103,679.200989 234.833801,679.231506 
	C233.082428,679.234436 231.331512,679.508484 228.599045,679.739746 
	C229.810486,682.192078 230.633408,683.857971 231.443329,685.718872 
	C231.430328,685.913879 231.610336,686.260742 231.623566,686.621094 
	C232.573853,688.840393 233.510925,690.699402 234.493347,692.813660 
	C234.690765,693.377869 234.842850,693.686829 234.961899,694.372437 
	C238.845169,702.327271 242.761475,709.905396 246.743866,717.745117 
	C247.007706,718.304749 247.205475,718.602783 247.338684,719.216064 
	C247.788269,720.126099 248.302444,720.720825 248.868332,721.659363 
	C260.171997,742.494080 273.787720,761.327942 289.179565,778.872437 
	C295.738922,786.349121 302.713531,793.461548 309.049622,800.817261 
	C307.994507,800.977417 307.389221,801.059875 306.783905,801.142334 
	C300.714539,795.408752 294.002289,790.208069 288.713379,783.827637 
	C278.578979,771.601746 268.791565,759.025879 259.741394,745.983765 
	C252.784103,735.957642 246.918747,725.135620 241.054932,714.401428 
	C236.647980,706.334167 232.943054,697.877502 229.079147,689.523132 
	C227.909210,686.993530 227.214859,684.238647 226.368973,681.565918 
	C225.215164,677.920288 227.554855,676.299927 230.419891,676.266846 
	C249.001816,676.052124 267.586670,676.093689 286.170593,676.049377 
	C296.639923,676.024414 298.533905,677.529846 301.223419,688.021240 
	C301.264191,688.180237 301.356995,688.325806 301.801514,688.961548 
	C302.457977,689.660828 302.738403,689.876282 303.018829,690.091736 
	C303.018829,690.091736 303.057220,690.450989 303.050293,690.979797 
	C303.703217,692.360535 304.363068,693.212524 305.022919,694.064453 
z"/>
<path fill="#69547B" opacity="1.000000" stroke="none" 
	d="
M511.003662,274.983093 
	C500.981812,277.831451 490.765625,280.140533 480.987244,283.664551 
	C468.731354,288.081421 456.819244,293.457977 444.786469,298.486694 
	C423.243164,307.489929 403.697083,320.056152 386.043182,334.996155 
	C371.194427,347.562256 358.059357,362.175201 344.391113,376.103241 
	C339.193359,381.399689 334.624725,387.313538 329.448792,393.038757 
	C329.128204,393.131561 329.017822,393.027496 328.825684,392.736420 
	C328.302795,392.281067 327.972076,392.116760 327.785645,391.635132 
	C328.976074,389.553223 330.022156,387.788574 331.305908,385.798950 
	C333.014832,383.694885 334.486115,381.815796 336.283997,379.846283 
	C338.781067,376.873901 340.951569,373.991974 343.062866,371.056824 
	C343.003662,371.003662 343.110352,371.121735 343.423462,371.073975 
	C344.164856,370.353912 344.593140,369.681641 345.296143,368.862396 
	C346.341339,367.736755 347.111786,366.758057 348.189880,365.720154 
	C349.033508,364.802643 349.569458,363.944336 350.064301,363.038025 
	C350.023224,362.989990 350.091553,363.094269 350.406494,362.997131 
	C352.149658,361.266571 353.577911,359.633209 355.228790,357.904541 
	C355.734955,357.625092 355.925781,357.371246 356.350769,356.952271 
	C362.285889,351.364410 367.894196,345.872009 373.655182,340.331299 
	C373.807831,340.282959 374.012329,340.036499 374.328918,339.961823 
	C375.490845,338.987823 376.336151,338.088531 377.093811,337.095215 
	C377.006104,337.001221 377.184235,337.189453 377.527679,337.165527 
	C380.238953,335.429321 382.606750,333.717041 385.124756,331.964813 
	C385.600891,331.779999 385.705078,331.538116 385.586670,330.992676 
	C385.585846,330.786133 385.576294,330.373230 385.818237,330.295776 
	C386.394989,329.851990 386.729767,329.485626 387.384277,329.076538 
	C388.261505,328.436005 388.818970,327.838165 389.543854,327.201111 
	C389.711273,327.161865 389.962158,326.926697 390.306458,326.941406 
	C391.579468,326.069763 392.508148,325.183380 393.742798,324.206879 
	C395.338715,323.395599 396.628632,322.674408 398.237305,321.946533 
	C399.049500,321.590698 399.542908,321.241608 400.258881,320.657471 
	C402.631927,318.600464 404.782379,316.778442 406.962341,314.977600 
	C406.991821,314.998840 406.964020,314.931671 407.282410,314.951141 
	C408.160004,314.423309 408.719147,313.875977 409.215332,313.213501 
	C409.152374,313.098358 409.320160,313.300232 409.674622,313.293457 
	C414.174286,310.633881 418.319489,307.981049 422.622070,305.303223 
	C422.779419,305.278198 423.020142,305.069397 423.377350,305.079773 
	C425.662048,303.859009 427.589569,302.627869 429.671387,301.368408 
	C429.825745,301.340057 430.048309,301.118774 430.409760,301.116882 
	C432.722168,299.940277 434.673096,298.765564 436.876160,297.564453 
	C437.428864,297.388641 437.729431,297.239197 438.379578,297.103149 
	C439.602417,296.484772 440.475708,295.852966 441.623444,295.193176 
	C442.232117,295.019104 442.566345,294.872955 443.257874,294.779297 
	C446.274902,293.404144 448.934631,291.976532 451.768494,290.554077 
	C451.942627,290.559204 452.236877,290.372650 452.602600,290.376831 
	C454.645264,289.576111 456.322205,288.771179 458.323303,287.949341 
	C459.396210,287.539459 460.144958,287.146515 461.246765,286.802795 
	C462.742950,286.252716 463.886047,285.653412 465.349731,285.007996 
	C466.433319,284.616486 467.196411,284.271088 468.262665,283.975342 
	C469.054047,283.708862 469.542236,283.392792 470.376923,283.062592 
	C471.798309,282.668365 472.873199,282.288269 474.343658,281.932800 
	C481.834229,279.645752 488.929230,277.334076 496.344421,275.016052 
	C497.068756,274.875916 497.472900,274.742126 498.268372,274.727661 
	C502.776825,274.025726 506.894012,273.204468 511.008789,272.816650 
	C511.005493,273.827728 511.004578,274.405426 511.003662,274.983093 
z"/>
<path fill="#644D77" opacity="1.000000" stroke="none" 
	d="
M785.928223,353.424469 
	C786.213806,352.764282 786.595032,352.431793 787.427979,351.870361 
	C788.534302,352.131653 789.188843,352.621948 789.897522,353.447723 
	C804.523499,368.793549 818.038879,384.676971 829.830139,402.374634 
	C831.800598,406.232330 833.226685,410.169739 837.819092,411.455475 
	C836.168823,408.634216 834.518616,405.812927 832.791565,402.629028 
	C832.463440,401.844849 832.212158,401.423279 831.929321,400.722534 
	C831.578064,400.019897 831.258301,399.596466 830.827759,398.842529 
	C824.796143,389.380188 818.875183,380.248322 813.096619,370.845825 
	C813.559937,370.325195 813.880859,370.075165 814.201782,369.825134 
	C816.601501,372.469452 819.355042,374.873169 821.343323,377.797272 
	C833.879395,396.233887 844.762085,415.619598 853.915100,435.955078 
	C854.919250,438.186035 855.695374,440.660980 855.867432,443.073395 
	C855.974915,444.580597 854.751221,446.182739 854.124878,447.742249 
	C852.562500,446.713043 850.428101,446.032227 849.548279,444.587402 
	C847.175720,440.691010 845.182190,436.538635 843.316040,432.363647 
	C836.821716,417.834167 827.951660,404.769897 818.539551,392.053894 
	C812.930237,384.475464 807.255981,376.922272 801.188904,369.712860 
	C796.472168,364.107971 791.103027,359.052094 785.928223,353.424469 
M848.451782,435.199310 
	C849.235291,437.061890 850.018799,438.924469 850.802307,440.787048 
	C851.285461,440.549103 851.768616,440.311157 852.251709,440.073242 
	C848.805847,431.870941 846.459778,423.111298 840.191650,416.339172 
	C839.620239,416.622467 839.048828,416.905731 838.477417,417.188995 
	C841.658081,422.990387 844.838806,428.791779 848.451782,435.199310 
M852.516235,442.391754 
	C852.516235,442.391754 852.581116,442.504395 852.516235,442.391754 
z"/>
<path fill="#6A537A" opacity="1.000000" stroke="none" 
	d="
M720.905518,326.103760 
	C724.565430,327.645813 728.199707,329.136047 731.419739,331.255585 
	C739.530762,336.594513 747.856567,341.723755 755.366028,347.834381 
	C768.782715,358.751801 781.351501,370.573303 792.659302,383.810944 
	C803.900146,396.970337 813.739075,410.991394 822.447266,425.874054 
	C823.439758,427.570190 824.120544,429.448822 824.541626,431.636780 
	C823.544189,432.101257 822.950989,432.171814 822.154053,432.016785 
	C821.056702,430.704102 820.163086,429.617035 819.247559,428.207825 
	C818.473083,426.601807 817.720398,425.317932 817.005127,423.715027 
	C816.519958,422.777405 815.997498,422.158844 815.366821,421.261658 
	C814.803955,420.344788 814.349365,419.706573 813.914490,418.717468 
	C812.729492,416.789398 811.524719,415.212189 810.268066,413.465942 
	C810.216064,413.296936 810.012878,413.007507 810.018799,412.658264 
	C808.792419,410.748718 807.560120,409.188354 806.187561,407.339233 
	C805.046326,405.693176 804.045349,404.335876 803.035828,402.657471 
	C802.431335,401.791351 801.835266,401.246338 801.175293,400.532166 
	C801.111389,400.363037 800.903381,400.067200 800.923218,399.740051 
	C800.317505,398.907104 799.692017,398.401276 798.968872,397.615601 
	C798.250427,396.599060 797.629517,395.862335 796.985657,394.770813 
	C794.129272,391.221466 791.295898,388.026947 788.308960,384.542480 
	C787.118225,383.141846 786.080994,382.031097 785.009766,380.589233 
	C783.401062,378.758850 781.826294,377.259583 780.135986,375.852783 
	C780.020447,375.945221 780.232544,375.738678 780.146484,375.414673 
	C776.785217,371.988342 773.510071,368.886017 770.124084,365.880493 
	C770.013245,365.977325 770.220947,365.768585 770.150879,365.443298 
	C767.877991,363.289185 765.675232,361.460388 763.429199,359.624054 
	C763.385986,359.616516 763.359131,359.533020 763.286621,359.213135 
	C761.478394,357.579712 759.742676,356.266174 757.917847,354.827332 
	C757.828735,354.701996 757.538025,354.601501 757.435059,354.272217 
	C753.291321,350.888519 749.250671,347.834167 745.113525,344.887451 
	C745.017090,344.995117 745.205811,344.780792 745.128479,344.453979 
	C743.356445,343.103333 741.661804,342.079437 739.846069,340.850769 
	C739.548645,340.336700 739.282715,340.192841 738.839294,339.933960 
	C738.237732,339.397644 737.724304,339.141693 737.027344,338.616150 
	C735.928589,337.805908 735.013367,337.265259 733.857910,336.501434 
	C730.583191,334.358063 727.548767,332.437866 724.368713,330.228180 
	C723.276611,329.522797 722.330017,329.106903 721.348022,328.687134 
	C721.312622,328.683319 721.287476,328.616638 721.249634,328.351868 
	C720.895264,327.660492 720.578674,327.233887 720.262085,326.807281 
	C720.405151,326.651001 720.548218,326.494751 720.905518,326.103760 
z"/>
<path fill="#644D77" opacity="1.000000" stroke="none" 
	d="
M738.360840,729.755615 
	C738.360840,729.755615 738.583069,730.117981 738.341309,730.363037 
	C737.718933,730.743652 737.338318,730.879272 736.747070,731.113708 
	C736.273010,731.400940 736.098816,731.651489 735.889832,732.228577 
	C735.532837,733.008789 735.299866,733.524597 735.066895,734.040283 
	C734.192139,734.890747 733.317444,735.741272 731.910583,736.877258 
	C730.132080,738.625000 728.885742,740.087219 727.639404,741.549500 
	C727.639404,741.549500 727.637512,741.630920 727.327637,741.725586 
	C726.566223,742.406067 726.114685,742.991882 725.663147,743.577759 
	C725.663147,743.577759 725.650757,743.652893 725.299805,743.707642 
	C724.159241,744.753723 723.369690,745.745117 722.580200,746.736572 
	C722.126160,746.985718 721.672058,747.234863 720.761841,747.761475 
	C718.187744,750.017456 716.069641,751.995972 713.951538,753.974487 
	C713.619202,754.038818 713.359070,754.211792 712.719971,754.739746 
	C709.151428,757.633057 706.034058,760.280029 702.916626,762.927002 
	C702.916626,762.927002 702.995667,762.999390 702.617371,762.964844 
	C701.329651,763.844543 700.420288,764.758850 699.510864,765.673096 
	C699.510864,765.673096 699.210022,765.749939 698.778503,765.851685 
	C697.748413,766.536926 697.149902,767.120422 696.551331,767.703918 
	C696.103088,767.936401 695.654785,768.168884 694.710815,768.627686 
	C693.382751,769.213684 692.550476,769.573425 691.460815,769.976074 
	C690.807983,770.325256 690.412598,770.631409 689.750977,771.156128 
	C681.498962,775.964600 673.513123,780.554504 665.192505,785.208679 
	C664.609619,785.630676 664.361511,785.988403 663.847534,786.552856 
	C661.514893,787.580322 659.448181,788.401123 656.974487,789.156860 
	C656.018738,789.050110 655.469910,789.008362 654.921082,788.966553 
	C659.488037,786.041260 664.070557,783.139954 668.618958,780.186035 
	C680.764038,772.298157 693.225098,764.842529 704.922302,756.337280 
	C713.732605,749.931152 722.048401,742.650696 729.676270,734.869385 
	C736.827393,727.574341 742.700745,719.038452 749.319092,711.202820 
	C751.387268,708.754272 752.288940,704.688049 757.149780,705.008179 
	C757.002869,706.304382 756.427612,707.562988 755.413940,709.274353 
	C751.665222,714.161621 748.354980,718.596191 745.044800,723.030762 
	C744.676270,723.384460 744.307739,723.738159 743.505249,724.443665 
	C741.917053,725.861084 740.762695,726.926697 739.382324,728.026306 
	C738.891174,728.625427 738.625977,729.190491 738.360840,729.755615 
z"/>
<path fill="#644D77" opacity="1.000000" stroke="none" 
	d="
M325.446289,689.098633 
	C328.006836,691.828735 330.763763,694.401978 333.092010,697.317566 
	C340.831207,707.009094 348.171936,717.027588 356.115906,726.544739 
	C369.022491,742.007385 384.637817,754.525085 400.839966,766.331909 
	C404.511200,769.007141 408.267365,771.565857 412.012390,774.567505 
	C412.040405,774.956848 411.959503,775.041199 411.529053,775.043152 
	C410.526337,775.115479 409.954071,775.185791 409.058533,775.225952 
	C406.795959,773.876038 404.856689,772.556396 402.799255,770.937378 
	C402.095276,770.437500 401.509460,770.237000 400.601898,769.924561 
	C394.828400,765.728333 389.376678,761.643982 383.834351,757.433594 
	C383.594635,757.156433 383.419678,757.046509 382.904205,756.855835 
	C381.049805,755.399292 379.510071,754.064636 377.881897,752.573608 
	C377.637695,752.225464 377.442017,752.092163 376.959412,751.786865 
	C374.439301,749.469238 372.166290,747.382141 369.797333,744.997314 
	C369.345093,744.508850 368.988800,744.318115 368.453522,744.061584 
	C368.047943,743.844910 367.916290,743.634644 367.812103,743.067749 
	C367.235046,742.489990 366.725464,742.209473 365.937866,741.824524 
	C365.064484,740.950684 364.469086,740.181213 363.795959,739.093384 
	C363.359192,738.554810 363.000061,738.334717 362.359741,737.956665 
	C359.656799,735.074585 357.235046,732.350403 354.767395,729.284790 
	C354.160370,728.626404 353.599274,728.309509 352.935120,727.888794 
	C352.832001,727.785095 352.861328,727.494080 352.809082,727.132935 
	C352.028778,726.223755 351.300690,725.675842 350.280396,725.006226 
	C348.292206,722.950195 346.596222,721.015747 344.811584,718.752136 
	C341.158173,713.590820 337.593475,708.758728 334.019958,703.572144 
	C333.242340,702.179016 332.473541,701.140320 331.416687,699.980347 
	C330.336273,698.617371 329.543884,697.375732 328.698364,695.794922 
	C327.578918,693.336731 326.512604,691.217651 325.446289,689.098633 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M822.357788,432.242371 
	C822.950989,432.171814 823.544189,432.101257 824.585083,432.000427 
	C827.648315,436.717560 830.597412,441.319733 832.751587,446.267975 
	C833.996704,449.128174 832.769958,451.120453 828.870239,451.082458 
	C811.579102,450.913971 794.277710,450.718201 776.996704,451.170135 
	C770.775330,451.332855 769.367126,446.791870 766.981201,443.245392 
	C762.485168,436.562256 758.495056,429.538727 754.581604,422.327087 
	C755.696045,421.955811 756.523560,421.914185 757.580933,421.972168 
	C758.204224,422.381165 758.597473,422.690582 758.987671,423.323853 
	C759.550781,424.231384 760.117065,424.815033 760.728027,425.563904 
	C760.772766,425.729095 760.966614,426.011261 760.965027,426.331116 
	C761.529907,427.234589 762.096375,427.818176 762.749756,428.719238 
	C763.908447,430.679504 764.980286,432.322327 766.027832,434.284729 
	C766.576538,435.189117 767.149597,435.773987 767.806519,436.641907 
	C768.303772,437.592255 768.716980,438.259583 769.107544,439.248932 
	C769.629578,440.161682 770.174194,440.752411 770.815552,441.684235 
	C774.415039,448.192322 775.511047,448.685364 783.488037,447.641357 
	C794.933960,447.672211 805.940002,447.546082 817.381104,447.601074 
	C821.509399,447.782196 825.202637,447.782196 829.851318,447.782196 
	C827.022949,441.916840 824.690369,437.079620 822.357788,432.242371 
z"/>
<path fill="#5D4476" opacity="1.000000" stroke="none" 
	d="
M850.384766,686.279236 
	C850.156006,686.235291 849.927185,686.191345 849.352173,686.100342 
	C849.461182,683.126282 850.915649,680.479309 854.000793,681.638550 
	C855.052551,682.033630 855.728760,686.543884 854.875977,688.387146 
	C849.284180,700.474854 843.721191,712.626770 837.172302,724.205383 
	C831.426941,734.363403 824.506287,743.868713 817.892639,753.516968 
	C815.552612,756.930664 812.586243,759.915039 809.338501,762.931030 
	C808.634033,762.630737 808.496826,762.494812 808.456299,762.035400 
	C812.032288,756.475037 815.511719,751.238159 819.169067,745.849854 
	C819.590515,745.490417 819.760437,745.233704 820.151733,744.775330 
	C821.395264,743.101929 822.343872,741.581543 823.616760,739.991699 
	C827.758850,733.596497 831.576721,727.270752 835.604126,720.722534 
	C838.852905,714.681641 841.892212,708.863281 845.223938,702.847717 
	C847.882996,697.778809 850.379517,692.963989 852.548889,688.006042 
	C853.356445,686.160522 853.139343,684.309875 850.384766,686.279236 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M333.135345,429.137268 
	C329.699219,435.307892 326.372894,441.543701 322.769623,447.615112 
	C321.444183,449.848450 319.318665,452.670990 316.545624,450.308929 
	C315.386108,449.321289 315.420990,445.578064 316.309875,443.777222 
	C319.832977,436.639221 323.770569,429.692322 327.824005,422.833862 
	C330.931671,417.575714 334.387451,412.518402 337.814728,407.457336 
	C338.705109,406.142487 340.068756,405.148132 341.649475,404.044495 
	C342.083923,404.082397 342.261871,404.255402 342.199982,404.595184 
	C337.655365,412.513672 333.172668,420.092377 328.390930,427.826050 
	C326.312012,431.564575 324.532104,435.148132 322.442383,438.862183 
	C321.650970,439.971405 321.169312,440.950134 320.362366,442.030212 
	C319.347412,443.839508 318.657745,445.547424 317.968079,447.255341 
	C318.551575,447.510468 319.135071,447.765594 319.718567,448.020721 
	C320.762054,445.984589 321.805511,443.948486 322.938110,441.588104 
	C323.138123,440.606171 323.248993,439.948517 323.623596,439.089447 
	C326.014832,435.670258 328.142303,432.452454 330.433960,429.166260 
	C330.598145,429.097900 330.929047,428.967407 331.261139,428.974365 
	C332.034027,428.959900 332.474854,428.938446 332.970734,428.971863 
	C333.025818,429.026794 333.135345,429.137268 333.135345,429.137268 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M368.956482,781.545532 
	C368.956482,781.545532 368.943390,781.656921 369.038330,781.998657 
	C369.220612,782.742432 369.307983,783.144531 369.018707,783.667725 
	C367.244965,782.970886 365.933685,781.887939 364.429474,781.400818 
	C362.462067,780.763855 359.193390,777.768066 358.006287,776.368835 
	C355.461823,773.369629 352.483856,770.732910 349.630371,768.003174 
	C348.100220,766.539307 346.147491,765.414307 344.945099,763.735535 
	C343.264130,761.388550 342.422760,758.420227 340.658722,756.156982 
	C336.853058,751.274231 332.529755,746.793213 328.757233,741.887268 
	C327.448669,740.185547 327.141388,737.713867 326.690033,735.720947 
	C330.242096,739.648621 333.480896,743.449219 336.820831,747.574219 
	C339.461487,750.776367 342.001007,753.654053 344.633606,756.855469 
	C347.378845,759.955139 350.031006,762.731018 352.780151,765.787598 
	C354.046021,767.155457 355.214874,768.242615 356.496765,769.629456 
	C357.271057,770.395386 357.932281,770.861511 358.602875,771.350464 
	C358.612244,771.373291 358.619629,771.421997 358.660400,771.750671 
	C359.792206,773.101624 360.883301,774.123901 362.116577,775.304688 
	C362.460297,775.664551 362.678192,775.846252 363.028992,776.309021 
	C363.701935,776.923645 364.258362,777.237488 364.852203,777.803833 
	C365.267883,778.363647 365.646149,778.671021 366.154968,779.285095 
	C367.175842,780.243042 368.066162,780.894287 368.956482,781.545532 
z"/>
<path fill="#644D77" opacity="1.000000" stroke="none" 
	d="
M417.971924,778.058594 
	C421.228119,779.412231 424.525818,780.674744 427.733215,782.135559 
	C440.808685,788.091064 453.856628,794.107117 466.980103,800.723633 
	C467.050873,801.534119 467.056305,801.721863 467.045380,801.933350 
	C467.029022,801.957214 466.996979,802.005371 466.614990,802.009094 
	C460.623871,800.013977 454.952026,798.173035 449.421265,795.976929 
	C442.229736,793.121399 435.150269,789.983643 428.014160,786.985352 
	C428.005737,787.003296 428.035187,786.973938 428.037231,786.734375 
	C428.039307,786.494812 427.966583,786.021179 428.127625,785.840820 
	C427.733917,785.491150 427.179169,785.321777 426.456848,785.171997 
	C426.289307,785.191467 426.008575,785.004395 425.867615,784.757690 
	C425.399139,784.327271 425.071625,784.143555 424.437805,783.859375 
	C422.095459,782.728760 420.059448,781.698486 418.019958,780.341309 
	C418.001678,779.362488 417.986786,778.710510 417.971924,778.058594 
z"/>
<path fill="#695378" opacity="1.000000" stroke="none" 
	d="
M814.104980,369.485413 
	C813.880859,370.075165 813.559937,370.325195 812.760864,370.762482 
	C810.013672,368.003143 807.872070,364.946625 805.446533,362.134979 
	C801.513550,357.576172 797.394348,353.178040 793.297974,348.725189 
	C793.242249,348.738312 793.306702,348.643616 793.261902,348.310272 
	C790.789978,345.357025 788.362793,342.737122 785.724976,339.861694 
	C784.422119,338.207367 783.329773,336.808563 782.237427,335.409790 
	C782.844055,335.294983 783.450623,335.180176 784.519043,335.028870 
	C793.999512,344.872894 803.056702,354.718842 811.984741,364.680511 
	C813.024963,365.841125 813.351013,367.641754 814.104980,369.485413 
z"/>
<path fill="#5D4476" opacity="1.000000" stroke="none" 
	d="
M777.426270,800.116211 
	C776.997681,799.845093 776.929504,799.517517 776.961731,798.484253 
	C785.663330,788.162903 794.264465,778.547241 803.215454,768.920166 
	C804.978882,766.839111 806.392334,764.769287 807.915894,762.568115 
	C808.025879,762.436707 808.359680,762.358826 808.359680,762.358826 
	C808.496826,762.494812 808.634033,762.630737 808.981506,762.969727 
	C808.528198,765.177185 808.361389,767.585205 807.116455,769.116943 
	C801.238831,776.348694 795.245911,783.506653 788.925537,790.350464 
	C785.603455,793.947815 781.524109,796.845886 777.426270,800.116211 
z"/>
<path fill="#6B567C" opacity="1.000000" stroke="none" 
	d="
M336.719666,747.249817 
	C333.480896,743.449219 330.242096,739.648621 326.629700,735.555542 
	C326.256073,735.263000 326.004517,735.013367 325.981903,734.662720 
	C323.758759,731.389221 321.558228,728.466370 319.259613,725.267700 
	C318.718414,724.353516 318.275269,723.715271 317.862030,722.718506 
	C316.993317,720.789490 316.069092,719.233032 315.200592,717.645996 
	C311.799042,711.430420 308.415100,705.205200 305.347595,699.008423 
	C306.460144,699.383179 307.249695,699.733154 308.039246,700.083069 
	C308.039246,700.083069 308.077148,700.432190 308.065674,700.958130 
	C308.702789,702.354370 309.351410,703.224609 310.000031,704.094849 
	C312.981567,709.181458 315.699738,714.446777 319.003143,719.315002 
	C324.722687,727.743896 330.804932,735.926758 336.870422,744.861450 
	C337.028015,745.983521 337.050293,746.456848 336.984985,747.010864 
	C336.897400,747.091431 336.719666,747.249817 336.719666,747.249817 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M388.204895,355.127686 
	C387.011383,356.929291 385.732880,358.292480 383.858795,359.821075 
	C382.873566,360.404938 382.483887,360.823395 381.997742,361.499817 
	C381.810364,362.110840 381.719482,362.463867 381.628571,362.816925 
	C381.173370,363.089294 380.718201,363.361694 379.865051,363.919128 
	C378.661804,364.799896 377.856537,365.395569 376.875000,366.024902 
	C376.590546,366.344635 376.482330,366.630737 376.360718,367.114746 
	C376.347351,367.312622 376.402466,367.705505 376.402466,367.705505 
	C374.664764,369.143280 372.927094,370.581085 370.559998,372.214539 
	C369.748199,372.830536 369.565796,373.250854 369.383392,373.671204 
	C368.894165,374.371246 368.404938,375.071289 367.553711,376.150574 
	C366.700165,377.306580 366.208649,378.083374 365.717133,378.860107 
	C364.536896,379.921783 363.356659,380.983429 361.541870,382.180115 
	C360.622284,382.896057 360.337189,383.476990 360.052124,384.057922 
	C360.052155,384.057922 359.974854,384.197693 359.897949,384.227783 
	C358.040741,383.968506 355.974915,383.597870 358.241821,381.363373 
	C367.874817,371.868134 377.605927,362.472443 387.644165,352.975159 
	C388.031433,353.503082 388.075714,354.096191 388.204895,355.127686 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M603.029602,805.056335 
	C603.454956,805.035400 603.880249,805.014465 604.837524,805.254639 
	C606.897095,804.942932 608.424866,804.370056 609.952576,803.797180 
	C610.734924,803.537537 611.517273,803.277893 612.787781,803.246765 
	C613.846008,803.276184 614.416077,803.077148 614.986145,802.878174 
	C615.450928,802.597717 615.915649,802.317200 616.880249,802.272522 
	C619.277222,801.995850 621.174377,801.483398 623.071472,800.970947 
	C624.743530,800.292603 626.415588,799.614258 628.790405,798.985596 
	C630.017944,799.408813 630.542664,799.782471 630.810669,800.407227 
	C622.703003,803.126160 614.851929,805.593933 606.677368,808.071289 
	C605.948853,808.222961 605.543884,808.364990 604.750244,808.393005 
	C600.565308,809.164185 596.768982,810.049438 592.650879,810.935181 
	C591.910095,811.016968 591.491028,811.098206 590.708740,811.079590 
	C589.233459,811.364319 588.121399,811.748901 586.676514,812.100220 
	C585.908997,812.148987 585.474365,812.231079 584.618652,812.195435 
	C580.450012,812.330627 576.702454,812.583557 572.962280,812.489502 
	C572.993408,811.450928 573.017151,810.759277 573.040894,810.067627 
	C573.471436,810.054504 573.901917,810.041321 574.913452,810.304565 
	C577.340637,810.088623 579.186768,809.596191 581.032959,809.103760 
	C581.763489,809.072693 582.494080,809.041565 583.812256,809.276611 
	C585.628967,809.333618 586.858093,809.124451 588.087158,808.915344 
	C589.795898,808.682312 591.504578,808.449280 593.674438,808.259827 
	C594.383240,808.101135 594.630981,807.898865 594.878784,807.696655 
	C596.664185,807.133484 598.449585,806.570374 600.770386,806.234375 
	C601.880371,805.993164 602.455017,805.524719 603.029602,805.056335 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M572.574463,810.043640 
	C573.017151,810.759277 572.993408,811.450928 572.658081,812.603088 
	C571.915771,813.063354 571.484985,813.063110 570.788818,812.766785 
	C567.997620,812.893982 565.471863,813.317261 562.504517,813.832336 
	C559.052429,813.956604 556.042114,813.989075 552.737915,813.722656 
	C547.620605,813.616882 542.797180,813.809998 537.528198,814.053650 
	C534.406799,813.953735 531.730957,813.803284 528.937927,813.317993 
	C526.851379,812.298523 524.881958,811.613831 522.912598,810.929199 
	C526.432068,810.949890 529.953369,811.054504 533.470642,810.976379 
	C546.350464,810.690308 559.229065,810.343567 572.574463,810.043640 
z"/>
<path fill="#644D77" opacity="1.000000" stroke="none" 
	d="
M352.884155,737.104614 
	C351.441162,736.419434 349.422852,736.127380 348.644470,734.988159 
	C341.100739,723.947021 333.756378,712.769409 326.397583,701.602783 
	C326.063660,701.096130 326.134338,700.322754 326.312683,699.394714 
	C327.105560,699.350464 327.602356,699.585144 328.143463,700.180420 
	C329.842224,703.313965 331.319183,706.213623 333.188324,708.833313 
	C337.050781,714.246826 341.122131,719.511292 345.091736,724.876648 
	C345.075928,724.916260 345.161194,724.913208 345.140747,725.236816 
	C345.803253,726.259460 346.486237,726.958435 347.267334,727.993103 
	C349.329803,731.144592 351.294159,733.960449 353.164856,736.858337 
	C353.071228,736.940369 352.884125,737.104614 352.884155,737.104614 
z"/>
<path fill="#644D77" opacity="1.000000" stroke="none" 
	d="
M377.057892,762.943665 
	C372.516510,759.825073 367.862366,756.832092 363.890839,753.110535 
	C360.335693,749.779297 357.452820,745.707092 354.449646,741.825439 
	C353.616791,740.748901 353.411804,739.186584 352.901428,737.475891 
	C352.884125,737.104614 353.071228,736.940369 353.458435,736.987183 
	C355.219086,738.360901 356.592529,739.687744 358.002563,741.330994 
	C358.698059,742.424072 359.357025,743.200684 360.178070,744.272827 
	C366.097626,750.502502 371.855072,756.436646 377.612549,762.370850 
	C377.489136,762.497986 377.365692,762.625061 377.057892,762.943665 
z"/>
<path fill="#644D77" opacity="1.000000" stroke="none" 
	d="
M321.019135,688.985657 
	C317.638916,687.896057 314.070892,682.136292 315.209198,679.146362 
	C316.205627,676.528992 318.198517,674.607971 320.713928,677.368774 
	C323.104279,679.992249 327.232849,682.238770 324.127289,686.922791 
	C322.805786,686.370117 322.110779,685.923706 321.019135,685.222656 
	C321.019135,686.836853 321.019135,687.911255 321.019135,688.985657 
M320.071228,683.460815 
	C320.266174,683.442078 320.461151,683.423340 320.656097,683.404663 
	C320.479034,683.232666 320.301941,683.060669 320.134064,682.249939 
	C320.087433,681.826538 320.040802,681.403198 319.904602,680.360474 
	C319.440125,680.111206 318.975647,679.861938 318.511169,679.612671 
	C318.669250,680.741333 318.827362,681.869934 319.443695,683.077759 
	C319.443695,683.077759 319.907990,683.104309 320.071228,683.460815 
z"/>
<path fill="#F1D4CA" opacity="1.000000" stroke="none" 
	d="
M834.513550,261.851318 
	C835.073364,262.038879 835.355957,262.409149 835.832703,263.266235 
	C830.382080,269.963287 824.737305,276.173523 818.971313,282.080566 
	C818.531433,281.489685 818.212769,281.201996 817.894043,280.914307 
	C823.341492,274.620880 828.789001,268.327423 834.513550,261.851318 
z"/>
<path fill="#6D587B" opacity="1.000000" stroke="none" 
	d="
M757.351013,421.872559 
	C756.523560,421.914185 755.696045,421.955811 754.455322,422.011780 
	C754.041992,422.026093 753.991943,421.992737 753.973022,421.678833 
	C753.650818,420.910950 753.347534,420.457001 753.044312,420.003052 
	C752.163025,418.847565 751.281677,417.692108 750.175842,415.966217 
	C749.595886,414.967712 749.240479,414.539612 748.885132,414.111511 
	C746.598633,411.332428 744.312073,408.553345 741.901550,405.196655 
	C741.607483,404.321533 741.354919,404.136810 741.019775,404.064911 
	C741.019775,404.064911 740.959412,404.051117 740.970581,403.693787 
	C740.981995,402.896790 740.982300,402.457123 741.130737,401.868530 
	C741.278931,401.719604 741.575256,401.421783 741.854980,401.595245 
	C744.882141,404.673553 747.629639,407.578400 750.429932,410.500610 
	C750.482727,410.517944 750.460022,410.626709 750.481689,410.953064 
	C752.785828,414.810455 755.068420,418.341522 757.351013,421.872559 
z"/>
<path fill="#6D587B" opacity="1.000000" stroke="none" 
	d="
M352.928589,400.931152 
	C350.568329,404.695679 348.108032,408.350006 345.521729,411.912842 
	C343.195618,415.117249 340.731262,418.221313 337.846313,421.790283 
	C336.713104,422.328491 336.061493,422.446014 335.530334,422.243500 
	C337.170074,419.364380 338.689392,416.805267 340.398193,413.864929 
	C340.420441,412.996643 340.253143,412.509583 340.050446,412.013916 
	C340.015045,412.005310 340.045624,412.071472 340.408081,412.208984 
	C340.869781,411.910553 340.969025,411.474640 341.031799,411.031982 
	C340.995361,411.025238 341.049316,411.076080 341.389862,411.249786 
	C344.280518,408.397430 346.830627,405.371399 349.541077,402.183655 
	C349.906799,401.798920 350.111877,401.575592 350.604309,401.297485 
	C351.415833,400.988953 351.939850,400.734955 352.463867,400.480957 
	C352.463867,400.480957 352.773651,400.781128 352.928589,400.931152 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M353.315460,391.592804 
	C353.315460,391.592804 353.316223,391.847443 352.943237,392.049438 
	C352.428711,392.609650 352.287201,392.967896 352.145691,393.326141 
	C352.148956,393.594086 352.061493,393.828094 351.374573,394.179688 
	C350.648010,394.717468 350.430206,395.103790 350.111206,395.791626 
	C349.446136,397.232758 348.882263,398.372345 348.135223,399.609131 
	C347.729584,399.897186 347.596161,400.137482 347.290405,400.550385 
	C346.440521,401.150696 345.851929,401.627869 344.964325,402.166504 
	C344.372162,402.506897 344.078979,402.785889 343.640137,403.183350 
	C343.293030,403.481323 343.204773,403.705383 342.993103,404.062714 
	C342.591614,404.186096 342.426758,404.220764 342.261871,404.255402 
	C342.261871,404.255402 342.083923,404.082397 341.993378,403.995178 
	C344.836029,396.120239 349.369781,389.605652 356.751221,385.697327 
	C356.989197,386.434357 357.171173,386.785797 357.353149,387.137207 
	C356.893646,387.739838 356.434143,388.342438 355.339539,389.100952 
	C354.241486,390.035522 353.778473,390.814178 353.315460,391.592804 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M484.231384,804.906189 
	C484.613678,804.910522 484.995941,804.914856 485.880615,805.230103 
	C488.597046,805.709595 490.811066,805.878052 493.025085,806.046570 
	C494.125885,806.266235 495.226685,806.485901 496.650116,807.214600 
	C496.995483,808.438721 497.018219,809.153809 496.701599,809.948914 
	C495.898987,810.069580 495.435760,810.110229 494.732605,809.849487 
	C492.688049,809.316528 490.883423,809.084900 488.786346,808.661377 
	C487.337219,808.303162 486.180481,808.136780 484.773193,807.699341 
	C480.000214,805.994080 475.477814,804.559814 471.062988,802.882080 
	C471.447845,802.424316 471.725128,802.210022 472.342102,802.144165 
	C474.128479,802.516418 475.575226,802.740173 477.021973,802.963989 
	C477.021973,802.963989 477.002441,803.000000 477.093475,803.164124 
	C477.476410,803.209961 477.768341,803.091675 478.060272,802.973389 
	C478.483276,802.983154 478.906281,802.992920 479.724823,803.384094 
	C481.490692,804.145813 482.861023,804.526001 484.231384,804.906189 
z"/>
<path fill="#69517A" opacity="1.000000" stroke="none" 
	d="
M329.023193,821.002930 
	C327.319641,819.996521 325.438110,819.200256 323.949738,817.939819 
	C320.878021,815.338379 318.015900,812.489563 315.027863,809.374390 
	C314.988098,809.006348 315.056122,808.924927 315.319916,808.828857 
	C316.089020,808.479004 316.594360,808.225159 317.389404,808.127930 
	C321.551788,811.646912 325.424469,815.009338 329.220093,818.806519 
	C329.103088,819.828552 329.063141,820.415771 329.023193,821.002930 
z"/>
<path fill="#6D587B" opacity="1.000000" stroke="none" 
	d="
M738.990540,402.026306 
	C736.346741,399.263245 733.702881,396.500183 731.023804,393.063660 
	C730.971680,391.947571 730.954773,391.504913 730.937805,391.062286 
	C730.937805,391.062286 731.132080,390.866119 731.549316,390.863159 
	C734.489319,393.049438 737.012085,395.238678 739.647034,397.415009 
	C739.759277,397.402100 739.553101,397.494598 739.576782,397.807556 
	C740.258667,399.220947 740.916992,400.321381 741.575256,401.421783 
	C741.575256,401.421783 741.278931,401.719604 740.798950,401.861816 
	C739.876221,402.011475 739.433350,402.018890 738.990540,402.026306 
z"/>
<path fill="#A39998" opacity="1.000000" stroke="none" 
	d="
M321.010315,688.992493 
	C321.019135,687.911255 321.019135,686.836853 321.019135,685.222656 
	C322.110779,685.923706 322.805786,686.370117 323.784119,687.012329 
	C324.067444,687.208191 324.088837,687.534668 324.188110,687.972046 
	C324.603546,688.603638 324.919708,688.797913 325.235870,688.992188 
	C325.235870,688.992188 325.341461,689.055054 325.393860,689.076843 
	C326.512604,691.217651 327.578918,693.336731 328.428223,695.812866 
	C328.173859,697.386597 328.136536,698.603210 328.099182,699.819824 
	C327.602356,699.585144 327.105560,699.350464 326.347534,699.016235 
	C326.086334,698.916626 326.059906,698.937927 326.039673,698.587341 
	C324.350891,695.154053 322.682373,692.071289 321.013855,688.988586 
	C321.013855,688.988586 321.001526,688.999329 321.010315,688.992493 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M497.040955,809.868896 
	C497.018219,809.153809 496.995483,808.438721 496.957703,807.366211 
	C503.442688,807.825500 509.942749,808.642273 516.498291,810.011719 
	C515.023743,811.022827 513.493591,811.481140 511.640686,811.992676 
	C510.895142,811.949341 510.472382,811.852844 509.959534,811.428345 
	C508.258698,811.022339 506.647949,810.944336 504.700378,810.866455 
	C503.928101,810.772034 503.492615,810.677429 502.954346,810.284058 
	C501.564087,809.963684 500.276611,809.942078 498.631165,809.989746 
	C497.800751,810.166077 497.328339,810.273193 496.884644,810.246094 
	C496.913391,810.111877 497.040955,809.868896 497.040955,809.868896 
z"/>
<path fill="#69547B" opacity="1.000000" stroke="none" 
	d="
M523.828552,269.908386 
	C525.317505,270.275787 526.806396,270.643219 528.683655,271.041504 
	C524.018127,272.062225 518.964294,273.052124 513.352783,273.562073 
	C512.697327,272.750183 512.599609,272.418243 512.584412,272.055969 
	C512.666931,272.025635 512.840637,271.996704 513.213684,271.998901 
	C514.705139,271.714417 515.823547,271.427734 517.306641,270.996338 
	C519.723755,270.537201 521.776184,270.222778 523.828552,269.908386 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M631.067383,800.156128 
	C630.542664,799.782471 630.017944,799.408813 629.152527,798.981262 
	C633.638672,796.937805 638.465576,794.948303 643.515381,793.279053 
	C640.808105,795.344177 637.877930,797.089111 634.633057,798.973267 
	C633.234802,799.460449 632.151062,799.808289 631.067383,800.156128 
z"/>
<path fill="#6B567C" opacity="1.000000" stroke="none" 
	d="
M352.683136,765.506897 
	C350.031006,762.731018 347.378845,759.955139 344.750793,756.722046 
	C344.774933,756.264893 345.005951,755.995117 345.341248,756.005127 
	C346.124084,756.015808 346.571686,756.016602 347.019257,756.017334 
	C348.930450,758.410034 350.841644,760.802795 352.832703,763.923401 
	C352.836090,764.936523 352.759613,765.221680 352.683136,765.506897 
z"/>
<path fill="#69517A" opacity="1.000000" stroke="none" 
	d="
M306.885742,801.467163 
	C307.389221,801.059875 307.994507,800.977417 309.343292,800.913940 
	C311.795258,802.750854 313.503723,804.568848 315.155396,806.808777 
	C315.084473,807.795471 315.070312,808.360229 315.056122,808.924927 
	C315.056122,808.924927 314.988098,809.006348 314.951782,809.046631 
	C312.272858,806.655212 309.630188,804.223633 306.885742,801.467163 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M654.543701,788.973145 
	C655.469910,789.008362 656.018738,789.050110 656.766235,789.420654 
	C655.597473,790.672791 654.230103,791.596252 652.631470,792.602783 
	C652.400208,792.685913 652.002869,792.974976 651.617554,792.952209 
	C649.515198,793.421204 647.798157,793.912964 646.040283,793.999573 
	C645.967834,793.324890 645.936218,793.055359 645.904663,792.785767 
	C648.658508,791.517090 651.412415,790.248413 654.543701,788.973145 
z"/>
<path fill="#6B567C" opacity="1.000000" stroke="none" 
	d="
M344.657715,756.398315 
	C342.001007,753.654053 339.461487,750.776367 336.820831,747.574219 
	C336.719666,747.249817 336.897400,747.091431 337.306152,747.074585 
	C339.151794,748.036743 340.588654,749.015747 342.025513,749.994690 
	C342.025513,749.994690 341.996124,749.998474 342.024902,750.329895 
	C343.016266,751.456604 343.978851,752.251831 344.941437,753.047058 
	C344.941437,753.047058 344.993774,753.465454 344.992065,754.028687 
	C344.995575,755.059631 345.000763,755.527405 345.005951,755.995117 
	C345.005951,755.995117 344.774933,756.264893 344.657715,756.398315 
z"/>
<path fill="#F4DED6" opacity="1.000000" stroke="none" 
	d="
M817.598633,281.075043 
	C818.212769,281.201996 818.531433,281.489685 818.785156,282.327515 
	C816.044495,285.535309 813.368774,288.192963 810.402832,290.507019 
	C809.913574,289.939209 809.714478,289.715027 809.515442,289.490814 
	C812.111389,286.739136 814.707336,283.987457 817.598633,281.075043 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M366.024414,778.978394 
	C365.646149,778.671021 365.267883,778.363647 364.790771,777.458130 
	C364.098755,776.576111 363.505585,776.292175 362.912415,776.008301 
	C362.678192,775.846252 362.460297,775.664551 362.105225,774.954346 
	C361.656067,773.562561 361.360443,772.679688 361.064789,771.796753 
	C363.312744,773.658997 365.560699,775.521179 367.689209,777.791931 
	C367.054626,778.459717 366.539490,778.719055 366.024414,778.978394 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M369.395355,783.546631 
	C369.307983,783.144531 369.220612,782.742432 369.087036,782.026367 
	C372.254517,780.108948 374.132141,782.073975 375.977509,784.622192 
	C375.206635,784.989258 374.375305,785.020996 372.865356,785.034851 
	C371.672607,784.860535 371.158478,784.704102 370.575592,784.490479 
	C370.506805,784.433350 370.356689,784.336121 370.303406,784.128784 
	C370.058441,783.658203 369.803741,783.562683 369.468079,783.607910 
	C369.450073,783.581116 369.395355,783.546631 369.395355,783.546631 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M352.780151,765.787598 
	C352.759613,765.221680 352.836090,764.936523 353.001343,764.214844 
	C355.044708,765.264099 356.999268,766.749756 358.983795,768.610840 
	C359.013794,768.986267 358.950043,769.050049 358.520142,769.059326 
	C357.521423,769.155640 356.952545,769.242676 356.383698,769.329712 
	C355.214874,768.242615 354.046021,767.155457 352.780151,765.787598 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M634.947693,798.833984 
	C637.877930,797.089111 640.808105,795.344177 643.916626,793.280273 
	C644.095032,792.961182 644.506653,792.933472 644.812012,793.393799 
	C645.185059,794.158386 645.252808,794.462708 645.118286,795.020142 
	C641.593323,796.460144 638.270508,797.647095 634.947693,798.833984 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M762.358582,699.618408 
	C763.657043,703.106506 762.143066,705.102234 758.386963,705.676147 
	C758.182068,705.084656 758.238220,704.652161 758.362976,703.905334 
	C759.635559,702.299927 760.839539,701.008850 762.358582,699.618408 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M522.609253,810.929932 
	C524.881958,811.613831 526.851379,812.298523 528.515869,813.417175 
	C525.793457,813.572266 523.376038,813.293335 520.967896,812.661072 
	C521.014832,811.836792 521.052490,811.365845 521.090210,810.894897 
	C521.495483,810.906860 521.900696,810.918823 522.609253,810.929932 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M335.409882,422.563538 
	C336.061493,422.446014 336.713104,422.328491 337.679016,422.082245 
	C336.587860,424.281860 335.182404,426.610229 333.456146,429.037903 
	C333.135345,429.137268 333.025818,429.026794 332.979492,428.586304 
	C332.880249,427.630371 332.827271,427.114868 332.814880,426.350342 
	C333.131805,425.692810 333.408203,425.284302 333.990784,424.781067 
	C334.535065,424.121735 334.773224,423.557129 335.106506,422.881042 
	C335.201569,422.769592 335.409882,422.563538 335.409882,422.563538 
z"/>
<path fill="#644D77" opacity="1.000000" stroke="none" 
	d="
M320.978363,689.390869 
	C322.682373,692.071289 324.350891,695.154053 326.040649,698.598022 
	C324.355560,695.903870 322.649200,692.848450 320.978363,689.390869 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M511.963470,811.939453 
	C513.493591,811.481140 515.023743,811.022827 516.789307,810.300720 
	C517.453491,810.031189 517.882202,810.025452 518.640625,810.381531 
	C518.997131,811.441772 519.023926,812.140198 518.647156,812.924072 
	C516.150208,812.652832 514.056824,812.296143 511.963470,811.939453 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M342.018677,749.626831 
	C340.588654,749.015747 339.151794,748.036743 337.393738,746.994019 
	C337.050293,746.456848 337.028015,745.983521 337.035706,745.151611 
	C338.714355,746.281738 340.363098,747.770386 342.018677,749.626831 
z"/>
<path fill="#644D77" opacity="1.000000" stroke="none" 
	d="
M412.079926,774.913818 
	C413.734741,775.665344 415.350037,776.459839 416.639648,777.620300 
	C415.490479,778.012207 414.667023,778.038208 413.583405,778.013794 
	C412.880646,777.672058 412.438019,777.380676 411.996307,776.747437 
	C411.984650,775.950806 411.972076,775.495972 411.959503,775.041199 
	C411.959503,775.041199 412.040405,774.956848 412.079926,774.913818 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M492.665161,805.879883 
	C490.811066,805.878052 488.597046,805.709595 486.166931,805.246460 
	C488.068939,805.205566 490.187103,805.459412 492.665161,805.879883 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M580.642822,809.074524 
	C579.186768,809.596191 577.340637,810.088623 575.236450,810.311035 
	C576.736511,809.709167 578.494568,809.377258 580.642822,809.074524 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M476.854004,802.645874 
	C475.575226,802.740173 474.128479,802.516418 472.336304,801.883972 
	C471.987396,801.301819 471.983917,801.128357 471.980469,800.954834 
	C473.548981,801.412476 475.117523,801.870117 476.854004,802.645874 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M622.658813,800.958313 
	C621.174377,801.483398 619.277222,801.995850 617.163208,802.254395 
	C618.712891,801.648865 620.479492,801.297241 622.658813,800.958313 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M344.941711,752.674255 
	C343.978851,752.251831 343.016266,751.456604 342.033081,750.317383 
	C342.988983,750.749451 343.965485,751.525452 344.941711,752.674255 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M413.843536,778.064148 
	C414.667023,778.038208 415.490479,778.012207 416.728546,777.960083 
	C417.143127,777.933899 417.559753,777.982849 417.765839,778.020752 
	C417.986786,778.710510 418.001678,779.362488 417.705261,780.335327 
	C416.605927,780.427673 415.817810,780.199097 415.015442,779.985229 
	C415.001221,780.000000 415.024475,779.968445 414.944580,779.694702 
	C414.524323,778.968689 414.183929,778.516418 413.843536,778.064148 
z"/>
<path fill="#644D77" opacity="1.000000" stroke="none" 
	d="
M471.583679,800.942749 
	C471.983917,801.128357 471.987396,801.301819 471.996643,801.735535 
	C471.725128,802.210022 471.447845,802.424316 470.707336,802.885132 
	C469.161713,802.756226 468.079346,802.380798 466.996979,802.005371 
	C466.996979,802.005371 467.029022,801.957214 467.269073,801.840271 
	C467.769531,801.506470 467.935638,801.232361 468.007477,800.901001 
	C469.067291,800.910889 470.127075,800.920776 471.583679,800.942749 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M609.571106,803.850098 
	C608.424866,804.370056 606.897095,804.942932 605.156860,805.252563 
	C606.359497,804.627197 607.774536,804.265076 609.571106,803.850098 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M484.093872,804.566895 
	C482.861023,804.526001 481.490692,804.145813 480.041748,803.400452 
	C481.294220,803.432739 482.625275,803.830200 484.093872,804.566895 
z"/>
<path fill="#6B567C" opacity="1.000000" stroke="none" 
	d="
M361.060547,771.617126 
	C361.360443,772.679688 361.656067,773.562561 361.963013,774.795837 
	C360.883301,774.123901 359.792206,773.101624 359.051636,771.632385 
	C359.942596,771.150146 360.483063,771.114868 361.023560,771.079590 
	C361.023560,771.079590 361.056335,771.437500 361.060547,771.617126 
z"/>
<path fill="#6B567C" opacity="1.000000" stroke="none" 
	d="
M329.318115,821.034790 
	C329.063141,820.415771 329.103088,819.828552 329.422913,818.804443 
	C330.605042,819.173279 331.507324,819.978943 332.409637,820.784607 
	C332.140259,820.841125 331.870850,820.897644 331.196289,821.045776 
	C330.398438,821.113708 330.005737,821.090149 329.318115,821.034790 
z"/>
<path fill="#6B567C" opacity="1.000000" stroke="none" 
	d="
M366.154968,779.285095 
	C366.539490,778.719055 367.054626,778.459717 367.843201,778.101379 
	C368.420349,778.924744 368.724030,779.847229 368.992096,781.157593 
	C368.066162,780.894287 367.175842,780.243042 366.154968,779.285095 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M308.032593,699.708984 
	C307.249695,699.733154 306.460144,699.383179 305.192322,698.779053 
	C304.583069,698.180481 304.452057,697.836060 304.321045,697.491638 
	C304.467499,697.388794 304.613983,697.286011 304.968323,697.009644 
	C306.126099,697.669006 307.075989,698.501892 308.032593,699.708984 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M587.864380,808.696289 
	C586.858093,809.124451 585.628967,809.333618 584.177979,809.279175 
	C585.184570,808.836182 586.413147,808.656738 587.864380,808.696289 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M519.050659,812.838623 
	C519.023926,812.140198 518.997131,811.441772 518.962280,810.395020 
	C519.533142,810.177979 520.111938,810.309204 520.890503,810.667664 
	C521.052490,811.365845 521.014832,811.836792 520.645874,812.687195 
	C519.893311,812.990723 519.471985,812.914673 519.050659,812.838623 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M305.020386,693.703430 
	C304.363068,693.212524 303.703217,692.360535 303.048767,691.160461 
	C303.708740,691.655640 304.363281,692.499023 305.020386,693.703430 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M310.005615,703.730225 
	C309.351410,703.224609 308.702789,702.354370 308.063202,701.133789 
	C308.718536,701.644165 309.364868,702.504822 310.005615,703.730225 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M730.604492,391.041992 
	C730.954773,391.504913 730.971680,391.947571 730.981201,392.723785 
	C730.334412,392.598724 729.695068,392.140076 728.994446,391.368134 
	C729.379211,391.043793 729.825256,391.032745 730.604492,391.041992 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M512.501892,272.086273 
	C512.599609,272.418243 512.697327,272.750183 512.926575,273.585052 
	C512.572144,274.384827 512.086182,274.681702 511.301941,274.980835 
	C511.004578,274.405426 511.005493,273.827728 511.255341,272.760132 
	C511.836823,272.208923 512.169373,272.147583 512.501892,272.086273 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M739.032837,402.339844 
	C739.433350,402.018890 739.876221,402.011475 740.650818,402.010742 
	C740.982300,402.457123 740.981995,402.896790 740.958069,403.665436 
	C740.314697,403.547394 739.694946,403.100372 739.032837,402.339844 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M345.341248,756.005127 
	C345.000763,755.527405 344.995575,755.059631 345.004059,754.239136 
	C345.652985,754.381592 346.288208,754.876770 346.971344,755.694641 
	C346.571686,756.016602 346.124084,756.015808 345.341248,756.005127 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M360.991943,770.750427 
	C360.483063,771.114868 359.942596,771.150146 359.010864,771.303711 
	C358.619629,771.421997 358.612244,771.373291 358.730713,770.980469 
	C358.882812,770.075134 358.916412,769.562561 358.950043,769.050049 
	C358.950043,769.050049 359.013794,768.986267 359.046570,768.953491 
	C359.706329,769.420898 360.333313,769.921082 360.991943,770.750427 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M602.726318,805.058228 
	C602.455017,805.524719 601.880371,805.993164 601.132324,806.227539 
	C601.446960,805.682434 601.934998,805.371277 602.726318,805.058228 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M748.934387,414.393829 
	C749.240479,414.539612 749.595886,414.967712 749.961304,415.696808 
	C749.642090,415.557251 749.312866,415.116699 748.934387,414.393829 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M753.037842,420.295227 
	C753.347534,420.457001 753.650818,420.910950 753.961060,421.651215 
	C753.655823,421.487457 753.343628,421.037415 753.037842,420.295227 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M614.646606,802.904297 
	C614.416077,803.077148 613.846008,803.276184 613.111084,803.249756 
	C613.399902,802.992981 613.853455,802.961731 614.646606,802.904297 
z"/>
<path fill="#786A7E" opacity="1.000000" stroke="none" 
	d="
M357.621796,387.044678 
	C357.171173,386.785797 356.989197,386.434357 356.999756,385.905792 
	C357.401398,385.772675 357.610504,385.816681 358.135040,385.922821 
	C358.263763,386.307312 358.077118,386.629730 357.621796,387.044678 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M726.094543,388.288788 
	C726.348694,388.138550 726.606140,388.323395 726.850037,388.856506 
	C726.612793,388.972626 726.366638,388.778961 726.094543,388.288788 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M725.021790,387.283295 
	C725.251770,387.106354 725.530334,387.261749 725.836426,387.765625 
	C725.603149,387.928131 725.338623,387.761963 725.021790,387.283295 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M477.802216,802.958557 
	C477.768341,803.091675 477.476410,803.209961 477.106079,803.146606 
	C477.199829,802.957825 477.372009,802.950745 477.802216,802.958557 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M467.781189,800.880249 
	C467.935638,801.232361 467.769531,801.506470 467.285431,801.816406 
	C467.056305,801.721863 467.050873,801.534119 467.076263,801.064087 
	C467.107086,800.781677 467.554901,800.859375 467.781189,800.880249 
z"/>
<path fill="#F4DED6" opacity="1.000000" stroke="none" 
	d="
M808.827637,290.820557 
	C808.652222,290.231262 808.848816,289.971619 809.309753,289.623596 
	C809.714478,289.715027 809.913574,289.939209 810.253662,290.646545 
	C810.205505,291.328003 809.999390,291.506775 809.776306,291.666046 
	C809.564514,291.479279 809.352722,291.292511 808.827637,290.820557 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M594.634888,807.761108 
	C594.630981,807.898865 594.383240,808.101135 594.015747,808.113037 
	C594.060974,807.890320 594.226013,807.857971 594.634888,807.761108 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M645.320496,794.767090 
	C645.252808,794.462708 645.185059,794.158386 645.018250,793.396118 
	C644.919128,792.938171 645.414490,792.876648 645.659546,792.831177 
	C645.936218,793.055359 645.967834,793.324890 645.859375,794.108765 
	C645.719360,794.622986 645.320496,794.767090 645.320496,794.767090 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M377.038208,785.722961 
	C376.870789,785.778992 376.560181,785.618774 376.138916,785.214844 
	C376.317200,785.149597 376.606110,785.328125 377.038208,785.722961 
z"/>
<path fill="#5A3F79" opacity="1.000000" stroke="none" 
	d="
M302.926514,689.830139 
	C302.738403,689.876282 302.457977,689.660828 302.054443,689.206787 
	C302.232300,689.168274 302.533264,689.368408 302.926514,689.830139 
z"/>
<path fill="#644D77" opacity="1.000000" stroke="none" 
	d="
M325.094147,688.765686 
	C324.919708,688.797913 324.603546,688.603638 324.216797,688.133057 
	C324.414948,688.084167 324.683685,688.311646 325.094147,688.765686 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M741.102051,404.283203 
	C741.354919,404.136810 741.607483,404.321533 741.838013,404.852936 
	C741.603088,404.961639 741.365051,404.766571 741.102051,404.283203 
z"/>
<path fill="#F99A0F" opacity="1.000000" stroke="none" 
	d="
M235.266937,296.702698 
	C232.452011,291.955933 229.664093,287.606171 227.277420,283.046356 
	C226.598557,281.749390 226.414734,279.375549 227.161575,278.297607 
	C229.804245,274.483307 232.867065,270.943970 235.963074,267.472015 
	C236.676743,266.671692 238.167267,266.237030 239.275787,266.279144 
	C239.710800,266.295685 239.945236,268.167175 240.503555,269.046173 
	C241.830627,271.135468 242.837463,273.975037 244.780060,275.038483 
	C258.339508,282.461456 272.042236,289.633179 285.862183,296.559875 
	C288.266937,297.765167 291.443970,297.429840 294.264435,297.805298 
	C295.442810,297.962128 296.621429,298.116882 297.799957,298.272583 
	C297.569855,299.477386 297.712311,300.966095 297.047028,301.839355 
	C293.628479,306.326569 290.001282,310.654846 286.449463,315.040649 
	C285.611389,316.075470 284.899200,317.247345 283.921967,318.125793 
	C282.565430,319.345245 281.013336,320.347076 279.544952,321.442108 
	C279.035736,319.857513 278.675507,318.202759 277.972137,316.709625 
	C277.364716,315.420105 276.634247,313.836914 275.500061,313.202484 
	C265.919830,307.843475 256.239288,302.663452 246.566422,297.471497 
	C246.069580,297.204834 245.367569,297.320404 243.606400,297.139191 
	C246.426254,301.794586 248.739731,305.701843 251.138580,309.556000 
	C256.145721,317.600769 261.160858,325.641174 266.256531,333.629883 
	C267.613678,335.757568 268.262115,337.446350 266.406982,339.846527 
	C264.493134,342.322632 262.677643,341.857117 260.313202,341.050995 
	C248.661896,337.078735 236.982651,333.187836 225.295334,329.322479 
	C223.482941,328.723083 221.577744,328.404327 219.120300,328.904114 
	C223.059723,333.314697 226.968338,337.753357 230.949051,342.126343 
	C233.750305,345.203644 236.701401,348.144928 239.489899,351.233337 
	C242.051636,354.070587 244.197708,357.536194 249.015259,355.311798 
	C249.568863,355.056183 250.827713,356.328064 252.221863,357.171326 
	C249.084717,361.110474 246.283737,364.774109 243.282501,368.265625 
	C242.719131,368.921021 241.100418,369.384552 240.430725,369.039581 
	C239.706848,368.666779 239.501160,367.251709 239.110489,366.274841 
	C237.930893,363.325500 237.459808,359.764099 235.487595,357.515869 
	C225.617050,346.264008 215.451843,335.263519 205.134033,324.418396 
	C203.450485,322.648804 200.503632,322.057098 198.104080,321.004425 
	C197.120972,320.573090 195.543777,320.653717 195.144485,319.970734 
	C194.745697,319.288635 195.181915,317.720245 195.771606,316.926239 
	C198.547256,313.188904 201.184097,309.242981 204.555176,306.107635 
	C205.824341,304.927246 209.163269,305.074585 211.180954,305.748077 
	C221.274872,309.117279 231.236511,312.881409 241.262390,316.456909 
	C243.080048,317.105133 244.980392,317.521545 247.737015,317.058594 
	C243.640076,310.379059 239.543137,303.699493 235.266937,296.702698 
z"/>
<path fill="#F99A0F" opacity="1.000000" stroke="none" 
	d="
M326.771790,259.349792 
	C324.049469,257.068268 321.860046,257.344788 319.406311,259.375488 
	C315.569153,262.551178 311.557983,265.528870 307.498718,268.419342 
	C305.039307,270.170593 304.585327,272.192688 305.626099,274.895294 
	C307.268372,279.159943 307.181671,284.793060 314.014587,284.712799 
	C314.633087,284.705536 315.267487,286.051086 316.221954,287.143036 
	C311.925659,290.442566 307.925354,293.601440 303.799957,296.587463 
	C303.196411,297.024323 301.724152,296.985260 301.150726,296.534393 
	C300.585236,296.089783 300.185791,294.590240 300.513000,293.987823 
	C303.064209,289.291626 301.161591,284.895538 299.817749,280.472351 
	C295.615448,266.640900 291.342102,252.831039 287.147766,238.997177 
	C286.048370,235.371170 284.995850,231.909622 280.118042,232.045166 
	C279.431274,232.064255 278.712006,230.914795 277.334198,229.724091 
	C282.144257,226.036392 286.396393,222.375214 291.100677,219.438248 
	C292.351898,218.657104 295.261108,219.624451 296.949585,220.591370 
	C313.677368,230.170700 330.266846,239.991943 347.015320,249.534378 
	C349.323669,250.849548 352.301331,250.963089 354.936310,251.743637 
	C356.189178,252.114792 357.364868,252.746429 358.575531,253.260056 
	C357.993774,254.316696 357.665924,255.703064 356.789978,256.377686 
	C349.953644,261.642456 343.011932,266.770447 335.235535,272.589630 
	C333.776306,267.147156 333.605652,261.026611 326.771790,259.349792 
M304.641418,262.214905 
	C308.234772,259.399628 311.828125,256.584381 316.240814,253.127228 
	C309.147797,248.767593 302.981445,244.977539 296.815094,241.187485 
	C296.477478,241.486923 296.139832,241.786362 295.802216,242.085800 
	C297.722717,248.565201 299.594757,255.060089 301.649139,261.496765 
	C301.827393,262.055237 303.208405,262.229828 304.641418,262.214905 
z"/>
<path fill="#F99A0F" opacity="1.000000" stroke="none" 
	d="
M340.324768,203.188828 
	C337.116547,188.473663 350.211456,178.118164 363.678040,174.972672 
	C364.485931,174.783951 365.321655,174.714813 366.143890,174.586685 
	C376.588104,172.959061 379.778839,174.996658 382.537079,185.284119 
	C382.767426,186.143219 382.211426,188.015854 381.788818,188.089661 
	C380.722229,188.275970 379.313232,188.005356 378.416046,187.380219 
	C375.582214,185.405624 373.206085,182.346649 370.112427,181.291061 
	C366.927307,180.204269 362.915985,180.254761 359.616730,181.129684 
	C355.945129,182.103317 353.670837,185.194687 353.835052,189.481979 
	C353.996948,193.709961 356.303192,196.537384 360.066193,197.608704 
	C364.186646,198.781784 368.530151,199.223389 372.801208,199.800293 
	C376.750702,200.333755 380.754395,200.478012 384.696320,201.053314 
	C392.541412,202.198257 398.744232,205.284775 401.546295,213.654602 
	C404.568634,222.682434 401.450684,229.807297 395.389526,235.826202 
	C386.965973,244.191025 376.078644,247.098801 364.556763,248.132324 
	C361.292938,248.425110 359.213013,247.304291 357.951080,244.192200 
	C356.892883,241.582489 355.245880,239.212128 354.178864,236.605118 
	C353.704529,235.446182 354.015747,233.965714 353.970428,232.631195 
	C355.405548,233.005051 357.191681,232.961868 358.215851,233.823944 
	C363.002441,237.852829 367.837097,241.813629 374.576080,240.973312 
	C377.797943,240.571533 381.079071,239.608429 384.015015,238.224350 
	C387.329559,236.661804 389.577057,233.839294 388.948456,229.804779 
	C388.285187,225.547958 385.585205,222.873581 381.407654,222.247726 
	C374.842163,221.264130 368.187744,220.866470 361.628815,219.848267 
	C351.685364,218.304626 343.533905,214.175232 340.324768,203.188828 
z"/>
<path fill="#683BB6" opacity="1.000000" stroke="none" 
	d="
M227.942245,334.047180 
	C227.872971,333.887787 227.964096,333.568970 228.229065,333.399780 
	C228.402924,333.549438 228.102646,333.887726 227.942245,334.047180 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M576.983215,264.968018 
	C573.632507,265.009430 570.281860,265.050812 566.234009,264.758240 
	C564.026306,264.288788 562.515747,264.153351 560.841187,263.768555 
	C560.119934,263.347382 559.562683,263.175568 558.694702,262.771271 
	C555.413513,262.032135 552.308105,260.709076 549.497803,261.173157 
	C541.323975,262.522919 533.588989,261.739166 525.676392,259.350525 
	C522.814392,258.486542 519.354553,259.512970 516.656555,257.162231 
	C516.309570,256.859863 515.294067,257.025604 514.749695,257.297607 
	C511.394409,258.974091 508.085449,260.743317 504.292603,262.726685 
	C504.444092,263.168457 504.895508,264.359131 505.260986,265.575562 
	C506.129150,268.465149 505.084625,269.701691 502.099731,269.016754 
	C496.229980,267.669830 496.142548,267.722351 495.633545,274.000671 
	C494.513977,274.063049 493.767303,274.097290 492.752899,273.813446 
	C489.037292,273.482758 485.570679,273.243042 482.147217,273.525726 
	C479.134003,273.774567 475.727448,275.816284 473.278290,274.921417 
	C468.720795,273.256134 466.476074,275.458771 464.104218,278.226746 
	C461.694366,281.039093 459.974365,284.799530 455.060730,283.114410 
	C454.117493,282.790955 452.825958,283.439972 451.705048,283.685608 
	C448.238586,284.445312 444.801941,285.695465 441.307068,285.882385 
	C436.854980,286.120514 432.355652,285.502869 432.288727,291.540314 
	C428.910400,291.540314 425.998383,291.451752 423.094940,291.571167 
	C421.130829,291.651947 419.178772,292.025360 417.221313,292.267883 
	C418.263580,293.787628 419.257904,295.343994 420.375427,296.806213 
	C420.740570,297.283997 421.426392,297.516693 422.178772,297.997833 
	C423.545898,296.422791 424.800354,294.977539 426.115662,293.462189 
	C428.067719,296.932220 426.750153,298.739227 421.660339,299.899994 
	C417.832855,300.772888 413.992523,301.589233 410.366394,302.385010 
	C410.036652,304.889008 409.786682,306.787018 409.539490,308.664154 
	C403.851593,308.046844 397.908905,308.629028 395.857330,313.345123 
	C393.634064,318.455841 390.772308,321.627167 386.568634,323.290955 
	C384.803986,321.802673 383.433258,319.657318 381.825439,319.461884 
	C374.857635,318.614777 374.869629,318.826324 373.968262,326.251160 
	C373.685211,328.582916 373.342163,330.948456 372.615540,333.163666 
	C372.351227,333.969574 370.941956,334.762146 369.964294,334.895569 
	C368.001221,335.163605 365.958618,335.142212 363.979095,334.941772 
	C358.035278,334.339996 356.171906,335.558258 356.097687,341.571686 
	C356.015198,348.250061 352.926086,352.842529 348.638306,356.036438 
	C346.838684,354.107483 345.369385,352.532623 343.073669,350.071960 
	C341.542236,356.511414 345.448517,362.457703 340.295380,366.855988 
	C339.464050,366.542084 338.699890,366.258972 337.939697,365.965698 
	C331.562622,363.505157 329.540466,364.631134 326.745575,370.837799 
	C325.533630,373.529114 323.489227,376.766724 321.017029,377.769714 
	C316.443207,379.625366 313.472321,381.690582 313.967316,387.047241 
	C314.165833,389.195526 314.175323,391.394867 313.961853,393.540253 
	C313.574341,397.435059 315.180542,399.562195 319.051331,400.388733 
	C319.118103,401.302246 319.191071,401.821136 319.264038,402.339996 
	C319.264069,402.339996 319.158508,402.680115 318.692291,402.879578 
	C317.980133,403.883057 317.734192,404.687103 317.488251,405.491119 
	C317.488251,405.491119 317.361450,405.815674 316.856781,405.948914 
	C315.804352,407.248535 315.256592,408.414886 314.708801,409.581268 
	C314.708801,409.581268 314.602753,409.976288 314.117584,410.086609 
	C309.999817,416.703766 306.367218,423.210541 302.437622,429.883911 
	C299.817657,434.412323 297.494751,438.774139 294.725433,443.090698 
	C291.148773,442.784088 288.176025,442.711334 289.118622,447.583191 
	C289.102295,447.822571 289.153259,448.299744 289.153259,448.299744 
	C275.712921,448.216980 262.272552,448.138702 248.832260,448.049377 
	C243.192673,448.011871 237.553253,447.950928 231.537506,447.398926 
	C231.801300,443.933350 232.441360,440.969513 233.081436,438.005646 
	C233.334457,437.199249 233.587479,436.392822 234.389648,435.257263 
	C235.148590,434.134460 235.358383,433.340851 235.568176,432.547241 
	C235.659668,432.205963 235.751175,431.864685 236.330429,431.223083 
	C237.038513,430.117279 237.258850,429.311798 237.479187,428.506317 
	C237.820007,427.483734 238.160843,426.461121 239.074951,425.182404 
	C240.192993,423.581818 244.485733,423.461151 240.995804,420.799255 
	C241.062103,420.596375 241.128418,420.393494 241.751404,419.914917 
	C242.651459,418.578430 242.994858,417.517609 243.338257,416.456787 
	C243.458252,416.123657 243.578262,415.790558 244.215210,415.182465 
	C244.938904,414.091553 245.145660,413.275635 245.352386,412.459686 
	C245.352386,412.459686 245.479660,412.130768 245.960510,412.087585 
	C247.223709,412.698822 248.006073,413.353210 249.382736,414.504700 
	C250.223846,411.257965 250.971283,408.372833 251.714386,405.504456 
	C253.810760,405.311768 257.281128,407.436096 256.490570,402.547607 
	C256.421265,402.119019 257.956787,401.600098 258.291382,400.904175 
	C259.176849,399.062378 260.267548,397.130768 260.397919,395.176270 
	C260.495972,393.706665 259.233734,392.146301 258.577026,390.626343 
	C258.577026,390.626343 258.925690,390.699768 259.132141,390.789368 
	C261.232025,391.566254 262.831329,392.465759 264.099060,389.540588 
	C265.703064,385.839447 268.058319,382.463867 270.112396,378.908417 
	C271.748779,381.245270 274.274414,381.017700 276.681396,378.719086 
	C278.220398,377.249359 280.614685,375.469879 280.595001,373.854004 
	C280.564972,371.391205 278.776917,368.949829 278.052216,367.244751 
	C281.151428,364.991333 284.116730,362.835297 286.532745,361.078644 
	C289.824280,361.847687 293.015564,362.593323 296.206818,363.338959 
	C296.540863,363.010437 296.874939,362.681885 297.208984,362.353363 
	C291.209137,353.431946 298.157257,348.783081 302.476624,343.246094 
	C308.357727,345.807526 308.758331,345.403595 309.078430,338.941437 
	C309.194427,336.600403 310.327423,334.309692 311.375122,331.955383 
	C314.674377,331.687103 317.612427,331.164459 320.508972,331.337494 
	C322.381531,331.449371 324.193268,332.579437 326.032257,333.253204 
	C326.056854,331.392700 326.327454,329.496277 326.061707,327.678192 
	C325.115723,321.205750 326.603607,318.671661 332.900970,317.620758 
	C335.718475,317.150574 338.679596,317.541260 341.952240,317.541260 
	C338.852844,309.231750 343.687958,299.842621 349.744263,301.651825 
	C354.910919,303.195221 355.326752,300.259888 357.387085,297.704926 
	C360.461578,293.892395 358.277557,293.308685 355.029266,292.701416 
	C355.066010,292.004242 355.082550,291.606110 355.099060,291.208008 
	C355.099060,291.208008 355.363220,290.941437 355.920593,290.934326 
	C358.662994,289.296661 360.848022,287.666077 363.033020,286.035522 
	C363.033051,286.035522 363.269257,285.796326 363.798950,285.826172 
	C365.148376,285.110413 365.968079,284.364807 366.787781,283.619202 
	C367.261963,283.347595 367.736115,283.075989 368.624481,283.063660 
	C369.025787,284.215302 369.012909,285.107666 368.715332,286.034668 
	C368.107910,286.374756 367.785156,286.680176 367.462402,286.985565 
	C367.672363,287.167603 367.882355,287.349609 368.092346,287.531616 
	C368.394897,287.021057 368.697449,286.510529 369.399994,286.000000 
	C370.866669,286.000031 371.933319,286.000031 373.006409,286.359253 
	C373.849915,287.217133 374.752472,288.229401 375.513611,288.132416 
	C381.116211,287.418457 383.736206,283.011139 386.587646,279.378601 
	C384.720886,277.845367 383.188141,276.586426 381.655365,275.327484 
	C381.744659,275.058624 381.921875,274.874359 382.769592,274.827087 
	C385.913177,273.269073 388.474274,271.658600 391.035370,270.048126 
	C391.421051,269.862366 391.806732,269.676605 392.630005,269.738403 
	C396.938232,268.559387 400.922729,267.373810 404.638000,265.618408 
	C406.972443,264.515381 408.902863,262.557404 411.014557,260.983002 
	C412.637177,260.222015 414.259827,259.461060 416.627014,258.788025 
	C418.213654,258.892731 419.055664,258.909424 420.247650,259.084076 
	C429.353882,261.852081 429.829834,261.636200 430.330322,254.817581 
	C439.083435,252.070923 447.499725,249.487137 456.198639,247.140045 
	C459.150665,249.202362 458.989075,254.790070 463.510773,253.461761 
	C464.541290,253.159058 464.262207,248.343994 464.527252,245.600128 
	C464.547058,245.395294 464.093048,245.144714 464.149414,244.651611 
	C470.960114,243.260727 477.480682,242.133606 484.073608,241.375381 
	C485.765747,241.986801 487.389252,242.452377 489.004578,242.425385 
	C492.358887,242.369339 495.706757,242.005524 499.061218,241.856445 
	C501.867035,241.731781 504.772125,242.151230 507.458771,241.550049 
	C509.751221,241.037109 513.167908,241.087616 513.478699,237.105911 
	C525.563232,236.718903 537.182007,236.340942 548.802673,236.034882 
	C552.866821,235.927841 556.935730,235.997879 561.003540,236.395569 
	C561.696960,237.853928 562.685059,239.894821 563.033081,239.790924 
	C565.105652,239.172318 567.030212,238.058121 569.404724,237.084335 
	C570.873230,237.020355 571.941467,236.982361 573.007690,237.349304 
	C573.520386,238.772629 574.237122,240.727036 574.519226,240.666183 
	C577.720459,239.975632 580.855103,238.976837 584.230957,237.939285 
	C584.642883,237.547348 584.831421,237.276688 585.019958,237.006012 
	C586.052368,237.068741 587.084778,237.131470 588.594482,237.612854 
	C590.047607,238.357559 591.023376,238.683594 592.042053,239.372406 
	C595.560730,243.797653 597.511169,244.025421 602.431885,240.956299 
	C604.881714,241.649307 606.959351,242.285980 609.030029,243.277039 
	C609.314941,244.253311 609.606750,244.875183 609.898560,245.497070 
	C610.259521,244.637436 610.620483,243.777786 611.370483,242.917358 
	C613.507996,243.269577 615.256470,243.622559 617.003052,244.437714 
	C617.000732,248.599716 617.000366,252.299545 616.633240,256.038940 
	C613.177673,257.719421 610.088867,259.360321 606.600037,261.000732 
	C605.133362,261.000519 604.066711,261.000854 602.618408,261.006409 
	C600.324524,261.026672 598.209778,261.582611 596.537170,260.956818 
	C591.222229,258.968323 586.092346,258.376373 580.432922,259.810638 
	C577.689331,260.505920 576.759705,261.309265 576.956177,264.129883 
	C576.960327,264.595703 576.971802,264.781860 576.983215,264.968018 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M319.057556,399.994049 
	C315.180542,399.562195 313.574341,397.435059 313.961853,393.540253 
	C314.175323,391.394867 314.165833,389.195526 313.967316,387.047241 
	C313.472321,381.690582 316.443207,379.625366 321.017029,377.769714 
	C323.489227,376.766724 325.533630,373.529114 326.745575,370.837799 
	C329.540466,364.631134 331.562622,363.505157 337.939697,365.965698 
	C338.699890,366.258972 339.464050,366.542084 340.295380,366.855988 
	C345.448517,362.457703 341.542236,356.511414 343.073669,350.071960 
	C345.369385,352.532623 346.838684,354.107483 348.638306,356.036438 
	C352.926086,352.842529 356.015198,348.250061 356.097687,341.571686 
	C356.171906,335.558258 358.035278,334.339996 363.979095,334.941772 
	C365.958618,335.142212 368.001221,335.163605 369.964294,334.895569 
	C370.941956,334.762146 372.351227,333.969574 372.615540,333.163666 
	C373.342163,330.948456 373.685211,328.582916 373.968262,326.251160 
	C374.869629,318.826324 374.857635,318.614777 381.825439,319.461884 
	C383.433258,319.657318 384.803986,321.802673 386.568634,323.290955 
	C390.772308,321.627167 393.634064,318.455841 395.857330,313.345123 
	C397.908905,308.629028 403.851593,308.046844 409.539490,308.664154 
	C409.786682,306.787018 410.036652,304.889008 410.366394,302.385010 
	C413.992523,301.589233 417.832855,300.772888 421.660339,299.899994 
	C426.750153,298.739227 428.067719,296.932220 426.115662,293.462189 
	C424.800354,294.977539 423.545898,296.422791 422.178772,297.997833 
	C421.426392,297.516693 420.740570,297.283997 420.375427,296.806213 
	C419.257904,295.343994 418.263580,293.787628 417.221313,292.267883 
	C419.178772,292.025360 421.130829,291.651947 423.094940,291.571167 
	C425.998383,291.451752 428.910400,291.540314 432.288727,291.540314 
	C432.355652,285.502869 436.854980,286.120514 441.307068,285.882385 
	C444.801941,285.695465 448.238586,284.445312 451.705048,283.685608 
	C452.825958,283.439972 454.117493,282.790955 455.060730,283.114410 
	C459.974365,284.799530 461.694366,281.039093 464.104218,278.226746 
	C466.476074,275.458771 468.720795,273.256134 473.278290,274.921417 
	C475.727448,275.816284 479.134003,273.774567 482.147217,273.525726 
	C485.570679,273.243042 489.037292,273.482758 492.505554,274.074341 
	C490.811523,275.143585 489.035339,275.482483 487.394653,276.152985 
	C482.882080,277.997070 478.426331,279.980133 473.948120,281.908203 
	C472.873199,282.288269 471.798309,282.668365 470.225464,282.819580 
	C469.138153,283.035706 468.548798,283.480682 467.959473,283.925690 
	C467.196411,284.271088 466.433319,284.616486 465.170715,284.760742 
	C463.412018,285.290955 462.152863,286.022278 460.893707,286.753571 
	C460.144958,287.146515 459.396210,287.539459 458.120789,287.667969 
	C454.182098,285.458954 451.842865,285.450012 452.236877,290.372650 
	C452.236877,290.372650 451.942627,290.559204 451.381744,290.403442 
	C448.180786,291.740753 445.540680,293.233795 442.900574,294.726837 
	C442.566345,294.872955 442.232117,295.019104 441.238464,295.127563 
	C439.729370,295.756531 438.879700,296.423126 438.030029,297.089722 
	C437.729431,297.239197 437.428864,297.388641 436.497742,297.405029 
	C433.927582,298.554230 431.987946,299.836517 430.048309,301.118774 
	C430.048309,301.118774 429.825745,301.340057 429.286377,301.265015 
	C426.838043,302.483124 424.929077,303.776245 423.020142,305.069397 
	C423.020142,305.069397 422.779419,305.278198 422.232971,305.223022 
	C417.564392,307.878662 413.442291,310.589447 409.320160,313.300232 
	C409.320160,313.300232 409.152374,313.098358 408.877686,313.138458 
	C408.056671,313.762909 407.510345,314.347290 406.964020,314.931671 
	C406.964020,314.931671 406.991821,314.998840 406.580170,314.969940 
	C402.865997,316.203430 398.766815,316.411346 397.975403,321.295410 
	C397.911255,321.508392 397.918549,321.953217 397.918549,321.953217 
	C396.628632,322.674408 395.338715,323.395599 393.377258,324.135620 
	C391.791199,325.078522 390.876678,326.002625 389.962158,326.926697 
	C389.962158,326.926697 389.711273,327.161865 389.179718,327.144775 
	C388.120300,327.791534 387.592438,328.455383 387.064545,329.119232 
	C386.729767,329.485626 386.394989,329.851990 385.434692,330.216858 
	C382.267548,332.540070 379.725891,334.864777 377.184235,337.189453 
	C377.184235,337.189453 377.006104,337.001221 376.710754,337.035645 
	C375.614380,338.058838 374.813354,339.047668 374.012329,340.036499 
	C374.012329,340.036499 373.807831,340.282959 373.272827,340.232086 
	C366.003021,343.091705 360.997528,348.108948 356.540955,353.718170 
	C355.933685,354.482483 356.175323,355.921234 356.023926,357.047729 
	C355.925781,357.371246 355.734955,357.625092 354.855042,357.886627 
	C352.869629,359.674103 351.480591,361.384186 350.091553,363.094269 
	C350.091553,363.094269 350.023224,362.989990 349.722687,363.036316 
	C348.908813,363.981537 348.395508,364.880463 347.882202,365.779388 
	C347.111786,366.758057 346.341339,367.736755 344.961365,368.890015 
	C343.938019,369.750305 343.524200,370.436035 343.110352,371.121735 
	C343.110352,371.121735 343.003662,371.003662 342.706390,371.041870 
	C338.694550,372.892975 336.314270,375.677826 335.957428,379.936707 
	C334.486115,381.815796 333.014832,383.694885 330.952240,385.811096 
	C328.026215,389.513916 325.691528,392.979645 323.356812,396.445343 
	C323.126190,396.908722 322.895599,397.372101 322.134979,397.968781 
	C320.755829,398.732727 319.906677,399.363373 319.057556,399.994049 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M782.019165,335.371796 
	C783.329773,336.808563 784.422119,338.207367 785.727905,340.229736 
	C787.550415,344.250000 787.456421,349.256836 793.306702,348.643616 
	C793.306702,348.643616 793.242249,348.738312 793.186096,349.100616 
	C792.034424,350.679352 790.938904,351.895782 789.843384,353.112244 
	C789.188843,352.621948 788.534302,352.131653 787.594360,351.527100 
	C787.326355,351.101654 787.245361,350.818604 786.999390,349.978241 
	C784.364929,343.908600 780.167908,340.321350 774.367737,338.600525 
	C774.367737,338.600525 774.058167,338.503601 773.966309,338.001587 
	C772.766113,336.771881 771.657898,336.044159 770.549683,335.316467 
	C770.101746,335.060730 769.653870,334.805023 769.070068,333.948547 
	C767.798950,332.682770 766.663757,332.017761 765.528503,331.352753 
	C764.763794,330.776306 763.999146,330.199860 763.082031,329.009064 
	C761.165833,327.344910 759.402100,326.295105 757.638367,325.245300 
	C755.895752,324.017303 754.153198,322.789337 752.157410,320.966003 
	C751.122559,320.018707 750.340881,319.666779 749.559265,319.314880 
	C748.125427,318.452332 746.691528,317.589783 745.067688,316.098999 
	C743.430603,314.761597 741.983521,314.052368 740.536438,313.343140 
	C740.536438,313.343170 740.225830,313.263580 740.070068,312.802429 
	C739.166626,312.045624 738.419067,311.749969 737.671448,311.454315 
	C737.671448,311.454315 737.624634,311.383759 737.708435,310.992493 
	C737.792236,309.648315 737.792236,308.695374 737.792236,307.742462 
	C737.468445,308.108704 737.144653,308.474945 736.820862,308.841187 
	C732.583374,306.461456 728.386536,304.003082 724.074036,301.768036 
	C723.209351,301.319946 721.904053,301.721924 720.801941,301.731934 
	C720.638184,301.682281 720.474365,301.632599 720.019165,301.078369 
	C717.379333,299.452240 715.030884,298.330597 712.682434,297.208984 
	C712.682434,297.208984 712.328003,297.158142 712.129333,296.715546 
	C707.299561,293.870331 702.788208,291.189209 698.008179,289.132782 
	C691.446960,286.310120 684.642273,284.059204 678.019958,281.371002 
	C674.240601,279.836792 670.616272,277.920532 667.090637,275.836365 
	C670.640198,272.843658 675.327881,271.164948 678.105591,274.103638 
	C683.820862,280.150055 690.460388,275.582947 696.492371,277.116272 
	C693.423584,275.023407 690.127319,272.863037 686.788818,272.795807 
	C680.567322,272.670441 674.658203,269.885040 671.900024,264.277649 
	C669.641479,264.562775 667.380493,264.848724 665.611511,265.072449 
	C664.649841,262.713959 663.862122,260.782104 663.518555,258.879272 
	C664.836548,258.754211 665.710388,258.600189 666.584229,258.446136 
	C667.181152,258.604004 667.778076,258.761902 668.724854,259.397247 
	C672.899475,261.423920 676.724243,262.973145 680.549072,264.522369 
	C680.873047,264.618317 681.196960,264.714294 681.802612,265.286835 
	C682.858826,266.607605 683.478455,267.753876 684.434509,268.244385 
	C688.898193,270.534576 693.405823,272.755493 698.000427,274.766632 
	C700.043396,275.660828 702.328674,276.001404 704.503967,276.593262 
	C704.503967,276.593262 704.812378,276.680573 704.945679,277.185516 
	C706.570007,278.299988 708.061035,278.909515 709.552124,279.519043 
	C709.552124,279.519043 709.867737,279.578766 709.978638,280.070618 
	C712.219971,281.589386 714.350403,282.616241 716.480835,283.643127 
	C716.480835,283.643127 716.793579,283.698517 716.920776,284.164825 
	C721.528442,287.293732 726.008972,289.956329 730.489441,292.618896 
	C730.489441,292.618896 730.800476,292.711334 730.919373,293.193420 
	C732.353760,294.454773 733.669189,295.234039 734.984680,296.013275 
	C734.984680,296.013275 735.000000,296.000000 734.996399,296.402893 
	C736.164795,297.409790 737.336792,298.013794 738.508789,298.617767 
	C738.508789,298.617767 738.810547,298.689209 738.944824,299.154541 
	C740.045044,300.086914 741.010925,300.554016 741.976807,301.021118 
	C742.296875,301.097290 742.568542,301.256256 742.902039,302.090057 
	C744.368835,303.425964 745.725220,304.169861 747.081665,304.913757 
	C747.081665,304.913757 746.997559,304.987610 747.039612,305.319702 
	C748.211670,306.313568 749.341614,306.975342 750.471619,307.637115 
	C750.471619,307.637115 750.782532,307.747620 750.913940,308.223694 
	C752.467957,309.669891 753.890686,310.640015 755.313354,311.610168 
	C755.313354,311.610168 755.307495,311.688660 755.254517,312.056519 
	C756.492676,313.260010 757.783813,314.095642 759.074951,314.931274 
	C759.074951,314.931274 759.002625,315.000580 759.043457,315.310394 
	C759.816040,315.972504 760.547729,316.324829 761.279419,316.677124 
	C761.279419,316.677124 761.138916,316.863007 761.158447,317.163452 
	C762.245605,318.124207 763.313232,318.784515 764.380920,319.444794 
	C764.380920,319.444794 764.452698,319.540192 764.367798,319.917480 
	C766.279602,321.758026 768.276245,323.221313 770.272888,324.684570 
	C770.272888,324.684570 770.125366,324.874695 770.150757,325.178650 
	C771.267517,326.214325 772.358887,326.946045 773.450195,327.677765 
	C773.840393,328.015381 774.230652,328.352966 774.824097,329.254517 
	C775.973389,332.737213 776.843994,335.742249 780.974487,335.020569 
	C781.267090,335.079773 781.542542,335.184174 782.019165,335.371796 
M761.493286,321.567413 
	C761.493286,321.567413 761.449463,321.485443 761.493286,321.567413 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M663.074463,258.850281 
	C663.862122,260.782104 664.649841,262.713959 665.611511,265.072449 
	C667.380493,264.848724 669.641479,264.562775 671.900024,264.277649 
	C674.658203,269.885040 680.567322,272.670441 686.788818,272.795807 
	C690.127319,272.863037 693.423584,275.023407 696.492371,277.116272 
	C690.460388,275.582947 683.820862,280.150055 678.105591,274.103638 
	C675.327881,271.164948 670.640198,272.843658 666.779236,275.817566 
	C665.881775,276.148987 665.464172,276.159210 665.026917,275.790680 
	C663.653931,269.827850 661.524414,276.047791 659.503662,275.073059 
	C657.419250,275.060577 655.699036,275.049042 653.978882,275.037537 
	C653.554993,274.995880 653.131104,274.954224 652.329651,274.498840 
	C650.954834,273.432831 649.957642,272.780548 648.970825,271.729706 
	C647.217041,268.858643 645.689087,266.141205 643.539856,264.067810 
	C642.921387,263.471130 640.531189,264.927582 638.918335,265.085297 
	C637.232727,265.250183 635.492371,265.089844 633.797852,264.887787 
	C628.075928,264.205536 622.194458,260.928680 616.549194,265.898468 
	C611.155945,265.786377 606.085876,265.822357 601.015869,265.858337 
	C599.329224,265.795929 597.642517,265.733490 595.443359,265.289520 
	C593.963013,264.309265 592.995178,263.710541 591.956299,262.734436 
	C589.335632,260.082947 587.468811,259.886932 586.550293,263.981049 
	C583.051697,263.951019 580.007568,263.900604 576.963501,263.850189 
	C576.759705,261.309265 577.689331,260.505920 580.432922,259.810638 
	C586.092346,258.376373 591.222229,258.968323 596.537170,260.956818 
	C598.209778,261.582611 600.324524,261.026672 602.679138,261.353058 
	C604.809448,263.674408 606.160828,263.770538 607.000000,261.001221 
	C610.088867,259.360321 613.177673,257.719421 616.867981,256.312805 
	C620.593750,257.673828 622.035400,256.063141 623.002625,253.335892 
	C623.427246,252.138397 624.319824,251.106873 625.001831,250.338196 
	C625.335754,251.451141 625.667908,252.225876 625.966064,253.350311 
	C625.317566,255.933395 624.703003,258.166748 623.786682,261.496826 
	C625.804321,261.362030 628.010986,261.214630 630.217651,261.067200 
	C629.944458,259.061157 629.768555,257.034363 629.348206,255.059647 
	C629.188904,254.311340 628.466003,253.683014 628.003662,252.641678 
	C629.016907,250.830704 630.026489,249.378067 631.367615,247.932907 
	C632.483459,248.240601 633.267761,248.540817 634.010376,249.200241 
	C634.310608,251.629623 634.652466,253.699814 634.987305,255.727676 
	C637.174622,255.727676 639.484741,255.179855 639.874695,255.813583 
	C643.264404,261.322205 646.995789,258.533264 649.773010,256.759369 
	C652.280884,258.383698 654.069824,259.897217 656.135376,260.613770 
	C656.611206,260.778870 658.034973,258.211456 659.441711,256.909851 
	C660.406982,256.807922 660.953186,256.697601 661.499329,256.587311 
	C661.839783,256.644745 662.180237,256.702179 662.755554,257.180847 
	C663.018433,258.018158 663.046448,258.434204 663.074463,258.850281 
z"/>
<path fill="#EEEDEC" opacity="1.000000" stroke="none" 
	d="
M310.996338,331.996033 
	C310.327423,334.309692 309.194427,336.600403 309.078430,338.941437 
	C308.758331,345.403595 308.357727,345.807526 302.476624,343.246094 
	C298.157257,348.783081 291.209137,353.431946 297.208984,362.353363 
	C296.874939,362.681885 296.540863,363.010437 296.206818,363.338959 
	C293.015564,362.593323 289.824280,361.847687 286.532745,361.078644 
	C284.116730,362.835297 281.151428,364.991333 278.052216,367.244751 
	C278.776917,368.949829 280.564972,371.391205 280.595001,373.854004 
	C280.614685,375.469879 278.220398,377.249359 276.681396,378.719086 
	C274.274414,381.017700 271.748779,381.245270 270.112396,378.908417 
	C268.058319,382.463867 265.703064,385.839447 264.099060,389.540588 
	C262.831329,392.465759 261.232025,391.566254 258.963562,390.731689 
	C261.026337,386.499725 263.225037,382.247955 265.942810,378.358948 
	C278.372192,360.572937 292.137787,343.904114 308.671631,329.001770 
	C309.914886,329.997620 310.455627,330.996826 310.996338,331.996033 
M293.003876,356.378357 
	C292.523895,354.990234 292.043945,353.602112 291.563965,352.214020 
	C290.124969,353.822632 289.464294,355.061646 289.459045,356.303406 
	C289.454437,357.400909 290.180176,358.501434 290.583435,359.600647 
	C291.382690,358.782806 292.181946,357.964966 293.003876,356.378357 
z"/>
<path fill="#F6F6F6" opacity="1.000000" stroke="none" 
	d="
M311.375122,331.955383 
	C310.455627,330.996826 309.914886,329.997620 308.990906,328.940247 
	C309.127075,328.286041 309.646515,327.690002 310.820251,327.020325 
	C312.262299,326.947815 313.050018,326.948914 314.241211,326.959167 
	C315.422150,325.617096 316.199585,324.265961 317.175690,322.644745 
	C317.911469,321.238281 318.448608,320.101868 318.985718,318.965485 
	C319.355316,318.608246 319.724884,318.251007 320.719910,317.876343 
	C322.864410,316.200714 324.383453,314.542572 325.902527,312.884430 
	C325.902527,312.884430 326.009949,312.968048 326.327637,312.976746 
	C328.074158,311.615448 329.502991,310.245483 330.931824,308.875519 
	C331.665924,308.246002 332.399994,307.616516 333.789001,306.946686 
	C336.627411,304.922668 338.810913,302.938965 340.994446,300.955261 
	C340.994446,300.955261 341.233521,300.744965 341.756531,300.788330 
	C342.839600,300.181854 343.399689,299.532013 343.959747,298.882141 
	C343.959747,298.882141 344.186737,298.678131 344.709564,298.737366 
	C345.795837,298.148407 346.359283,297.500244 346.922729,296.852051 
	C347.627228,296.205780 348.331757,295.559540 349.680603,294.890076 
	C351.886261,294.244720 353.447662,293.622589 355.009033,293.000458 
	C358.277557,293.308685 360.461578,293.892395 357.387085,297.704926 
	C355.326752,300.259888 354.910919,303.195221 349.744263,301.651825 
	C343.687958,299.842621 338.852844,309.231750 341.952240,317.541260 
	C338.679596,317.541260 335.718475,317.150574 332.900970,317.620758 
	C326.603607,318.671661 325.115723,321.205750 326.061707,327.678192 
	C326.327454,329.496277 326.056854,331.392700 326.032257,333.253204 
	C324.193268,332.579437 322.381531,331.449371 320.508972,331.337494 
	C317.612427,331.164459 314.674377,331.687103 311.375122,331.955383 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M484.001282,241.006485 
	C477.480682,242.133606 470.960114,243.260727 463.765411,244.664459 
	C460.699524,245.595154 458.307770,246.249237 455.915985,246.903320 
	C447.499725,249.487137 439.083435,252.070923 429.930298,254.861389 
	C427.600098,255.195953 426.006744,255.323853 424.413422,255.451767 
	C432.329010,252.610397 440.135101,249.403702 448.179779,246.993073 
	C477.496368,238.208191 507.596222,234.068985 538.115112,233.485123 
	C550.876648,233.240982 563.669922,234.659103 576.526611,235.893524 
	C575.406250,236.625168 574.208008,236.784760 573.009766,236.944366 
	C571.941467,236.982361 570.873230,237.020355 568.997620,237.045013 
	C565.794373,236.683487 563.398376,236.335281 561.002441,235.987076 
	C556.935730,235.997879 552.866821,235.927841 548.802673,236.034882 
	C537.182007,236.340942 525.563232,236.718903 513.036621,237.064789 
	C509.192108,237.381943 506.242126,237.616562 503.319763,238.041489 
	C496.872894,238.978943 490.439911,240.012268 484.001282,241.006485 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M659.022583,256.901550 
	C658.034973,258.211456 656.611206,260.778870 656.135376,260.613770 
	C654.069824,259.897217 652.280884,258.383698 649.773010,256.759369 
	C646.995789,258.533264 643.264404,261.322205 639.874695,255.813583 
	C639.484741,255.179855 637.174622,255.727676 634.987305,255.727676 
	C634.652466,253.699814 634.310608,251.629623 634.406372,249.232544 
	C641.088867,250.422684 647.333679,251.939743 653.578491,253.456802 
	C653.908203,253.534637 654.237915,253.612473 654.826660,254.162628 
	C656.398010,255.390488 657.710266,256.146027 659.022583,256.901550 
z"/>
<path fill="#EEEDEC" opacity="1.000000" stroke="none" 
	d="
M258.249939,390.699036 
	C259.233734,392.146301 260.495972,393.706665 260.397919,395.176270 
	C260.267548,397.130768 259.176849,399.062378 258.291382,400.904175 
	C257.956787,401.600098 256.421265,402.119019 256.490570,402.547607 
	C257.281128,407.436096 253.810760,405.311768 251.714386,405.504456 
	C250.971283,408.372833 250.223846,411.257965 249.382736,414.504700 
	C248.006073,413.353210 247.223709,412.698822 246.108459,411.991638 
	C249.824677,404.883179 253.873764,397.827454 258.249939,390.699036 
z"/>
<path fill="#F6F6F6" opacity="1.000000" stroke="none" 
	d="
M381.304321,275.350403 
	C383.188141,276.586426 384.720886,277.845367 386.587646,279.378601 
	C383.736206,283.011139 381.116211,287.418457 375.513611,288.132416 
	C374.752472,288.229401 373.849915,287.217133 373.356842,286.324493 
	C374.700653,285.355103 375.700470,284.779663 376.700287,284.204254 
	C376.272034,283.746887 375.843781,283.289520 375.415527,282.832184 
	C374.610352,283.888123 373.805176,284.944061 373.000000,286.000000 
	C371.933319,286.000031 370.866669,286.000031 369.399994,286.000000 
	C369.012909,285.107666 369.025787,284.215302 368.902863,283.064331 
	C368.767029,282.805695 368.677948,282.744598 369.033081,282.778015 
	C369.937561,282.226318 370.486908,281.641174 371.036255,281.056000 
	C371.036255,281.056000 371.265045,280.819031 371.793976,280.833984 
	C373.565613,279.931610 374.808319,279.014252 376.051025,278.096863 
	C377.685089,277.189026 379.319183,276.281158 381.304321,275.350403 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M625.000000,250.000000 
	C624.319824,251.106873 623.427246,252.138397 623.002625,253.335892 
	C622.035400,256.063141 620.593750,257.673828 617.234741,256.273254 
	C617.000366,252.299545 617.000732,248.599716 617.300720,244.409760 
	C618.071472,243.606384 618.542725,243.293137 619.013916,242.979889 
	C619.441101,243.010223 619.868286,243.040558 620.679077,243.492188 
	C622.375122,244.602493 623.687561,245.291504 625.000000,246.382980 
	C625.000000,247.856979 625.000000,248.928482 625.000000,250.000000 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M625.000000,245.980499 
	C623.687561,245.291504 622.375122,244.602493 620.982117,243.602417 
	C631.648499,246.494431 642.395386,249.697495 653.360413,253.178680 
	C647.333679,251.939743 641.088867,250.422684 634.447998,248.873322 
	C633.267761,248.540817 632.483459,248.240601 631.160278,247.689545 
	C629.413391,246.987045 628.205444,246.535400 626.665894,246.046204 
	C625.889526,245.999283 625.444763,245.989883 625.000000,245.980499 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M704.325378,276.300964 
	C702.328674,276.001404 700.043396,275.660828 698.000427,274.766632 
	C693.405823,272.755493 688.898193,270.534576 684.434509,268.244385 
	C683.478455,267.753876 682.858826,266.607605 682.021179,265.417542 
	C689.354370,268.717377 696.750549,272.363007 704.325378,276.300964 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M302.734619,429.717316 
	C306.367218,423.210541 309.999817,416.703766 313.997070,410.251831 
	C310.982208,416.678802 307.602753,423.050873 303.918610,429.770081 
	C303.613922,430.117188 303.215698,430.203949 303.094269,430.083435 
	C302.972839,429.962921 302.734619,429.717316 302.734619,429.717316 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M410.632324,260.925537 
	C408.902863,262.557404 406.972443,264.515381 404.638000,265.618408 
	C400.922729,267.373810 396.938232,268.559387 392.937317,269.642456 
	C398.621429,266.488617 404.435760,263.678345 410.632324,260.925537 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M295.171814,443.135986 
	C297.494751,438.774139 299.817657,434.412323 302.437622,429.883911 
	C302.734619,429.717316 302.972839,429.962921 303.067139,430.375854 
	C303.153625,431.177887 303.145813,431.566986 303.138000,431.956085 
	C300.975311,436.242340 298.982544,440.628113 296.561005,444.762726 
	C295.671204,446.282013 293.767670,447.207611 291.965637,448.270935 
	C291.597595,447.939697 291.590454,447.741516 291.896484,447.297363 
	C293.197021,445.746246 294.184418,444.441101 295.171814,443.135986 
z"/>
<path fill="#F6F6F6" opacity="1.000000" stroke="none" 
	d="
M424.105072,255.463440 
	C426.006744,255.323853 427.600098,255.195953 429.593445,255.024261 
	C429.829834,261.636200 429.353882,261.852081 420.388794,258.820007 
	C420.429504,257.857788 420.679016,257.317596 420.928528,256.777435 
	C421.884613,256.343353 422.840668,255.909241 424.105072,255.463440 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M618.735596,242.728226 
	C618.542725,243.293137 618.071472,243.606384 617.302612,243.947586 
	C615.256470,243.622559 613.507996,243.269577 611.046326,242.921143 
	C609.901001,242.924667 609.468994,242.923660 609.036926,242.922638 
	C606.959351,242.285980 604.881714,241.649307 602.405090,240.720871 
	C601.916321,240.122223 601.868164,239.808960 601.861694,239.489304 
	C607.393555,240.485062 612.925476,241.480820 618.735596,242.728226 
z"/>
<path fill="#F9FAF9" opacity="1.000000" stroke="none" 
	d="
M573.007690,237.349304 
	C574.208008,236.784760 575.406250,236.625168 576.827637,236.177139 
	C577.774658,235.929871 578.498474,235.971039 579.666016,236.450043 
	C581.409058,237.278763 582.708374,237.669678 584.007629,238.060577 
	C580.855103,238.976837 577.720459,239.975632 574.519226,240.666183 
	C574.237122,240.727036 573.520386,238.772629 573.007690,237.349304 
z"/>
<path fill="#F9FAF9" opacity="1.000000" stroke="none" 
	d="
M601.493896,239.359253 
	C601.868164,239.808960 601.916321,240.122223 602.032837,240.664520 
	C597.511169,244.025421 595.560730,243.797653 592.268311,239.254623 
	C592.640198,238.501389 592.828735,238.228699 593.017273,237.956024 
	C593.448914,237.966171 593.880493,237.976334 594.683838,238.391006 
	C596.044373,238.854141 597.033203,238.912781 598.022095,238.971420 
	C599.056763,239.057343 600.091492,239.143265 601.493896,239.359253 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M730.332092,292.317963 
	C726.008972,289.956329 721.528442,287.293732 717.035034,284.275116 
	C721.406372,286.618408 725.790527,289.317719 730.332092,292.317963 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M323.644073,396.325684 
	C325.691528,392.979645 328.026215,389.513916 330.714600,386.036072 
	C330.022156,387.788574 328.976074,389.553223 327.677460,391.812561 
	C327.424957,392.307281 327.341492,392.715729 327.341492,392.715729 
	C326.204773,393.879150 325.068054,395.042572 323.644073,396.325684 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M232.763214,438.164032 
	C232.441360,440.969513 231.801300,443.933350 231.174622,447.237122 
	C230.630539,446.703491 229.437332,445.641418 229.630371,444.990417 
	C230.312042,442.691681 231.468536,440.533752 232.763214,438.164032 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M680.365417,264.225769 
	C676.724243,262.973145 672.899475,261.423920 668.996460,259.546112 
	C672.672729,260.788086 676.427246,262.358643 680.365417,264.225769 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M780.890015,334.694336 
	C776.843994,335.742249 775.973389,332.737213 774.991089,329.452576 
	C776.905029,330.847168 778.855347,332.607635 780.890015,334.694336 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M340.645996,300.973206 
	C338.810913,302.938965 336.627411,304.922668 334.072388,306.861603 
	C335.899780,304.874969 338.098663,302.933075 340.645996,300.973206 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M390.671722,270.025269 
	C388.474274,271.658600 385.913177,273.269073 382.959290,274.775146 
	C385.147034,273.114594 387.727570,271.558502 390.671722,270.025269 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M362.675049,286.011902 
	C360.848022,287.666077 358.662994,289.296661 356.095032,290.865204 
	C357.913788,289.198212 360.115448,287.593262 362.675049,286.011902 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M770.186768,324.367889 
	C768.276245,323.221313 766.279602,321.758026 764.420776,319.945007 
	C766.406006,321.080597 768.253296,322.565887 770.186768,324.367889 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M716.316589,283.343750 
	C714.350403,282.616241 712.219971,281.589386 710.094238,280.182098 
	C712.116760,280.882599 714.134583,281.963501 716.316589,283.343750 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M325.569397,312.928558 
	C324.383453,314.542572 322.864410,316.200714 320.972534,317.801514 
	C322.145233,316.153656 323.690735,314.563171 325.569397,312.928558 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M240.731476,420.930328 
	C244.485733,423.461151 240.192993,423.581818 239.301788,424.953796 
	C239.459274,423.674652 239.963211,422.368042 240.731476,420.930328 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M355.029266,292.701416 
	C353.447662,293.622589 351.886261,294.244720 349.957611,294.798859 
	C351.195862,293.527802 352.801392,292.324707 354.752991,291.164795 
	C355.082550,291.606110 355.066010,292.004242 355.029266,292.701416 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M659.441711,256.909882 
	C657.710266,256.146027 656.398010,255.390488 655.047852,254.290588 
	C657.053345,254.622070 659.096741,255.297958 661.319702,256.280579 
	C660.953186,256.697601 660.406982,256.807922 659.441711,256.909882 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M330.598083,308.908508 
	C329.502991,310.245483 328.074158,311.615448 326.271484,312.941132 
	C327.353241,311.578369 328.808777,310.259949 330.598083,308.908508 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M313.837738,326.950043 
	C313.050018,326.948914 312.262299,326.947815 311.084290,326.878235 
	C311.832092,325.518585 312.970184,324.227417 314.559387,322.976746 
	C315.010559,323.017212 315.266388,323.271118 315.141296,323.574890 
	C314.623383,324.902466 314.230560,325.926239 313.837738,326.950043 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M584.230957,237.939301 
	C582.708374,237.669678 581.409058,237.278763 580.014404,236.553192 
	C581.453247,236.288208 582.987549,236.357895 584.770874,236.716797 
	C584.831421,237.276688 584.642883,237.547348 584.230957,237.939301 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M755.232117,311.296936 
	C753.890686,310.640015 752.467957,309.669891 751.024048,308.346558 
	C752.385498,308.990143 753.768188,309.986908 755.232117,311.296936 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M319.051331,400.388733 
	C319.906677,399.363373 320.755829,398.732727 321.942566,398.143921 
	C321.492157,399.510437 320.704163,400.835114 319.590088,402.249878 
	C319.191071,401.821136 319.118103,401.302246 319.051331,400.388733 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M709.374695,279.235168 
	C708.061035,278.909515 706.570007,278.299988 705.053955,277.303650 
	C706.418396,277.595001 707.807861,278.273132 709.374695,279.235168 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M746.972534,304.599030 
	C745.725220,304.169861 744.368835,303.425964 743.032349,302.290192 
	C744.322632,302.693665 745.593018,303.488983 746.972534,304.599030 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M318.664551,319.042816 
	C318.448608,320.101868 317.911469,321.238281 316.885651,322.682617 
	C316.020111,323.084076 315.643250,323.177582 315.266388,323.271118 
	C315.266388,323.271118 315.010559,323.017212 314.880737,322.891205 
	C315.948395,321.550201 317.145874,320.335175 318.664551,319.042816 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M759.035156,314.603027 
	C757.783813,314.095642 756.492676,313.260010 755.293335,312.049622 
	C756.588501,312.541504 757.791931,313.408112 759.035156,314.603027 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M738.393860,298.305542 
	C737.336792,298.013794 736.164795,297.409790 734.990112,296.411224 
	C736.084595,296.675568 737.181763,297.334442 738.393860,298.305542 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M734.897278,295.693298 
	C733.669189,295.234039 732.353760,294.454773 731.027222,293.314667 
	C732.280701,293.760284 733.545288,294.566803 734.897278,295.693298 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M592.761292,237.691956 
	C592.828735,238.228699 592.640198,238.501389 592.225342,238.891846 
	C591.023376,238.683594 590.047607,238.357559 588.964417,237.731689 
	C590.073120,237.430542 591.289185,237.429230 592.761292,237.691956 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M375.705383,278.060974 
	C374.808319,279.014252 373.565613,279.931610 371.951141,280.784790 
	C372.839478,279.822113 374.099609,278.923615 375.705383,278.060974 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M314.990417,409.435791 
	C315.256592,408.414886 315.804352,407.248535 316.734924,406.073425 
	C316.502533,407.139893 315.887299,408.215118 314.990417,409.435791 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M773.336060,327.375244 
	C772.358887,326.946045 771.267517,326.214325 770.243652,325.102173 
	C771.281433,325.505402 772.251648,326.289062 773.336060,327.375244 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M420.565369,256.753387 
	C420.679016,257.317596 420.429504,257.857788 420.038818,258.662048 
	C419.055664,258.909424 418.213654,258.892731 416.963593,258.754333 
	C417.771118,257.998199 418.986633,257.363770 420.565369,256.753387 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M764.294922,319.152618 
	C763.313232,318.784515 762.245605,318.124207 761.248779,317.089966 
	C762.282776,317.430817 763.245789,318.145630 764.294922,319.152618 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M750.335449,307.338928 
	C749.341614,306.975342 748.211670,306.313568 747.080872,305.279327 
	C748.119812,305.618164 749.159485,306.329468 750.335449,307.338928 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M597.808289,238.702255 
	C597.033203,238.912781 596.044373,238.854141 594.993774,238.486923 
	C595.819519,238.263260 596.707031,238.348190 597.808289,238.702255 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M663.518555,258.879272 
	C663.046448,258.434204 663.018433,258.018158 662.992554,257.287842 
	C664.067017,257.272186 665.139343,257.570801 666.397949,258.157776 
	C665.710388,258.600189 664.836548,258.754211 663.518555,258.879272 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M291.583344,447.543396 
	C291.590454,447.741516 291.597595,447.939697 291.588928,448.436768 
	C290.863647,448.709198 290.154083,448.682709 289.298889,448.477966 
	C289.153259,448.299744 289.102295,447.822571 289.416412,447.674438 
	C290.348145,447.531982 290.965729,447.537689 291.583344,447.543396 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M366.448853,283.649902 
	C365.968079,284.364807 365.148376,285.110413 363.959595,285.776611 
	C364.430298,285.025024 365.270111,284.352844 366.448853,283.649902 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M243.073730,416.632141 
	C242.994858,417.517609 242.651459,418.578430 241.916931,419.640869 
	C241.953583,418.697479 242.381393,417.752502 243.073730,416.632141 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M741.892090,300.703613 
	C741.010925,300.554016 740.045044,300.086914 739.052368,299.266235 
	C739.952759,299.403809 740.880066,299.894928 741.892090,300.703613 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M370.718933,281.045349 
	C370.486908,281.641174 369.937561,282.226318 369.051331,282.727173 
	C369.276825,282.106812 369.839233,281.570740 370.718933,281.045349 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M761.212402,316.368286 
	C760.547729,316.324829 759.816040,315.972504 759.074585,315.271362 
	C759.758301,315.301483 760.451843,315.680450 761.212402,316.368286 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M245.099945,412.632812 
	C245.145660,413.275635 244.938904,414.091553 244.371277,414.966248 
	C244.289413,414.285309 244.568466,413.545593 245.099945,412.632812 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M317.767395,405.344666 
	C317.734192,404.687103 317.980133,403.883057 318.576965,403.015259 
	C318.634094,403.700409 318.340302,404.449280 317.767395,405.344666 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M237.214752,428.671478 
	C237.258850,429.311798 237.038513,430.117279 236.470886,430.997253 
	C236.399185,430.326691 236.674759,429.581665 237.214752,428.671478 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M235.287766,432.699707 
	C235.358383,433.340851 235.148590,434.134460 234.615204,435.031769 
	C234.530197,434.374390 234.768784,433.613281 235.287766,432.699707 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M346.593506,296.868591 
	C346.359283,297.500244 345.795837,298.148407 344.860687,298.716248 
	C345.080719,298.052277 345.672485,297.468658 346.593506,296.868591 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M343.638062,298.903717 
	C343.399689,299.532013 342.839600,300.181854 341.913635,300.762573 
	C342.137299,300.104065 342.726837,299.514679 343.638062,298.903717 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M303.352966,431.793396 
	C303.145813,431.566986 303.153625,431.177887 303.188599,430.496368 
	C303.215698,430.203949 303.613922,430.117188 303.817596,430.110901 
	C303.870148,430.613281 303.719025,431.122009 303.352966,431.793396 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M327.728760,392.791016 
	C327.341492,392.715729 327.424957,392.307281 327.533142,392.129883 
	C327.972076,392.116760 328.302795,392.281067 328.768036,392.687134 
	C328.640381,392.908020 328.378174,392.887146 327.728760,392.791016 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M297.010773,449.032410 
	C296.887512,448.895599 296.766724,448.739655 296.645935,448.583710 
	C296.735809,448.633240 296.825653,448.682770 296.983521,448.870331 
	C297.051483,449.008331 297.013214,449.013275 297.010773,449.032410 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M485.023773,807.970398 
	C486.180481,808.136780 487.337219,808.303162 488.830597,809.029968 
	C491.102356,809.777283 493.037445,809.964050 494.972534,810.150879 
	C495.435760,810.110229 495.898987,810.069580 496.701599,809.948914 
	C497.040955,809.868896 496.913391,810.111877 497.007202,810.573608 
	C499.745300,811.321289 502.389526,811.607239 505.400024,811.908264 
	C506.484924,812.044922 507.203552,812.166504 508.034058,812.634155 
	C510.762360,813.638184 513.378723,814.296265 515.928711,815.327026 
	C515.220215,816.110229 514.603760,816.826233 513.931763,816.882874 
	C508.710876,817.322571 503.479309,817.635132 497.521484,818.036072 
	C498.171204,821.600037 498.639252,824.167419 499.067383,826.515930 
	C504.652954,825.584229 509.306732,824.731140 513.990295,824.098877 
	C514.813599,823.987671 515.729126,824.981628 516.659119,825.149841 
	C518.544556,825.491028 520.486938,825.892944 522.373535,825.770691 
	C525.842346,825.546021 529.224121,822.942017 534.039612,825.415466 
	C532.019470,826.368225 530.581177,826.589417 530.241150,827.321045 
	C529.801819,828.266357 529.981445,830.551697 530.340698,830.651428 
	C533.600952,831.555725 537.032593,832.880493 540.268921,832.569946 
	C544.322510,832.181030 544.798706,834.127441 544.560547,837.064453 
	C540.380310,836.547302 536.642700,835.877380 532.875427,835.557190 
	C528.922546,835.221191 524.941101,835.220398 520.972168,835.073120 
	C520.244263,835.025391 519.516418,834.977600 518.370178,834.522217 
	C516.980713,834.019836 516.009644,833.925110 514.697815,833.833374 
	C513.919739,833.718994 513.482422,833.601624 512.945496,833.161255 
	C509.553741,832.253540 506.261505,831.668701 502.969269,831.083862 
	C502.543182,831.038025 502.117126,830.992126 501.297852,830.486572 
	C492.603363,827.350708 484.302124,824.674622 475.737061,821.749390 
	C473.813080,821.251465 472.152893,821.002747 470.492676,820.754028 
	C469.904480,820.514465 469.316315,820.274841 468.347839,819.546692 
	C466.165924,818.501526 464.364319,817.944946 462.562683,817.388367 
	C461.561584,816.970764 460.560516,816.553101 459.298340,815.551758 
	C458.253204,814.313538 457.545685,813.524231 456.671692,813.028381 
	C453.293854,811.112366 449.363312,809.832336 446.592163,807.280090 
	C443.817078,804.724365 440.980804,805.200073 437.973877,805.049744 
	C437.581451,804.994385 437.188995,804.938965 436.465149,804.661133 
	C436.133698,804.438599 436.008575,803.987061 435.956055,803.624390 
	C434.847412,802.923035 433.791290,802.584412 432.735138,802.245789 
	C432.735138,802.245789 432.870331,802.002319 432.848267,801.770325 
	C430.248383,799.658813 427.028564,798.235840 425.275177,795.770081 
	C423.557953,793.355103 422.333435,793.501831 420.324097,794.582825 
	C420.010193,794.484253 419.696320,794.385742 419.177582,793.756958 
	C416.799927,791.929077 414.627106,790.631470 412.454254,789.333801 
	C412.127197,789.175415 411.800171,789.017029 411.293182,788.267334 
	C411.093475,782.787659 407.272858,783.822144 404.405273,783.371460 
	C404.405273,783.371460 404.132751,783.351318 404.040344,782.864746 
	C402.782623,779.807129 398.536072,777.711914 402.182312,774.548279 
	C406.706543,776.457825 410.865509,778.213135 415.024475,779.968506 
	C415.024475,779.968445 415.001221,780.000000 415.021240,780.325500 
	C415.491821,781.827148 415.942383,783.003357 415.945801,783.012207 
	C418.336884,783.822937 419.829987,784.345825 421.334045,784.835144 
	C423.563690,785.560547 425.801086,786.262207 428.035187,786.973938 
	C428.035187,786.973938 428.005737,787.003296 428.034119,787.350708 
	C428.895966,788.675781 429.538086,790.082520 430.596863,790.554749 
	C434.590210,792.335388 438.807556,793.623718 442.760223,795.481201 
	C444.778809,796.429932 446.413666,798.195190 448.200439,799.574768 
	C449.977203,799.659119 452.183502,799.354431 454.124939,799.935120 
	C459.319824,801.489014 464.318695,803.735107 469.551178,805.115967 
	C474.607635,806.450439 479.858368,807.048767 485.023773,807.970398 
M458.508636,803.167480 
	C458.212250,803.580322 457.915833,803.993225 457.619446,804.406128 
	C458.057098,804.515442 458.494781,804.624817 458.932434,804.734131 
	C458.985016,804.251099 459.037598,803.768127 458.508636,803.167480 
z"/>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" 
	d="
M544.990234,837.065552 
	C544.798706,834.127441 544.322510,832.181030 540.268921,832.569946 
	C537.032593,832.880493 533.600952,831.555725 530.340698,830.651428 
	C529.981445,830.551697 529.801819,828.266357 530.241150,827.321045 
	C530.581177,826.589417 532.019470,826.368225 534.039612,825.415466 
	C529.224121,822.942017 525.842346,825.546021 522.373535,825.770691 
	C520.486938,825.892944 518.544556,825.491028 516.659119,825.149841 
	C515.729126,824.981628 514.813599,823.987671 513.990295,824.098877 
	C509.306732,824.731140 504.652954,825.584229 499.067383,826.515930 
	C498.639252,824.167419 498.171204,821.600037 497.521484,818.036072 
	C503.479309,817.635132 508.710876,817.322571 513.931763,816.882874 
	C514.603760,816.826233 515.220215,816.110229 516.357727,815.329102 
	C518.567688,814.994629 520.282349,815.030701 522.047607,815.430725 
	C526.000305,819.774780 529.925537,819.848999 532.415100,815.973389 
	C534.220032,815.988892 535.608337,816.013000 537.003906,816.424683 
	C537.357361,820.223816 539.584717,820.959778 542.588074,821.044739 
	C546.172485,821.146057 549.974854,820.915588 553.275818,822.032104 
	C558.987244,823.963928 562.536255,822.099976 563.363403,815.951599 
	C564.468018,815.912354 565.204346,815.886292 565.994507,816.236694 
	C568.254639,818.072998 570.378479,819.689758 572.708618,820.914307 
	C573.623535,821.395081 575.018799,820.863586 576.162903,821.024109 
	C580.216736,821.592712 584.242554,822.397644 588.309631,822.812988 
	C589.620361,822.946838 591.025696,822.154358 592.387817,821.785645 
	C592.066833,820.976257 591.604370,819.433472 591.445435,819.464172 
	C586.767639,820.366455 585.523987,816.048157 583.036133,813.719421 
	C583.819763,813.105530 584.429749,812.709351 585.039734,812.313171 
	C585.474365,812.231079 585.908997,812.148987 586.837769,812.340820 
	C587.887085,812.754211 588.442322,812.893738 589.086670,813.418701 
	C591.980774,814.085022 594.831055,814.148987 597.575195,814.721863 
	C599.459839,815.115356 601.196960,816.215576 602.840942,817.365234 
	C602.456421,819.605286 602.230835,821.480164 601.949646,823.817444 
	C606.695496,824.085632 610.767517,824.575378 614.822388,824.460327 
	C617.897461,824.372925 620.932861,823.294922 624.017273,823.046997 
	C629.954773,822.569702 635.919678,822.092590 641.862244,822.202698 
	C643.924744,822.240906 645.955017,824.017456 647.558838,825.000000 
	C642.540894,825.008911 637.963867,824.984619 633.387573,825.034058 
	C625.046448,825.124146 624.021179,826.128601 623.691895,834.060425 
	C622.957642,834.079102 622.537964,834.086670 621.776978,833.932861 
	C618.903931,832.515320 616.268250,830.062683 613.863770,830.271545 
	C609.831665,830.621765 605.946838,832.667664 601.707764,833.715942 
	C601.273071,831.629028 601.387085,829.752502 600.898071,828.049072 
	C600.572998,826.916565 599.446899,825.222900 598.576416,825.157776 
	C587.054504,824.296936 575.521301,823.420349 563.975830,823.107666 
	C557.771423,822.939758 555.553955,826.324646 556.605164,832.390503 
	C556.801758,833.524597 556.221680,834.793396 555.648682,836.004761 
	C554.531067,836.656372 553.764709,837.303162 552.700562,837.976379 
	C551.930542,838.310181 551.458252,838.617615 550.645752,838.983398 
	C549.867737,839.163086 549.429871,839.284546 548.992004,839.405945 
	C548.851685,839.284912 548.711426,839.163940 548.252686,838.581848 
	C546.952942,837.768982 545.971558,837.417236 544.990234,837.065552 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M701.972534,809.955200 
	C701.491699,810.233032 701.010864,810.510803 700.006287,810.559326 
	C696.654236,811.897705 693.825928,813.465454 690.997620,815.033203 
	C690.693420,815.171814 690.389221,815.310425 689.427002,815.308167 
	C685.817444,816.704407 682.865906,818.241577 679.914307,819.778687 
	C678.853394,820.194275 677.792542,820.609924 676.180725,820.766785 
	C672.078430,822.072388 668.526978,823.636780 664.975525,825.201172 
	C664.650757,825.290894 664.325928,825.380737 663.335815,825.313354 
	C659.781006,825.781006 656.891541,826.405823 654.036072,826.675659 
	C654.513306,825.080139 654.956482,823.839539 655.492493,822.339111 
	C656.472900,822.819885 657.621643,823.793457 658.064819,823.538818 
	C664.583740,819.794434 671.256042,816.225647 677.307190,811.813232 
	C679.150574,810.469116 679.502869,807.713989 682.382385,810.233337 
	C682.672913,810.487427 684.100220,809.442200 685.354309,809.034668 
	C686.504456,809.235840 687.868774,809.750122 688.011414,809.517029 
	C690.431580,805.561401 692.678528,801.500366 695.015686,797.492920 
	C695.691101,796.334961 696.529358,795.271973 697.292847,794.165405 
	C698.197937,795.298157 699.164612,796.388855 699.981079,797.582275 
	C700.361938,798.138977 700.451904,798.894714 700.864014,800.131042 
	C703.538574,792.453125 704.193176,792.118469 712.373413,793.646545 
	C719.068298,794.897095 725.435059,789.295715 725.026428,782.579712 
	C724.830811,779.364502 724.676331,776.567871 729.706299,777.808167 
	C733.854736,778.831116 738.294861,778.671143 742.419128,779.019592 
	C741.826050,773.191956 739.813416,767.269348 742.410522,760.496338 
	C746.895630,765.167542 751.147461,764.258789 755.731567,762.215454 
	C759.154480,760.689819 762.928955,759.361633 765.918518,763.407227 
	C765.487305,764.740601 765.200928,765.777405 764.914551,766.814209 
	C764.914490,766.814209 764.694641,767.095703 764.148193,767.110657 
	C763.133667,767.266418 762.568359,767.284180 762.211792,767.565979 
	C755.350403,772.988953 748.467773,778.386902 741.694275,783.918213 
	C738.997009,786.120972 736.552368,788.632996 733.992676,791.004211 
	C733.992676,791.004211 733.755981,791.230713 733.249146,791.184570 
	C732.075012,791.679016 731.407837,792.219666 730.386475,792.829834 
	C727.683533,794.599182 725.334656,796.299011 722.985840,797.998901 
	C722.533875,798.280640 722.081970,798.562317 721.020447,798.926025 
	C719.614563,799.690552 718.818298,800.372925 717.943848,801.265686 
	C717.865723,801.476074 717.840027,801.924072 717.840027,801.924072 
	C717.271423,802.058777 716.702820,802.193481 715.437012,802.273132 
	C709.632080,803.391174 704.914917,805.208984 701.972534,809.955200 
z"/>
<path fill="#F9FAF9" opacity="1.000000" stroke="none" 
	d="
M818.971130,700.007507 
	C818.916504,700.369019 818.861877,700.730591 818.587891,701.508423 
	C817.954346,702.299988 817.540161,702.675293 816.767090,703.072632 
	C815.388428,705.226501 814.368591,707.358459 813.348755,709.490356 
	C813.348755,709.490356 813.269958,709.797974 812.794067,709.910400 
	C808.886902,715.365234 805.455627,720.707703 802.024292,726.050110 
	C801.944885,726.370300 801.772644,726.628540 800.919800,726.936035 
	C799.682068,728.206055 799.032166,729.364929 798.382202,730.523804 
	C798.382263,730.523804 798.283936,730.819092 797.802795,730.921387 
	C795.921021,733.060852 794.520386,735.098083 793.119751,737.135254 
	C793.119751,737.135254 793.019958,737.000977 792.715454,737.042358 
	C791.082336,738.909607 789.753662,740.735535 788.425049,742.561462 
	C788.425049,742.561462 788.341736,742.853149 787.851562,742.949158 
	C786.733704,744.148315 786.105957,745.251465 785.478271,746.354614 
	C785.478271,746.354614 785.394226,746.388000 785.054199,746.314880 
	C784.098389,747.152039 783.482605,748.062256 782.866882,748.972534 
	C782.425659,749.567871 781.984436,750.163269 781.053467,750.597656 
	C779.879150,751.494141 779.194580,752.551575 778.510010,753.609070 
	C778.419556,753.897583 778.252441,754.131287 777.436279,754.287964 
	C775.593018,755.865601 774.322083,757.465454 773.051147,759.065308 
	C773.051147,759.065308 773.005798,758.997559 772.669678,758.983398 
	C771.598938,759.004150 770.864441,759.039001 769.849304,758.852905 
	C769.475098,756.562927 769.579346,754.461609 769.247498,752.431580 
	C768.512390,747.934998 769.828308,746.492493 774.289368,747.238831 
	C779.327454,748.081665 780.881287,747.025635 780.954651,742.304260 
	C780.996155,739.634399 781.392395,737.891174 784.238708,736.421814 
	C785.658569,735.688904 786.641113,731.726501 786.005371,729.835388 
	C783.993958,723.852051 786.641724,719.189636 792.953552,719.011902 
	C795.404846,718.942871 797.859741,719.000671 800.120422,719.000671 
	C801.598450,713.085022 802.938416,707.721680 804.380737,701.948792 
	C806.863953,702.239197 808.928955,702.480713 812.072510,702.848328 
	C812.987854,698.395020 813.652100,693.785583 814.926575,689.351440 
	C816.190918,684.952637 814.546875,682.500977 809.823975,682.635498 
	C804.851135,682.777222 799.873962,683.019226 794.905823,682.906250 
	C793.589294,682.876343 792.298828,681.701721 791.464783,681.030334 
	C800.668152,681.004150 809.403076,680.995422 818.138062,680.997986 
	C825.818420,681.000183 825.818420,681.005737 825.424622,688.538818 
	C825.424622,688.538818 825.401245,688.847473 824.942871,688.985718 
	C822.646667,692.751831 820.808899,696.379639 818.971130,700.007507 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M556.000000,836.000000 
	C556.221680,834.793396 556.801758,833.524597 556.605164,832.390503 
	C555.553955,826.324646 557.771423,822.939758 563.975830,823.107666 
	C575.521301,823.420349 587.054504,824.296936 598.576416,825.157776 
	C599.446899,825.222900 600.572998,826.916565 600.898071,828.049072 
	C601.387085,829.752502 601.273071,831.629028 601.707764,834.098755 
	C602.004456,835.533264 602.008972,836.300903 601.713745,837.107910 
	C600.909058,837.682617 600.404053,838.217957 599.899048,838.753296 
	C599.740356,838.838318 599.581665,838.923279 598.859497,839.045654 
	C597.540100,839.032898 596.784119,838.982788 595.731506,838.640442 
	C582.289368,838.234558 569.143982,838.120972 555.999390,837.672729 
	C556.000122,836.892090 556.000061,836.446045 556.000000,836.000000 
z"/>
<path fill="#E0DDDB" opacity="1.000000" stroke="none" 
	d="
M414.944580,779.694702 
	C410.865509,778.213135 406.706543,776.457825 402.182312,774.548279 
	C398.536072,777.711914 402.782623,779.807129 403.958679,782.755188 
	C403.795044,783.184448 403.620636,783.236694 402.991730,783.131592 
	C402.356842,782.945557 402.176483,782.916870 401.996124,782.888245 
	C394.074615,776.131592 386.153137,769.374939 377.922119,762.494507 
	C371.855072,756.436646 366.097626,750.502502 360.188782,743.918091 
	C359.346893,742.516846 358.656433,741.765747 357.965973,741.014587 
	C356.592529,739.687744 355.219086,738.360901 353.552063,736.905151 
	C351.294159,733.960449 349.329803,731.144592 347.237274,727.677063 
	C346.459808,726.321350 345.810486,725.617249 345.161194,724.913208 
	C345.161194,724.913208 345.075928,724.916260 345.084656,724.547729 
	C345.028992,722.479919 344.964630,720.780640 344.900238,719.081299 
	C346.596222,721.015747 348.292206,722.950195 350.390808,725.333984 
	C351.482727,726.353577 352.172028,726.923828 352.861328,727.494080 
	C352.861328,727.494080 352.832001,727.785095 353.015900,728.200562 
	C353.737640,728.952820 354.275482,729.289551 354.813324,729.626282 
	C357.235046,732.350403 359.656799,735.074585 362.489044,738.237244 
	C363.224274,738.921082 363.548981,739.166382 363.873657,739.411743 
	C364.469086,740.181213 365.064484,740.950684 366.030396,742.100464 
	C366.893799,742.775513 367.386688,743.070251 367.879578,743.364990 
	C367.916290,743.634644 368.047943,743.844910 368.580292,744.335571 
	C369.221771,744.881958 369.557526,745.088501 369.893280,745.295044 
	C372.166290,747.382141 374.439301,749.469238 376.978516,751.996338 
	C377.409454,752.724670 377.651367,752.822571 377.970337,752.729980 
	C379.510071,754.064636 381.049805,755.399292 382.929840,757.043823 
	C383.270142,757.353760 383.550201,757.607239 383.550201,757.607239 
	C383.550201,757.607239 383.924927,757.559631 383.924927,757.559631 
	C389.376678,761.643982 394.828400,765.728333 400.754333,770.193054 
	C401.791504,770.794556 402.354431,771.015625 402.917389,771.236755 
	C404.856689,772.556396 406.795959,773.876038 409.228638,775.515625 
	C410.479797,776.253540 411.237579,776.671448 411.995361,777.089355 
	C412.438019,777.380676 412.880646,777.672058 413.583405,778.013794 
	C414.183929,778.516418 414.524323,778.968689 414.944580,779.694702 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M624.006470,834.049316 
	C624.021179,826.128601 625.046448,825.124146 633.387573,825.034058 
	C637.963867,824.984619 642.540894,825.008911 647.685364,825.297241 
	C648.501038,826.406982 648.748962,827.219421 648.670471,828.068848 
	C647.535889,829.004761 646.727783,829.903687 645.919617,830.802673 
	C645.756775,830.855225 645.593933,830.907715 644.861694,830.781250 
	C637.530457,831.751282 630.768433,832.900269 624.006470,834.049316 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M825.725586,688.354004 
	C825.818420,681.005737 825.818420,681.000183 818.138062,680.997986 
	C809.403076,680.995422 800.668152,681.004150 791.133179,681.011536 
	C789.890747,681.028076 789.448364,681.041077 789.005981,681.054199 
	C786.712463,681.036743 784.398499,681.207642 782.129944,680.960754 
	C777.993835,680.510681 774.427002,681.206909 771.532959,684.756287 
	C771.019958,684.388000 770.966370,684.208313 770.912720,684.028625 
	C771.810181,682.618591 772.707642,681.208557 774.187317,679.547974 
	C776.178894,679.196899 777.588257,679.096191 778.997620,678.995544 
	C780.716064,679.051392 782.434448,679.107239 785.039062,679.167847 
	C795.948303,679.111450 805.971252,679.050232 815.994263,678.989075 
	C817.387878,679.044617 818.781433,679.100159 821.023010,679.164795 
	C824.228027,679.177673 826.585022,679.181335 828.942078,679.185059 
	C827.970215,682.179749 826.998352,685.174500 825.725586,688.354004 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M555.998535,838.007324 
	C569.143982,838.120972 582.289368,838.234558 595.729126,838.910278 
	C596.014771,839.651978 596.006042,839.831543 595.997253,840.011169 
	C584.927612,840.190735 573.858398,840.442505 562.788086,840.508606 
	C559.164185,840.530273 555.537415,840.069214 551.451294,839.632202 
	C550.989075,839.265320 550.987488,839.095154 550.985901,838.925049 
	C551.458252,838.617615 551.930542,838.310181 553.074707,838.007263 
	C554.497253,838.010254 555.247864,838.008789 555.998535,838.007324 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M623.691895,834.060425 
	C630.768433,832.900269 637.530457,831.751282 644.605469,830.765930 
	C644.918518,830.929626 644.973267,831.347290 644.584717,831.547913 
	C643.125061,832.156311 642.053955,832.564087 640.982849,832.971863 
	C640.982849,832.971863 641.000000,833.000000 640.594727,832.965698 
	C638.580505,833.544800 636.971436,834.158264 635.362427,834.771667 
	C632.772583,834.998840 630.182800,835.226074 626.938599,835.625427 
	C625.871399,835.932678 625.458557,836.067810 625.045715,836.202881 
	C621.900208,836.603027 618.754761,837.003174 614.941711,837.571106 
	C613.856384,837.855347 613.438599,837.971802 613.020813,838.088257 
	C608.944824,838.399658 604.868896,838.711121 600.345947,838.887939 
	C600.404053,838.217957 600.909058,837.682617 602.153564,837.109253 
	C609.301514,836.078918 615.709900,835.086609 622.118347,834.094238 
	C622.537964,834.086670 622.957642,834.079102 623.691895,834.060425 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M734.314575,791.023804 
	C736.552368,788.632996 738.997009,786.120972 741.694275,783.918213 
	C748.467773,778.386902 755.350403,772.988953 762.211792,767.565979 
	C762.568359,767.284180 763.133667,767.266418 763.982178,767.176453 
	C757.188843,773.296814 750.022949,779.375732 742.834534,785.427795 
	C741.435852,786.605408 739.949219,787.678650 737.988403,789.018555 
	C737.159180,789.397888 736.989075,789.654419 736.963318,790.006775 
	C736.187744,790.352295 735.412109,790.697876 734.314575,791.023804 
z"/>
<path fill="#E0DDDB" opacity="1.000000" stroke="none" 
	d="
M438.113556,805.361084 
	C440.980804,805.200073 443.817078,804.724365 446.592163,807.280090 
	C449.363312,809.832336 453.293854,811.112366 456.671692,813.028381 
	C457.545685,813.524231 458.253204,814.313538 459.062073,815.324463 
	C458.675293,815.749146 458.263611,815.817505 457.408844,815.671021 
	C456.654022,815.359070 456.342285,815.261963 456.030548,815.164917 
	C450.104767,812.000732 444.178986,808.836548 438.113556,805.361084 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M476.000854,821.998535 
	C484.302124,824.674622 492.603363,827.350708 501.000732,830.360229 
	C492.943939,828.238037 484.791016,825.782349 476.324249,822.910889 
	C476.010406,822.495178 476.000854,821.998535 476.000854,821.998535 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M544.560547,837.064453 
	C545.971558,837.417236 546.952942,837.768982 548.013794,838.440063 
	C545.708130,838.780457 543.323059,838.801514 540.243774,838.642334 
	C538.695251,838.442505 537.840820,838.422791 536.986389,838.403137 
	C535.929871,838.222168 534.873352,838.041199 533.164307,837.806274 
	C532.338196,837.771667 532.164612,837.791016 531.991089,837.810303 
	C530.885681,837.538635 529.780212,837.266968 528.088989,836.952942 
	C527.336243,836.939148 527.169373,836.967651 527.002441,836.996094 
	C525.146240,836.536194 523.290100,836.076355 521.203003,835.344849 
	C524.941101,835.220398 528.922546,835.221191 532.875427,835.557190 
	C536.642700,835.877380 540.380310,836.547302 544.560547,837.064453 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M802.347900,725.944336 
	C805.455627,720.707703 808.886902,715.365234 812.669922,710.009338 
	C809.571594,715.276733 806.121582,720.557617 802.347900,725.944336 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M646.325500,830.909668 
	C646.727783,829.903687 647.535889,829.004761 649.041077,828.077637 
	C651.159424,827.709900 652.580688,827.370239 654.002014,827.030640 
	C656.891541,826.405823 659.781006,825.781006 663.082825,825.286011 
	C657.907166,827.282715 652.319275,829.149719 646.325500,830.909668 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M702.343506,809.974243 
	C704.914917,805.208984 709.632080,803.391174 715.147217,802.330322 
	C711.274597,804.959473 706.994629,807.476440 702.343506,809.974243 
z"/>
<path fill="#E0DDDB" opacity="1.000000" stroke="none" 
	d="
M420.499207,794.869934 
	C422.333435,793.501831 423.557953,793.355103 425.275177,795.770081 
	C427.028564,798.235840 430.248383,799.658813 432.797241,801.899841 
	C428.736938,799.893250 424.705627,797.525146 420.499207,794.869934 
z"/>
<path fill="#69527B" opacity="1.000000" stroke="none" 
	d="
M815.700256,678.693359 
	C805.971252,679.050232 795.948303,679.111450 785.462646,679.088501 
	C787.045959,678.669617 789.092224,678.048096 791.137695,678.050537 
	C799.227295,678.060059 807.316772,678.265076 815.700256,678.693359 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M770.962341,685.922668 
	C768.516846,690.256592 766.071350,694.590454 763.278076,698.953613 
	C765.202209,694.835693 767.474243,690.688538 770.109741,686.224487 
	C770.473206,685.907593 770.962341,685.922668 770.962341,685.922668 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M665.244629,825.371704 
	C668.526978,823.636780 672.078430,822.072388 675.834290,820.773682 
	C676.038757,821.039429 676.073486,821.524902 675.832642,821.901184 
	C675.517517,822.431519 675.443176,822.585632 675.368896,822.739807 
	C672.083862,823.673950 668.798828,824.608093 665.244629,825.371704 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M819.289307,699.874756 
	C820.808899,696.379639 822.646667,692.751831 824.843994,689.104858 
	C823.338196,692.637756 821.472839,696.189819 819.289307,699.874756 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M691.376770,815.056091 
	C693.825928,813.465454 696.654236,811.897705 699.721985,810.576904 
	C697.226318,812.242126 694.491089,813.660583 691.376770,815.056091 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M680.293823,819.841553 
	C682.865906,818.241577 685.817444,816.704407 689.174133,815.316406 
	C686.610596,816.945190 683.641968,818.424805 680.293823,819.841553 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M503.169556,831.336060 
	C506.261505,831.668701 509.553741,832.253540 512.578491,833.255127 
	C510.540161,833.444397 508.769226,833.216797 506.998291,832.989197 
	C505.788818,832.522217 504.579346,832.055237 503.169556,831.336060 
z"/>
<path fill="#E0DDDB" opacity="1.000000" stroke="none" 
	d="
M404.512817,783.699463 
	C407.272858,783.822144 411.093475,782.787659 411.131714,788.043213 
	C408.973602,786.949341 406.796967,785.488403 404.512817,783.699463 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M723.342285,797.997803 
	C725.334656,796.299011 727.683533,794.599182 730.487427,792.924683 
	C730.942444,792.950073 731.140381,793.143860 731.102783,793.360962 
	C731.065186,793.578125 731.084106,794.018433 731.084106,794.018433 
	C728.622314,795.344482 726.160522,796.670593 723.342285,797.997803 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M765.241882,766.766235 
	C765.200928,765.777405 765.487305,764.740601 766.187988,763.201294 
	C767.736694,762.134460 768.871155,761.570068 770.141479,761.163574 
	C770.277222,761.321533 770.570923,761.616821 770.570923,761.616821 
	C768.903687,763.317261 767.236450,765.017761 765.241882,766.766235 
z"/>
<path fill="#E0DDDB" opacity="1.000000" stroke="none" 
	d="
M412.599762,789.643677 
	C414.627106,790.631470 416.799927,791.929077 419.003052,793.577820 
	C416.937317,792.603821 414.841309,791.278687 412.599762,789.643677 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M793.449585,737.073120 
	C794.520386,735.098083 795.921021,733.060852 797.675537,731.010986 
	C796.612732,733.002502 795.196045,735.006714 793.449585,737.073120 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M788.752014,742.453613 
	C789.753662,740.735535 791.082336,738.909607 792.775085,737.101074 
	C791.785828,738.860962 790.432434,740.603333 788.752014,742.453613 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M813.656494,709.334900 
	C814.368591,707.358459 815.388428,705.226501 816.850952,703.310791 
	C817.358582,703.682251 817.423523,703.837524 817.488525,703.992798 
	C816.313721,705.721680 815.138977,707.450562 813.656494,709.334900 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M773.363159,759.048462 
	C774.322083,757.465454 775.593018,755.865601 777.241211,754.385925 
	C777.495667,754.839783 777.372803,755.173584 777.038696,755.953674 
	C776.768799,756.557312 776.710205,756.714661 776.651550,756.872070 
	C775.659424,757.591919 774.667297,758.311829 773.363159,759.048462 
z"/>
<path fill="#C3BCBA" opacity="1.000000" stroke="none" 
	d="
M462.545959,817.602295 
	C464.364319,817.944946 466.165924,818.501526 468.059753,819.412842 
	C466.985382,819.581848 465.818787,819.396179 464.134766,818.878662 
	C463.258026,818.446350 462.898743,818.345825 462.539459,818.245239 
	C462.539459,818.245239 462.529236,817.816162 462.545959,817.602295 
z"/>
<path fill="#C3BCBA" opacity="1.000000" stroke="none" 
	d="
M475.737061,821.749390 
	C476.000854,821.998535 476.010406,822.495178 476.010620,822.743530 
	C475.563385,822.990540 475.115875,822.989136 474.151672,822.766541 
	C473.097473,822.395752 472.560028,822.246155 472.022552,822.096558 
	C471.597961,821.797974 471.173370,821.499390 470.620728,820.977417 
	C472.152893,821.002747 473.813080,821.251465 475.737061,821.749390 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M507.239258,833.274048 
	C508.769226,833.216797 510.540161,833.444397 512.678101,833.578064 
	C513.482422,833.601624 513.919739,833.718994 514.691589,834.107422 
	C515.070190,834.555725 515.114380,834.732849 515.158508,834.909973 
	C512.599060,834.459656 510.039673,834.009277 507.239258,833.274048 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M778.810852,753.488098 
	C779.194580,752.551575 779.879150,751.494141 780.775513,750.680176 
	C780.362061,751.738159 779.736877,752.552673 778.810852,753.488098 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M783.184570,748.917114 
	C783.482605,748.062256 784.098389,747.152039 785.044739,746.359131 
	C784.750977,747.271484 784.126648,748.066589 783.184570,748.917114 
z"/>
<path fill="#69527B" opacity="1.000000" stroke="none" 
	d="
M828.722900,678.856934 
	C826.585022,679.181335 824.228027,679.177673 821.435608,679.087402 
	C823.501404,678.843506 826.002563,678.686218 828.722900,678.856934 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M798.686646,730.385986 
	C799.032166,729.364929 799.682068,728.206055 800.709717,727.045044 
	C800.388733,728.111267 799.689880,729.179688 798.686646,730.385986 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M785.776611,746.249390 
	C786.105957,745.251465 786.733704,744.148315 787.730591,743.040649 
	C787.424927,744.072144 786.749939,745.108154 785.776611,746.249390 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M596.179016,840.257812 
	C596.006042,839.831543 596.014771,839.651978 596.025818,839.202515 
	C596.784119,838.982788 597.540100,839.032898 598.651062,839.217163 
	C598.124329,839.735718 597.242554,840.120056 596.179016,840.257812 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M537.064392,838.695068 
	C537.840820,838.422791 538.695251,838.442505 539.797974,838.672119 
	C539.078247,838.917114 538.110352,838.952087 537.064392,838.695068 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M635.755737,834.750732 
	C636.971436,834.158264 638.580505,833.544800 640.584351,832.951538 
	C639.369202,833.557739 637.759094,834.143738 635.755737,834.750732 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M515.528992,834.893982 
	C515.114380,834.732849 515.070190,834.555725 515.032288,834.104492 
	C516.009644,833.925110 516.980713,834.019836 518.023010,834.407959 
	C517.362610,834.760193 516.631042,834.819092 515.528992,834.893982 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M770.129883,759.073853 
	C770.864441,759.039001 771.598938,759.004150 772.699097,759.012024 
	C772.427551,759.830261 771.790405,760.605835 770.862122,761.499146 
	C770.570923,761.616821 770.277222,761.321533 770.162231,760.841064 
	C770.074768,759.931702 770.102295,759.502747 770.129883,759.073853 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M718.022034,801.055359 
	C718.818298,800.372925 719.614563,799.690552 720.780457,799.043152 
	C720.861389,799.541931 720.572693,800.005615 719.797485,800.707520 
	C718.881287,800.982300 718.451660,801.018860 718.022034,801.055359 
z"/>
<path fill="#E0DDDB" opacity="1.000000" stroke="none" 
	d="
M432.723206,802.459717 
	C433.791290,802.584412 434.847412,802.923035 435.626648,803.645813 
	C434.913208,804.004700 434.476685,803.979431 434.040161,803.954163 
	C434.040161,803.954163 434.005890,803.998230 433.875183,803.743164 
	C433.414551,803.358704 433.084595,803.229248 432.754608,803.099854 
	C432.754608,803.099854 432.711243,802.673645 432.723206,802.459717 
z"/>
<path fill="#69527B" opacity="1.000000" stroke="none" 
	d="
M778.759888,678.721313 
	C777.588257,679.096191 776.178894,679.196899 774.340271,679.285767 
	C775.448059,678.998413 776.985168,678.722778 778.759888,678.721313 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M550.645752,838.983398 
	C550.987488,839.095154 550.989075,839.265320 551.014404,839.689453 
	C550.390503,839.939453 549.742981,839.935425 549.043701,839.668701 
	C549.429871,839.284546 549.867737,839.163086 550.645752,838.983398 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M641.359253,832.987915 
	C642.053955,832.564087 643.125061,832.156311 644.550903,831.755737 
	C643.848938,832.176636 642.792297,832.590332 641.359253,832.987915 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M730.841553,792.855164 
	C731.407837,792.219666 732.075012,791.679016 733.088135,791.214966 
	C733.328064,791.631287 733.222046,791.971008 732.726196,792.600159 
	C731.937622,792.974304 731.539001,793.059082 731.140381,793.143860 
	C731.140381,793.143860 730.942444,792.950073 730.841553,792.855164 
z"/>
<path fill="#69527B" opacity="1.000000" stroke="none" 
	d="
M817.782837,703.903687 
	C817.423523,703.837524 817.358582,703.682251 817.209778,703.288818 
	C817.540161,702.675293 817.954346,702.299988 818.612061,701.782104 
	C818.596069,702.364502 818.336609,703.089478 817.782837,703.903687 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M613.183472,838.305420 
	C613.438599,837.971802 613.856384,837.855347 614.600952,837.760986 
	C614.400513,838.029602 613.873291,838.276062 613.183472,838.305420 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M625.217224,836.396851 
	C625.458557,836.067810 625.871399,835.932678 626.613770,835.830322 
	C626.425110,836.105652 625.906921,836.348267 625.217224,836.396851 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M434.209503,804.226318 
	C434.476685,803.979431 434.913208,804.004700 435.679138,804.008545 
	C436.008575,803.987061 436.133698,804.438599 436.167236,804.670227 
	C435.593445,804.767456 434.986145,804.632996 434.209503,804.226318 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M432.655884,803.281006 
	C433.084595,803.229248 433.414551,803.358704 433.885681,803.717773 
	C433.536926,803.785645 433.047058,803.623962 432.655884,803.281006 
z"/>
<path fill="#69527B" opacity="1.000000" stroke="none" 
	d="
M717.943848,801.265747 
	C718.451660,801.018860 718.881287,800.982300 719.635620,800.935181 
	C719.467224,801.263794 718.974121,801.603149 718.160522,801.933289 
	C717.840027,801.924072 717.865723,801.476074 717.943848,801.265747 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M462.520203,818.432373 
	C462.898743,818.345825 463.258026,818.446350 463.786987,818.776489 
	C463.471436,818.877258 462.986206,818.748352 462.520203,818.432373 
z"/>
<path fill="#69527B" opacity="1.000000" stroke="none" 
	d="
M771.006836,685.695129 
	C770.962341,685.922668 770.473206,685.907593 770.228760,685.901184 
	C770.142822,685.377502 770.301331,684.860229 770.686279,684.185791 
	C770.966370,684.208313 771.019958,684.388000 771.190063,684.823975 
	C771.306519,685.080261 771.051392,685.467590 771.006836,685.695129 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M472.194794,822.325623 
	C472.560028,822.246155 473.097473,822.395752 473.815186,822.769592 
	C473.452637,822.847412 472.909851,822.701050 472.194794,822.325623 
z"/>
<path fill="#69527B" opacity="1.000000" stroke="none" 
	d="
M731.102783,793.360962 
	C731.539001,793.059082 731.937622,792.974304 732.640259,792.854370 
	C732.523926,793.197449 732.103455,793.575623 731.383545,793.986084 
	C731.084106,794.018433 731.065186,793.578125 731.102783,793.360962 
z"/>
<path fill="#69527B" opacity="1.000000" stroke="none" 
	d="
M776.851440,756.868896 
	C776.710205,756.714661 776.768799,756.557312 777.002380,756.218872 
	C777.253296,756.260620 777.342407,756.480835 777.388184,756.709656 
	C777.393921,756.738098 777.169250,756.812622 776.851440,756.868896 
z"/>
<path fill="#69527B" opacity="1.000000" stroke="none" 
	d="
M737.167358,789.931396 
	C736.989075,789.654419 737.159180,789.397888 737.712158,789.233887 
	C737.829834,789.505981 737.636719,789.714478 737.167358,789.931396 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M456.170166,815.367798 
	C456.342285,815.261963 456.654022,815.359070 457.091858,815.681763 
	C456.869385,815.918701 456.566650,815.806519 456.170166,815.367798 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M402.037994,783.062256 
	C402.176483,782.916870 402.356842,782.945557 402.722290,783.175964 
	C402.631531,783.330566 402.355713,783.283447 402.037994,783.062256 
z"/>
<path fill="#69527B" opacity="1.000000" stroke="none" 
	d="
M675.594910,822.780518 
	C675.443176,822.585632 675.517517,822.431519 675.810669,822.143555 
	C676.103760,822.253296 676.177856,822.496826 676.252014,822.740417 
	C676.108337,822.767334 675.964600,822.794250 675.594910,822.780518 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M527.081909,837.165649 
	C527.169373,836.967651 527.336243,836.939148 527.746338,836.983643 
	C527.713440,837.149597 527.437439,837.242432 527.081909,837.165649 
z"/>
<path fill="#6C567D" opacity="1.000000" stroke="none" 
	d="
M532.060181,838.009033 
	C532.164612,837.791016 532.338196,837.771667 532.759277,837.838135 
	C532.714233,838.018677 532.421692,838.113281 532.060181,838.009033 
z"/>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" 
	d="
M317.832123,723.077026 
	C318.275269,723.715271 318.718414,724.353516 319.166168,725.643188 
	C321.448700,729.200867 323.726624,732.107117 326.004517,735.013367 
	C326.004517,735.013367 326.256073,735.263000 326.316406,735.428467 
	C327.141388,737.713867 327.448669,740.185547 328.757233,741.887268 
	C332.529755,746.793213 336.853058,751.274231 340.658722,756.156982 
	C342.422760,758.420227 343.264130,761.388550 344.945099,763.735535 
	C346.147491,765.414307 348.100220,766.539307 349.630371,768.003174 
	C352.483856,770.732910 355.461823,773.369629 358.006287,776.368835 
	C359.193390,777.768066 362.462067,780.763855 364.429474,781.400818 
	C365.933685,781.887939 367.244965,782.970886 369.018707,783.667725 
	C369.395355,783.546631 369.450073,783.581116 369.420166,783.892090 
	C369.712433,784.247375 370.034546,784.291748 370.356689,784.336121 
	C370.356689,784.336121 370.506805,784.433350 370.411438,784.830872 
	C372.675995,787.059021 375.035889,788.889648 377.395752,790.720215 
	C377.395752,790.720276 377.716339,790.824463 377.870941,791.293823 
	C379.487579,792.741882 380.949615,793.720581 382.411652,794.699341 
	C383.185272,795.280151 383.958862,795.860962 384.886383,797.045776 
	C387.534119,799.281982 390.027954,800.914185 392.521759,802.546387 
	C392.521759,802.546387 392.821991,802.667908 392.941864,803.146484 
	C395.787811,805.323425 398.513916,807.021790 401.240021,808.720154 
	C401.240021,808.720154 401.124695,808.978577 401.154541,809.223083 
	C405.873840,815.487732 412.410156,818.485596 419.463318,820.637756 
	C420.166565,820.947937 420.869812,821.258118 421.812622,822.117981 
	C424.809296,824.037231 427.566376,825.406799 430.323456,826.776428 
	C430.323456,826.776428 430.672333,826.860413 430.872314,827.333130 
	C432.583130,828.313110 434.093994,828.820435 435.604858,829.327759 
	C435.934875,829.432800 436.264862,829.537964 436.838623,830.137451 
	C442.245483,832.890320 447.408600,835.148682 452.571716,837.407043 
	C452.903564,837.496582 453.235443,837.586121 453.834656,838.181274 
	C456.196533,839.363342 458.291077,840.039856 460.385620,840.716309 
	C460.385620,840.716309 460.724640,840.762634 460.917053,841.224182 
	C468.496918,844.448669 475.826263,847.384277 483.297791,849.897095 
	C486.720306,851.048035 490.422424,851.367554 493.997742,852.064209 
	C494.418640,852.130188 494.839539,852.196106 495.696411,852.691528 
	C501.078278,854.152954 506.024170,855.184937 510.970062,856.216980 
	C511.396790,856.225830 511.823517,856.234619 512.684692,856.665894 
	C515.745605,857.416077 518.372070,857.743835 520.998596,858.071533 
	C521.422546,858.099609 521.846497,858.127686 522.694702,858.579590 
	C526.417297,859.341492 529.715515,859.679443 533.013794,860.017456 
	C535.074341,860.230591 537.134888,860.443787 539.728271,861.037048 
	C540.888062,861.628113 541.515076,861.839111 542.411194,862.282776 
	C545.902710,863.341064 549.091858,864.456482 552.365845,864.831665 
	C553.479736,864.959351 554.807190,863.222900 556.037109,862.338257 
	C558.722717,862.362366 561.408264,862.386414 564.567139,862.930664 
	C570.977600,863.565063 576.914612,863.682556 582.852356,863.753784 
	C582.924866,863.754700 583.004639,863.160645 583.080933,862.844238 
	C587.082275,862.571106 591.083557,862.298035 595.827942,862.350281 
	C599.045410,862.770813 601.519775,862.866028 604.176880,863.302002 
	C609.303833,866.169434 614.572510,864.994141 616.388855,861.027039 
	C617.873352,860.532715 618.965454,860.058289 620.057495,859.583923 
	C620.346497,859.454895 620.649048,859.381287 621.599548,859.572876 
	C622.820007,859.840759 623.406006,859.898743 624.165100,860.285156 
	C626.816406,862.149292 629.395020,864.037415 630.239990,858.989990 
	C630.480042,858.980042 630.960388,858.971008 631.282471,859.024170 
	C631.723145,860.641174 631.841736,862.204956 632.042847,864.856567 
	C635.022888,861.955505 637.526184,859.518616 640.365112,857.021667 
	C642.189697,856.190125 643.678528,855.418579 645.167419,854.646973 
	C646.180725,854.416687 647.194092,854.186279 648.626831,854.272705 
	C649.347778,854.921448 649.649353,855.253357 649.950867,855.585327 
	C650.301331,855.123596 650.651794,854.661865 651.331299,854.135986 
	C652.106140,854.044250 652.551819,854.016663 653.154907,854.336060 
	C654.616089,855.452942 656.010376,856.999939 657.208374,856.860474 
	C662.867004,856.201721 664.738403,858.665833 664.075928,863.965393 
	C663.943115,865.027649 664.423218,866.166565 664.632385,867.335022 
	C662.370789,868.214478 659.963684,870.126587 658.226990,869.641052 
	C653.203491,868.236633 652.507019,871.098267 651.958618,874.665833 
	C651.186279,875.024170 650.414001,875.382507 649.150146,875.553101 
	C648.106812,875.249878 647.554993,875.134521 646.849976,874.705872 
	C645.018616,872.349426 643.678772,869.174438 640.203796,872.512512 
	C640.814880,874.040405 641.402954,875.510803 641.763184,877.108643 
	C641.298584,877.681763 641.061890,878.127441 640.825195,878.573181 
	C640.427429,878.667969 640.029663,878.762756 639.330933,878.413147 
	C637.227661,876.443604 635.615173,874.462280 633.537903,873.597717 
	C632.586975,873.201904 630.721375,875.003540 629.393372,875.740540 
	C632.008667,877.003174 633.988953,877.959229 635.708984,879.115723 
	C635.305298,879.716553 635.161743,880.116943 635.018188,880.517334 
	C634.689209,880.572510 634.360168,880.627686 633.485352,880.496277 
	C632.231140,880.672180 631.522766,881.034607 630.814392,881.397095 
	C630.122314,881.618042 629.430176,881.839050 628.316162,881.667847 
	C626.654968,880.303040 625.531860,878.783630 624.148621,878.489990 
	C622.217468,878.080017 620.031677,878.367249 618.104919,878.899353 
	C617.755493,878.995911 618.126404,881.700562 617.854919,883.442871 
	C610.345703,884.831116 603.129150,885.885742 595.964294,887.218201 
	C594.820618,887.430908 593.908508,888.889160 592.889343,889.771606 
	C592.177246,889.862305 591.465210,889.953003 590.109314,889.756104 
	C585.281799,890.250549 581.098145,891.032593 576.914429,891.814636 
	C576.490601,891.886780 576.066711,891.958923 575.033447,891.708191 
	C570.914978,891.853394 567.405945,892.321472 563.896851,892.789490 
	C563.178833,892.859802 562.460754,892.930176 561.317749,892.642639 
	C560.674805,892.049438 560.459167,891.610291 560.238525,891.607849 
	C547.681091,891.464966 535.123230,891.339233 522.565247,891.315430 
	C522.037476,891.314392 521.507874,892.294983 520.979126,892.818237 
	C520.565002,892.817200 520.150879,892.816101 519.288940,892.403320 
	C515.887878,891.993896 512.934509,891.996155 509.981201,891.998413 
	C509.558746,891.989441 509.136292,891.980408 508.358154,891.501343 
	C507.194031,890.396240 506.446503,889.313599 505.567993,889.194153 
	C494.899078,887.743530 484.214813,886.402161 473.524414,885.116577 
	C471.374207,884.857971 469.181793,884.950867 467.008820,884.881775 
	C466.586121,884.873596 466.163422,884.865356 465.319641,884.427368 
	C464.186798,883.623474 463.511444,883.137695 462.757782,882.891968 
	C450.742859,878.973450 438.740601,875.013306 426.681458,871.234863 
	C423.026825,870.089661 419.208618,869.466370 415.465332,868.604004 
	C415.133545,868.491028 414.801758,868.378052 414.197205,867.775635 
	C403.345398,861.057129 392.252625,855.866516 381.046509,850.609375 
	C381.497589,848.637268 381.687347,846.956238 382.348816,845.487610 
	C384.285248,841.188049 381.951691,839.047241 378.333771,838.406128 
	C374.825714,837.784485 371.125702,837.936584 367.548401,838.225159 
	C366.615692,838.300354 365.826141,840.150330 364.650269,840.993652 
	C362.741760,840.315979 361.153839,839.829834 359.565948,839.343689 
	C358.496918,838.739441 357.427887,838.135132 356.197266,836.954895 
	C355.977234,835.934692 355.918854,835.490479 356.253418,834.963013 
	C356.970551,832.440735 358.256775,829.491516 357.318359,827.722168 
	C356.549438,826.272339 353.135864,825.686462 350.887482,825.669617 
	C345.858673,825.631775 342.567902,823.824402 342.317871,820.420471 
	C342.214172,819.008972 341.601135,817.634827 341.219757,816.243713 
	C340.051880,817.286621 338.883972,818.329468 337.569183,819.503601 
	C336.600891,819.365479 335.302887,819.180298 333.942993,818.640869 
	C329.558380,811.991577 325.068573,805.801941 320.998535,799.347534 
	C319.461395,796.909790 317.938690,794.919250 315.225739,796.624146 
	C311.722473,798.825623 310.547455,796.771790 310.000946,794.160889 
	C309.329865,790.954712 309.623077,787.536194 308.855927,784.365112 
	C308.463409,782.742615 306.380676,780.070251 305.551147,780.241516 
	C298.540283,781.689392 295.387421,777.022461 292.249146,772.385986 
	C290.280670,769.477783 288.174347,767.602600 284.083527,769.951660 
	C284.872101,767.415588 285.430298,765.620483 285.930298,764.012512 
	C280.947632,761.177002 276.736389,758.520447 276.838348,752.225403 
	C276.860901,750.830811 275.227722,749.013489 273.881348,748.068054 
	C270.094421,745.408508 264.830811,744.652832 263.321930,739.137512 
	C263.082275,738.261475 261.357971,737.791626 260.265411,737.099365 
	C260.716553,736.591370 260.908081,736.341187 261.134064,736.127380 
	C262.146210,735.169495 262.934204,723.720825 262.074158,722.775574 
	C260.373993,720.907043 258.700806,719.014038 256.722717,716.802734 
	C252.213409,718.256287 251.693085,718.166870 248.989594,714.596924 
	C248.648911,712.406189 248.539688,710.536865 247.895706,708.874756 
	C247.188095,707.048462 245.013947,705.315796 245.174088,703.711487 
	C245.810242,697.337952 241.765915,695.957642 236.813187,694.763184 
	C236.090927,694.347046 235.542938,694.171387 234.994934,693.995789 
	C234.842850,693.686829 234.690765,693.377869 234.656647,692.367188 
	C234.811707,690.116882 234.848801,688.568359 235.214355,686.801208 
	C238.658066,686.054932 241.882416,685.862976 244.855179,684.896851 
	C247.389450,684.073242 249.634293,682.359070 252.475128,681.023071 
	C261.960480,681.021729 270.977844,681.036194 280.044067,681.431641 
	C281.013947,682.856628 281.816956,684.644409 282.876892,684.812622 
	C286.936218,685.456665 291.078156,685.580383 295.434875,686.139160 
	C297.422485,689.536682 299.176514,692.686829 300.904022,695.851501 
	C304.659180,702.730652 308.261993,709.698486 312.221100,716.457886 
	C313.661041,718.916321 315.937408,720.884949 317.832123,723.077026 
M334.075562,814.357788 
	C336.706543,813.690979 339.337524,813.024170 342.308197,812.271240 
	C339.958282,806.225220 335.774536,812.340881 332.662262,808.474609 
	C333.140198,811.315063 333.351074,812.568420 334.075562,814.357788 
M639.799316,861.395386 
	C639.795044,861.786987 639.790771,862.178589 639.786499,862.570251 
	C639.949036,862.571777 640.252869,862.584290 640.253723,862.573303 
	C640.283936,862.183594 640.285767,861.791748 639.799316,861.395386 
z"/>
<path fill="#F1F0EF" opacity="1.000000" stroke="none" 
	d="
M652.318359,874.726685 
	C652.507019,871.098267 653.203491,868.236633 658.226990,869.641052 
	C659.963684,870.126587 662.370789,868.214478 664.632385,867.335022 
	C664.423218,866.166565 663.943115,865.027649 664.075928,863.965393 
	C664.738403,858.665833 662.867004,856.201721 657.208374,856.860474 
	C656.010376,856.999939 654.616089,855.452942 653.170532,854.101257 
	C653.088135,853.211670 653.125244,852.901123 653.140198,852.587952 
	C655.857544,851.795166 658.574829,851.002319 661.875427,850.429443 
	C663.322510,850.149353 664.186340,849.649292 665.050171,849.149231 
	C665.943054,848.951599 666.835876,848.753967 668.454163,848.674927 
	C670.817993,848.002869 672.456482,847.212280 674.094910,846.421631 
	C675.623840,845.800232 677.152832,845.178772 679.370972,844.648560 
	C680.730530,844.249207 681.401001,843.758545 682.071411,843.267944 
	C682.948364,843.022339 683.825256,842.776672 685.395752,842.650085 
	C686.745728,842.237854 687.402039,841.706665 688.058350,841.175415 
	C707.487244,832.803528 726.334290,823.333984 743.980652,811.590515 
	C772.334106,792.721680 796.839966,769.752380 817.500549,742.676880 
	C818.103638,741.886475 818.807007,741.172607 820.127930,740.205505 
	C821.625610,740.012390 822.459045,740.036804 823.292480,740.061157 
	C822.343872,741.581543 821.395264,743.101929 819.900146,744.847839 
	C819.232849,745.382690 819.111938,745.691956 818.991089,746.001282 
	C815.511719,751.238159 812.032288,756.475037 808.456299,762.035400 
	C808.359680,762.358826 808.025879,762.436707 807.590454,762.662964 
	C805.725220,764.903320 804.295349,766.917419 802.865479,768.931519 
	C794.264465,778.547241 785.663330,788.162903 776.842163,797.996704 
	C769.883362,804.075256 763.275879,810.095276 756.381165,815.766052 
	C735.429199,832.998474 712.923096,847.930054 688.463440,859.683167 
	C676.807068,865.284119 664.623108,869.787231 652.318359,874.726685 
z"/>
<path fill="#C8C2C0" opacity="1.000000" stroke="none" 
	d="
M823.616760,739.991699 
	C822.459045,740.036804 821.625610,740.012390 820.375061,739.979370 
	C824.051025,733.740479 828.144165,727.510193 832.940674,721.108704 
	C834.227600,720.939941 834.811096,720.942505 835.394592,720.945068 
	C831.576721,727.270752 827.758850,733.596497 823.616760,739.991699 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M493.785248,851.763428 
	C490.422424,851.367554 486.720306,851.048035 483.297791,849.897095 
	C475.826263,847.384277 468.496918,844.448669 461.057251,841.322266 
	C466.956482,842.934387 472.896484,844.945435 478.863007,846.874512 
	C483.749878,848.454651 488.668304,849.937012 493.785248,851.763428 
z"/>
<path fill="#BBB6B2" opacity="1.000000" stroke="none" 
	d="
M835.604126,720.722534 
	C834.811096,720.942505 834.227600,720.939941 833.208740,720.910156 
	C836.036316,715.094421 839.299194,709.305908 843.118835,703.280273 
	C844.094177,703.043823 844.512878,703.044373 844.931519,703.044983 
	C841.892212,708.863281 838.852905,714.681641 835.604126,720.722534 
z"/>
<path fill="#F1F0EF" opacity="1.000000" stroke="none" 
	d="
M582.645508,862.642334 
	C583.004639,863.160645 582.924866,863.754700 582.852356,863.753784 
	C576.914612,863.682556 570.977600,863.565063 564.982849,863.092102 
	C570.686829,862.635681 576.448425,862.538025 582.645508,862.642334 
z"/>
<path fill="#F1F0EF" opacity="1.000000" stroke="none" 
	d="
M615.996399,861.046936 
	C614.572510,864.994141 609.303833,866.169434 604.190674,863.032715 
	C604.047485,862.064087 604.073181,861.705627 604.098938,861.347107 
	C604.823059,861.222839 605.547180,861.098572 606.764587,861.229614 
	C607.878357,861.250183 608.498901,861.015320 609.119446,860.780518 
	C611.112183,860.518982 613.104858,860.257446 615.531616,860.277344 
	C615.965576,860.558716 615.996399,861.046936 615.996399,861.046936 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M419.315857,820.330566 
	C412.410156,818.485596 405.873840,815.487732 401.227783,809.101990 
	C407.236938,812.498718 413.202667,816.261047 419.315857,820.330566 
z"/>
<path fill="#998C8E" opacity="1.000000" stroke="none" 
	d="
M845.223938,702.847717 
	C844.512878,703.044373 844.094177,703.043823 843.361694,703.046082 
	C844.855713,699.391846 846.722900,695.762390 848.444214,692.065002 
	C849.196655,690.448853 849.650513,688.693726 850.312378,686.640442 
	C853.139343,684.309875 853.356445,686.160522 852.548889,688.006042 
	C850.379517,692.963989 847.882996,697.778809 845.223938,702.847717 
z"/>
<path fill="#F1F0EF" opacity="1.000000" stroke="none" 
	d="
M555.684998,862.178406 
	C554.807190,863.222900 553.479736,864.959351 552.365845,864.831665 
	C549.091858,864.456482 545.902710,863.341064 542.378357,862.025757 
	C542.057861,861.364868 542.039307,861.193604 542.020752,861.022339 
	C543.709351,861.118591 545.397888,861.214844 547.625732,861.726440 
	C549.783630,862.084351 551.402344,862.026855 553.020996,861.969360 
	C553.791626,861.985718 554.562256,862.002136 555.684998,862.178406 
z"/>
<path fill="#F1F0EF" opacity="1.000000" stroke="none" 
	d="
M640.029419,857.081665 
	C637.526184,859.518616 635.022888,861.955505 632.042847,864.856567 
	C631.841736,862.204956 631.723145,860.641174 631.312988,858.673889 
	C631.022705,858.035889 631.024048,857.801270 631.025330,857.566650 
	C631.327148,857.428955 631.641968,857.385498 632.511475,857.616699 
	C633.738159,857.443298 634.423218,857.089539 635.108337,856.735718 
	C636.140015,856.509216 637.171692,856.282715 638.708984,856.288452 
	C639.443970,856.768921 639.715637,856.955933 640.029419,857.081665 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M452.368866,837.131836 
	C447.408600,835.148682 442.245483,832.890320 437.057556,830.279236 
	C442.077179,832.236572 447.121613,834.546631 452.368866,837.131836 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M510.742676,855.914246 
	C506.024170,855.184937 501.078278,854.152954 495.991882,852.816284 
	C500.739349,853.544983 505.627289,854.578247 510.742676,855.914246 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M532.756470,859.739319 
	C529.715515,859.679443 526.417297,859.341492 522.999268,858.673462 
	C526.086060,858.716003 529.292664,859.088623 532.756470,859.739319 
z"/>
<path fill="#F1F0EF" opacity="1.000000" stroke="none" 
	d="
M630.000000,859.000000 
	C629.395020,864.037415 626.816406,862.149292 624.179749,860.085938 
	C624.078979,859.298157 624.121826,859.035461 624.149719,858.770386 
	C625.485107,858.504028 626.820496,858.237732 628.670288,858.208740 
	C629.404358,858.707336 629.676147,858.891968 630.000000,859.000000 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M401.148315,808.400024 
	C398.513916,807.021790 395.787811,805.323425 393.046753,803.269897 
	C395.706696,804.636414 398.381653,806.358154 401.148315,808.400024 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M377.298859,790.400757 
	C375.035889,788.889648 372.675995,787.059021 370.480225,784.888000 
	C371.158478,784.704102 371.672607,784.860535 372.452362,785.081787 
	C374.212616,786.791443 375.707275,788.436340 377.298859,790.400757 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M430.202026,826.454346 
	C427.566376,825.406799 424.809296,824.037231 422.036224,822.302185 
	C424.707001,823.335205 427.393799,824.733704 430.202026,826.454346 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M520.775818,857.768188 
	C518.372070,857.743835 515.745605,857.416077 512.986694,856.776001 
	C515.420593,856.797302 517.986816,857.131042 520.775818,857.768188 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M392.381958,802.242920 
	C390.027954,800.914185 387.534119,799.281982 385.034119,797.295898 
	C387.432709,798.607788 389.837402,800.273621 392.381958,802.242920 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M603.678955,861.217834 
	C604.073181,861.705627 604.047485,862.064087 604.007935,862.691895 
	C601.519775,862.866028 599.045410,862.770813 596.291931,862.394775 
	C598.428223,861.772156 600.843567,861.430359 603.678955,861.217834 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M460.224396,840.401733 
	C458.291077,840.039856 456.196533,839.363342 454.053406,838.320190 
	C456.024231,838.664734 458.043701,839.375916 460.224396,840.401733 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M640.365112,857.021667 
	C639.715637,856.955933 639.443970,856.768921 639.108154,856.296509 
	C640.824707,855.505737 642.647583,854.939087 644.818970,854.509766 
	C643.678528,855.418579 642.189697,856.190125 640.365112,857.021667 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M382.327637,794.372070 
	C380.949615,793.720581 379.487579,792.741882 377.992432,791.410583 
	C379.387421,792.053589 380.815521,793.049194 382.327637,794.372070 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M552.744995,861.708557 
	C551.402344,862.026855 549.783630,862.084351 548.030884,861.844604 
	C549.420776,861.514099 550.944885,861.480896 552.744995,861.708557 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M673.747864,846.327942 
	C672.456482,847.212280 670.817993,848.002869 668.799072,848.678101 
	C670.079346,847.786621 671.740112,847.010437 673.747864,846.327942 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M435.425720,829.049866 
	C434.093994,828.820435 432.583130,828.313110 431.010193,827.447937 
	C432.380920,827.650696 433.813782,828.211365 435.425720,829.049866 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M616.388855,861.027039 
	C615.996399,861.046936 615.965576,860.558716 615.966675,860.314209 
	C617.164673,859.788086 618.361572,859.506409 619.807983,859.404297 
	C618.965454,860.058289 617.873352,860.532715 616.388855,861.027039 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M664.705811,849.105896 
	C664.186340,849.649292 663.322510,850.149353 662.255493,850.422729 
	C662.822021,849.818237 663.591736,849.440430 664.705811,849.105896 
z"/>
<path fill="#F1F0EF" opacity="1.000000" stroke="none" 
	d="
M651.002258,854.200134 
	C650.651794,854.661865 650.301331,855.123596 649.950867,855.585327 
	C649.649353,855.253357 649.347778,854.921448 649.019531,854.298340 
	C649.460510,853.703369 649.928223,853.399414 650.679443,853.383301 
	C650.975952,853.847412 650.989075,854.023804 651.002258,854.200134 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M623.880005,858.599976 
	C624.121826,859.035461 624.078979,859.298157 624.006653,859.757568 
	C623.406006,859.898743 622.820007,859.840759 621.833740,859.584412 
	C622.159058,859.067200 622.884705,858.748413 623.880005,858.599976 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M541.880371,860.809204 
	C542.039307,861.193604 542.057861,861.364868 542.109253,861.793091 
	C541.515076,861.839111 540.888062,861.628113 540.105835,861.117676 
	C540.547119,860.744202 541.143494,860.670166 541.880371,860.809204 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M651.331299,854.135986 
	C650.989075,854.023804 650.975952,853.847412 650.979004,853.406494 
	C651.517151,852.874756 652.039307,852.607605 652.850830,852.464233 
	C653.125244,852.901123 653.088135,853.211670 653.013184,853.754272 
	C652.551819,854.016663 652.106140,854.044250 651.331299,854.135986 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M681.760498,843.191895 
	C681.401001,843.758545 680.730530,844.249207 679.718994,844.596802 
	C680.068481,844.007812 680.759033,843.561829 681.760498,843.191895 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M687.734070,841.121826 
	C687.402039,841.706665 686.745728,842.237854 685.738403,842.637695 
	C686.061523,842.026855 686.735657,841.547485 687.734070,841.121826 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M634.822021,856.586792 
	C634.423218,857.089539 633.738159,857.443298 632.742676,857.684326 
	C633.133423,857.193665 633.834534,856.815796 634.822021,856.586792 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M630.239990,858.989990 
	C629.676147,858.891968 629.404358,858.707336 629.085388,858.217896 
	C629.537048,857.749634 630.088074,857.509460 630.832153,857.417969 
	C631.024048,857.801270 631.022705,858.035889 630.990906,858.620728 
	C630.960388,858.971008 630.480042,858.980042 630.239990,858.989990 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M608.852051,860.636047 
	C608.498901,861.015320 607.878357,861.250183 607.132080,861.272461 
	C607.532410,860.870483 608.058533,860.681030 608.852051,860.636047 
z"/>
<path fill="#F1F0EF" opacity="1.000000" stroke="none" 
	d="
M248.985352,715.009766 
	C251.693085,718.166870 252.213409,718.256287 256.722717,716.802734 
	C258.700806,719.014038 260.373993,720.907043 262.074158,722.775574 
	C262.934204,723.720825 262.146210,735.169495 261.134064,736.127380 
	C260.908081,736.341187 260.716553,736.591370 260.265411,737.099365 
	C261.357971,737.791626 263.082275,738.261475 263.321930,739.137512 
	C264.830811,744.652832 270.094421,745.408508 273.881348,748.068054 
	C275.227722,749.013489 276.860901,750.830811 276.838348,752.225403 
	C276.736389,758.520447 280.947632,761.177002 285.930298,764.012512 
	C285.430298,765.620483 284.872101,767.415588 284.083527,769.951660 
	C288.174347,767.602600 290.280670,769.477783 292.249146,772.385986 
	C295.387421,777.022461 298.540283,781.689392 305.551147,780.241516 
	C306.380676,780.070251 308.463409,782.742615 308.855927,784.365112 
	C309.623077,787.536194 309.329865,790.954712 310.000946,794.160889 
	C310.547455,796.771790 311.722473,798.825623 315.225739,796.624146 
	C317.938690,794.919250 319.461395,796.909790 320.998535,799.347534 
	C325.068573,805.801941 329.558380,811.991577 333.944672,818.964844 
	C334.009521,820.074707 334.010864,820.506409 334.012177,820.937988 
	C333.657349,820.901855 333.302490,820.865662 332.678650,820.807007 
	C331.507324,819.978943 330.605042,819.173279 329.499939,818.369690 
	C325.424469,815.009338 321.551788,811.646912 317.258759,807.833923 
	C316.296356,807.051147 315.754272,806.718994 315.212189,806.386841 
	C313.503723,804.568848 311.795258,802.750854 309.793091,800.836182 
	C302.713531,793.461548 295.738922,786.349121 289.179565,778.872437 
	C273.787720,761.327942 260.171997,742.494080 248.911530,721.335449 
	C248.403122,720.078735 247.903198,719.489807 247.403259,718.900879 
	C247.205475,718.602783 247.007706,718.304749 246.840118,717.345459 
	C246.879105,716.140869 246.887894,715.597351 247.245117,715.037048 
	C248.057480,715.016724 248.521408,715.013245 248.985352,715.009766 
z"/>
<path fill="#F6F6F6" opacity="1.000000" stroke="none" 
	d="
M334.113373,821.233643 
	C334.010864,820.506409 334.009521,820.074707 334.006531,819.319092 
	C335.302887,819.180298 336.600891,819.365479 337.569183,819.503601 
	C338.883972,818.329468 340.051880,817.286621 341.219757,816.243713 
	C341.601135,817.634827 342.214172,819.008972 342.317871,820.420471 
	C342.567902,823.824402 345.858673,825.631775 350.887482,825.669617 
	C353.135864,825.686462 356.549438,826.272339 357.318359,827.722168 
	C358.256775,829.491516 356.970551,832.440735 355.881897,834.954468 
	C354.367584,835.047485 353.617798,835.065796 352.868011,835.084106 
	C352.595764,834.952515 352.329041,834.810608 351.938416,834.139221 
	C350.424286,832.828369 349.039490,832.036621 347.654724,831.244873 
	C347.654694,831.244873 347.316559,831.122864 347.143280,830.661377 
	C344.536713,828.613098 342.103424,827.026367 339.670105,825.439636 
	C339.670105,825.439636 339.643280,825.364075 339.678101,825.000366 
	C338.559540,824.046875 337.406128,823.457092 336.252747,822.867310 
	C335.573364,822.421204 334.893982,821.975220 334.113373,821.233643 
z"/>
<path fill="#F6F6F6" opacity="1.000000" stroke="none" 
	d="
M364.970825,841.185242 
	C365.826141,840.150330 366.615692,838.300354 367.548401,838.225159 
	C371.125702,837.936584 374.825714,837.784485 378.333771,838.406128 
	C381.951691,839.047241 384.285248,841.188049 382.348816,845.487610 
	C381.687347,846.956238 381.497589,848.637268 380.733032,850.471741 
	C376.491974,848.874573 372.610168,847.026917 368.728363,845.179321 
	C368.728363,845.179321 368.372559,845.084229 368.184418,844.660400 
	C366.987793,843.219543 365.979309,842.202393 364.970825,841.185242 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M368.848419,845.502197 
	C372.610168,847.026917 376.491974,848.874573 380.687225,850.859863 
	C392.252625,855.866516 403.345398,861.057129 413.974091,867.634888 
	C401.804840,862.310303 389.555420,856.701172 377.389099,850.917297 
	C374.441498,849.515930 371.768005,847.537903 368.848419,845.502197 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M415.678406,868.869263 
	C419.208618,869.466370 423.026825,870.089661 426.681458,871.234863 
	C438.740601,875.013306 450.742859,878.973450 462.757782,882.891968 
	C463.511444,883.137695 464.186798,883.623474 465.019928,884.321655 
	C458.864594,883.174866 452.448334,882.131348 446.342560,880.137207 
	C436.088226,876.788086 426.031433,872.834045 415.678406,868.869263 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M467.253601,885.172791 
	C469.181793,884.950867 471.374207,884.857971 473.524414,885.116577 
	C484.214813,886.402161 494.899078,887.743530 505.567993,889.194153 
	C506.446503,889.313599 507.194031,890.396240 508.049866,891.424316 
	C498.110901,890.506042 488.111938,889.281494 478.144745,887.837524 
	C474.556641,887.317688 471.044952,886.270386 467.253601,885.172791 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M521.265625,893.123291 
	C521.507874,892.294983 522.037476,891.314392 522.565247,891.315430 
	C535.123230,891.339233 547.681091,891.464966 560.238525,891.607849 
	C560.459167,891.610291 560.674805,892.049438 560.955933,892.632080 
	C558.622253,893.290039 556.224121,893.875732 553.828857,893.864014 
	C543.069519,893.811462 532.310974,893.589294 521.265625,893.123291 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M593.309204,889.895264 
	C593.908508,888.889160 594.820618,887.430908 595.964294,887.218201 
	C603.129150,885.885742 610.345703,884.831116 618.149902,883.408813 
	C619.153259,883.112610 619.551941,883.092163 620.294678,883.059204 
	C621.094910,882.999878 621.550964,882.953186 622.044434,883.088074 
	C622.081848,883.269714 622.085205,883.640564 622.085205,883.640564 
	C615.582581,885.319336 609.100830,887.086182 602.567749,888.636658 
	C599.675049,889.323120 596.678467,889.571594 593.309204,889.895264 
z"/>
<path fill="#F1F0EF" opacity="1.000000" stroke="none" 
	d="
M619.950562,883.071655 
	C619.551941,883.092163 619.153259,883.112610 618.459717,883.167114 
	C618.126404,881.700562 617.755493,878.995911 618.104919,878.899353 
	C620.031677,878.367249 622.217468,878.080017 624.148621,878.489990 
	C625.531860,878.783630 626.654968,880.303040 627.950928,881.636108 
	C626.300964,882.528320 624.594238,883.059998 622.486389,883.616150 
	C622.085205,883.640564 622.081848,883.269714 621.997070,882.796326 
	C621.258362,882.572510 620.604431,882.822083 619.950562,883.071655 
z"/>
<path fill="#F1F0EF" opacity="1.000000" stroke="none" 
	d="
M635.969238,878.915283 
	C633.988953,877.959229 632.008667,877.003174 629.393372,875.740540 
	C630.721375,875.003540 632.586975,873.201904 633.537903,873.597717 
	C635.615173,874.462280 637.227661,876.443604 639.029785,878.334351 
	C638.697449,878.895203 638.365356,879.090393 637.378601,879.183777 
	C636.472412,879.026428 636.220825,878.970886 635.969238,878.915283 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M577.344360,891.910095 
	C581.098145,891.032593 585.281799,890.250549 589.749756,889.751831 
	C585.947449,890.692017 581.860901,891.348755 577.344360,891.910095 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M564.342224,892.917847 
	C567.405945,892.321472 570.914978,891.853394 574.710632,891.671204 
	C571.593994,892.320068 568.190796,892.683105 564.342224,892.917847 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M364.650269,840.993652 
	C365.979309,842.202393 366.987793,843.219543 368.034088,844.554077 
	C365.306335,843.245728 362.540802,841.619995 359.670593,839.668945 
	C361.153839,839.829834 362.741760,840.315979 364.650269,840.993652 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M339.750366,825.757141 
	C342.103424,827.026367 344.536713,828.613098 347.013489,830.536987 
	C344.648193,829.274353 342.239410,827.674500 339.750366,825.757141 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M510.292755,892.252502 
	C512.934509,891.996155 515.887878,891.993896 518.986084,892.333252 
	C516.288757,892.618835 513.446533,892.562683 510.292755,892.252502 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M647.003174,875.019226 
	C647.554993,875.134521 648.106812,875.249878 648.828247,875.594727 
	C646.522583,876.778442 644.047363,877.732666 641.198669,878.630005 
	C641.061890,878.127441 641.298584,877.681763 642.112305,877.082886 
	C644.127319,876.292908 645.565247,875.656067 647.003174,875.019226 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M336.283875,823.192871 
	C337.406128,823.457092 338.559540,824.046875 339.638000,825.000122 
	C338.480408,824.748596 337.397736,824.133545 336.283875,823.192871 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M347.751526,831.567871 
	C349.039490,832.036621 350.424286,832.828369 351.762024,833.996582 
	C350.426086,833.545654 349.137207,832.718323 347.751526,831.567871 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M352.993042,835.386841 
	C353.617798,835.065796 354.367584,835.047485 355.488892,835.037720 
	C355.918854,835.490479 355.977234,835.934692 356.007263,836.714355 
	C355.025269,836.596436 354.071686,836.143066 352.993042,835.386841 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M631.094849,881.541016 
	C631.522766,881.034607 632.231140,880.672180 633.234558,880.488281 
	C632.811462,881.006226 632.093445,881.345642 631.094849,881.541016 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M635.708984,879.115723 
	C636.220825,878.970886 636.472412,879.026428 637.087158,879.219360 
	C636.788269,879.827454 636.126099,880.298279 635.241089,880.643250 
	C635.161743,880.116943 635.305298,879.716553 635.708984,879.115723 
z"/>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" 
	d="
M816.946106,447.419922 
	C805.940002,447.546082 794.933960,447.672211 783.479980,447.267761 
	C782.880127,445.280121 783.188354,443.522064 782.475342,442.431885 
	C781.190186,440.466919 779.293640,438.901825 777.652466,437.169678 
	C776.729797,438.307190 775.807129,439.444702 774.903625,440.558624 
	C773.605286,440.367981 772.320068,440.179260 771.003174,439.767670 
	C770.971558,439.544800 770.942566,439.095490 770.960815,438.642944 
	C770.540222,435.142761 770.094421,432.096039 769.666443,429.046875 
	C769.510437,427.935486 768.858887,426.400574 769.348816,425.769531 
	C773.284485,420.699921 769.356384,416.734528 767.828491,411.074432 
	C766.876099,413.217957 766.439941,414.179688 766.020691,415.148743 
	C765.600037,416.121155 765.196289,417.100891 764.541809,418.655518 
	C763.496643,415.752045 762.797363,413.809448 762.098083,411.866821 
	C761.610046,412.024597 761.122009,412.182343 760.633911,412.340088 
	C760.167236,413.758636 759.700500,415.177155 759.073914,417.081726 
	C756.433044,410.569519 755.328003,409.801758 750.460022,410.626709 
	C750.460022,410.626709 750.482727,410.517944 750.623291,410.106842 
	C753.556519,405.486511 749.803772,401.824524 749.409851,397.898773 
	C749.310547,396.908752 744.914673,396.136902 742.417236,395.720123 
	C741.584412,395.581116 740.514587,396.862518 739.553101,397.494598 
	C739.553101,397.494598 739.759277,397.402100 739.803467,396.984314 
	C741.747681,391.661102 740.312683,387.066132 738.915527,381.744537 
	C737.256958,383.078308 736.192261,383.934540 736.099121,384.009430 
	C731.794434,382.013947 728.267090,380.378815 724.268677,378.525330 
	C723.776123,376.300323 723.112671,373.303345 722.505432,370.560486 
	C717.180176,369.419312 712.240723,368.360809 707.301270,367.302307 
	C707.301270,367.302307 707.109924,367.316193 707.063721,366.854553 
	C707.984375,364.939789 708.951294,363.486633 709.636292,362.457123 
	C707.350037,360.652008 705.396790,359.109802 703.797852,357.847382 
	C701.001892,358.075043 698.284973,358.296265 695.567993,358.517456 
	C695.567993,358.517487 695.527039,358.446106 695.680603,358.081360 
	C696.180908,356.307739 696.527588,354.898865 697.021912,352.890411 
	C687.981018,356.642792 689.623779,347.934906 685.997437,345.493774 
	C680.474670,346.195038 674.978821,344.015717 675.497681,340.674530 
	C676.112000,336.718201 673.177673,336.315918 671.599243,336.533203 
	C667.008484,337.165222 663.505798,334.871979 659.612183,333.439392 
	C657.630127,332.710052 655.139221,332.072876 655.080872,335.705139 
	C654.173279,335.403381 653.265625,335.101654 652.261169,334.190918 
	C651.739136,332.387421 651.660278,330.694000 650.813843,330.106598 
	C649.252258,329.022949 646.903442,328.994110 645.476929,327.809906 
	C640.525269,323.699249 635.071411,321.323853 628.370789,321.672668 
	C628.162598,322.714417 627.939819,323.828918 627.717041,324.943390 
	C626.711121,324.674805 625.705200,324.406219 624.312988,323.620087 
	C619.943054,317.847321 613.502930,320.177094 608.462219,318.161682 
	C608.137390,318.031830 607.446716,318.817322 606.926270,319.177002 
	C606.185181,319.132263 605.444153,319.087494 604.313110,318.559814 
	C600.106995,312.892944 599.073730,312.750031 596.974731,317.115784 
	C596.527161,317.117371 596.079651,317.118958 595.315918,316.600525 
	C592.998962,312.070953 591.317566,311.890839 586.916565,315.214661 
	C585.538635,315.175262 584.160645,315.135834 582.308350,314.587463 
	C579.075256,311.963257 573.933655,313.507172 572.984802,308.401001 
	C572.966431,308.302368 570.602173,308.448242 569.427307,308.798584 
	C567.417664,309.397888 565.451416,310.955719 563.522888,310.868805 
	C556.506897,310.552582 549.514465,309.716095 542.511597,309.093903 
	C541.855225,309.035614 541.181335,309.049103 540.526062,309.122803 
	C530.383728,310.263702 520.243164,311.420044 509.316528,312.662079 
	C509.344360,312.465546 509.168671,313.705170 508.992981,314.944824 
	C508.573120,315.063690 508.153259,315.182556 507.190491,314.943573 
	C505.095825,315.027405 503.544067,315.469086 501.992310,315.910767 
	C501.583984,316.057434 501.175659,316.204102 500.237946,315.996277 
	C498.004395,315.585144 496.008820,316.085052 494.648224,315.372467 
	C489.608154,312.732788 489.315308,312.793854 488.968201,318.672943 
	C488.231903,318.844086 487.495605,319.015259 486.377625,318.724548 
	C484.296387,314.484528 483.400238,314.211792 478.082672,316.210388 
	C479.845428,316.783356 481.196625,317.222565 482.397217,317.612823 
	C479.471588,319.255554 476.756104,320.780273 474.040649,322.305023 
	C474.040649,322.305023 473.655823,322.551544 473.427856,322.550781 
	C471.195465,322.340515 468.475037,321.211609 467.328857,322.104218 
	C464.553497,324.265625 462.406555,325.268829 459.017944,323.319336 
	C457.147430,322.243256 454.419922,322.754822 452.349518,321.897095 
	C450.695374,321.211792 449.450439,319.538666 447.982666,317.867218 
	C448.086151,317.248932 448.255096,317.093872 448.965485,316.954102 
	C449.984222,316.958740 450.485809,316.974457 451.292480,317.140381 
	C452.885193,317.599670 454.452240,318.540344 455.414856,318.114563 
	C461.394775,315.469666 467.265411,312.569550 473.088318,309.589996 
	C473.678741,309.287872 473.710480,307.893982 474.226196,306.980621 
	C474.450897,306.954468 474.902435,306.926331 475.305115,306.954651 
	C477.227417,307.261536 478.747040,307.540131 480.763458,307.909790 
	C480.763458,306.133453 480.763458,304.755402 480.763458,303.377380 
	C481.476959,303.080750 482.190430,302.784088 483.592224,302.641724 
	C484.853088,302.849670 485.425598,302.903412 486.241699,303.245178 
	C490.159821,302.805908 493.847900,302.134399 497.486481,301.257751 
	C497.819550,301.177490 497.836334,299.784485 498.220886,298.890503 
	C498.694611,298.377014 498.947449,297.974091 499.200256,297.571167 
	C499.589447,297.429077 499.978699,297.287018 500.774017,297.343658 
	C501.408447,297.775696 501.680603,297.933441 502.005066,298.384216 
	C502.512268,299.443268 503.010986,300.133636 503.509705,300.824036 
	C505.346191,299.230865 507.182648,297.637695 509.042725,295.791687 
	C509.056122,295.369873 509.045929,295.200867 509.035767,295.031891 
	C509.947571,294.827850 510.859406,294.623779 512.552734,294.591370 
	C517.194458,294.438934 521.054688,294.114899 525.079712,294.134033 
	C526.206177,294.860657 527.167725,295.244141 528.129272,295.627625 
	C528.312866,294.265137 528.496460,292.902618 528.680054,291.540131 
	C528.680054,291.540131 528.627808,291.414459 529.029175,291.565399 
	C530.288635,291.798950 531.146790,291.881592 532.057434,292.314819 
	C537.697266,296.576416 543.168335,295.464935 545.413940,290.009888 
	C547.539795,289.926178 549.239990,289.881805 550.956970,290.240784 
	C551.132812,291.972290 551.291870,293.300476 551.450928,294.628662 
	C551.965149,294.711090 552.479370,294.793549 552.993591,294.876007 
	C553.668274,292.726471 554.343018,290.576904 555.017761,288.427368 
	C555.449036,288.297668 555.880249,288.167969 556.916748,288.340271 
	C559.347961,288.759399 561.173950,288.876526 563.060669,289.342590 
	C564.862976,291.698608 566.219543,291.772766 567.203613,288.803528 
	C567.673096,288.299042 567.931885,287.911224 568.190674,287.523376 
	C570.783447,287.467957 573.376282,287.412537 576.497986,287.695801 
	C577.686523,288.333679 578.346130,288.632782 579.059448,289.286621 
	C580.798706,291.712708 582.144897,291.791595 583.465820,288.966583 
	C586.133728,288.747955 588.334534,288.542389 590.535339,288.336792 
	C591.146912,288.319611 591.758423,288.302399 592.863281,288.724701 
	C596.751831,289.160187 600.146973,289.156158 603.542175,289.152161 
	C603.891724,289.151123 604.241211,289.150085 604.820923,289.589844 
	C605.067322,290.302429 605.083679,290.574280 604.944824,291.183563 
	C603.731689,293.180511 602.673645,294.840027 601.362915,296.895874 
	C603.691345,297.696228 605.896790,298.454315 608.360657,299.301239 
	C608.867188,297.147552 609.189209,295.778320 609.776550,293.280792 
	C612.688477,295.699310 615.099792,297.536621 617.314087,299.586700 
	C619.634338,301.734863 622.059570,303.148010 624.520752,300.232788 
	C626.026611,298.449158 626.862976,296.100311 628.366943,294.003967 
	C630.488586,294.033264 632.243164,294.063904 633.997803,294.094543 
	C634.417603,294.116760 634.837402,294.139008 635.674500,294.580383 
	C638.396423,295.361237 640.701050,295.722931 643.005676,296.084625 
	C643.417114,296.149628 643.828491,296.214630 644.654053,296.730530 
	C649.054138,298.105225 653.039978,299.029083 657.025879,299.952911 
	C657.437805,300.024292 657.849731,300.095703 658.663696,300.603210 
	C659.731018,301.514008 660.332092,302.160736 661.071533,302.436920 
	C668.768372,305.311279 676.663818,307.729156 684.155029,311.059296 
	C693.253052,315.103851 701.981812,319.972595 710.940247,324.341492 
	C714.288025,325.974182 717.832642,327.203430 721.287476,328.616638 
	C721.287476,328.616638 721.312622,328.683319 721.254761,329.034943 
	C721.504944,330.181274 721.812988,330.975983 722.118469,332.140656 
	C724.497437,334.510986 727.371521,336.152435 729.149475,338.592590 
	C732.110596,342.656525 735.090515,343.136169 738.927368,340.214355 
	C739.282715,340.192841 739.548645,340.336700 739.890259,341.196777 
	C741.772278,342.758667 743.489075,343.769714 745.205811,344.780792 
	C745.205811,344.780792 745.017090,344.995117 745.092712,345.269867 
	C746.096008,347.849426 747.023682,350.154236 747.951416,352.459045 
	C748.137390,352.081970 748.323364,351.704926 748.509399,351.327850 
	C750.169983,352.029266 751.808838,352.789795 753.498230,353.413147 
	C754.812073,353.897949 756.189087,354.211731 757.538025,354.601501 
	C757.538025,354.601501 757.828735,354.701996 757.901978,355.196411 
	C759.769836,356.971527 761.564453,358.252289 763.359131,359.533020 
	C763.359131,359.533020 763.385986,359.616516 763.305298,359.985413 
	C765.556763,362.159088 767.888855,363.963837 770.220947,365.768616 
	C770.220947,365.768585 770.013245,365.977325 770.041321,366.278992 
	C770.050293,367.389862 770.031128,368.199097 769.891174,369.064728 
	C769.844177,369.076782 769.917908,369.032440 770.337524,369.023041 
	C772.947693,371.351837 775.212036,373.645660 777.914185,376.383057 
	C778.061401,376.342163 779.146973,376.040405 780.232544,375.738678 
	C780.232544,375.738678 780.020447,375.945221 780.075684,376.215057 
	C781.612305,378.136414 780.951050,382.161194 785.043762,380.920349 
	C786.080994,382.031097 787.118225,383.141846 787.972534,384.732910 
	C787.131470,391.874939 794.240051,394.654755 795.423645,400.408020 
	C795.436401,400.470123 796.015259,400.415741 797.008667,400.415741 
	C797.008667,398.628998 797.008667,396.877289 797.008667,395.125580 
	C797.629517,395.862335 798.250427,396.599060 798.946289,397.946533 
	C799.648621,399.060577 800.276001,399.563873 800.903381,400.067200 
	C800.903381,400.067200 801.111389,400.363037 801.128540,400.880157 
	C801.778564,401.924377 802.411438,402.451477 803.044312,402.978577 
	C804.045349,404.335876 805.046326,405.693176 806.104126,407.704895 
	C807.444946,409.908752 808.728882,411.458130 810.012878,413.007507 
	C810.012878,413.007507 810.216064,413.296936 810.184998,413.827759 
	C811.400879,415.928497 812.647766,417.498413 813.894714,419.068329 
	C814.349365,419.706573 814.803955,420.344788 815.247742,421.604187 
	C815.813843,422.828247 816.390808,423.431152 816.967712,424.034088 
	C817.720398,425.317932 818.473083,426.601807 819.051758,428.531616 
	C818.372375,429.958313 817.421143,430.746063 817.433289,431.518707 
	C817.516785,436.830231 818.693237,442.161926 816.946106,447.419922 
M582.895020,309.806244 
	C582.699829,306.599152 582.535034,306.531311 577.225708,308.446289 
	C579.548096,309.153839 580.861450,309.553955 582.895020,309.806244 
M514.550659,305.908203 
	C515.220825,305.465729 515.890991,305.023224 516.561157,304.580750 
	C515.813293,304.117889 515.065430,303.655029 514.317566,303.192169 
	C514.167969,304.031708 514.018372,304.871216 514.550659,305.908203 
M507.537598,309.662170 
	C507.562927,308.913452 507.588257,308.164764 507.613556,307.416016 
	C506.905548,307.528656 506.197510,307.641266 505.489502,307.753906 
	C505.973328,308.312042 506.457123,308.870178 507.537598,309.662170 
M473.065369,313.482025 
	C473.542664,313.299652 474.019958,313.117279 474.497223,312.934875 
	C473.842407,312.647156 473.187561,312.359467 472.532745,312.071777 
	C472.537018,312.452332 472.541290,312.832916 473.065369,313.482025 
M774.269043,435.096710 
	C774.767883,435.041840 775.266724,434.987000 775.765503,434.932129 
	C775.650696,434.485199 775.535889,434.038269 775.421082,433.591309 
	C774.995239,433.893768 774.569397,434.196228 774.269043,435.096710 
M464.314941,319.473541 
	C463.975952,320.021484 463.636932,320.569397 463.297943,321.117340 
	C463.572479,321.252502 463.846985,321.387665 464.121490,321.522827 
	C464.326569,320.986237 464.531647,320.449677 464.314941,319.473541 
M615.392822,317.201996 
	C615.786133,317.206299 616.179504,317.210571 616.572815,317.214874 
	C616.574341,317.051666 616.586914,316.746613 616.575867,316.745758 
	C616.184387,316.715454 615.790649,316.713684 615.392822,317.201996 
M496.587891,313.517151 
	C496.587891,313.517151 496.475708,313.583832 496.587891,313.517151 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M707.396973,367.647980 
	C712.240723,368.360809 717.180176,369.419312 722.505432,370.560486 
	C723.112671,373.303345 723.776123,376.300323 724.268677,378.525330 
	C728.267090,380.378815 731.794434,382.013947 736.099121,384.009430 
	C736.192261,383.934540 737.256958,383.078308 738.915527,381.744537 
	C740.312683,387.066132 741.747681,391.661102 739.691284,396.997253 
	C737.012085,395.238678 734.489319,393.049438 731.646118,390.764740 
	C724.459473,384.110352 717.595154,377.549408 710.725037,370.994629 
	C709.661377,369.979858 708.570801,368.993286 707.396973,367.647980 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M655.261658,335.939880 
	C655.139221,332.072876 657.630127,332.710052 659.612183,333.439392 
	C663.505798,334.871979 667.008484,337.165222 671.599243,336.533203 
	C673.177673,336.315918 676.112000,336.718201 675.497681,340.674530 
	C674.978821,344.015717 680.474670,346.195038 685.997437,345.493774 
	C689.623779,347.934906 687.981018,356.642792 697.021912,352.890411 
	C696.527588,354.898865 696.180908,356.307739 695.649048,358.107727 
	C691.090820,355.953949 686.760559,353.331696 682.335632,350.880371 
	C673.398438,345.929321 664.409851,341.071106 655.261658,335.939880 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M509.423859,315.031311 
	C509.168671,313.705170 509.344360,312.465546 509.316528,312.662079 
	C520.243164,311.420044 530.383728,310.263702 540.526062,309.122803 
	C541.181335,309.049103 541.855225,309.035614 542.511597,309.093903 
	C549.514465,309.716095 556.506897,310.552582 563.522888,310.868805 
	C565.451416,310.955719 567.417664,309.397888 569.427307,308.798584 
	C570.602173,308.448242 572.966431,308.302368 572.984802,308.401001 
	C573.933655,313.507172 579.075256,311.963257 581.922729,314.432648 
	C571.407654,314.154480 560.798096,312.934113 550.201416,313.037781 
	C536.747009,313.169403 523.303162,314.375214 509.423859,315.031311 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M627.879395,325.216125 
	C627.939819,323.828918 628.162598,322.714417 628.370789,321.672668 
	C635.071411,321.323853 640.525269,323.699249 645.476929,327.809906 
	C646.903442,328.994110 649.252258,329.022949 650.813843,330.106598 
	C651.660278,330.694000 651.739136,332.387421 652.004517,333.936340 
	C643.910400,331.356812 635.976135,328.422821 627.879395,325.216125 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M695.643799,358.835632 
	C698.284973,358.296265 701.001892,358.075043 703.797852,357.847382 
	C705.396790,359.109802 707.350037,360.652008 709.636292,362.457123 
	C708.951294,363.486633 707.984375,364.939789 706.995361,366.787292 
	C703.221985,364.505737 699.470825,361.829803 695.643799,358.835632 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M474.450623,322.343201 
	C476.756104,320.780273 479.471588,319.255554 482.397217,317.612823 
	C481.196625,317.222565 479.845428,316.783356 478.082672,316.210388 
	C483.400238,314.211792 484.296387,314.484528 485.997742,318.666504 
	C482.286560,320.173981 478.573578,321.277679 474.450623,322.343201 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M607.167603,319.455322 
	C607.446716,318.817322 608.137390,318.031830 608.462219,318.161682 
	C613.502930,320.177094 619.943054,317.847321 624.028564,323.500122 
	C618.556580,322.509705 612.982727,321.121643 607.167603,319.455322 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M489.354919,318.726501 
	C489.315308,312.793854 489.608154,312.732788 494.648224,315.372467 
	C496.008820,316.085052 498.004395,315.585144 499.911194,315.955200 
	C496.656464,317.105743 493.199066,317.942902 489.354919,318.726501 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M587.194580,315.465057 
	C591.317566,311.890839 592.998962,312.070953 595.007935,316.457153 
	C592.501709,316.461060 589.987183,316.088257 587.194580,315.465057 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M597.229675,317.373047 
	C599.073730,312.750031 600.106995,312.892944 603.971680,318.408142 
	C601.841736,318.369659 599.663147,317.999969 597.229675,317.373047 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M348.318420,399.511932 
	C348.882263,398.372345 349.446136,397.232758 350.318420,395.797058 
	C350.626801,395.500977 351.040833,395.519318 351.040833,395.519318 
	C352.179901,395.688019 353.318939,395.856720 354.809662,396.058533 
	C354.334045,397.353760 353.506805,398.615906 352.571716,400.179504 
	C351.939850,400.734955 351.415833,400.988953 350.345825,400.916168 
	C349.306030,400.230255 348.812225,399.871094 348.318420,399.511932 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M502.364197,316.009247 
	C503.544067,315.469086 505.095825,315.027405 506.864197,314.890778 
	C505.632599,315.499817 504.184326,315.803772 502.364197,316.009247 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M380.389984,372.092346 
	C380.624420,371.489716 381.170502,370.828339 382.089600,370.224976 
	C381.875580,370.866486 381.288605,371.450043 380.389984,372.092346 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M721.249634,328.351868 
	C717.832642,327.203430 714.288025,325.974182 710.940247,324.341492 
	C701.981812,319.972595 693.253052,315.103851 684.155029,311.059296 
	C676.663818,307.729156 668.768372,305.311279 661.071533,302.436920 
	C660.332092,302.160736 659.731018,301.514008 658.953125,300.724579 
	C668.636902,303.820099 678.675354,306.672119 688.165466,310.788452 
	C698.911377,315.449493 709.165344,321.244385 719.947632,326.676758 
	C720.578674,327.233887 720.895264,327.660492 721.249634,328.351868 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M474.001526,307.006775 
	C473.710480,307.893982 473.678741,309.287872 473.088318,309.589996 
	C467.265411,312.569550 461.394775,315.469666 455.414856,318.114563 
	C454.452240,318.540344 452.885193,317.599670 451.408234,316.895325 
	C451.492340,316.037170 451.765808,315.574310 452.039246,315.111450 
	C452.346100,314.956177 452.652954,314.800903 453.564056,314.715210 
	C454.797821,314.230835 455.427368,313.676880 456.056885,313.122925 
	C456.364655,312.968414 456.672394,312.813904 457.568787,312.731934 
	C458.806274,312.294189 459.455139,311.783905 460.103973,311.273621 
	C464.158417,309.560913 468.212860,307.848206 472.724731,306.316772 
	C473.409637,306.741119 473.682770,306.910675 474.001526,307.006775 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M633.745728,293.808472 
	C632.243164,294.063904 630.488586,294.033264 627.963257,293.923828 
	C619.828430,292.845398 612.464172,291.845764 605.099976,290.846100 
	C605.083679,290.574280 605.067322,290.302429 605.081238,289.622192 
	C614.572205,290.649963 624.032959,292.086182 633.745728,293.808472 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M544.988220,290.049164 
	C543.168335,295.464935 537.697266,296.576416 532.454468,292.249756 
	C535.893799,290.951172 538.988586,290.068298 542.083374,289.185364 
	C542.805664,289.106659 543.527954,289.027924 544.614319,289.227936 
	C544.981689,289.687500 544.984985,289.868347 544.988220,290.049164 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M498.000000,299.001099 
	C497.836334,299.784485 497.819550,301.177490 497.486481,301.257751 
	C493.847900,302.134399 490.159821,302.805908 486.432800,303.003082 
	C487.294037,301.767151 488.207703,301.061340 489.121368,300.355530 
	C490.006042,300.101349 490.890717,299.847137 492.429810,299.675873 
	C493.770142,299.356384 494.456024,298.953979 495.141907,298.551575 
	C495.549438,298.421539 495.957001,298.291534 496.787018,298.348022 
	C497.437042,298.750977 497.700562,298.906494 498.000000,299.001099 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M524.914856,293.790863 
	C521.054688,294.114899 517.194458,294.438934 512.902893,294.569885 
	C517.116089,293.281433 521.760742,292.186066 526.641113,291.566986 
	C526.222839,292.625824 525.568848,293.208344 524.914856,293.790863 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M590.267334,288.056580 
	C588.334534,288.542389 586.133728,288.747955 583.066895,288.947876 
	C581.135803,288.938782 580.070740,288.935333 579.005737,288.931885 
	C578.346130,288.632782 577.686523,288.333679 576.923828,287.773132 
	C581.213623,287.599976 585.606506,287.688171 590.267334,288.056580 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M656.832947,299.644226 
	C653.039978,299.029083 649.054138,298.105225 644.938110,296.864319 
	C648.751953,297.476685 652.695984,298.406128 656.832947,299.644226 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M541.649109,289.081512 
	C538.988586,290.068298 535.893799,290.951172 532.401978,291.899139 
	C531.146790,291.881592 530.288635,291.798950 529.051514,291.501160 
	C532.853333,290.516510 537.034058,289.747070 541.649109,289.081512 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M509.019104,296.044495 
	C507.182648,297.637695 505.346191,299.230865 503.509705,300.824036 
	C503.010986,300.133636 502.512268,299.443268 502.222656,298.242218 
	C502.666260,297.285950 502.900757,296.840332 503.135254,296.394684 
	C503.544769,296.290009 503.954254,296.185303 504.872559,296.330902 
	C506.593964,296.402283 507.806519,296.223389 509.019104,296.044495 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M554.653320,288.274902 
	C554.343018,290.576904 553.668274,292.726471 552.993591,294.876007 
	C552.479370,294.793549 551.965149,294.711090 551.450928,294.628662 
	C551.291870,293.300476 551.132812,291.972290 550.987915,290.001587 
	C551.098755,289.046539 551.151672,288.726746 551.160706,288.399689 
	C552.203430,288.307281 553.246094,288.214874 554.653320,288.274902 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M563.000000,288.993652 
	C561.173950,288.876526 559.347961,288.759399 557.259033,288.362427 
	C560.434265,287.781219 563.872253,287.479919 567.750488,287.351013 
	C567.931885,287.911224 567.673096,288.299042 566.804260,288.799347 
	C565.129456,288.939117 564.064758,288.966370 563.000000,288.993652 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M603.251221,288.925201 
	C600.146973,289.156158 596.751831,289.160187 593.167847,288.758301 
	C596.306213,288.467682 599.633240,288.582947 603.251221,288.925201 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M480.394775,303.382324 
	C480.763458,304.755402 480.763458,306.133453 480.763458,307.909790 
	C478.747040,307.540131 477.227417,307.261536 475.447021,306.694092 
	C475.424347,305.869965 475.662445,305.334686 475.900543,304.799377 
	C477.275726,304.328674 478.650940,303.857941 480.394775,303.382324 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M642.789307,295.781006 
	C640.701050,295.722931 638.396423,295.361237 635.973755,294.684570 
	C638.094788,294.738831 640.333801,295.108124 642.789307,295.781006 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M394.739197,351.207825 
	C393.330811,352.763153 391.593353,354.392090 389.570496,355.947937 
	C390.993469,354.343689 392.701813,352.812561 394.739197,351.207825 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M525.079712,294.134033 
	C525.568848,293.208344 526.222839,292.625824 526.975342,291.723053 
	C527.440430,291.381531 527.806946,291.360260 528.426758,291.439545 
	C528.496460,292.902618 528.312866,294.265137 528.129272,295.627625 
	C527.167725,295.244141 526.206177,294.860657 525.079712,294.134033 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M550.768555,288.262665 
	C551.151672,288.726746 551.098755,289.046539 550.971130,289.598267 
	C549.239990,289.881805 547.539795,289.926178 545.413940,290.009888 
	C544.984985,289.868347 544.981689,289.687500 544.978882,289.235443 
	C546.778381,288.684692 548.577454,288.405151 550.768555,288.262665 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M488.771606,300.282043 
	C488.207703,301.061340 487.294037,301.767151 486.189270,302.715057 
	C485.425598,302.903412 484.853088,302.849670 483.886841,302.610504 
	C485.136047,301.686249 486.778961,300.947418 488.771606,300.282043 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M509.042725,295.791687 
	C507.806519,296.223389 506.593964,296.402283 505.191345,296.339325 
	C506.100555,295.688995 507.199829,295.280518 508.667419,294.951965 
	C509.045929,295.200867 509.056122,295.369873 509.042725,295.791687 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M451.697205,315.068848 
	C451.765808,315.574310 451.492340,316.037170 451.103149,316.745117 
	C450.485809,316.974457 449.984222,316.958740 449.113373,316.862122 
	C449.614441,316.196228 450.484802,315.611237 451.697205,315.068848 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M475.559998,304.789307 
	C475.662445,305.334686 475.424347,305.869965 475.044342,306.665802 
	C474.902435,306.926331 474.450897,306.954468 474.226196,306.980621 
	C473.682770,306.910675 473.409637,306.741119 473.090332,306.273102 
	C473.738831,305.625183 474.479126,305.202209 475.559998,304.789307 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M439.705353,321.082062 
	C439.438293,321.693817 438.846375,322.280060 437.901031,322.799011 
	C438.158569,322.173370 438.769531,321.614960 439.705353,321.082062 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M442.821259,319.349426 
	C442.535065,319.912720 441.936859,320.397766 441.019867,320.838837 
	C441.303833,320.286987 441.906525,319.779114 442.821259,319.349426 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M455.732391,313.069763 
	C455.427368,313.676880 454.797821,314.230835 453.818787,314.668030 
	C454.115509,314.039734 454.761688,313.528168 455.732391,313.069763 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M459.789917,311.217255 
	C459.455139,311.783905 458.806274,312.294189 457.822083,312.679077 
	C458.149811,312.089386 458.812836,311.625153 459.789917,311.217255 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M402.706848,345.078979 
	C402.480103,345.686127 401.930573,346.296112 401.033356,346.865936 
	C401.251831,346.244476 401.817932,345.663177 402.706848,345.078979 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M410.749573,339.208984 
	C410.525879,339.804596 409.985718,340.369629 409.116577,340.910339 
	C409.336090,340.316833 409.884613,339.747589 410.749573,339.208984 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M413.687958,337.026245 
	C413.476196,337.651428 412.937103,338.277527 412.048187,338.869568 
	C412.252441,338.232697 412.806549,337.629974 413.687958,337.026245 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M422.738190,331.166168 
	C422.452118,331.744446 421.846832,332.308533 420.891724,332.801819 
	C421.167572,332.204681 421.793274,331.678345 422.738190,331.166168 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M502.831848,296.292480 
	C502.900757,296.840332 502.666260,297.285950 502.214172,297.873535 
	C501.680603,297.933441 501.408447,297.775696 501.085693,297.323914 
	C501.503693,296.800415 502.016052,296.495361 502.831848,296.292480 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M494.848328,298.430298 
	C494.456024,298.953979 493.770142,299.356384 492.771484,299.620605 
	C493.157379,299.091309 493.856079,298.700165 494.848328,298.430298 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M498.220886,298.890503 
	C497.700562,298.906494 497.437042,298.750977 497.109192,298.326843 
	C497.541260,297.845428 498.073669,297.571716 498.903168,297.434570 
	C498.947449,297.974091 498.694611,298.377014 498.220886,298.890503 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M415.812683,335.383301 
	C415.786255,335.845062 415.469025,336.309998 414.848816,336.780487 
	C414.871216,336.319519 415.196564,335.852997 415.812683,335.383301 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M769.849243,758.852905 
	C770.102295,759.502747 770.074768,759.931702 770.026489,760.683105 
	C768.871155,761.570068 767.736694,762.134460 766.332764,762.904785 
	C762.928955,759.361633 759.154480,760.689819 755.731567,762.215454 
	C751.147461,764.258789 746.895630,765.167542 742.410522,760.496338 
	C739.813416,767.269348 741.826050,773.191956 742.419128,779.019592 
	C738.294861,778.671143 733.854736,778.831116 729.706299,777.808167 
	C724.676331,776.567871 724.830811,779.364502 725.026428,782.579712 
	C725.435059,789.295715 719.068298,794.897095 712.373413,793.646545 
	C704.193176,792.118469 703.538574,792.453125 700.864014,800.131042 
	C700.451904,798.894714 700.361938,798.138977 699.981079,797.582275 
	C699.164612,796.388855 698.197937,795.298157 697.292847,794.165405 
	C696.529358,795.271973 695.691101,796.334961 695.015686,797.492920 
	C692.678528,801.500366 690.431580,805.561401 688.011414,809.517029 
	C687.868774,809.750122 686.504456,809.235840 685.003052,808.880859 
	C677.036377,808.220581 669.775146,807.748779 662.091858,807.249573 
	C662.091858,805.646118 661.407715,803.387390 662.216919,802.516968 
	C664.917908,799.611755 667.656555,796.838501 669.000000,793.000000 
	C676.748840,792.973816 677.682312,792.312988 680.134460,785.376160 
	C680.390747,784.651245 680.887695,783.710510 681.509583,783.461792 
	C688.510498,780.662109 695.005798,776.093079 703.106445,777.007141 
	C707.197693,777.468933 709.413147,776.243713 709.103333,771.575684 
	C708.931824,768.990479 709.594849,766.321594 710.080627,763.727722 
	C710.823547,759.760498 714.599304,758.959656 720.393738,761.507446 
	C721.234253,761.877075 721.965332,762.495728 723.347961,763.386108 
	C723.999512,761.438721 724.772095,759.990479 724.941528,758.474670 
	C725.695190,751.732056 729.196838,747.420898 735.975159,747.212036 
	C741.177917,747.051636 741.794495,745.445190 741.159180,740.739685 
	C740.266907,734.131348 741.865356,732.843994 748.643738,731.956848 
	C752.541077,731.446777 755.060425,729.445435 755.005127,725.127808 
	C754.973572,722.660889 754.999451,720.193298 754.999451,717.946289 
	C760.205200,717.013611 764.912354,716.170227 769.851318,715.285339 
	C768.676941,709.261597 768.161560,703.593079 771.900940,698.111572 
	C773.273743,696.099243 772.717285,692.770874 773.154541,689.084473 
	C776.785278,687.882996 781.390015,686.504639 785.862854,684.783447 
	C787.082520,684.314087 787.905334,682.813110 788.958130,681.419800 
	C789.448364,681.041077 789.890747,681.028076 790.664795,681.033875 
	C792.298828,681.701721 793.589294,682.876343 794.905823,682.906250 
	C799.873962,683.019226 804.851135,682.777222 809.823975,682.635498 
	C814.546875,682.500977 816.190918,684.952637 814.926575,689.351440 
	C813.652100,693.785583 812.987854,698.395020 812.072510,702.848328 
	C808.928955,702.480713 806.863953,702.239197 804.380737,701.948792 
	C802.938416,707.721680 801.598450,713.085022 800.120422,719.000671 
	C797.859741,719.000671 795.404846,718.942871 792.953552,719.011902 
	C786.641724,719.189636 783.993958,723.852051 786.005371,729.835388 
	C786.641113,731.726501 785.658569,735.688904 784.238708,736.421814 
	C781.392395,737.891174 780.996155,739.634399 780.954651,742.304260 
	C780.881287,747.025635 779.327454,748.081665 774.289368,747.238831 
	C769.828308,746.492493 768.512390,747.934998 769.247498,752.431580 
	C769.579346,754.461609 769.475098,756.562927 769.849243,758.852905 
z"/>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" 
	d="
M669.005371,792.623657 
	C667.656555,796.838501 664.917908,799.611755 662.216919,802.516968 
	C661.407715,803.387390 662.091858,805.646118 662.091858,807.249573 
	C669.775146,807.748779 677.036377,808.220581 684.648804,808.846191 
	C684.100220,809.442200 682.672913,810.487427 682.382385,810.233337 
	C679.502869,807.713989 679.150574,810.469116 677.307190,811.813232 
	C671.256042,816.225647 664.583740,819.794434 658.064819,823.538818 
	C657.621643,823.793457 656.472900,822.819885 655.492493,822.339111 
	C654.956482,823.839539 654.513306,825.080139 654.036072,826.675659 
	C652.580688,827.370239 651.159424,827.709900 649.367493,828.040771 
	C648.748962,827.219421 648.501038,826.406982 648.502563,825.289429 
	C649.773987,824.062317 651.428284,823.294250 651.697205,822.188965 
	C652.453613,819.080444 654.519348,818.859863 657.357178,818.999390 
	C658.659729,818.635498 659.605103,818.272217 660.550476,817.908936 
	C660.033630,817.605957 659.516846,817.302979 659.045776,816.611328 
	C660.059509,813.348938 661.311218,810.277100 656.925354,809.180786 
	C653.326660,808.281311 649.644958,807.714294 646.000488,806.610352 
	C647.183105,804.074707 648.365234,801.928589 649.979858,798.997253 
	C649.921814,798.819885 649.445679,797.364868 649.184814,795.663208 
	C650.267578,794.602722 651.135193,793.788818 652.002808,792.974976 
	C652.002869,792.974976 652.400208,792.685913 652.645813,792.794067 
	C652.891418,792.902283 653.039368,793.254700 653.200134,793.509094 
	C653.641907,795.413452 653.922913,797.063354 654.282104,799.172546 
	C656.182007,798.476746 658.399780,796.781433 660.008789,797.236938 
	C665.337891,798.745361 665.033447,795.598938 665.062195,792.192322 
	C665.071594,791.076904 665.637817,789.966187 666.091736,788.575073 
	C667.113037,787.837463 667.992065,787.378113 668.871094,786.918823 
	C668.917603,788.695007 668.964172,790.471191 669.005371,792.623657 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M669.053650,786.624390 
	C667.992065,787.378113 667.113037,787.837463 665.708923,788.610107 
	C661.135620,790.367126 657.087524,791.810913 653.039368,793.254700 
	C653.039368,793.254700 652.891418,792.902283 652.877075,792.710938 
	C654.230103,791.596252 655.597473,790.672791 657.173218,789.485596 
	C659.448181,788.401123 661.514893,787.580322 664.145874,786.437744 
	C664.982544,785.792114 665.254944,785.468323 665.527344,785.144470 
	C673.513123,780.554504 681.498962,775.964600 690.043701,771.174438 
	C690.986938,770.901611 691.371155,770.828857 691.868652,770.872192 
	C691.981934,770.988342 692.203674,771.225159 692.203674,771.225159 
	C688.119873,774.172058 684.120239,777.245911 679.925903,780.026184 
	C676.482788,782.308655 672.808167,784.241943 669.053650,786.624390 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M745.409302,723.002991 
	C748.354980,718.596191 751.665222,714.161621 755.290894,709.599792 
	C754.133789,715.287781 752.656433,721.099548 745.409302,723.002991 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M703.305664,762.968262 
	C706.034058,760.280029 709.151428,757.633057 712.616089,754.955383 
	C711.508972,759.494202 708.199585,761.937134 703.305664,762.968262 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M714.331787,754.005859 
	C716.069641,751.995972 718.187744,750.017456 720.642822,747.996582 
	C718.890503,749.981873 716.801270,752.009521 714.331787,754.005859 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M728.004150,741.655518 
	C728.885742,740.087219 730.132080,738.625000 731.704224,737.102844 
	C730.809631,738.615723 729.589294,740.188599 728.004150,741.655518 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M739.608337,727.992310 
	C740.762695,726.926697 741.917053,725.861084 743.380127,724.667175 
	C742.662292,726.006958 741.635803,727.475037 740.187256,728.702271 
	C739.765320,728.461426 739.608337,727.992310 739.608337,727.992310 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M735.443787,734.045959 
	C735.299866,733.524597 735.532837,733.008789 736.130981,732.174927 
	C736.788696,731.652161 736.942505,731.371582 736.957703,731.014893 
	C737.338318,730.879272 737.718933,730.743652 738.399414,730.567505 
	C737.739685,731.701721 736.780151,732.876648 735.443787,734.045959 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M699.885559,765.753418 
	C700.420288,764.758850 701.329651,763.844543 702.584717,762.922485 
	C702.040344,763.887695 701.150330,764.860718 699.885559,765.753418 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M722.963745,746.790466 
	C723.369690,745.745117 724.159241,744.753723 725.262207,743.701172 
	C724.832886,744.708130 724.090088,745.776306 722.963745,746.790466 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M691.718201,769.933105 
	C692.550476,769.573425 693.382751,769.213684 694.556396,768.833008 
	C694.224915,769.565002 693.552063,770.317993 692.541443,771.148071 
	C692.203674,771.225159 691.981934,770.988342 691.885742,770.665405 
	C691.789551,770.342529 691.718201,769.933105 691.718201,769.933105 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M726.025208,743.675293 
	C726.114685,742.991882 726.566223,742.406067 727.288635,741.737305 
	C727.168762,742.360535 726.778015,743.066650 726.025208,743.675293 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M696.910461,767.767212 
	C697.149902,767.120422 697.748413,766.536926 698.678528,765.970459 
	C698.429932,766.601868 697.849731,767.216187 696.910461,767.767212 
z"/>
<path fill="#69527B" opacity="1.000000" stroke="none" 
	d="
M739.382324,728.026306 
	C739.608337,727.992310 739.765320,728.461426 739.820312,728.702515 
	C739.551453,729.148682 739.227478,729.353638 738.632202,729.657104 
	C738.625977,729.190491 738.891174,728.625427 739.382324,728.026306 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M376.792542,367.776672 
	C376.402466,367.705505 376.347351,367.312622 376.608337,366.940247 
	C376.929932,366.375641 376.990601,366.183441 377.051270,365.991211 
	C377.856537,365.395569 378.661804,364.799896 379.767365,364.171814 
	C379.105957,365.375519 378.144287,366.611664 376.792542,367.776672 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M366.031860,378.899445 
	C366.208649,378.083374 366.700165,377.306580 367.525360,376.400024 
	C367.354919,377.159729 366.850739,378.049255 366.031860,378.899445 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M381.993469,362.852570 
	C381.719482,362.463867 381.810364,362.110840 382.307678,361.452484 
	C383.120819,361.060150 383.527588,360.973083 383.934326,360.886047 
	C383.409027,361.553436 382.883698,362.220825 381.993469,362.852570 
z"/>
<path fill="#786A7E" opacity="1.000000" stroke="none" 
	d="
M353.644196,391.489655 
	C353.778473,390.814178 354.241486,390.035522 355.070740,389.209778 
	C354.948975,389.903931 354.460938,390.645233 353.644196,391.489655 
z"/>
<path fill="#786A7E" opacity="1.000000" stroke="none" 
	d="
M383.949951,360.645142 
	C383.527588,360.973083 383.120819,361.060150 382.404144,361.194519 
	C382.483887,360.823395 382.873566,360.404938 383.566467,359.958740 
	C383.869751,359.930969 383.965576,360.404205 383.949951,360.645142 
z"/>
<path fill="#786A7E" opacity="1.000000" stroke="none" 
	d="
M360.359558,383.969116 
	C360.337189,383.476990 360.622284,382.896057 361.257080,382.282410 
	C361.293549,382.793274 360.980255,383.336792 360.359558,383.969116 
z"/>
<path fill="#786A7E" opacity="1.000000" stroke="none" 
	d="
M351.224304,395.219055 
	C351.040833,395.519318 350.626801,395.500977 350.419586,395.495544 
	C350.430206,395.103790 350.648010,394.717468 351.213043,394.281433 
	C351.509399,394.460754 351.458588,394.689758 351.224304,395.219055 
z"/>
<path fill="#786A7E" opacity="1.000000" stroke="none" 
	d="
M352.421265,393.205811 
	C352.287201,392.967896 352.428711,392.609650 352.843628,392.128754 
	C352.976929,392.365875 352.836884,392.725677 352.421265,393.205811 
z"/>
<path fill="#786A7E" opacity="1.000000" stroke="none" 
	d="
M369.689514,373.551941 
	C369.565796,373.250854 369.748199,372.830536 370.298706,372.374451 
	C370.443115,372.703369 370.219391,373.068024 369.689514,373.551941 
z"/>
<path fill="#F89B10" opacity="1.000000" stroke="none" 
	d="
M809.528564,291.933228 
	C809.999390,291.506775 810.205505,291.328003 810.543823,290.990143 
	C813.368774,288.192963 816.044495,285.535309 818.906372,282.630707 
	C824.737305,276.173523 830.382080,269.963287 836.143311,263.432251 
	C837.077637,260.785004 837.945862,258.474609 838.692871,256.125641 
	C839.088989,254.880035 839.274658,253.567459 839.813660,251.104691 
	C843.962097,254.820068 847.287659,257.715912 850.491211,260.740936 
	C851.093201,261.309418 851.722656,262.790833 851.440308,263.156281 
	C850.865662,263.899902 849.688904,264.193939 848.740356,264.629974 
	C846.342407,265.732269 843.322205,266.227570 841.633362,267.998169 
	C833.824707,276.184723 826.550659,284.879944 818.772949,293.097473 
	C807.988525,304.491669 793.438354,304.621796 782.058350,293.877838 
	C774.566223,286.804535 769.835999,278.731964 770.729553,267.910980 
	C771.243225,261.691101 774.122559,256.881256 778.028198,252.434052 
	C784.727722,244.805527 791.634216,237.349899 798.083008,229.516434 
	C799.619934,227.649536 799.677856,224.572906 800.446960,222.062149 
	C800.760132,221.039963 800.918030,219.792389 801.610046,219.136383 
	C801.975830,218.789658 803.572266,219.218033 804.212097,219.765259 
	C810.156250,224.848984 816.048340,229.995728 821.849426,235.241562 
	C822.554565,235.879242 823.144531,237.405182 822.854797,238.162521 
	C822.579651,238.881699 821.100952,239.184341 820.114929,239.578766 
	C817.944397,240.447067 815.093811,240.652405 813.675476,242.192032 
	C805.672668,250.878952 797.722595,259.647949 790.318176,268.842926 
	C783.432434,277.393768 784.848022,287.715088 792.943298,292.859070 
	C798.505615,296.393524 803.974121,295.877014 809.528564,291.933228 
z"/>
<path fill="#FE9803" opacity="1.000000" stroke="none" 
	d="
M767.112061,199.449646 
	C766.635010,199.348358 766.158020,199.247055 765.171753,198.716537 
	C760.286987,196.475784 756.257202,198.335388 755.363708,202.578400 
	C755.229309,203.181000 755.094910,203.783600 754.496155,204.783630 
	C752.126831,210.288116 754.686035,218.546021 759.230347,221.955338 
	C759.230347,221.955338 759.397766,222.173157 759.288330,222.672531 
	C759.762329,223.792603 760.345764,224.413300 760.929199,225.033997 
	C761.252808,225.499039 761.576477,225.964081 761.669312,226.931931 
	C762.556580,229.303925 763.674744,231.173096 764.792847,233.042282 
	C765.010864,233.629318 765.228943,234.216339 765.311768,235.572266 
	C767.384338,243.304214 764.875793,249.045700 760.035217,253.768387 
	C756.049866,257.656647 751.554138,260.880035 745.270203,259.326233 
	C741.458923,258.383820 737.532959,257.904999 733.658508,257.217896 
	C733.658508,257.217896 733.290955,257.142303 732.983276,256.794739 
	C732.145630,256.291718 731.615601,256.136261 731.085571,255.980820 
	C731.085571,255.980820 730.836365,255.839645 730.767334,255.321991 
	C729.438477,254.220261 728.178650,253.636185 726.918884,253.052109 
	C726.604065,253.013763 726.332764,252.884201 726.019836,252.072662 
	C726.321716,250.196503 726.708801,248.911102 727.095825,247.625687 
	C727.595642,247.979401 728.095520,248.333115 728.818481,249.284531 
	C731.471802,253.724060 735.138916,255.297302 739.456726,254.826447 
	C740.681458,254.692886 741.721924,252.870178 742.846924,251.822128 
	C742.846924,251.822128 742.976379,251.842178 743.279907,251.872299 
	C749.100647,248.271210 748.081177,237.058838 741.870300,233.060593 
	C741.565002,232.583603 741.259705,232.106613 741.177734,231.161392 
	C740.201721,228.851700 739.002441,227.010239 737.803162,225.168777 
	C737.803162,225.168777 737.813171,225.151047 737.879517,224.728729 
	C737.381042,221.739365 737.137085,219.047058 736.192688,216.628235 
	C733.075256,208.643814 736.235901,202.359482 741.616150,196.967041 
	C746.564331,192.007645 752.328674,189.479904 759.372253,193.401581 
	C760.501282,194.030197 762.411072,193.256332 763.959961,193.130798 
	C764.519653,193.269623 765.079285,193.408447 765.858032,194.163193 
	C766.422119,196.335953 766.767090,197.892792 767.112061,199.449646 
z"/>
<path fill="#FE9803" opacity="1.000000" stroke="none" 
	d="
M722.778748,228.985352 
	C722.762207,229.423935 722.745728,229.862488 722.301147,230.704620 
	C719.622681,234.103210 720.797913,240.536163 724.205200,243.873703 
	C724.205200,243.873703 724.048340,243.986572 724.049072,244.317429 
	C723.036377,245.763687 722.022888,246.879089 721.009460,247.994507 
	C721.009460,247.994507 721.000000,248.000000 720.989746,247.679657 
	C720.538574,247.012497 720.097595,246.665695 719.656616,246.318893 
	C719.656616,246.318893 719.887207,246.336945 719.779175,246.031342 
	C719.350281,245.597992 719.029297,245.470215 718.708374,245.342453 
	C718.708374,245.342453 718.660645,245.301132 718.734375,244.939667 
	C717.768982,240.494049 712.489685,237.901779 714.628662,232.699448 
	C714.628662,232.699448 714.699646,232.907639 715.035156,232.791107 
	C717.192322,230.340622 719.013916,228.006668 721.035767,225.416061 
	C721.685730,226.747055 722.232239,227.866211 722.778748,228.985352 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M763.738037,192.833801 
	C762.411072,193.256332 760.501282,194.030197 759.372253,193.401581 
	C752.328674,189.479904 746.564331,192.007645 741.616150,196.967041 
	C736.235901,202.359482 733.075256,208.643814 736.192688,216.628235 
	C737.137085,219.047058 737.381042,221.739365 737.888428,224.725204 
	C731.416016,213.714432 732.286316,203.011124 740.465637,195.220856 
	C747.415649,188.601532 755.321777,189.055679 763.738037,192.833801 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M733.891968,257.502625 
	C737.532959,257.904999 741.458923,258.383820 745.270203,259.326233 
	C751.554138,260.880035 756.049866,257.656647 760.035217,253.768387 
	C764.875793,249.045700 767.384338,243.304214 765.301392,235.888184 
	C771.884827,247.265976 761.604187,259.719025 750.922424,260.614685 
	C745.440979,261.074310 739.731689,258.816864 733.891968,257.502625 
z"/>
<path fill="#FE9803" opacity="1.000000" stroke="none" 
	d="
M773.179565,220.933746 
	C773.181030,220.511978 773.182556,220.090225 773.622986,219.248795 
	C777.168335,212.584808 776.819092,208.193405 772.963013,205.010452 
	C772.963013,205.010452 772.750793,204.767639 772.823486,204.205872 
	C772.923462,203.101318 772.950745,202.558533 772.990356,202.006409 
	C773.002686,201.997086 773.025269,201.974945 773.381714,202.000931 
	C774.879944,202.303802 776.021729,202.580673 777.163452,202.857544 
	C777.163452,202.857559 777.129150,202.986313 777.107544,203.253113 
	C777.524048,203.884003 777.962158,204.248077 778.400330,204.612167 
	C778.400330,204.612167 778.404175,204.703964 778.320679,205.065964 
	C779.195740,208.656601 780.154419,211.885223 781.113037,215.113861 
	C781.113037,215.113861 781.094971,214.989334 780.744751,215.041473 
	C779.394043,217.164108 778.393616,219.234589 777.393188,221.305054 
	C777.393188,221.305054 777.330322,221.333588 776.928101,221.247681 
	C775.410400,221.085770 774.294983,221.009766 773.179565,220.933746 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M772.978027,202.015732 
	C772.950745,202.558533 772.923462,203.101318 772.807373,204.045441 
	C770.978882,202.926346 769.239258,201.405930 767.305786,199.667572 
	C766.767090,197.892792 766.422119,196.335953 766.042847,194.388641 
	C767.674255,194.969330 769.339966,195.940506 770.948730,197.379791 
	C770.795288,197.941589 770.698608,198.035263 770.601990,198.128937 
	C770.890564,198.026077 771.179138,197.923233 771.467712,197.820404 
	C773.321411,199.283325 775.175049,200.746246 777.096069,202.533356 
	C776.021729,202.580673 774.879944,202.303802 773.369995,201.757751 
	C772.870728,201.214676 772.739685,200.940735 772.608704,200.666794 
	C772.731812,201.116440 772.854919,201.566086 772.978027,202.015732 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M741.893555,233.433014 
	C748.081177,237.058838 749.100647,248.271210 743.216431,251.873520 
	C748.195557,245.539124 744.374390,239.707520 741.893555,233.433014 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M759.214966,221.576202 
	C754.686035,218.546021 752.126831,210.288116 754.386047,205.072281 
	C756.226807,210.374680 757.713257,215.785873 759.214966,221.576202 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M722.926514,228.644012 
	C722.232239,227.866211 721.685730,226.747055 721.035767,225.416061 
	C719.013916,228.006668 717.192322,230.340622 715.008423,232.689301 
	C715.722107,230.523102 716.552612,228.164352 717.959167,226.222992 
	C718.916260,224.901993 720.679932,224.165359 722.082886,223.167419 
	C722.413330,224.879166 722.743774,226.590927 722.926514,228.644012 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M742.465332,251.882843 
	C741.721924,252.870178 740.681458,254.692886 739.456726,254.826447 
	C735.138916,255.297302 731.471802,253.724060 728.982727,249.545181 
	C732.990356,251.660110 736.962219,254.567627 742.465332,251.882843 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M714.312744,232.816833 
	C712.489685,237.901779 717.768982,240.494049 718.703308,244.945374 
	C712.544373,240.363632 711.386108,237.247940 714.312744,232.816833 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M724.175842,243.462326 
	C720.797913,240.536163 719.622681,234.103210 722.201782,231.019562 
	C723.069214,234.970963 723.607849,239.010956 724.175842,243.462326 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M772.997925,205.421494 
	C776.819092,208.193405 777.168335,212.584808 773.731079,218.949509 
	C773.277832,214.657440 773.155334,210.244980 772.997925,205.421494 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M721.106812,248.311249 
	C722.022888,246.879089 723.036377,245.763687 724.114014,244.245850 
	C725.074280,244.920471 725.970398,245.997513 726.981201,247.350113 
	C726.708801,248.911102 726.321716,250.196503 725.885376,251.887695 
	C724.292114,251.071671 722.748169,249.849838 721.106812,248.311249 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M781.432495,214.930099 
	C780.154419,211.885223 779.195740,208.656601 778.360107,205.042480 
	C781.378357,207.435104 785.101440,209.945023 781.432495,214.930099 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M755.691101,202.428513 
	C756.257202,198.335388 760.286987,196.475784 764.827759,198.591003 
	C762.001526,200.022690 759.010010,201.150665 755.691101,202.428513 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M764.814087,232.696014 
	C763.674744,231.173096 762.556580,229.303925 761.696716,227.223694 
	C762.915100,228.791672 763.875183,230.570694 764.814087,232.696014 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M777.693359,221.177551 
	C778.393616,219.234589 779.394043,217.164108 780.767090,215.100800 
	C780.090881,217.088669 779.042175,219.069351 777.693359,221.177551 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M737.838196,225.507721 
	C739.002441,227.010239 740.201721,228.851700 741.125793,230.876160 
	C739.858154,229.321671 738.865662,227.584167 737.838196,225.507721 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M773.084412,221.304123 
	C774.294983,221.009766 775.410400,221.085770 776.938110,221.280716 
	C776.752258,222.282974 776.154236,223.166306 775.556152,224.049622 
	C774.700500,223.257919 773.844910,222.466217 773.084412,221.304123 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M727.090637,253.336121 
	C728.178650,253.636185 729.438477,254.220261 730.711304,255.190186 
	C729.570374,254.924057 728.416382,254.272095 727.090637,253.336121 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M760.914795,224.711456 
	C760.345764,224.413300 759.762329,223.792603 759.270386,222.808731 
	C759.874756,223.093353 760.387512,223.741135 760.914795,224.711456 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M719.706665,246.601318 
	C720.097595,246.665695 720.538574,247.012497 720.992676,247.674744 
	C720.589417,247.621368 720.173035,247.252548 719.706665,246.601318 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M731.214355,256.240906 
	C731.615601,256.136261 732.145630,256.291718 732.831787,256.684082 
	C732.439697,256.780975 731.891357,256.640991 731.214355,256.240906 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M778.365601,204.324646 
	C777.962158,204.248077 777.524048,203.884003 777.114807,203.189392 
	C777.539490,203.251617 777.935181,203.644363 778.365601,204.324646 
z"/>
<path fill="#EFA12C" opacity="1.000000" stroke="none" 
	d="
M718.778625,245.576233 
	C719.029297,245.470215 719.350281,245.597992 719.666870,246.009491 
	C719.309570,246.269760 719.038330,246.108688 718.778625,245.576233 
z"/>
<path fill="#F99A0F" opacity="1.000000" stroke="none" 
	d="
M608.850098,180.826843 
	C608.214600,185.720459 607.190735,190.163925 607.151672,194.616013 
	C607.125000,197.669449 608.471924,200.733917 609.196533,203.795441 
	C609.445557,204.847656 610.145569,206.154144 609.763184,206.887817 
	C609.417969,207.550385 607.836609,207.952637 606.868896,207.846359 
	C599.103577,206.993500 591.347473,206.042328 583.609802,204.968475 
	C582.735718,204.847168 581.417297,203.844803 581.335938,203.133179 
	C581.235596,202.255127 582.039185,200.608170 582.757141,200.412491 
	C586.803894,199.309464 586.771484,196.135773 587.175964,192.932938 
	C588.966675,178.751648 590.914612,164.590210 592.702271,150.408554 
	C592.986328,148.155273 592.742126,145.835419 592.742126,143.589935 
	C584.288025,141.581940 580.203552,143.420868 575.519104,150.769485 
	C574.607117,152.200134 572.751221,153.029022 571.330688,154.135498 
	C571.174683,152.357742 570.630981,150.508804 570.941650,148.816742 
	C571.631165,145.061905 572.952209,141.422226 573.627197,137.665771 
	C574.156555,134.719696 575.495239,133.816315 578.394043,134.202911 
	C595.531189,136.488342 612.676147,138.719543 629.835022,140.834488 
	C632.644775,141.180832 634.011658,142.054214 633.853699,145.138596 
	C633.624146,149.623001 633.905823,154.133957 633.663635,158.617065 
	C633.601929,159.759933 632.388184,160.840591 631.703735,161.949829 
	C630.793762,160.993271 629.327026,160.166794 629.068054,159.058121 
	C627.795959,153.613495 625.668762,148.953842 620.023743,146.793900 
	C614.743408,144.773453 613.105530,145.717789 612.438538,151.188248 
	C611.252197,160.918869 610.067139,170.649658 608.850098,180.826843 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M496.006439,273.972565 
	C496.142548,267.722351 496.229980,267.669830 502.099731,269.016754 
	C505.084625,269.701691 506.129150,268.465149 505.260986,265.575562 
	C504.895508,264.359131 504.444092,263.168457 504.292603,262.726685 
	C508.085449,260.743317 511.394409,258.974091 514.749695,257.297607 
	C515.294067,257.025604 516.309570,256.859863 516.656555,257.162231 
	C519.354553,259.512970 522.814392,258.486542 525.676392,259.350525 
	C533.588989,261.739166 541.323975,262.522919 549.497803,261.173157 
	C552.308105,260.709076 555.413513,262.032135 558.576538,263.043762 
	C558.510376,264.081635 558.251770,264.614471 557.993164,265.147339 
	C554.947632,265.423950 551.902161,265.700562 548.149963,265.668884 
	C544.932007,265.829742 542.420654,266.298920 539.909363,266.768097 
	C539.191956,266.863098 538.474609,266.958099 537.188416,266.787415 
	C535.375122,266.906006 534.130676,267.290253 532.886169,267.674500 
	C531.867432,267.828461 530.848755,267.982452 529.212036,267.832245 
	C524.034668,268.607117 519.475342,269.686188 514.612549,270.871155 
	C513.882446,270.999908 513.455750,271.022766 512.786499,270.766174 
	C507.489624,271.272308 501.846771,269.963440 497.877075,274.608337 
	C497.472900,274.742126 497.068756,274.875916 496.351135,274.753418 
	C496.027222,274.322266 496.016815,274.147400 496.006439,273.972565 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M616.872498,266.046539 
	C622.194458,260.928680 628.075928,264.205536 633.797852,264.887787 
	C635.492371,265.089844 637.232727,265.250183 638.918335,265.085297 
	C640.531189,264.927582 642.921387,263.471130 643.539856,264.067810 
	C645.689087,266.141205 647.217041,268.858643 648.558594,271.702271 
	C647.007568,271.870880 645.886292,271.606140 644.749695,271.476746 
	C640.169312,270.955292 635.584106,270.475586 631.000732,269.980438 
	C630.570679,269.944214 630.140625,269.907959 629.279968,269.413513 
	C625.697876,268.526581 622.546448,268.097900 619.394958,267.669220 
	C618.787842,267.678986 618.180725,267.688721 617.276123,267.247803 
	C616.943237,266.546936 616.907837,266.296722 616.872498,266.046539 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M665.046570,276.169403 
	C665.464172,276.159210 665.881775,276.148987 666.610779,276.157562 
	C670.616272,277.920532 674.240601,279.836792 678.019958,281.371002 
	C684.642273,284.059204 691.446960,286.310120 698.008179,289.132782 
	C702.788208,291.189209 707.299561,293.870331 711.977417,296.620728 
	C710.036804,296.256500 708.041260,295.566437 706.063293,294.829010 
	C688.864624,288.416809 671.669006,281.996185 654.225708,275.307800 
	C655.699036,275.049042 657.419250,275.060577 659.733032,275.308044 
	C661.900024,275.752472 663.473267,275.960938 665.046570,276.169403 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M720.919922,302.019226 
	C721.904053,301.721924 723.209351,301.319946 724.074036,301.768036 
	C728.386536,304.003082 732.583374,306.461456 736.820862,308.841187 
	C737.144653,308.474945 737.468445,308.108704 737.792236,307.742462 
	C737.792236,308.695374 737.792236,309.648315 737.666077,310.992249 
	C732.039246,308.357666 726.538574,305.332092 720.919922,302.019226 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M631.281494,270.243958 
	C635.584106,270.475586 640.169312,270.955292 644.749695,271.476746 
	C645.886292,271.606140 647.007568,271.870880 648.548096,272.100830 
	C649.957642,272.780548 650.954834,273.432831 652.030884,274.382446 
	C645.260498,273.289032 638.411438,271.898254 631.281494,270.243958 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M774.491333,338.906433 
	C780.167908,340.321350 784.364929,343.908600 786.927490,349.765900 
	C782.819824,346.496796 778.717346,342.854584 774.491333,338.906433 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M576.956177,264.129883 
	C580.007568,263.900604 583.051697,263.951019 586.922058,264.002625 
	C589.174683,263.706482 590.600952,263.409119 592.027283,263.111755 
	C592.995178,263.710541 593.963013,264.309265 595.041626,265.162964 
	C589.313171,265.434204 583.473999,265.450439 577.309021,265.217346 
	C576.971802,264.781860 576.960327,264.595703 576.956177,264.129883 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M616.549194,265.898468 
	C616.907837,266.296722 616.943237,266.546936 616.979858,267.177185 
	C611.850403,267.183319 606.719849,266.809418 601.302551,266.146912 
	C606.085876,265.822357 611.155945,265.786377 616.549194,265.898468 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M514.916016,270.765259 
	C519.475342,269.686188 524.034668,268.607117 528.823853,267.837524 
	C527.511292,268.604065 525.968872,269.061157 524.127502,269.713318 
	C521.776184,270.222778 519.723755,270.537201 516.967529,270.935547 
	C515.814392,270.934692 515.365234,270.849976 514.916016,270.765259 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M619.635742,267.947266 
	C622.546448,268.097900 625.697876,268.526581 628.977417,269.293976 
	C626.029175,269.163544 622.952820,268.694397 619.635742,267.947266 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M540.330811,266.887085 
	C542.420654,266.298920 544.932007,265.829742 547.716553,265.634888 
	C545.577332,266.274872 543.164795,266.640503 540.330811,266.887085 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M712.810303,297.533630 
	C715.030884,298.330597 717.379333,299.452240 719.808472,300.931641 
	C717.572205,300.145721 715.255127,299.001984 712.810303,297.533630 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M757.759155,325.561493 
	C759.402100,326.295105 761.165833,327.344910 762.930847,328.760620 
	C761.248047,328.043549 759.563965,326.960632 757.759155,325.561493 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M561.005127,264.017944 
	C562.515747,264.153351 564.026306,264.288788 565.779419,264.713196 
	C564.517273,265.199768 563.012695,265.397400 561.241211,265.110413 
	C560.984619,264.423187 560.994873,264.220551 561.005127,264.017944 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M740.676453,313.648529 
	C741.983521,314.052368 743.430603,314.761597 744.875977,315.844604 
	C743.521667,315.463531 742.169067,314.708740 740.676453,313.648529 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M533.243530,267.792847 
	C534.130676,267.290253 535.375122,266.906006 536.824829,266.787384 
	C535.886963,267.339081 534.743958,267.625183 533.243530,267.792847 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M765.645020,331.662415 
	C766.663757,332.017761 767.798950,332.682770 768.943359,333.719452 
	C767.888855,333.384735 766.825134,332.678406 765.645020,331.662415 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M770.640259,335.637207 
	C771.657898,336.044159 772.766113,336.771881 773.854614,337.884033 
	C772.800171,337.498260 771.765503,336.728119 770.640259,335.637207 
z"/>
<path fill="#EEEDEC" opacity="1.000000" stroke="none" 
	d="
M560.841187,263.768555 
	C560.994873,264.220551 560.984619,264.423187 560.953918,264.929749 
	C560.193970,265.264099 559.454468,265.294495 558.354065,265.236115 
	C558.251770,264.614471 558.510376,264.081635 558.887207,263.276306 
	C559.562683,263.175568 560.119934,263.347382 560.841187,263.768555 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M749.681274,319.615967 
	C750.340881,319.666779 751.122559,320.018707 751.935059,320.737305 
	C751.245056,320.708344 750.524109,320.312714 749.681274,319.615967 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M737.762939,311.746185 
	C738.419067,311.749969 739.166626,312.045624 739.952820,312.692993 
	C739.279053,312.709167 738.566711,312.373627 737.762939,311.746185 
z"/>
<path fill="#F99A0F" opacity="1.000000" stroke="none" 
	d="
M531.673279,133.863007 
	C535.065796,133.455093 536.571167,134.887650 537.794434,137.513062 
	C546.086243,155.309341 554.438660,173.079285 563.030823,190.731537 
	C564.238831,193.213211 566.914856,194.959900 568.802429,197.141159 
	C569.567566,198.025375 570.040649,199.162277 570.646667,200.184174 
	C569.715515,200.713455 568.809692,201.648209 567.849243,201.708237 
	C559.709229,202.216995 551.560913,202.612274 543.410156,202.904205 
	C542.409973,202.940048 540.603088,202.276703 540.528198,201.745651 
	C540.375671,200.665344 540.812805,199.092163 541.599976,198.356979 
	C545.912354,194.329254 541.789246,191.053436 540.779602,187.554291 
	C539.858521,184.361526 537.986328,182.510025 534.197571,182.982452 
	C530.076660,183.496307 525.890137,183.464935 521.737732,183.751801 
	C519.041382,183.938080 515.524719,183.017441 514.856506,186.965805 
	C514.124573,191.290710 509.662140,195.630341 515.059082,200.142334 
	C517.438110,202.131271 516.304382,204.088989 513.258301,204.317963 
	C508.468170,204.678055 503.664093,204.853302 497.159790,205.196960 
	C498.509735,202.858414 499.132996,201.541138 499.970764,200.378448 
	C501.565491,198.165222 504.016632,196.284454 504.830048,193.839157 
	C510.083374,178.046082 515.106934,162.172607 519.868225,146.224701 
	C520.486938,144.152328 519.255249,141.481064 518.708923,139.127060 
	C518.437866,137.959198 517.105774,136.533783 517.460144,135.850952 
	C517.912109,134.980026 519.554443,134.398407 520.735962,134.296387 
	C524.216614,133.995834 527.722961,133.993515 531.673279,133.863007 
M533.103455,170.339310 
	C530.797913,165.278366 528.492371,160.217438 526.186829,155.156494 
	C525.771240,155.201385 525.355591,155.246292 524.940002,155.291199 
	C522.718384,162.215057 520.496765,169.138931 517.949829,177.076843 
	C524.343933,176.793289 529.479492,176.565536 535.345276,176.305420 
	C534.437500,173.753326 533.949707,172.381958 533.103455,170.339310 
z"/>
<path fill="#F99C14" opacity="1.000000" stroke="none" 
	d="
M637.556580,204.853958 
	C637.745789,206.885590 637.970398,208.607971 638.473633,210.244659 
	C639.215637,212.658020 638.923523,214.655869 635.963135,213.891754 
	C630.889648,212.582199 625.960999,210.711960 619.515503,208.600098 
	C621.957153,206.912750 623.130920,206.056885 624.348877,205.269440 
	C626.656189,203.777664 629.587219,202.805206 631.213440,200.778091 
	C641.405273,188.073196 651.290771,175.122894 661.322388,162.288712 
	C663.372559,159.665726 664.890076,157.341965 662.633789,153.814789 
	C661.326721,151.771561 662.398865,149.718094 665.386230,150.585098 
	C670.014282,151.928268 674.752991,153.129501 679.066956,155.184143 
	C680.546997,155.889069 681.453064,158.917114 681.662231,160.981033 
	C682.617432,170.404633 683.225281,179.863327 683.969910,189.308441 
	C684.701233,198.585022 685.200684,207.890320 686.320007,217.120193 
	C686.712280,220.354828 688.697693,223.383362 689.841431,226.547318 
	C690.201416,227.543045 690.680908,229.226562 690.238525,229.619949 
	C689.460938,230.311356 687.866638,230.734344 686.872559,230.429062 
	C679.088806,228.038803 671.352234,225.490555 663.644165,222.864548 
	C662.717957,222.548996 661.502563,221.536499 661.415527,220.738541 
	C661.328796,219.943100 662.379028,218.379211 663.127991,218.243835 
	C667.310120,217.487869 667.113647,214.524551 667.047668,211.420837 
	C666.870850,203.111237 666.906372,203.101791 659.095154,200.400482 
	C657.369324,199.803650 655.615723,199.286362 653.871521,198.743454 
	C643.936646,195.651169 643.936279,195.652298 637.556580,204.853958 
M662.864746,193.888550 
	C663.578613,193.395004 664.916199,192.895966 664.911865,192.408737 
	C664.856445,186.111191 664.609985,179.815323 664.411865,173.519012 
	C663.828430,173.393753 663.245056,173.268478 662.661682,173.143204 
	C658.592346,178.374588 654.523010,183.605972 649.912659,189.532837 
	C654.652405,191.155701 658.354980,192.423447 662.864746,193.888550 
z"/>
<path fill="#F99A0D" opacity="1.000000" stroke="none" 
	d="
M871.898499,285.072144 
	C877.469482,292.752319 883.235901,299.900482 887.949463,307.686035 
	C890.333557,311.623993 891.670532,316.659119 891.968750,321.295471 
	C892.508484,329.686340 885.204834,336.420990 876.753174,336.277588 
	C874.654968,336.241974 872.562988,335.835022 869.964661,335.541931 
	C870.333801,337.414032 870.597961,338.985291 870.956787,340.534668 
	C872.806519,348.522522 870.738342,355.221558 864.867859,359.111511 
	C858.211487,363.522247 850.777344,363.853577 844.768188,359.041779 
	C838.260132,353.830444 832.762329,347.245972 827.333740,340.829041 
	C821.775940,334.259430 816.835938,327.167236 811.015137,319.499695 
	C812.936584,318.913849 814.125854,318.480438 815.349426,318.192627 
	C818.090210,317.547852 821.420532,317.761902 823.504944,316.244507 
	C835.859741,307.250183 847.986267,297.936676 860.026917,288.522461 
	C861.798828,287.137115 862.781067,284.689392 863.982483,282.647156 
	C864.668884,281.480164 865.059937,280.139435 865.583984,278.876923 
	C866.759583,279.619843 868.099976,280.196014 869.066284,281.150787 
	C870.112305,282.184296 870.809326,283.571014 871.898499,285.072144 
M856.880066,326.927765 
	C854.789917,324.401123 854.130676,318.786530 849.143372,321.774902 
	C843.608032,325.091644 838.642029,329.389954 833.615723,333.490753 
	C832.873169,334.096619 832.594910,336.248016 833.067688,337.209381 
	C836.262756,343.706909 842.881714,346.449463 848.875488,344.028137 
	C856.210815,341.064789 859.176697,335.215057 856.880066,326.927765 
M880.135132,312.189667 
	C879.743835,310.107086 879.631470,307.920380 878.901245,305.964355 
	C877.530884,302.293671 875.609009,300.986267 872.032593,304.283844 
	C868.529907,307.513397 864.534180,310.248688 860.566101,312.920654 
	C857.093201,315.259155 857.879150,317.244904 860.438904,319.579681 
	C866.980591,325.546356 876.026123,322.524139 880.135132,312.189667 
z"/>
<path fill="#FA9A0D" opacity="1.000000" stroke="none" 
	d="
M476.268188,207.369629 
	C463.217896,214.606201 448.768402,215.943222 435.064758,219.761520 
	C434.179504,220.008179 432.540863,219.590607 432.128967,218.933105 
	C431.701996,218.251541 431.964050,216.514984 432.581360,215.910828 
	C435.208130,213.340118 434.461243,210.596191 433.718903,207.582687 
	C430.104095,192.908066 426.772034,178.161484 422.947937,163.542923 
	C422.293030,161.039398 419.731842,159.037750 418.064850,156.794174 
	C417.434082,155.945221 416.221100,154.910721 416.389954,154.243301 
	C416.597900,153.421463 417.887024,152.514236 418.854980,152.298111 
	C431.002289,149.585587 443.064178,145.978439 455.370911,144.618912 
	C471.406189,142.847458 484.927246,150.065567 490.781616,166.630127 
	C496.512573,182.845535 491.541504,197.128036 476.268188,207.369629 
M449.490051,152.306534 
	C446.631348,153.332428 440.955444,151.378494 442.187225,157.091385 
	C445.657837,173.187408 449.773254,189.146881 453.876038,205.098022 
	C454.124146,206.062622 456.639618,207.267563 457.829437,207.026260 
	C463.494934,205.877136 468.071503,202.847702 470.246948,197.278488 
	C474.836121,185.530060 472.126709,174.386749 466.725708,163.686310 
	C463.370422,157.038742 458.060120,152.809509 449.490051,152.306534 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M279.995239,681.050659 
	C270.977844,681.036194 261.960480,681.021729 252.006287,681.000916 
	C244.712265,680.994568 238.355042,680.994568 232.003647,680.994568 
	C231.794281,682.727112 231.625305,684.125488 231.456329,685.523865 
	C230.633408,683.857971 229.810486,682.192078 228.599045,679.739746 
	C231.331512,679.508484 233.082428,679.234436 234.833801,679.231506 
	C252.993103,679.200989 271.153687,679.327942 289.310913,679.132751 
	C293.673828,679.085876 295.958771,680.653015 297.517334,684.680542 
	C299.186401,688.993591 301.644806,693.001282 304.039856,697.316895 
	C304.452057,697.836060 304.583069,698.180481 304.869324,698.754272 
	C308.415100,705.205200 311.799042,711.430420 315.200592,717.645996 
	C316.069092,719.233032 316.993317,720.789490 317.862030,722.718506 
	C315.937408,720.884949 313.661041,718.916321 312.221100,716.457886 
	C308.261993,709.698486 304.659180,702.730652 300.904022,695.851501 
	C299.176514,692.686829 297.422485,689.536682 295.388977,685.766235 
	C293.318207,683.772644 291.704712,681.614624 289.721527,681.192139 
	C286.610565,680.529419 283.250061,681.038086 279.995239,681.050659 
z"/>
<path fill="#F6F6F6" opacity="1.000000" stroke="none" 
	d="
M231.443329,685.718872 
	C231.625305,684.125488 231.794281,682.727112 232.003647,680.994568 
	C238.355042,680.994568 244.712265,680.994568 251.538330,681.016724 
	C249.634293,682.359070 247.389450,684.073242 244.855179,684.896851 
	C241.882416,685.862976 238.658066,686.054932 234.901123,686.666992 
	C233.376389,686.587769 232.493362,686.424255 231.610336,686.260742 
	C231.610336,686.260742 231.430328,685.913879 231.443329,685.718872 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M246.896698,715.053894 
	C246.887894,715.597351 246.879105,716.140869 246.774048,717.083923 
	C242.761475,709.905396 238.845169,702.327271 234.961899,694.372437 
	C235.542938,694.171387 236.090927,694.347046 236.847977,695.124207 
	C240.336914,702.168457 243.616821,708.611206 246.896698,715.053894 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M231.623566,686.621094 
	C232.493362,686.424255 233.376389,686.587769 234.572662,686.885498 
	C234.848801,688.568359 234.811707,690.116882 234.611313,692.111938 
	C233.510925,690.699402 232.573853,688.840393 231.623566,686.621094 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M247.338684,719.216064 
	C247.903198,719.489807 248.403122,720.078735 248.859833,720.991699 
	C248.302444,720.720825 247.788269,720.126099 247.338684,719.216064 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M356.350769,356.952271 
	C356.175323,355.921234 355.933685,354.482483 356.540955,353.718170 
	C360.997528,348.108948 366.003021,343.091705 373.120178,340.280396 
	C367.894196,345.872009 362.285889,351.364410 356.350769,356.952271 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M495.633545,274.000671 
	C496.016815,274.147400 496.027222,274.322266 496.030945,274.759766 
	C488.929230,277.334076 481.834229,279.645752 474.343658,281.932800 
	C478.426331,279.980133 482.882080,277.997070 487.394653,276.152985 
	C489.035339,275.482483 490.811523,275.143585 492.773254,274.392456 
	C493.767303,274.097290 494.513977,274.063049 495.633545,274.000671 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M498.268372,274.727661 
	C501.846771,269.963440 507.489624,271.272308 512.756470,271.007324 
	C512.969055,271.527985 512.840637,271.996704 512.840637,271.996704 
	C512.840637,271.996704 512.666931,272.025635 512.584412,272.055969 
	C512.169373,272.147583 511.836823,272.208923 511.257721,272.326721 
	C506.894012,273.204468 502.776825,274.025726 498.268372,274.727661 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M409.674622,313.293457 
	C413.442291,310.589447 417.564392,307.878662 422.075623,305.248047 
	C418.319489,307.981049 414.174286,310.633881 409.674622,313.293457 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M377.527679,337.165527 
	C379.725891,334.864777 382.267548,332.540070 385.192749,330.294312 
	C385.576294,330.373230 385.585846,330.786133 385.373993,331.148804 
	C385.099609,331.675873 385.037109,331.840302 384.974579,332.004761 
	C382.606750,333.717041 380.238953,335.429321 377.527679,337.165527 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M336.283997,379.846283 
	C336.314270,375.677826 338.694550,372.892975 342.765625,371.095032 
	C340.951569,373.991974 338.781067,376.873901 336.283997,379.846283 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M398.039581,321.082458 
	C398.766815,316.411346 402.865997,316.203430 406.550659,314.948730 
	C404.782379,316.778442 402.631927,318.600464 399.923462,320.678894 
	C398.923523,320.984375 398.481567,321.033417 398.039581,321.082458 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M452.602600,290.376831 
	C451.842865,285.450012 454.182098,285.458954 457.796631,287.684875 
	C456.322205,288.771179 454.645264,289.576111 452.602600,290.376831 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M443.257874,294.779297 
	C445.540680,293.233795 448.180786,291.740753 451.207611,290.398315 
	C448.934631,291.976532 446.274902,293.404144 443.257874,294.779297 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M430.409760,301.116882 
	C431.987946,299.836517 433.927582,298.554230 436.245605,297.431396 
	C434.673096,298.765564 432.722168,299.940277 430.409760,301.116882 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M350.406494,362.997131 
	C351.480591,361.384186 352.869629,359.674103 354.632385,357.981934 
	C353.577911,359.633209 352.149658,361.266571 350.406494,362.997131 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M423.377350,305.079773 
	C424.929077,303.776245 426.838043,302.483124 429.132019,301.293365 
	C427.589569,302.627869 425.662048,303.859009 423.377350,305.079773 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M461.246765,286.802795 
	C462.152863,286.022278 463.412018,285.290955 464.850159,284.806885 
	C463.886047,285.653412 462.742950,286.252716 461.246765,286.802795 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M390.306458,326.941406 
	C390.876678,326.002625 391.791199,325.078522 393.071259,324.225708 
	C392.508148,325.183380 391.579468,326.069763 390.306458,326.941406 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M374.328918,339.961823 
	C374.813354,339.047668 375.614380,338.058838 376.798462,337.129639 
	C376.336151,338.088531 375.490845,338.987823 374.328918,339.961823 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M438.379578,297.103149 
	C438.879700,296.423126 439.729370,295.756531 440.964020,295.155518 
	C440.475708,295.852966 439.602417,296.484772 438.379578,297.103149 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M348.189880,365.720154 
	C348.395508,364.880463 348.908813,363.981537 349.763763,363.084351 
	C349.569458,363.944336 349.033508,364.802643 348.189880,365.720154 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M387.384277,329.076538 
	C387.592438,328.455383 388.120300,327.791534 389.012299,327.184021 
	C388.818970,327.838165 388.261505,328.436005 387.384277,329.076538 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M468.262665,283.975342 
	C468.548798,283.480682 469.138153,283.035706 469.878967,282.833740 
	C469.542236,283.392792 469.054047,283.708862 468.262665,283.975342 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M407.282410,314.951141 
	C407.510345,314.347290 408.056671,313.762909 408.940674,313.253601 
	C408.719147,313.875977 408.160004,314.423309 407.282410,314.951141 
z"/>
<path fill="#D2CDCB" opacity="1.000000" stroke="none" 
	d="
M343.423462,371.073975 
	C343.524200,370.436035 343.938019,369.750305 344.686615,369.036987 
	C344.593140,369.681641 344.164856,370.353912 343.423462,371.073975 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M397.975403,321.295410 
	C398.481567,321.033417 398.923523,320.984375 399.700928,320.913910 
	C399.542908,321.241608 399.049500,321.590698 398.237305,321.946533 
	C397.918549,321.953217 397.911255,321.508392 397.975403,321.295410 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M385.124756,331.964813 
	C385.037109,331.840302 385.099609,331.675873 385.374817,331.355316 
	C385.705078,331.538116 385.600891,331.779999 385.124756,331.964813 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M789.897461,353.447693 
	C790.938904,351.895782 792.034424,350.679352 793.241821,349.087494 
	C797.394348,353.178040 801.513550,357.576172 805.446533,362.134979 
	C807.872070,364.946625 810.013672,368.003143 812.618530,371.033081 
	C818.875183,380.248322 824.796143,389.380188 830.852905,399.121094 
	C831.312805,400.154022 831.636780,400.577881 831.960815,401.001740 
	C832.212158,401.423279 832.463440,401.844849 832.387451,402.562256 
	C831.307190,402.575043 830.554199,402.291962 829.801270,402.008881 
	C818.038879,384.676971 804.523499,368.793549 789.897461,353.447693 
z"/>
<path fill="#998C8E" opacity="1.000000" stroke="none" 
	d="
M848.235596,434.896240 
	C844.838806,428.791779 841.658081,422.990387 838.477417,417.188995 
	C839.048828,416.905731 839.620239,416.622467 840.191650,416.339172 
	C846.459778,423.111298 848.805847,431.870941 852.251709,440.073242 
	C851.768616,440.311157 851.285461,440.549103 850.802307,440.787048 
	C850.018799,438.924469 849.235291,437.061890 848.235596,434.896240 
z"/>
<path fill="#C8C2C0" opacity="1.000000" stroke="none" 
	d="
M829.830139,402.374634 
	C830.554199,402.291962 831.307190,402.575043 832.464233,402.924866 
	C834.518616,405.812927 836.168823,408.634216 837.819092,411.455475 
	C833.226685,410.169739 831.800598,406.232330 829.830139,402.374634 
z"/>
<path fill="#C8C2C0" opacity="1.000000" stroke="none" 
	d="
M831.929321,400.722534 
	C831.636780,400.577881 831.312805,400.154022 830.963684,399.451569 
	C831.258301,399.596466 831.578064,400.019897 831.929321,400.722534 
z"/>
<path fill="#998C8E" opacity="1.000000" stroke="none" 
	d="
M852.548706,442.448059 
	C852.581116,442.504395 852.516235,442.391754 852.548706,442.448059 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M817.381104,447.601074 
	C818.693237,442.161926 817.516785,436.830231 817.433289,431.518707 
	C817.421143,430.746063 818.372375,429.958313 819.073608,428.853760 
	C820.163086,429.617035 821.056702,430.704102 822.154053,432.016785 
	C824.690369,437.079620 827.022949,441.916840 829.851318,447.782196 
	C825.202637,447.782196 821.509399,447.782196 817.381104,447.601074 
z"/>
<path fill="#EEEDEC" opacity="1.000000" stroke="none" 
	d="
M738.839294,339.933960 
	C735.090515,343.136169 732.110596,342.656525 729.149475,338.592590 
	C727.371521,336.152435 724.497437,334.510986 722.424377,332.014954 
	C723.326599,331.185425 723.920471,330.851532 724.514282,330.517639 
	C727.548767,332.437866 730.583191,334.358063 733.877197,336.883759 
	C735.161438,337.954742 736.186096,338.420258 737.210815,338.885742 
	C737.724304,339.141693 738.237732,339.397644 738.839294,339.933960 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M796.985657,394.770813 
	C797.008667,396.877289 797.008667,398.628998 797.008667,400.415741 
	C796.015259,400.415741 795.436401,400.470123 795.423645,400.408020 
	C794.240051,394.654755 787.131470,391.874939 788.126038,385.022858 
	C791.295898,388.026947 794.129272,391.221466 796.985657,394.770813 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M757.435059,354.272217 
	C756.189087,354.211731 754.812073,353.897949 753.498230,353.413147 
	C751.808838,352.789795 750.169983,352.029266 748.509399,351.327850 
	C748.323364,351.704926 748.137390,352.081970 747.951416,352.459045 
	C747.023682,350.154236 746.096008,347.849426 745.189148,345.162201 
	C749.250671,347.834167 753.291321,350.888519 757.435059,354.272217 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M780.146484,375.414673 
	C779.146973,376.040405 778.061401,376.342163 777.914185,376.383057 
	C775.212036,373.645660 772.947693,371.351837 770.341675,369.028992 
	C770.000000,369.000000 770.011963,369.008301 770.011963,369.008301 
	C770.031128,368.199097 770.050293,367.389862 770.152161,366.182190 
	C773.510071,368.886017 776.785217,371.988342 780.146484,375.414673 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M770.150879,365.443298 
	C767.888855,363.963837 765.556763,362.159088 763.348511,359.992981 
	C765.675232,361.460388 767.877991,363.289185 770.150879,365.443298 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M763.286621,359.213135 
	C761.564453,358.252289 759.769836,356.971527 757.991089,355.321716 
	C759.742676,356.266174 761.478394,357.579712 763.286621,359.213135 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M785.009766,380.589233 
	C780.951050,382.161194 781.612305,378.136414 780.191223,376.122620 
	C781.826294,377.259583 783.401062,378.758850 785.009766,380.589233 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M745.128479,344.453979 
	C743.489075,343.769714 741.772278,342.758667 740.011353,341.401550 
	C741.661804,342.079437 743.356445,343.103333 745.128479,344.453979 
z"/>
<path fill="#CCC8C7" opacity="1.000000" stroke="none" 
	d="
M813.914490,418.717468 
	C812.647766,417.498413 811.400879,415.928497 810.236938,413.996765 
	C811.524719,415.212189 812.729492,416.789398 813.914490,418.717468 
z"/>
<path fill="#CCC8C7" opacity="1.000000" stroke="none" 
	d="
M810.018799,412.658264 
	C808.728882,411.458130 807.444946,409.908752 806.244385,407.993652 
	C807.560120,409.188354 808.792419,410.748718 810.018799,412.658264 
z"/>
<path fill="#D1CDCA" opacity="1.000000" stroke="none" 
	d="
M724.368713,330.228180 
	C723.920471,330.851532 723.326599,331.185425 722.426880,331.645020 
	C721.812988,330.975983 721.504944,330.181274 721.290161,329.038757 
	C722.330017,329.106903 723.276611,329.522797 724.368713,330.228180 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M737.027344,338.616150 
	C736.186096,338.420258 735.161438,337.954742 734.117432,337.106934 
	C735.013367,337.265259 735.928589,337.805908 737.027344,338.616150 
z"/>
<path fill="#CCC8C7" opacity="1.000000" stroke="none" 
	d="
M803.035828,402.657440 
	C802.411438,402.451477 801.778564,401.924377 801.192505,401.049316 
	C801.835266,401.246338 802.431335,401.791351 803.035828,402.657440 
z"/>
<path fill="#CCC8C7" opacity="1.000000" stroke="none" 
	d="
M800.923218,399.740051 
	C800.276001,399.563873 799.648621,399.060577 799.043823,398.226349 
	C799.692017,398.401276 800.317505,398.907104 800.923218,399.740051 
z"/>
<path fill="#CCC8C7" opacity="1.000000" stroke="none" 
	d="
M817.005127,423.715027 
	C816.390808,423.431152 815.813843,422.828247 815.355957,421.882812 
	C815.997498,422.158844 816.519958,422.777405 817.005127,423.715027 
z"/>
<path fill="#69527B" opacity="1.000000" stroke="none" 
	d="
M691.460815,769.976074 
	C691.718201,769.933105 691.789551,770.342529 691.772461,770.549316 
	C691.371155,770.828857 690.986938,770.901611 690.309937,770.955933 
	C690.412598,770.631409 690.807983,770.325256 691.460815,769.976074 
z"/>
<path fill="#69527B" opacity="1.000000" stroke="none" 
	d="
M736.747070,731.113708 
	C736.942505,731.371582 736.788696,731.652161 736.255005,731.910400 
	C736.098816,731.651489 736.273010,731.400940 736.747070,731.113708 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M665.192505,785.208679 
	C665.254944,785.468323 664.982544,785.792114 664.411804,786.231079 
	C664.361511,785.988403 664.609619,785.630676 665.192505,785.208679 
z"/>
<path fill="#C3BCBA" opacity="1.000000" stroke="none" 
	d="
M344.811584,718.752136 
	C344.964630,720.780640 345.028992,722.479919 345.100464,724.508179 
	C341.122131,719.511292 337.050781,714.246826 333.188324,708.833313 
	C331.319183,706.213623 329.842224,703.313965 328.143494,700.180420 
	C328.136536,698.603210 328.173859,697.386597 328.481323,696.151978 
	C329.543884,697.375732 330.336273,698.617371 331.497009,700.310730 
	C332.586517,701.817139 333.307648,702.871826 334.028778,703.926575 
	C337.593475,708.758728 341.158173,713.590820 344.811584,718.752136 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M334.019958,703.572144 
	C333.307648,702.871826 332.586517,701.817139 331.785065,700.432007 
	C332.473541,701.140320 333.242340,702.179016 334.019958,703.572144 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M411.996277,776.747498 
	C411.237579,776.671448 410.479797,776.253540 409.551880,775.545898 
	C409.954071,775.185791 410.526337,775.115479 411.529053,775.043152 
	C411.972076,775.495972 411.984650,775.950806 411.996277,776.747498 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M352.809082,727.132935 
	C352.172028,726.923828 351.482727,726.353577 350.683044,725.455566 
	C351.300690,725.675842 352.028778,726.223755 352.809082,727.132935 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M402.799255,770.937378 
	C402.354431,771.015625 401.791504,770.794556 401.076111,770.304932 
	C401.509460,770.237000 402.095276,770.437500 402.799255,770.937378 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M354.767395,729.284790 
	C354.275482,729.289551 353.737640,728.952820 353.119019,728.304321 
	C353.599274,728.309509 354.160370,728.626404 354.767395,729.284790 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M367.812073,743.067749 
	C367.386688,743.070251 366.893799,742.775513 366.308380,742.204834 
	C366.725464,742.209473 367.235046,742.489990 367.812073,743.067749 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M363.795990,739.093384 
	C363.548981,739.166382 363.224274,738.921082 362.770264,738.395142 
	C363.000061,738.334717 363.359192,738.554810 363.795990,739.093384 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M369.797333,744.997314 
	C369.557526,745.088501 369.221771,744.881958 368.759277,744.401367 
	C368.988800,744.318115 369.345093,744.508850 369.797333,744.997314 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M377.881897,752.573608 
	C377.651367,752.822571 377.409454,752.724670 377.225555,752.226929 
	C377.442017,752.092163 377.637695,752.225464 377.881897,752.573608 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M383.834351,757.433594 
	C383.924927,757.559631 383.550201,757.607239 383.550201,757.607239 
	C383.550201,757.607239 383.270142,757.353760 383.244507,757.165771 
	C383.419678,757.046509 383.594635,757.156433 383.834351,757.433594 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M750.481689,410.953064 
	C755.328003,409.801758 756.433044,410.569519 759.073914,417.081726 
	C759.700500,415.177155 760.167236,413.758636 760.633911,412.340088 
	C761.122009,412.182343 761.610046,412.024597 762.098083,411.866821 
	C762.797363,413.809448 763.496643,415.752045 764.541809,418.655518 
	C765.196289,417.100891 765.600037,416.121155 766.020691,415.148743 
	C766.439941,414.179688 766.876099,413.217957 767.828491,411.074432 
	C769.356384,416.734528 773.284485,420.699921 769.348816,425.769531 
	C768.858887,426.400574 769.510437,427.935486 769.666443,429.046875 
	C770.094421,432.096039 770.540222,435.142761 770.660278,438.601868 
	C769.937744,438.984497 769.533997,438.955719 769.130188,438.926910 
	C768.716980,438.259583 768.303772,437.592255 767.872803,436.294617 
	C767.254028,435.097900 766.653076,434.531555 766.052124,433.965210 
	C764.980286,432.322327 763.908447,430.679504 762.831421,428.365204 
	C762.206421,427.132904 761.586548,426.572083 760.966614,426.011261 
	C760.966614,426.011261 760.772766,425.729095 760.791992,425.210449 
	C760.204407,424.127869 759.597595,423.563934 758.990784,422.999969 
	C758.597473,422.690582 758.204224,422.381165 757.580933,421.972168 
	C755.068420,418.341522 752.785828,414.810455 750.481689,410.953064 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M771.034851,439.990540 
	C772.320068,440.179260 773.605286,440.367981 774.903625,440.558624 
	C775.807129,439.444702 776.729797,438.307190 777.652466,437.169678 
	C779.293640,438.901825 781.190186,440.466919 782.475342,442.431885 
	C783.188354,443.522064 782.880127,445.280121 783.040161,447.110779 
	C775.511047,448.685364 774.415039,448.192322 770.914551,441.350342 
	C770.956177,440.447083 770.995483,440.218811 771.034851,439.990540 
z"/>
<path fill="#CCC8C7" opacity="1.000000" stroke="none" 
	d="
M771.003174,439.767670 
	C770.995483,440.218811 770.956177,440.447083 770.817810,441.009277 
	C770.174194,440.752411 769.629578,440.161682 769.107544,439.248932 
	C769.533997,438.955719 769.937744,438.984497 770.642090,439.054382 
	C770.942566,439.095490 770.971558,439.544800 771.003174,439.767670 
z"/>
<path fill="#CCC8C7" opacity="1.000000" stroke="none" 
	d="
M758.987671,423.323853 
	C759.597595,423.563934 760.204407,424.127869 760.747314,425.045258 
	C760.117065,424.815033 759.550781,424.231384 758.987671,423.323853 
z"/>
<path fill="#CCC8C7" opacity="1.000000" stroke="none" 
	d="
M760.965027,426.331116 
	C761.586548,426.572083 762.206421,427.132904 762.744629,428.047760 
	C762.096375,427.818176 761.529907,427.234589 760.965027,426.331116 
z"/>
<path fill="#CCC8C7" opacity="1.000000" stroke="none" 
	d="
M766.027832,434.284729 
	C766.653076,434.531555 767.254028,435.097900 767.788818,436.011566 
	C767.149597,435.773987 766.576538,435.189117 766.027832,434.284729 
z"/>
<path fill="#C8C2C0" opacity="1.000000" stroke="none" 
	d="
M819.169067,745.849854 
	C819.111938,745.691956 819.232849,745.382690 819.605225,745.000854 
	C819.760437,745.233704 819.590515,745.490417 819.169067,745.849854 
z"/>
<path fill="#BBB6B2" opacity="1.000000" stroke="none" 
	d="
M333.684631,424.875793 
	C333.408203,425.284302 333.131805,425.692810 332.528931,426.494965 
	C331.777985,427.581543 331.353516,428.274475 330.929047,428.967407 
	C330.929047,428.967407 330.598145,429.097900 330.107056,428.974609 
	C329.307281,428.457916 328.998627,428.064514 328.689941,427.671082 
	C333.172668,420.092377 337.655365,412.513672 342.199982,404.595184 
	C342.426758,404.220764 342.591614,404.186096 343.219696,404.118530 
	C343.682892,404.085571 344.137054,404.214478 343.983185,404.520996 
	C342.902649,406.910400 341.975983,408.993225 341.049316,411.076080 
	C341.049316,411.076080 340.995361,411.025238 340.780304,411.109558 
	C340.392029,411.486389 340.218811,411.778931 340.045624,412.071472 
	C340.045624,412.071472 340.015045,412.005310 339.776001,412.150482 
	C339.760834,412.945831 339.984741,413.595978 340.208679,414.246155 
	C338.689392,416.805267 337.170074,419.364380 335.530334,422.243530 
	C335.409882,422.563538 335.201569,422.769592 334.840698,423.024109 
	C334.214783,423.811005 333.949707,424.343384 333.684631,424.875793 
z"/>
<path fill="#A39998" opacity="1.000000" stroke="none" 
	d="
M328.390930,427.826050 
	C328.998627,428.064514 329.307281,428.457916 329.942841,429.042999 
	C328.142303,432.452454 326.014832,435.670258 323.464355,438.954407 
	C323.041321,439.020721 322.752197,438.731659 322.752197,438.731659 
	C324.532104,435.148132 326.312012,431.564575 328.390930,427.826050 
z"/>
<path fill="#897B87" opacity="1.000000" stroke="none" 
	d="
M322.848999,441.912354 
	C321.805511,443.948486 320.762054,445.984589 319.718567,448.020721 
	C319.135071,447.765594 318.551575,447.510468 317.968079,447.255341 
	C318.657745,445.547424 319.347412,443.839508 320.725342,442.058472 
	C321.892059,441.961029 322.370514,441.936707 322.848999,441.912354 
z"/>
<path fill="#A39998" opacity="1.000000" stroke="none" 
	d="
M322.938110,441.588135 
	C322.370514,441.936707 321.892059,441.961029 321.050629,441.957092 
	C321.169312,440.950134 321.650970,439.971405 322.442383,438.862183 
	C322.752197,438.731659 323.041321,439.020721 323.200592,439.155762 
	C323.248993,439.948517 323.138123,440.606171 322.938110,441.588135 
z"/>
<path fill="#6D587B" opacity="1.000000" stroke="none" 
	d="
M331.261139,428.974365 
	C331.353516,428.274475 331.777985,427.581543 332.488403,426.744019 
	C332.827271,427.114868 332.880249,427.630371 332.924438,428.531403 
	C332.474854,428.938446 332.034027,428.959900 331.261139,428.974365 
z"/>
<path fill="#6B567C" opacity="1.000000" stroke="none" 
	d="
M358.520142,769.059326 
	C358.916412,769.562561 358.882812,770.075134 358.721375,770.957642 
	C357.932281,770.861511 357.271057,770.395386 356.496765,769.629456 
	C356.952545,769.242676 357.521423,769.155640 358.520142,769.059326 
z"/>
<path fill="#6B567C" opacity="1.000000" stroke="none" 
	d="
M363.028992,776.309021 
	C363.505585,776.292175 364.098755,776.576111 364.753357,777.205688 
	C364.258362,777.237488 363.701935,776.923645 363.028992,776.309021 
z"/>
<path fill="#E0DDDB" opacity="1.000000" stroke="none" 
	d="
M484.773193,807.699341 
	C479.858368,807.048767 474.607635,806.450439 469.551178,805.115967 
	C464.318695,803.735107 459.319824,801.489014 454.124939,799.935120 
	C452.183502,799.354431 449.977203,799.659119 448.200439,799.574768 
	C446.413666,798.195190 444.778809,796.429932 442.760223,795.481201 
	C438.807556,793.623718 434.590210,792.335388 430.596863,790.554749 
	C429.538086,790.082520 428.895966,788.675781 428.042542,787.332764 
	C435.150269,789.983643 442.229736,793.121399 449.421265,795.976929 
	C454.952026,798.173035 460.623871,800.013977 466.614990,802.009155 
	C468.079346,802.380798 469.161713,802.756226 470.599731,803.128662 
	C475.477814,804.559814 480.000214,805.994080 484.773193,807.699341 
z"/>
<path fill="#E0DDDB" opacity="1.000000" stroke="none" 
	d="
M428.037231,786.734375 
	C425.801086,786.262207 423.563690,785.560547 421.334045,784.835144 
	C419.829987,784.345825 418.336884,783.822937 415.945801,783.012207 
	C415.942383,783.003357 415.491821,781.827148 415.035461,780.310791 
	C415.817810,780.199097 416.605927,780.427673 417.708710,780.662231 
	C420.059448,781.698486 422.095459,782.728760 424.532410,784.151001 
	C425.291779,784.696838 425.650177,784.850586 426.008575,785.004395 
	C426.008575,785.004395 426.289307,785.191467 426.624207,785.396301 
	C427.294891,785.741150 427.630737,785.881165 427.966583,786.021179 
	C427.966583,786.021179 428.039307,786.494812 428.037231,786.734375 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M428.127625,785.840820 
	C427.630737,785.881165 427.294891,785.741150 426.791748,785.376831 
	C427.179169,785.321777 427.733917,785.491150 428.127625,785.840820 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M425.867615,784.757690 
	C425.650177,784.850586 425.291779,784.696838 424.838715,784.251465 
	C425.071625,784.143555 425.399139,784.327271 425.867615,784.757690 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M793.261902,348.310272 
	C787.456421,349.256836 787.550415,344.250000 785.938477,340.485291 
	C788.362793,342.737122 790.789978,345.357025 793.261902,348.310272 
z"/>
<path fill="#897B87" opacity="1.000000" stroke="none" 
	d="
M803.215454,768.920166 
	C804.295349,766.917419 805.725220,764.903320 807.480469,762.794434 
	C806.392334,764.769287 804.978882,766.839111 803.215454,768.920166 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M325.981934,734.662720 
	C323.726624,732.107117 321.448700,729.200867 319.264221,725.919067 
	C321.558228,728.466370 323.758759,731.389221 325.981934,734.662720 
z"/>
<path fill="#786A7E" opacity="1.000000" stroke="none" 
	d="
M376.875000,366.024872 
	C376.990601,366.183441 376.929932,366.375641 376.621704,366.742340 
	C376.482330,366.630737 376.590546,366.344635 376.875000,366.024872 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M648.969604,795.909912 
	C649.445679,797.364868 649.921814,798.819885 649.979858,798.997253 
	C648.365234,801.928589 647.183105,804.074707 645.539673,806.610413 
	C641.157593,806.751770 637.238342,806.405029 633.314575,806.340637 
	C632.553162,806.328125 631.773621,807.416809 631.001221,807.998779 
	C631.000000,808.000000 630.999512,808.002441 630.650879,807.968750 
	C629.357727,807.751892 628.238586,807.148987 627.498535,807.456909 
	C623.998474,808.913452 620.680481,810.822083 617.149170,812.185303 
	C612.505371,813.978271 607.722900,815.411865 603.000000,817.000000 
	C601.196960,816.215576 599.459839,815.115356 597.575195,814.721863 
	C594.831055,814.148987 591.980774,814.085022 589.391052,813.357666 
	C590.094910,812.333984 590.583435,811.756714 591.071960,811.179443 
	C591.491028,811.098206 591.910095,811.016968 592.903809,811.217224 
	C597.455872,810.854919 602.055237,812.634766 605.138855,808.507019 
	C605.543884,808.364990 605.948853,808.222961 606.913086,808.340210 
	C608.270813,808.576294 609.127930,808.736267 609.859192,808.502563 
	C622.905090,804.333496 635.935730,800.116516 648.969604,795.909912 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M649.184814,795.663208 
	C635.935730,800.116516 622.905090,804.333496 609.859192,808.502563 
	C609.127930,808.736267 608.270813,808.576294 607.236572,808.330688 
	C614.851929,805.593933 622.703003,803.126160 630.810669,800.407227 
	C632.151062,799.808289 633.234802,799.460449 634.633057,798.973267 
	C638.270508,797.647095 641.593323,796.460144 645.118286,795.020142 
	C645.320496,794.767090 645.719360,794.622986 645.900208,794.513794 
	C647.798157,793.912964 649.515198,793.421204 651.617554,792.952148 
	C651.135193,793.788818 650.267578,794.602722 649.184814,795.663208 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M584.618652,812.195435 
	C584.429749,812.709351 583.819763,813.105530 582.628113,813.722534 
	C580.781555,814.194946 579.514465,814.666870 578.252136,814.654724 
	C575.141846,814.625000 572.033752,814.372925 569.186401,814.045044 
	C569.983398,813.607544 570.518860,813.335205 571.054260,813.062866 
	C571.484985,813.063110 571.915771,813.063354 572.650757,812.950073 
	C576.702454,812.583557 580.450012,812.330627 584.618652,812.195435 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M604.750244,808.393005 
	C602.055237,812.634766 597.455872,810.854919 593.225464,811.216675 
	C596.768982,810.049438 600.565308,809.164185 604.750244,808.393005 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M590.708740,811.079590 
	C590.583435,811.756714 590.094910,812.333984 589.302002,812.972290 
	C588.442322,812.893738 587.887085,812.754211 587.170654,812.374146 
	C588.121399,811.748901 589.233459,811.364319 590.708740,811.079590 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M565.940735,815.860229 
	C565.204346,815.886292 564.468018,815.912354 562.909729,815.944336 
	C553.724121,815.979248 545.360352,816.008240 536.996643,816.037109 
	C535.608337,816.013000 534.220032,815.988892 531.987976,815.959595 
	C528.095154,815.658508 525.046021,815.362610 521.996948,815.066650 
	C520.282349,815.030701 518.567688,814.994629 516.424072,814.956543 
	C513.378723,814.296265 510.762360,813.638184 508.378113,812.504761 
	C509.090027,811.938354 509.569824,811.847351 510.049591,811.756348 
	C510.472382,811.852844 510.895142,811.949341 511.640686,811.992676 
	C514.056824,812.296143 516.150208,812.652832 518.647095,812.924072 
	C519.471985,812.914673 519.893311,812.990723 520.636597,813.040588 
	C523.376038,813.293335 525.793457,813.572266 528.633057,813.752014 
	C531.730957,813.803284 534.406799,813.953735 537.989258,814.150757 
	C543.607788,814.138672 548.319763,814.080139 553.031738,814.021606 
	C556.042114,813.989075 559.052429,813.956604 562.895203,813.936890 
	C564.519897,814.070007 565.312195,814.190430 566.090637,814.571167 
	C566.031433,815.174438 565.986084,815.517334 565.940735,815.860229 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M566.104492,814.310913 
	C565.312195,814.190430 564.519897,814.070007 563.336914,813.845093 
	C565.471863,813.317261 567.997620,812.893982 570.788818,812.766785 
	C570.518860,813.335205 569.983398,813.607544 568.833862,813.994629 
	C567.514709,814.176575 566.809570,814.243774 566.104492,814.310913 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M552.737915,813.722656 
	C548.319763,814.080139 543.607788,814.138672 538.434753,814.100220 
	C542.797180,813.809998 547.620605,813.616882 552.737915,813.722656 
z"/>
<path fill="#C3BCBA" opacity="1.000000" stroke="none" 
	d="
M345.140747,725.236816 
	C345.810486,725.617249 346.459808,726.321350 347.139160,727.341431 
	C346.486237,726.958435 345.803253,726.259460 345.140747,725.236816 
z"/>
<path fill="#C3BCBA" opacity="1.000000" stroke="none" 
	d="
M358.002563,741.330994 
	C358.656433,741.765747 359.346893,742.516846 360.026672,743.622681 
	C359.357025,743.200684 358.698059,742.424072 358.002563,741.330994 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M318.985474,682.998596 
	C318.827362,681.869934 318.669250,680.741333 318.511169,679.612671 
	C318.975647,679.861938 319.440125,680.111206 319.696350,680.833008 
	C319.320557,681.869873 319.153015,682.434204 318.985474,682.998596 
z"/>
<path fill="#A39998" opacity="1.000000" stroke="none" 
	d="
M319.214600,683.038208 
	C319.153015,682.434204 319.320557,681.869873 319.741119,681.142639 
	C320.040802,681.403198 320.087433,681.826538 320.073608,682.621582 
	C320.013153,682.993225 319.907990,683.104309 319.907990,683.104309 
	C319.907990,683.104309 319.443695,683.077759 319.214600,683.038208 
z"/>
<path fill="#A39998" opacity="1.000000" stroke="none" 
	d="
M320.069000,682.940979 
	C320.301941,683.060669 320.479034,683.232666 320.656097,683.404663 
	C320.461151,683.423340 320.266174,683.442078 319.989624,683.282593 
	C319.907990,683.104309 320.013153,682.993225 320.069000,682.940979 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M739.576782,397.807556 
	C740.514587,396.862518 741.584412,395.581116 742.417236,395.720123 
	C744.914673,396.136902 749.310547,396.908752 749.409851,397.898773 
	C749.803772,401.824524 753.556519,405.486511 750.570557,410.089478 
	C747.629639,407.578400 744.882141,404.673553 741.854980,401.595245 
	C740.916992,400.321381 740.258667,399.220947 739.576782,397.807556 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M341.389862,411.249786 
	C341.975983,408.993225 342.902649,406.910400 344.091919,404.366821 
	C344.534363,403.719421 344.649719,403.499054 344.895203,402.986572 
	C345.147614,402.520508 345.205475,402.312805 345.263336,402.105072 
	C345.851929,401.627869 346.440521,401.150696 347.532410,400.928619 
	C348.484070,401.570923 348.932404,401.958130 349.380737,402.345337 
	C346.830627,405.371399 344.280518,408.397430 341.389862,411.249786 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M349.541077,402.183655 
	C348.932404,401.958130 348.484070,401.570923 347.793701,400.805481 
	C347.596161,400.137482 347.729584,399.897186 348.135223,399.609131 
	C348.812225,399.871094 349.306030,400.230255 350.058289,400.970703 
	C350.111877,401.575592 349.906799,401.798920 349.541077,402.183655 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M340.398193,413.864929 
	C339.984741,413.595978 339.760834,412.945831 339.811401,412.159088 
	C340.253143,412.509583 340.420441,412.996643 340.398193,413.864929 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M340.408081,412.208984 
	C340.218811,411.778931 340.392029,411.486389 340.816742,411.116272 
	C340.969025,411.474640 340.869781,411.910553 340.408081,412.208984 
z"/>
<path fill="#683BB6" opacity="1.000000" stroke="none" 
	d="
M344.700592,403.244904 
	C344.649719,403.499054 344.534363,403.719421 344.245789,404.060272 
	C344.137054,404.214478 343.682892,404.085571 343.456299,404.029785 
	C343.204773,403.705383 343.293030,403.481323 343.870850,403.224548 
	C344.247192,403.147278 344.700592,403.244904 344.700592,403.244904 
z"/>
<path fill="#786A7E" opacity="1.000000" stroke="none" 
	d="
M344.895203,402.986572 
	C344.700592,403.244904 344.247192,403.147278 344.016479,403.106079 
	C344.078979,402.785889 344.372162,402.506897 344.964325,402.166504 
	C345.205475,402.312805 345.147614,402.520508 344.895203,402.986572 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M494.732605,809.849487 
	C493.037445,809.964050 491.102356,809.777283 489.123016,809.221924 
	C490.883423,809.084900 492.688049,809.316528 494.732605,809.849487 
z"/>
<path fill="#6B567C" opacity="1.000000" stroke="none" 
	d="
M315.155396,806.808838 
	C315.754272,806.718994 316.296356,807.051147 316.969055,807.677368 
	C316.594360,808.225159 316.089020,808.479004 315.319916,808.828857 
	C315.070312,808.360229 315.084473,807.795471 315.155396,806.808838 
z"/>
<path fill="#D8D4D2" opacity="1.000000" stroke="none" 
	d="
M505.033783,811.893188 
	C502.389526,811.607239 499.745300,811.321289 496.978455,810.707764 
	C497.328339,810.273193 497.800751,810.166077 498.935669,810.176453 
	C500.751129,810.390259 501.904144,810.486572 503.057129,810.582886 
	C503.492615,810.677429 503.928101,810.772034 504.714661,811.123352 
	C505.055115,811.551086 505.044434,811.722107 505.033783,811.893188 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M505.400024,811.908264 
	C505.044434,811.722107 505.055115,811.551086 505.051453,811.123169 
	C506.647949,810.944336 508.258698,811.022339 509.959534,811.428345 
	C509.569824,811.847351 509.090027,811.938354 508.266235,812.158752 
	C507.203552,812.166504 506.484924,812.044922 505.400024,811.908264 
z"/>
<path fill="#6C5779" opacity="1.000000" stroke="none" 
	d="
M502.954346,810.284058 
	C501.904144,810.486572 500.751129,810.390259 499.293640,810.107178 
	C500.276611,809.942078 501.564087,809.963684 502.954346,810.284058 
z"/>
<path fill="#6A557B" opacity="1.000000" stroke="none" 
	d="
M513.213684,271.998901 
	C512.840637,271.996704 512.969055,271.527985 512.999084,271.286804 
	C513.455750,271.022766 513.882446,270.999908 514.612549,270.871155 
	C515.365234,270.849976 515.814392,270.934692 516.602783,271.080261 
	C515.823547,271.427734 514.705139,271.714417 513.213684,271.998901 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M370.303406,784.128784 
	C370.034546,784.291748 369.712433,784.247375 369.438171,783.918884 
	C369.803741,783.562683 370.058441,783.658203 370.303406,784.128784 
z"/>
<path fill="#6D587B" opacity="1.000000" stroke="none" 
	d="
M333.990784,424.781067 
	C333.949707,424.343384 334.214783,423.811005 334.745636,423.135559 
	C334.773224,423.557129 334.535065,424.121735 333.990784,424.781067 
z"/>
<path fill="#F5E0CE" opacity="1.000000" stroke="none" 
	d="
M304.336060,262.398804 
	C303.208405,262.229828 301.827393,262.055237 301.649139,261.496765 
	C299.594757,255.060089 297.722717,248.565201 295.802216,242.085800 
	C296.139832,241.786362 296.477478,241.486923 296.815094,241.187485 
	C302.981445,244.977539 309.147797,248.767593 316.240814,253.127228 
	C311.828125,256.584381 308.234772,259.399628 304.336060,262.398804 
M306.397064,250.133362 
	C304.509460,249.042114 302.621826,247.950851 299.721893,246.274353 
	C301.282928,251.327179 302.452576,255.113174 303.807526,259.498993 
	C306.701752,257.273102 308.962433,255.534424 311.636566,253.477814 
	C309.732910,252.237122 308.386322,251.359497 306.397064,250.133362 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M484.073608,241.375381 
	C490.439911,240.012268 496.872894,238.978943 503.319763,238.041489 
	C506.242126,237.616562 509.192108,237.381943 512.571655,237.102112 
	C513.167908,241.087616 509.751221,241.037109 507.458771,241.550049 
	C504.772125,242.151230 501.867035,241.731781 499.061218,241.856445 
	C495.706757,242.005524 492.358887,242.369339 489.004578,242.425385 
	C487.389252,242.452377 485.765747,241.986801 484.073608,241.375381 
z"/>
<path fill="#F6F6F6" opacity="1.000000" stroke="none" 
	d="
M456.198639,247.140045 
	C458.307770,246.249237 460.699524,245.595154 463.475281,244.928223 
	C464.093048,245.144714 464.547058,245.395294 464.527252,245.600128 
	C464.262207,248.343994 464.541290,253.159058 463.510773,253.461761 
	C458.989075,254.790070 459.150665,249.202362 456.198639,247.140045 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M561.003540,236.395569 
	C563.398376,236.335281 565.794373,236.683487 568.597412,237.071014 
	C567.030212,238.058121 565.105652,239.172318 563.033081,239.790924 
	C562.685059,239.894821 561.696960,237.853928 561.003540,236.395569 
z"/>
<path fill="#F1F0EF" opacity="1.000000" stroke="none" 
	d="
M291.896484,447.297363 
	C290.965729,447.537689 290.348145,447.531982 289.432739,447.435059 
	C288.176025,442.711334 291.148773,442.784088 294.725433,443.090698 
	C294.184418,444.441101 293.197021,445.746246 291.896484,447.297363 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M606.600037,261.000702 
	C606.160828,263.770538 604.809448,263.674408 603.060669,261.347839 
	C604.066711,261.000854 605.133362,261.000519 606.600037,261.000702 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M609.030029,243.277039 
	C609.468994,242.923660 609.901001,242.924667 610.657227,242.921906 
	C610.620483,243.777786 610.259521,244.637436 609.898560,245.497070 
	C609.606750,244.875183 609.314941,244.253311 609.030029,243.277039 
z"/>
<path fill="#F6F6F6" opacity="1.000000" stroke="none" 
	d="
M368.715332,286.034668 
	C368.697449,286.510529 368.394897,287.021057 368.092346,287.531616 
	C367.882355,287.349609 367.672363,287.167603 367.462402,286.985565 
	C367.785156,286.680176 368.107910,286.374756 368.715332,286.034668 
z"/>
<path fill="#D7D3D0" opacity="1.000000" stroke="none" 
	d="
M761.471375,321.526428 
	C761.449463,321.485443 761.493286,321.567413 761.471375,321.526428 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M628.000000,253.000000 
	C628.466003,253.683014 629.188904,254.311340 629.348206,255.059647 
	C629.768555,257.034363 629.944458,259.061157 630.217651,261.067200 
	C628.010986,261.214630 625.804321,261.362030 623.786682,261.496826 
	C624.703003,258.166748 625.317566,255.933395 626.299316,253.350311 
	C627.111084,253.000397 627.555542,253.000198 628.000000,253.000000 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M628.003662,252.641678 
	C627.555542,253.000198 627.111084,253.000397 626.333313,253.000610 
	C625.667908,252.225876 625.335754,251.451141 625.356750,250.275360 
	C626.139038,248.610779 626.568237,247.347260 626.997498,246.083740 
	C628.205444,246.535400 629.413391,246.987045 630.828674,247.682068 
	C630.026489,249.378067 629.016907,250.830704 628.003662,252.641678 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M591.956299,262.734436 
	C590.600952,263.409119 589.174683,263.706482 587.376587,263.982269 
	C587.468811,259.886932 589.335632,260.082947 591.956299,262.734436 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M665.026917,275.790680 
	C663.473267,275.960938 661.900024,275.752472 660.097290,275.309021 
	C661.524414,276.047791 663.653931,269.827850 665.026917,275.790680 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M292.992554,356.762756 
	C292.181946,357.964966 291.382690,358.782806 290.583435,359.600647 
	C290.180176,358.501434 289.454437,357.400909 289.459045,356.303406 
	C289.464294,355.061646 290.124969,353.822632 291.563965,352.214020 
	C292.043945,353.602112 292.523895,354.990234 292.992554,356.762756 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M315.141296,323.574890 
	C315.643250,323.177582 316.020111,323.084076 316.687012,322.952698 
	C316.199585,324.265961 315.422150,325.617096 314.241211,326.959167 
	C314.230560,325.926239 314.623383,324.902466 315.141296,323.574890 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M373.350403,285.965271 
	C373.805176,284.944061 374.610352,283.888123 375.415527,282.832184 
	C375.843781,283.289520 376.272034,283.746887 376.700287,284.204254 
	C375.700470,284.779663 374.700653,285.355103 373.350403,285.965271 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M626.665894,246.046204 
	C626.568237,247.347260 626.139038,248.610779 625.354919,249.937149 
	C625.000000,248.928482 625.000000,247.856979 625.000000,246.382965 
	C625.444763,245.989883 625.889526,245.999283 626.665894,246.046204 
z"/>
<path fill="#E0DDDB" opacity="1.000000" stroke="none" 
	d="
M458.799408,803.226318 
	C459.037598,803.768127 458.985016,804.251099 458.932434,804.734131 
	C458.494781,804.624817 458.057098,804.515442 457.619446,804.406128 
	C457.915833,803.993225 458.212250,803.580322 458.799408,803.226318 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M631.002441,807.997559 
	C631.773621,807.416809 632.553162,806.328125 633.314575,806.340637 
	C637.238342,806.405029 641.157593,806.751770 645.539185,807.000000 
	C649.644958,807.714294 653.326660,808.281311 656.925354,809.180786 
	C661.311218,810.277100 660.059509,813.348938 658.724976,816.652710 
	C657.905640,817.721863 657.452820,818.360962 657.000000,819.000000 
	C654.519348,818.859863 652.453613,819.080444 651.697205,822.188965 
	C651.428284,823.294250 649.773987,824.062317 648.376038,824.992188 
	C645.955017,824.017456 643.924744,822.240906 641.862244,822.202698 
	C635.919678,822.092590 629.954773,822.569702 624.017273,823.046997 
	C620.932861,823.294922 617.897461,824.372925 614.822388,824.460327 
	C610.767517,824.575378 606.695496,824.085632 601.949646,823.817444 
	C602.230835,821.480164 602.456421,819.605286 602.840942,817.365234 
	C607.722900,815.411865 612.505371,813.978271 617.149170,812.185303 
	C620.680481,810.822083 623.998474,808.913452 627.498535,807.456909 
	C628.238586,807.148987 629.357727,807.751892 630.773438,808.174438 
	C631.595154,808.398376 631.945618,808.382996 632.296021,808.367554 
	C631.864807,808.244263 631.433655,808.120911 631.002441,807.997559 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M566.090637,814.571167 
	C566.809570,814.243774 567.514709,814.176575 568.572266,814.159790 
	C572.033752,814.372925 575.141846,814.625000 578.252136,814.654724 
	C579.514465,814.666870 580.781555,814.194946 582.454468,813.940186 
	C585.523987,816.048157 586.767639,820.366455 591.445435,819.464172 
	C591.604370,819.433472 592.066833,820.976257 592.387817,821.785645 
	C591.025696,822.154358 589.620361,822.946838 588.309631,822.812988 
	C584.242554,822.397644 580.216736,821.592712 576.162903,821.024109 
	C575.018799,820.863586 573.623535,821.395081 572.708618,820.914307 
	C570.378479,819.689758 568.254639,818.072998 565.994507,816.236694 
	C565.986084,815.517334 566.031433,815.174438 566.090637,814.571167 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M537.003906,816.424683 
	C545.360352,816.008240 553.724121,815.979248 562.541504,815.957581 
	C562.536255,822.099976 558.987244,823.963928 553.275818,822.032104 
	C549.974854,820.915588 546.172485,821.146057 542.588074,821.044739 
	C539.584717,820.959778 537.357361,820.223816 537.003906,816.424683 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M621.776978,833.932922 
	C615.709900,835.086609 609.301514,836.078918 602.453247,837.069946 
	C602.008972,836.300903 602.004456,835.533264 602.000000,834.382812 
	C605.946838,832.667664 609.831665,830.621765 613.863770,830.271545 
	C616.268250,830.062683 618.903931,832.515320 621.776978,833.932922 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M522.047607,815.430664 
	C525.046021,815.362610 528.095154,815.658508 531.571350,815.968140 
	C529.925537,819.848999 526.000305,819.774780 522.047607,815.430664 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M555.999390,837.672729 
	C555.247864,838.008789 554.497253,838.010254 553.372437,837.980896 
	C553.764709,837.303162 554.531067,836.656372 555.648682,836.004761 
	C556.000061,836.446045 556.000122,836.892090 555.999390,837.672729 
z"/>
<path fill="#F6F6F6" opacity="1.000000" stroke="none" 
	d="
M247.245117,715.037048 
	C243.616821,708.611206 240.336914,702.168457 237.022232,695.364746 
	C241.765915,695.957642 245.810242,697.337952 245.174088,703.711487 
	C245.013947,705.315796 247.188095,707.048462 247.895706,708.874756 
	C248.539688,710.536865 248.648911,712.406189 248.989594,714.596924 
	C248.521408,715.013245 248.057480,715.016724 247.245117,715.037048 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M280.044067,681.431641 
	C283.250061,681.038086 286.610565,680.529419 289.721527,681.192139 
	C291.704712,681.614624 293.318207,683.772644 295.143341,685.525391 
	C291.078156,685.580383 286.936218,685.456665 282.876892,684.812622 
	C281.816956,684.644409 281.013947,682.856628 280.044067,681.431641 
z"/>
<path fill="#F1F0EF" opacity="1.000000" stroke="none" 
	d="
M333.818787,814.089783 
	C333.351074,812.568420 333.140198,811.315063 332.662262,808.474609 
	C335.774536,812.340881 339.958282,806.225220 342.308197,812.271240 
	C339.337524,813.024170 336.706543,813.690979 333.818787,814.089783 
z"/>
<path fill="#F1F0EF" opacity="1.000000" stroke="none" 
	d="
M646.849976,874.705933 
	C645.565247,875.656067 644.127319,876.292908 642.340210,876.955444 
	C641.402954,875.510803 640.814880,874.040405 640.203796,872.512512 
	C643.678772,869.174438 645.018616,872.349426 646.849976,874.705933 
z"/>
<path fill="#F1F0EF" opacity="1.000000" stroke="none" 
	d="
M640.045410,861.397766 
	C640.285767,861.791748 640.283936,862.183594 640.253723,862.573303 
	C640.252869,862.584290 639.949036,862.571777 639.786499,862.570190 
	C639.790771,862.178589 639.795044,861.786987 640.045410,861.397766 
z"/>
<path fill="#FDFDFD" opacity="1.000000" stroke="none" 
	d="
M620.294678,883.059143 
	C620.604431,882.822083 621.258362,882.572510 621.959656,882.614685 
	C621.550964,882.953186 621.094910,882.999878 620.294678,883.059143 
z"/>
<path fill="#F9FAF9" opacity="1.000000" stroke="none" 
	d="
M604.944824,291.183563 
	C612.464172,291.845764 619.828430,292.845398 627.596313,293.925171 
	C626.862976,296.100311 626.026611,298.449158 624.520752,300.232788 
	C622.059570,303.148010 619.634338,301.734863 617.314087,299.586700 
	C615.099792,297.536621 612.688477,295.699310 609.776550,293.280792 
	C609.189209,295.778320 608.867188,297.147552 608.360657,299.301239 
	C605.896790,298.454315 603.691345,297.696228 601.362915,296.895874 
	C602.673645,294.840027 603.731689,293.180511 604.944824,291.183563 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M582.534912,309.880188 
	C580.861450,309.553955 579.548096,309.153839 577.225708,308.446289 
	C582.535034,306.531311 582.699829,306.599152 582.534912,309.880188 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M514.209717,305.809479 
	C514.018372,304.871216 514.167969,304.031708 514.317566,303.192169 
	C515.065430,303.655029 515.813293,304.117889 516.561157,304.580750 
	C515.890991,305.023224 515.220825,305.465729 514.209717,305.809479 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M507.239258,309.545258 
	C506.457123,308.870178 505.973328,308.312042 505.489502,307.753906 
	C506.197510,307.641266 506.905548,307.528656 507.613586,307.416016 
	C507.588257,308.164764 507.562927,308.913452 507.239258,309.545258 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M563.060669,289.342590 
	C564.064758,288.966370 565.129456,288.939117 566.593628,288.916016 
	C566.219543,291.772766 564.862976,291.698608 563.060669,289.342590 
z"/>
<path fill="#F9F9F9" opacity="1.000000" stroke="none" 
	d="
M579.059448,289.286621 
	C580.070740,288.935333 581.135803,288.938782 582.599792,288.960938 
	C582.144897,291.791595 580.798706,291.712708 579.059448,289.286621 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M472.805481,313.347748 
	C472.541290,312.832916 472.537018,312.452332 472.532715,312.071777 
	C473.187561,312.359467 473.842407,312.647156 474.497223,312.934875 
	C474.019958,313.117279 473.542664,313.299652 472.805481,313.347748 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M774.206299,434.797699 
	C774.569397,434.196228 774.995239,433.893768 775.421021,433.591309 
	C775.535889,434.038269 775.650696,434.485199 775.765503,434.932129 
	C775.266724,434.987000 774.767883,435.041840 774.206299,434.797699 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M464.525818,319.693298 
	C464.531647,320.449677 464.326569,320.986237 464.121490,321.522827 
	C463.846985,321.387665 463.572479,321.252502 463.297943,321.117340 
	C463.636932,320.569397 463.975952,320.021484 464.525818,319.693298 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M615.395020,316.954956 
	C615.790649,316.713684 616.184387,316.715454 616.575867,316.745758 
	C616.586914,316.746613 616.574341,317.051666 616.572815,317.214874 
	C616.179504,317.210571 615.786133,317.206299 615.395020,316.954956 
z"/>
<path fill="#DAD7D5" opacity="1.000000" stroke="none" 
	d="
M769.995850,368.994049 
	C769.917908,369.032440 769.844177,369.076782 769.891174,369.064728 
	C770.011963,369.008301 770.000000,369.000000 769.995850,368.994049 
z"/>
<path fill="#F4F3F2" opacity="1.000000" stroke="none" 
	d="
M496.531799,313.550476 
	C496.475708,313.583832 496.587891,313.517151 496.531799,313.550476 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M653.200134,793.509094 
	C657.087524,791.810913 661.135620,790.367126 665.566589,788.888306 
	C665.637817,789.966187 665.071594,791.076904 665.062195,792.192322 
	C665.033447,795.598938 665.337891,798.745361 660.008789,797.236938 
	C658.399780,796.781433 656.182007,798.476746 654.282104,799.172546 
	C653.922913,797.063354 653.641907,795.413452 653.200134,793.509094 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M657.357178,818.999390 
	C657.452820,818.360962 657.905640,817.721863 658.679199,817.041382 
	C659.516846,817.302979 660.033630,817.605957 660.550476,817.908936 
	C659.605103,818.272217 658.659729,818.635498 657.357178,818.999390 
z"/>
<path fill="#FE9803" opacity="1.000000" stroke="none" 
	d="
M772.990356,202.006409 
	C772.854919,201.566086 772.731812,201.116440 772.608704,200.666794 
	C772.739685,200.940735 772.870728,201.214676 773.013550,201.731781 
	C773.025269,201.974945 773.002686,201.997086 772.990356,202.006409 
z"/>
<path fill="#FE9803" opacity="1.000000" stroke="none" 
	d="
M771.371826,197.772095 
	C771.179138,197.923233 770.890564,198.026077 770.601990,198.128937 
	C770.698608,198.035263 770.795288,197.941589 771.033936,197.690735 
	C771.176025,197.533585 771.275879,197.723801 771.371826,197.772095 
z"/>
<path fill="#F5E0CE" opacity="1.000000" stroke="none" 
	d="
M533.282715,170.674957 
	C533.949707,172.381958 534.437500,173.753326 535.345276,176.305420 
	C529.479492,176.565536 524.343933,176.793289 517.949829,177.076843 
	C520.496765,169.138931 522.718384,162.215057 524.940002,155.291199 
	C525.355591,155.246292 525.771240,155.201385 526.186829,155.156494 
	C528.492371,160.217438 530.797913,165.278366 533.282715,170.674957 
M529.703918,168.985550 
	C528.592346,166.732803 527.480774,164.480057 525.836975,161.148727 
	C524.308411,166.102737 523.180603,169.757935 521.947144,173.755432 
	C525.264526,173.755432 527.984131,173.755432 531.077087,173.755432 
	C530.661682,172.163300 530.341064,170.934494 529.703918,168.985550 
z"/>
<path fill="#B098CE" opacity="1.000000" stroke="none" 
	d="
M662.461182,193.789886 
	C658.354980,192.423447 654.652405,191.155701 649.912659,189.532837 
	C654.523010,183.605972 658.592346,178.374588 662.661682,173.143204 
	C663.245056,173.268478 663.828430,173.393753 664.411865,173.519012 
	C664.609985,179.815323 664.856445,186.111191 664.911865,192.408737 
	C664.916199,192.895966 663.578613,193.395004 662.461182,193.789886 
z"/>
<path fill="#F5E0CE" opacity="1.000000" stroke="none" 
	d="
M857.026855,327.294556 
	C859.176697,335.215057 856.210815,341.064789 848.875488,344.028137 
	C842.881714,346.449463 836.262756,343.706909 833.067688,337.209381 
	C832.594910,336.248016 832.873169,334.096619 833.615723,333.490753 
	C838.642029,329.389954 843.608032,325.091644 849.143372,321.774902 
	C854.130676,318.786530 854.789917,324.401123 857.026855,327.294556 
M850.536682,340.327454 
	C857.448914,334.509674 857.649719,329.673004 850.872192,323.874176 
	C845.766907,327.765350 840.592102,331.709503 835.465393,335.616974 
	C840.067627,342.615234 843.443237,343.700409 850.536682,340.327454 
z"/>
<path fill="#F5E0CE" opacity="1.000000" stroke="none" 
	d="
M880.071045,312.593079 
	C876.026123,322.524139 866.980591,325.546356 860.438904,319.579681 
	C857.879150,317.244904 857.093201,315.259155 860.566101,312.920654 
	C864.534180,310.248688 868.529907,307.513397 872.032593,304.283844 
	C875.609009,300.986267 877.530884,302.293671 878.901245,305.964355 
	C879.631470,307.920380 879.743835,310.107086 880.071045,312.593079 
M862.993225,314.709503 
	C860.457031,318.649292 864.137878,319.304993 866.218445,319.852020 
	C870.898499,321.082489 874.076172,318.212433 876.619690,314.733246 
	C879.089722,311.354645 877.858154,308.394379 875.392212,305.442200 
	C871.256592,308.527496 867.419739,311.389893 862.993225,314.709503 
z"/>
<path fill="#F4DFD7" opacity="1.000000" stroke="none" 
	d="
M449.896667,152.313629 
	C458.060120,152.809509 463.370422,157.038742 466.725708,163.686310 
	C472.126709,174.386749 474.836121,185.530060 470.246948,197.278488 
	C468.071503,202.847702 463.494934,205.877136 457.829437,207.026260 
	C456.639618,207.267563 454.124146,206.062622 453.876038,205.098022 
	C449.773254,189.146881 445.657837,173.187408 442.187225,157.091385 
	C440.955444,151.378494 446.631348,153.332428 449.896667,152.313629 
M452.304596,188.194504 
	C453.673248,193.648483 455.041931,199.102448 456.443207,204.686432 
	C462.380859,203.774857 466.242737,200.607086 468.479950,195.308395 
	C472.854523,184.947556 467.786438,165.067886 458.819000,158.240982 
	C454.704620,155.108719 450.116028,153.726990 444.667816,156.272583 
	C447.191132,166.801132 449.658142,177.094803 452.304596,188.194504 
z"/>
<path fill="#6A45B4" opacity="1.000000" stroke="none" 
	d="
M306.718384,250.307617 
	C308.386322,251.359497 309.732910,252.237122 311.636566,253.477814 
	C308.962433,255.534424 306.701752,257.273102 303.807526,259.498993 
	C302.452576,255.113174 301.282928,251.327179 299.721893,246.274353 
	C302.621826,247.950851 304.509460,249.042114 306.718384,250.307617 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M631.001221,807.998779 
	C631.433655,808.120911 631.864807,808.244263 632.296021,808.367554 
	C631.945618,808.382996 631.595154,808.398376 631.122131,808.208130 
	C630.999512,808.002441 631.000000,808.000000 631.001221,807.998779 
z"/>
<path fill="#6A45B4" opacity="1.000000" stroke="none" 
	d="
M529.862183,169.345612 
	C530.341064,170.934494 530.661682,172.163300 531.077087,173.755432 
	C527.984131,173.755432 525.264526,173.755432 521.947144,173.755432 
	C523.180603,169.757935 524.308411,166.102737 525.836975,161.148727 
	C527.480774,164.480057 528.592346,166.732803 529.862183,169.345612 
z"/>
<path fill="#6A45B4" opacity="1.000000" stroke="none" 
	d="
M850.206055,340.533447 
	C843.443237,343.700409 840.067627,342.615234 835.465393,335.616974 
	C840.592102,331.709503 845.766907,327.765350 850.872192,323.874176 
	C857.649719,329.673004 857.448914,334.509674 850.206055,340.533447 
z"/>
<path fill="#6A45B4" opacity="1.000000" stroke="none" 
	d="
M863.288086,314.480896 
	C867.419739,311.389893 871.256592,308.527496 875.392212,305.442200 
	C877.858154,308.394379 879.089722,311.354645 876.619690,314.733246 
	C874.076172,318.212433 870.898499,321.082489 866.218445,319.852020 
	C864.137878,319.304993 860.457031,318.649292 863.288086,314.480896 
z"/>
<path fill="#6840B5" opacity="1.000000" stroke="none" 
	d="
M452.214874,187.791504 
	C449.658142,177.094803 447.191132,166.801132 444.667816,156.272583 
	C450.116028,153.726990 454.704620,155.108719 458.819000,158.240982 
	C467.786438,165.067886 472.854523,184.947556 468.479950,195.308395 
	C466.242737,200.607086 462.380859,203.774857 456.443207,204.686432 
	C455.041931,199.102448 453.673248,193.648483 452.214874,187.791504 
z"/>





</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
